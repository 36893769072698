import React, { useEffect, useState } from "react";
import UserFiltering from "../../shared/UserFiltering";
import "./UserTab.css"; // Import a CSS file for styling
import AddIcon from "@mui/icons-material/Add";
import editIcon from "../../asset/editIcon.png";
import deleteIcon from "../../asset/deleteIcon.png";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  ThemeProvider,
  Pagination,
  createMuiTheme,
  CircularProgress,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router";
import { userManagementService } from "../../service/userManagementService";
import { Layout } from "../../Layout";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { useEffectOnce } from "react-use";
import { MasterData, UserPermission, WebUser } from "../../type";
import { DeleteIcon, EditIcon } from "../../icon";
import { useSelector } from "react-redux";
import { DeleteDialog } from "../../components/dialog/DeleteDialog";
import { set } from "date-fns";

const theme = createMuiTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          "&.Mui-selected": {
            backgroundColor: "#DF5C8E",
            color: "#ffffff",
          },
        },
      },
    },
  },
});

const UserTab = () => {
  const navigate = useNavigate();
  const tableCellValues = [
    "ชื่อผู้ใช้งาน",
    "ชื่อ-สกุล",
    "สังกัด",
    "สิทธิ์การใช้งาน",
    "สถานะ",
    "เครื่องมือ",
  ];

  const [userList, setUserList] = useState<WebUser[]>([]);
  const [originalUserList, setOriginalUserList] = useState<WebUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUsers, setCurrentUsers] = useState<WebUser[]>([]);
  const usersPerPage = 10;
  const [userPermissionList, setUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [affiliateHospitalList, setAffiliateHospitalList] = useState<
    MasterData[]
  >([]);
  const [isShowConfrimationDialog, setIsShowConfrimationDialog] =
    useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const totalPages = Math.ceil(userList.length / 10);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const userDetail = useSelector((state: any) => state.loginUser.userDetail);
  const userPermission = useSelector(
    (state: any) => state.loginUser.userPermission
  );
  const userId = useSelector((state: any) => state.loginUser.userId);
  const permissionEditAble =
    userId === "1" ? true : userPermission?.userManagePermission.editAble;
  const permissionDeleteAble =
    userId === "1" ? true : userPermission?.userManagePermission?.deleteAble;
  const permissionAddAble = userId === "1" ? true : userPermission?.userManagePermission.addAble;

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const userList = await userManagementService.getUserList();
        setUserList(userList);
        setOriginalUserList(userList);
        const userPermissionList =
          await userManagementService.getUserPermission();
        setUserPermissionList(userPermissionList);
        const affiliatedHospitalList =
          await userManagementService.getAffiliatedHospital();
        setAffiliateHospitalList(affiliatedHospitalList);
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    setIsLoading(true);
    fetchData().then(() => setIsLoading(false));
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCurrentUsers(
          userList.slice(
            (currentPage - 1) * usersPerPage,
            currentPage * usersPerPage
          )
        );
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData().then(() => setIsLoading(false));
  }, [currentPage, userList]);

  const handleSearch = (data: {
    name: string;
    userPermission: string;
    affiliatedHospital: string;
    status: string;
  }) => {
    const { name, userPermission, affiliatedHospital, status } = data;
    const filteredUsers = originalUserList.filter((user) => {
      const isNameMatch =
        name === ""
          ? true
          : user.firstName.includes(name) ||
            user.lastName.includes(name) ||
            `${user.firstName} ${user.lastName}`.includes(name);
      const permissionItem = userPermissionList.find(
        (item) => item.nameThai === user.userPermission
      );
      const isPermissionMatch =
        userPermission === ""
          ? true
          : permissionItem?.id === Number(userPermission);
      const hospitalItem = affiliateHospitalList.find(
        (item) => item.nameThai === user.affiliatedHospital
      );
      const isAffiliatedHospitalMatch =
        affiliatedHospital === ""
          ? true
          : hospitalItem?.id === Number(affiliatedHospital);
      const isStatusMatch =
        status === ""
          ? true
          : status === (user.activated ? "active" : "inactive");
      return (
        isNameMatch &&
        isPermissionMatch &&
        isAffiliatedHospitalMatch &&
        isStatusMatch
      );
    });
    setUserList(filteredUsers);
    setCurrentPage(1);
    // setCurrentUsers(
    //   filteredUsers.slice(
    //     0, usersPerPage
    //   )
    // );
  };

  const handleClearFiltering = () => {
    setUserList(originalUserList);
    setCurrentUsers(
      originalUserList.slice(
        (currentPage - 1) * usersPerPage,
        currentPage * usersPerPage
      )
    );
  };

  const handleCloseConfirmationDialog = () => {
    setIsShowConfrimationDialog(false);
  };

  const handleDelete = (index: number) => {
    setIsShowConfrimationDialog(true);
    setDeleteIndex(index);
  };

  const handleDeleteClick = async () => {
    try {
      await userManagementService.deleteWebUser(deleteIndex.toString());
      const updatedUserList = [...userList];
      updatedUserList.map((user, index) => {
        if (user.userId === deleteIndex) {
          updatedUserList.splice(index, 1);
        }
      });
      setUserList(updatedUserList);
      setIsShowConfrimationDialog(false);
    } catch (error) {
      setErrorDelete(true);
    }
  };

  return (
    <Layout activeTab="userPermission">
      <div className="user-filtering-container">
        <UserFiltering
          handleSearch={handleSearch}
          handleClearFiltering={handleClearFiltering}
        />
        <Box
          sx={{
            p: 3,
            overflowY: "auto",
            scrollbarWidth: "thin",
            backgroundColor: "white",
            // height: "calc(100vh - 300px)",
            mt: 2,
          }}
        >
          {permissionAddAble && (
            <Button
              variant="contained"
              className="grant-permission-button"
              style={{
                backgroundColor: "#FF366B",
                float: "right",
                width: "130px",
                height: "40px",
                fontSize: "14px",
                marginBottom: "20px",
              }}
              startIcon={
                <AddIcon style={{ color: "white", padding: "0.5px" }} />
              }
              onClick={() => navigate("/addUser")}
            >
              เพิ่มผู้ใช้งาน
            </Button>
          )}

          <Box>
            <TableContainer component={Paper}>
              <Table className="pink-table-line">
                <TableHead>
                  <TableRow className="pink-bottom-line">
                    {tableCellValues.map((value) => (
                      <TableCell style={{ fontSize: "16px" }}>
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {currentUsers.length > 0 && (
                  <TableBody>
                    {currentUsers.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {(currentPage - 1) * usersPerPage + index + 1}
                        </TableCell>
                        <TableCell>
                          {user.firstName} {user.lastName}
                        </TableCell>
                        <TableCell>
                          {user.affiliatedHospital
                            ? user.affiliatedHospital
                            : "-"}
                        </TableCell>{" "}
                        <TableCell>{user.userPermission}</TableCell>
                        <TableCell>
                          {user.accountStatus === "approved" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  marginRight: "5px",
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "green",
                                }}
                              />
                              ใช้งานได้
                            </div>
                          ) : (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  marginRight: "5px",
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                }}
                              />
                              ปิดการใช้งาน
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          <Stack spacing={1} direction={"row"}>
                            <Box
                              sx={{
                                border: 1,
                                borderRadius: 2,
                                borderColor: "#DF5C8E",
                                width: "25px",
                                height: "25px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                permissionEditAble
                                  ? navigate("/editUser", {
                                      state: { user: user.userId },
                                    })
                                  : navigate("/viewUser", {
                                      state: { user: user.userId },
                                    })
                              }
                            >
                              <EditIcon
                                fontSize="small"
                                style={{ color: "#DF5C8E" }}
                              />
                            </Box>
                            <Box
                              sx={{
                                border: "1px solid #979797",
                                borderRadius: 2,
                                width: "25px",
                                height: "25px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: permissionDeleteAble
                                  ? "pointer"
                                  : "not-allowed",
                                pointerEvents: permissionDeleteAble
                                  ? "auto"
                                  : "none",
                              }}
                              onClick={() => {
                                if (permissionDeleteAble) {
                                  handleDelete(user.userId);
                                }
                              }}
                            >
                              <DeleteIcon fontSize="small" color="disabled" />
                            </Box>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: isLoading ? "flex" : "none",
                justifyContent: "center",
                fontSize: 20,
                color: "#9AA1AE",
                py: 8,
                border: "1px solid #ddd", // Add this line
                borderRadius: "4px",
              }}
            >
              <CircularProgress />
              <Typography>กำลังโหลดข้อมูล...</Typography>
            </Box>
            {currentUsers.length === 0 && !isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 20,
                  color: "#9AA1AE",
                  py: 2,
                  border: "1px solid #ddd", // Add this line
                  borderRadius: "4px",
                }}
              >
                --ไม่พบผู้ใช้งาน--
              </Box>
            )}
          </Box>
          <ThemeProvider theme={theme}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "30px",
              }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </ThemeProvider>
        </Box>
      </div>
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
      <ErrorDialog
        title="ลบข้อมูลผู้ใช้งานไม่สำเร็จ"
        content="ไม่สามารถลบข้อมูลผู้ใช้งานได้"
        openDialog={errorDelete}
        handleCloseDialog={() => {
          setErrorDelete(false);
          setIsShowConfrimationDialog(false);
        }}
      />
      <DeleteDialog
        openDialog={isShowConfrimationDialog}
        title="ยืนยันการลบข้อมูล"
        content="คุณต้องการลบข้อมูลใช่หรือไม่"
        handleCancelClick={handleCloseConfirmationDialog}
        handleCloseDialog={handleCloseConfirmationDialog}
        handleConfirmClick={handleDeleteClick}
      />
    </Layout>
  );
};

export default UserTab;

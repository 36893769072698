import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  RadioGreyBox,
  TextGreyBox,
  TextWhiteBox,
  YesNoBox,
} from "../../../components/TextBox";
import { Pathology } from "./Pathology";
import { store } from "../../../redux/store";

interface PathologyDialogProps extends Pathology {
  open: boolean;
  onClose: (data: Pathology) => void;
  onSave: (data: Pathology) => void;
}

export const PathologyDialog: React.FC<PathologyDialogProps> = ({
  open,
  onClose,
  onSave,
  stomach,
  liverPreop,
  liverPostop,
  gastroscopy,
  stomachDetail,
  liverPreopDetail,
  liverPostopDetail,
  gastroscopyDetail,
}) => {
  const initialValues = useMemo(
    () => ({
      stomach,
      liverPreop,
      liverPostop,
      gastroscopy,
      stomachDetail,
      liverPreopDetail,
      liverPostopDetail,
      gastroscopyDetail,
    }),
    [
      stomach,
      liverPreop,
      liverPostop,
      gastroscopy,
      stomachDetail,
      liverPreopDetail,
      liverPostopDetail,
      gastroscopyDetail,
    ]
  );
  const [pathologyValues, setPathologyValues] =
    useState<Pathology>(initialValues);
  const [pathologyValuesTemp, setPathologyTempValues] =
    useState<Pathology>(initialValues);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  
  useEffect(() => {
    setPathologyValues(initialValues);
    setPathologyTempValues(initialValues);
  }, [initialValues]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "stomach" && event.target.value === "no") {
      pathologyValues["stomachDetail"] = undefined;
    } else if (
      event.target.name === "liverPreop" &&
      event.target.value === "no"
    ) {
      pathologyValues["liverPreopDetail"] = undefined;
    } else if (
      event.target.name === "liverPostop" &&
      event.target.value === "no"
    ) {
      pathologyValues["liverPostopDetail"] = undefined;
    } else if (
      event.target.name === "gastroscopy" &&
      event.target.value === "no"
    ) {
      pathologyValues["gastroscopyDetail"] = undefined;
    }
    setPathologyValues({
      ...pathologyValues,
      [event.target.name]: event.target.value === "yes" ? "yes" : "no",
    });
  };
  const handleDetailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPathologyValues({
      ...pathologyValues,
      [event.target.name]: event.target.value,
    });
  };
  const handleClickCancle = () => {
    setPathologyValues(pathologyValuesTemp);
    onClose(pathologyValuesTemp);
  };
  const handleClickSave = () => {
    if(patientDataPermissionEditAble === false){
      setPathologyValues(pathologyValuesTemp);
      onClose(pathologyValuesTemp);
      return;
    }
    onSave(pathologyValues);
  };
  return (
    <Dialog open={open} onClose={handleClickCancle}>
      <DialogTitle>Pathology report</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div style={{ width: "550px", display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Stack spacing={1}>
              <Stack spacing={1} direction={"row"}>
                <TextWhiteBox>{``}</TextWhiteBox>
                <YesNoBox />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Stomach</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="stomach"
                    defaultValue="no"
                  >
                    <Radio
                      checked={pathologyValues.stomach === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={pathologyValues.stomach === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              {pathologyValues.stomach === "yes" && (
                <TextField
                  name="stomachDetail"
                  label="-- ระบุข้อมูลเพิ่มเติม (ถ้ามี) --"
                  variant="outlined"
                  size="small"
                  style={{ width: "470px" }}
                  value={pathologyValues.stomachDetail}
                  onChange={handleDetailChange}
                  multiline
                  rows={2}
                />
              )}
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Liver Preop</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="liverPreop"
                    defaultValue="no"
                  >
                    <Radio
                      checked={pathologyValues.liverPreop === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={pathologyValues.liverPreop === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              {pathologyValues.liverPreop === "yes" && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    name="liverPreopDetail"
                    label="-- ระบุข้อมูลเพิ่มเติม (ถ้ามี) --"
                    variant="outlined"
                    size="small"
                    style={{ width: "470px" }}
                    value={pathologyValues.liverPreopDetail}
                    onChange={handleDetailChange}
                    multiline
                    rows={2}
                  />
                </Grid>
              )}
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Liver Postop</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="liverPostop"
                    defaultValue="no"
                  >
                    <Radio
                      checked={pathologyValues.liverPostop === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={pathologyValues.liverPostop === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              {pathologyValues.liverPostop === "yes" && (
                <TextField
                  name="liverPostopDetail"
                  label="-- ระบุข้อมูลเพิ่มเติม (ถ้ามี) --"
                  variant="outlined"
                  size="small"
                  style={{ width: "470px" }}
                  value={pathologyValues.liverPostopDetail}
                  onChange={handleDetailChange}
                  multiline
                  rows={2}
                />
              )}
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Gastroscopy</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="gastroscopy"
                    defaultValue="no"
                  >
                    <Radio
                      checked={pathologyValues.gastroscopy === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={pathologyValues.gastroscopy === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              {pathologyValues.gastroscopy === "yes" && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    name="gastroscopyDetail"
                    label="-- ระบุข้อมูลเพิ่มเติม (ถ้ามี) --"
                    variant="outlined"
                    size="small"
                    style={{ width: "470px" }}
                    value={pathologyValues.gastroscopyDetail}
                    onChange={handleDetailChange}
                    multiline
                    rows={2}
                  />
                </Grid>
              )}
            </Stack>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClickCancle}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};

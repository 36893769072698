import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import MenuAppBar from "../../shared/MenuAppBar";
import VerticalTab from "../../shared/VerticalTab";
import DropZone from "../../shared/DropZone";
import { SetStateAction, useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { format, set } from "date-fns";
import { useDropzone } from "react-dropzone";
import { userManagementService } from "../../service/userManagementService";
import { useEffectOnce } from "react-use";
import { Layout } from "../../Layout";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { title16500 } from "../../style";
import infoIcon from "../../asset/icn.png";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { SuccessDialog } from "../../components/dialog/SuccessDialog";
import { ConfirmDialog } from "../../components/dialog/ConfirmDialog";
import { CancelDialog } from "../../components/dialog/CancelDialog";
import { SubmitApproveButtomBar } from "../SubmitApproveButtomBar";
import { LayoutTab } from "../../shared/visit/LayoutTab";
import DownloadFile from "../../shared/DownloadFile";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { symlink } from "fs";
import { UserPermission } from "../../type";
import { useSelector } from "react-redux";

type UserDetail = {
  title: string;
  firstName: string;
  lastName: string;
  nationalId: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  mobilePhoneNumber: string;
  affiliatedHospital: string;
  medicalLicenseNumber: string;
  jobPosition: string;
  siteRoleId: string;
  username: string;
  registrationDate: string;
  approvalDate: string;
  approveByUserId: string;
  email: string;
};

const ApproveUserDetailViewMode = () => {
  const navigate = useNavigate();
  const [userPermissionList, setUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [affiliatedHospitalList, setAffiliateHospitalHospitalList] = useState<
    any[]
  >([]);
  const [isShowConfrimApproveDialog, setIsShowConfrimApproveDialog] =
    useState(false);
  const [isShowCancelApprovalDialog, setIsShowCancelApprovalDialog] =
    useState(false);
  const [isSubmittedApproval, setIsSubmittedApproval] = useState(false);
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const [userDetail, setUserDetail] = useState<UserDetail>({
    title: "",
    firstName: "",
    lastName: "",
    nationalId: "",
    phoneNumber: "",
    phoneNumberExtension: "",
    mobilePhoneNumber: "",
    affiliatedHospital: "",
    medicalLicenseNumber: "",
    jobPosition: "",
    siteRoleId: "",
    username: "",
    registrationDate: "",
    approvalDate: "",
    approveByUserId: "",
    email: "",
  });

  let userId: string = "";

  const location = useLocation();
  if (location?.state?.user) {
    userId = location.state.user;
  }

  const userPermissionStatus = useSelector(
    (state: any) => state.loginUser.userPermission
  );
  const loginUserId = useSelector((state: any) => state.loginUser.userId);
  const permissionEditAble =
    loginUserId === "1"
      ? true
      : userPermissionStatus?.userApprovePermission.editAble;

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const affiliatedHospitalListResponse =
          await userManagementService.getAffiliatedHospital();
        setAffiliateHospitalHospitalList(affiliatedHospitalListResponse);

        const userPermissionList: UserPermission[] =
          await userManagementService.getUserPermission();
        setUserPermissionList(userPermissionList);

        if (userId) {
          const userDetail = await userManagementService.getUserDetail(userId);
          setUserDetail(userDetail);
          const uploadFileList = await userManagementService.getDocumentList(
            userId
          );
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData();
  });

  const { mutateAsync: approveUser } = useMutation({
    mutationFn: async () => {
      const response = await userManagementService.approveUser({
        targetUserId: userId,
        targetPermissionId: Number(userDetail.siteRoleId),
        isApproved: true,
      });
      return response;
    },
    onSuccess(data) {
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      setIsSubmittedApproval(true);
      setIsShowConfrimApproveDialog(false);
    },
  });

  const handleApproveUser = async () => {
    try {
      setIsShowConfrimApproveDialog(true);
    } catch (error) {
      console.error("Error fetching List", error);
    }
  };

  const { mutateAsync: rejectUser } = useMutation({
    mutationFn: async () => {
      const response = await userManagementService.approveUser({
        targetUserId: userId,
        targetPermissionId: Number(userDetail.siteRoleId),
        isApproved: false,
      });
      return response;
    },
    onSuccess(data) {
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      setIsSubmittedApproval(true);
      setIsShowCancelApprovalDialog(false);
    },
  });

  const handleRejectUser = async () => {
    try {
      setIsShowCancelApprovalDialog(true);
    } catch (error) {
      console.error("Error fetching List", error);
    }
  };

  const handleCancelClick = async () => {
    navigate("/ApproveUser");
  };

  return (
    <Layout activeTab="userPermission">
      <div className="user-filtering-container">
        <Box sx={{ py: 2 }}>
          <Typography component={"span"} style={title16500}>
            ผู้ใช้งานและสิทธิ์
          </Typography>
          <Typography component={"span"} color={"#7A8688"} style={title16500}>
            {` / อนุมัติผู้ใช้งาน`}
          </Typography>
          <Typography component={"span"} color={"#7A8688"} style={title16500}>
            {` / รายละเอียดอนุมัติผู้ใช้งาน`}
          </Typography>
        </Box>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            p={2}
            bgcolor={"white"}
            style={{
              width: "100%",
              paddingTop: "0px",
              height: "calc(100vh - 100px)",
              overflow: "auto",
              overflowY: "auto",
              scrollbarWidth: "thin",
            }}
            sx={{ height: "inherit" }}
          >
            <p style={{ borderBottom: "2px solid #D8D8D8", padding: "5px" }}>
              <span
                style={{
                  borderBottom: "3px solid #DF5C8E",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                รายละเอียดอนุมัติผู้ใช้งาน
              </span>
            </p>
            <p style={{ color: "#646D78" }}>
              กรุณาตรวจสอบข้อมูลเพื่ออนุมัติผู้ใช้งาน{" "}
            </p>

            {!!userId && (
              <div>
                <p style={{ color: "#646D78", paddingTop: "20px" }}>
                  Email Address <img src={infoIcon} />
                </p>
                <Typography style={{ width: "296px" }}>
                  {userDetail.email ? userDetail.email : "-"}
                </Typography>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div style={{ paddingRight: "50px", paddingTop: "20px" }}>
                <p style={{ color: "#646D78" }}>
                  คำนำหน้า <span style={{ color: "red" }}>*</span>
                </p>
                <Typography style={{ width: "296px" }}>
                  {userDetail.title ? userDetail.title : "-"}
                </Typography>
              </div>

              <div style={{ paddingRight: "50px", paddingTop: "20px" }}>
                <p style={{ color: "#646D78" }}>
                  ชื่อ <span style={{ color: "red" }}>*</span>
                </p>
                <Typography style={{ width: "296px" }}>
                  {userDetail.firstName ? userDetail.firstName : "-"}
                </Typography>
              </div>

              <div style={{ paddingRight: "50px", paddingTop: "20px" }}>
                <p style={{ color: "#646D78" }}>
                  นามสกุล <span style={{ color: "red" }}>*</span>
                </p>
                <Typography style={{ width: "296px" }}>
                  {userDetail.lastName ? userDetail.lastName : "-"}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div style={{ paddingRight: "10px", paddingTop: "20px" }}>
                <p style={{ color: "#646D78" }}>เบอร์โทรศัพท์</p>
                <Typography style={{ width: "168px" }}>
                  {userDetail.phoneNumber ? userDetail.phoneNumber : "-"}
                </Typography>
              </div>
              <div style={{ paddingRight: "50px", paddingTop: "20px" }}>
                <p style={{ color: "#646D78" }}>Ext</p>
                <Typography style={{ width: "114px" }}>
                  {userDetail.phoneNumberExtension
                    ? userDetail.phoneNumberExtension
                    : "-"}
                </Typography>
              </div>
              <div>
                <p style={{ color: "#646D78", paddingTop: "20px" }}>
                  เบอร์มือถือ <span style={{ color: "red" }}>*</span>
                </p>
                <Typography style={{ width: "296px" }}>
                  {userDetail.mobilePhoneNumber
                    ? userDetail.mobilePhoneNumber
                    : "-"}
                </Typography>
              </div>
            </div>
            <br />
            <p style={{ borderBottom: "1px solid #D8D8D8" }}></p>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "50px",
              }}
            >
              <div>
                <p style={{ color: "#646D78" }}>ชื่อโรงพยาบาล (สังกัด)</p>
                <Typography style={{ width: "296px" }}>
                  {userDetail.affiliatedHospital
                    ? userDetail.affiliatedHospital
                    : "-"}
                </Typography>
              </div>
              <div>
                <p style={{ color: "#646D78" }}>
                  เลขที่ใบอนุญาตใบประกอบวิชาชีพ
                </p>
                <Typography style={{ width: "296px" }}>
                  {userDetail.medicalLicenseNumber
                    ? userDetail.medicalLicenseNumber
                    : "-"}
                </Typography>
              </div>
              <div>
                <p style={{ color: "#646D78" }}>ตำแหน่ง</p>
                <Typography style={{ width: "296px" }}>
                  {userDetail.jobPosition ? userDetail.jobPosition : "-"}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "50px",
                paddingTop: "20px",
              }}
            >
              <div>
                <p style={{ color: "#646D78" }}>
                  สิทธิ์การใช้งาน <img src={infoIcon} />
                  <span style={{ color: "red" }}>
                    {" "}
                    (เลือกได้เพียง 1 ประเภทเท่านั้น)
                  </span>{" "}
                </p>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                  <Select
                    label="Regular Dropdown"
                    value={userDetail.siteRoleId}
                    size="small"
                    style={{ width: "296px" }}
                    onChange={(e) =>
                      permissionEditAble
                        ? setUserDetail({
                            ...userDetail,
                            siteRoleId: e.target.value,
                          })
                        : ""
                    }
                    MenuProps={{
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                        },
                      },
                    }}
                  >
                    {userPermissionList.map((userPermission) => (
                      <MenuItem value={userPermission.id} sx={{ fontSize: 14 }}>
                        {userPermission.nameThai}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <br />
            <p style={{ borderBottom: "1px solid #D8D8D8" }}></p>
            <div>
              <Typography style={{ color: "#212121", fontWeight: "bold" }}>
                เอกสารแนบ
              </Typography>
            </div>
            <DownloadFile userId={userId} />
          </Box>
        </div>

        {!isSubmittedApproval && (
          <Box sx={{ mt: 1, width: 1 }}>
            <SubmitApproveButtomBar
              permissionEditAble={permissionEditAble}
              handleCancelClick={handleCancelClick}
              handleRejectClicked={handleRejectUser}
              handleSubmitData={handleApproveUser}
            />
          </Box>
        )}

        {isSubmittedApproval && (
          <Box p={2} bgcolor={"white"} style={{ height: "50px" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#7A8688",
                  width: "90px",
                  height: "35px",
                  marginRight: "10px",
                }}
                onClick={() => navigate("/ApproveUser")}
              >
                ย้อนกลับ
              </Button>
            </div>
          </Box>
        )}
        <ConfirmDialog
          title="ต้องการอนุมัติรายการใช่หรือไม่"
          content="คุณต้องการอนุมัติรายการ ต้องการดำเนินการต่อใช่หรือไม่?"
          openDialog={isShowConfrimApproveDialog}
          handleCloseDialog={() => {
            setIsShowConfrimApproveDialog(false);
          }}
          handleCancelClick={() => {
            setIsShowConfrimApproveDialog(false);
          }}
          handleConfirmClick={async () => {
            await approveUser();
          }}
        />
        <CancelDialog
          title="ไม่อนุมัติรายการใช่หรือไม่"
          content="คุณไม่ต้องการอนุมัติรายการ ต้องการดำเนินการต่อใช่หรือไม่?"
          openDialog={isShowCancelApprovalDialog}
          handleCloseDialog={() => {
            setIsShowCancelApprovalDialog(false);
          }}
          handleCancelClick={() => {
            setIsShowCancelApprovalDialog(false);
          }}
          handleConfirmClick={async () => {
            await rejectUser();
          }}
        />
        <ErrorDialog
          title="เซสชันหมดอายุ"
          content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
          openDialog={isTokenExpired}
          handleCloseDialog={() => {
            setIsTokenExpired(false);
            navigate("/");
          }}
        />
      </div>
    </Layout>
  );
};
export default ApproveUserDetailViewMode;

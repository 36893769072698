// In your Redux slice file
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EntryIds,
  PatientUser,
  PatientUserProfile,
  PregnancyStatus,
} from "../../type";

interface PatientState {
  patientUser: PatientUser | null;
  selectedPatientUserProfile: PatientUserProfile | null;
  selectedVisitId: number | null;
  selectedVisitDate: string | null;
  selectedLasEntryId: number | null;
  latestEntryIds: EntryIds | null;
  entryIds: EntryIds | null;
  tabsIsDirty: boolean;
}

const initialState: PatientState = {
  patientUser: null,
  selectedPatientUserProfile: null,
  selectedVisitId: null,
  selectedVisitDate: null,
  selectedLasEntryId: null,
  latestEntryIds: null,
  entryIds: null,
  tabsIsDirty: false,
};

interface LatestEntryIdsPayload {
  bariaticSurgeryStatus?: number;
  bodyCompositionAndLaboratoryStatus?: number;
  comorbiditiesAndConditionsStatus?: number;
  demographicDataStatus?: number;
  endOfStudyStatus?: number;
  gynecologicConditionsStatus?: number;
  investigationStatus?: number;
  lateComplicationStatus?: number;
  medicationAndNutritionalSupplementsStatus?: number;
  pathologyStatus?: number;
}

interface PatientUserProfilePayload {
  prefix?: string;
  firstName?: string;
  lastName?: string;
  sex?: string;
  hn?: string;
  birthDate?: string;
  height?: number;
  highestWeight?: number;
  occupation?: string;
  occupationOther?: string;
  domicile?: string;
  affiliatedHospital?: string;
  healthBenefit?: string;
  healthBenefitOther?: string;
  bariatricSurgeryHistory?: boolean;
  drinkingHistory?: boolean;
  smokingHistory?: boolean;
  weightLossMethod?: string[];
  weightLossMethodOther?: string;
  chronicDisease?: string[];
  psychoticDisorderOther?: string;
  cancerOther?: string;
  chronicDiseaseOther?: string;
  isSexuallyActive?: boolean;
  sexualActivityFrequency?: string;
  // protectionMethod?: string[];
  contraceptionMethodList?: string[];
  protectionMethodOther?: string;
  wantChild?: boolean;
  yearTillChildIsExpected?: string;
  stillHavingPeriod?: boolean | null;
  periodGapDuration?: string;
  isPregnant?: boolean | null;
  pregnancyStatusList?: PregnancyStatus[];
  numberOfPregnancy?: number;
  numberOfMiscarriage?: number;
  registrationDate?: string;
  userStatus?: number;
  hasChild?: boolean;
  numberOfChild?: number;
  expectedNumberOfChild?: number;
  periodDuration?: string;
}
interface PregnancyStatusPayload {
  pregnancyStatusIndex: number;
  deliverMethod?: string;
  complication?: string;
}

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setPatientUser: (state, action: PayloadAction<PatientUser>) => {
      state.patientUser = action.payload;
    },
    setPatientUserProfile: (
      state,
      action: PayloadAction<PatientUserProfile>
    ) => {
      state.selectedPatientUserProfile = action.payload;
    },
    setSelectedVisitId: (state, action: PayloadAction<number>) => {
      state.selectedVisitId = action.payload;
    },
    setSelectedVisitDate: (state, action: PayloadAction<string>) => {
      state.selectedVisitDate = action.payload;
    },
    updateSelectedVisitDate: (state, action: PayloadAction<string>) => {
      state.selectedVisitDate = action.payload;
    },
    setSelectedLasEntryId: (state, action: PayloadAction<number>) => {
      state.selectedLasEntryId = action.payload;
    },
    setLatestEntryIds: (state, action: PayloadAction<any>) => {
      state.latestEntryIds = action.payload;
    },
    updateLatestEntryIds: (
      state,
      action: PayloadAction<LatestEntryIdsPayload>
    ) => {
      if (state.latestEntryIds) {
        state.latestEntryIds.bariaticSurgeryStatus =
          action.payload.bariaticSurgeryStatus ??
          state.latestEntryIds.bariaticSurgeryStatus;
        state.latestEntryIds.bodyCompositionAndLaboratoryStatus =
          action.payload.bodyCompositionAndLaboratoryStatus ??
          state.latestEntryIds.bodyCompositionAndLaboratoryStatus;
        state.latestEntryIds.comorbiditiesAndConditionsStatus =
          action.payload.comorbiditiesAndConditionsStatus ??
          state.latestEntryIds.comorbiditiesAndConditionsStatus;
        state.latestEntryIds.demographicDataStatus =
          action.payload.demographicDataStatus ??
          state.latestEntryIds.demographicDataStatus;
        state.latestEntryIds.endOfStudyStatus =
          action.payload.endOfStudyStatus ??
          state.latestEntryIds.endOfStudyStatus;
        state.latestEntryIds.gynecologicConditionsStatus =
          action.payload.gynecologicConditionsStatus ??
          state.latestEntryIds.gynecologicConditionsStatus;
        state.latestEntryIds.investigationStatus =
          action.payload.investigationStatus ??
          state.latestEntryIds.investigationStatus;
        state.latestEntryIds.lateComplicationStatus =
          action.payload.lateComplicationStatus ??
          state.latestEntryIds.lateComplicationStatus;
        state.latestEntryIds.medicationAndNutritionalSupplementsStatus =
          action.payload.medicationAndNutritionalSupplementsStatus ??
          state.latestEntryIds.medicationAndNutritionalSupplementsStatus;
        state.latestEntryIds.pathologyStatus =
          action.payload.pathologyStatus ??
          state.latestEntryIds.pathologyStatus;
      }
    },
    setEntryIds: (state, action: PayloadAction<any>) => {
      state.entryIds = action.payload;
    },
    updateEntryIds: (state, action: PayloadAction<LatestEntryIdsPayload>) => {
      if (state.entryIds) {
        state.entryIds.bariaticSurgeryStatus =
          action.payload.bariaticSurgeryStatus ??
          state.entryIds.bariaticSurgeryStatus;
        state.entryIds.bodyCompositionAndLaboratoryStatus =
          action.payload.bodyCompositionAndLaboratoryStatus ??
          state.entryIds.bodyCompositionAndLaboratoryStatus;
        state.entryIds.comorbiditiesAndConditionsStatus =
          action.payload.comorbiditiesAndConditionsStatus ??
          state.entryIds.comorbiditiesAndConditionsStatus;
        state.entryIds.demographicDataStatus =
          action.payload.demographicDataStatus ??
          state.entryIds.demographicDataStatus;
        state.entryIds.endOfStudyStatus =
          action.payload.endOfStudyStatus ?? state.entryIds.endOfStudyStatus;
        state.entryIds.gynecologicConditionsStatus =
          action.payload.gynecologicConditionsStatus ??
          state.entryIds.gynecologicConditionsStatus;
        state.entryIds.investigationStatus =
          action.payload.investigationStatus ??
          state.entryIds.investigationStatus;
        state.entryIds.lateComplicationStatus =
          action.payload.lateComplicationStatus ??
          state.entryIds.lateComplicationStatus;
        state.entryIds.medicationAndNutritionalSupplementsStatus =
          action.payload.medicationAndNutritionalSupplementsStatus ??
          state.entryIds.medicationAndNutritionalSupplementsStatus;
        state.entryIds.pathologyStatus =
          action.payload.pathologyStatus ?? state.entryIds.pathologyStatus;
      }
    },
    updateTabsIsDirty: (state, action) => {
      state.tabsIsDirty = action.payload;
    },
    updatePatientUserProfile: (
      state,
      action: PayloadAction<PatientUserProfilePayload>
    ) => {
      if (state.selectedPatientUserProfile) {
        state.selectedPatientUserProfile.prefix =
          action.payload.prefix ?? state.selectedPatientUserProfile?.prefix;
        state.selectedPatientUserProfile.firstName =
          action.payload.firstName ??
          state.selectedPatientUserProfile?.firstName;
        state.selectedPatientUserProfile.lastName =
          action.payload.lastName ?? state.selectedPatientUserProfile?.lastName;
        state.selectedPatientUserProfile.sex =
          action.payload.sex ?? state.selectedPatientUserProfile?.sex;
        state.selectedPatientUserProfile.hn =
          action.payload.hn ?? state.selectedPatientUserProfile?.hn;
        state.selectedPatientUserProfile.birthDate =
          action.payload.birthDate ??
          state.selectedPatientUserProfile?.birthDate;
        state.selectedPatientUserProfile.height =
          action.payload.height ?? state.selectedPatientUserProfile?.height;
        state.selectedPatientUserProfile.highestWeight =
          action.payload.highestWeight ??
          state.selectedPatientUserProfile?.highestWeight;
        state.selectedPatientUserProfile.occupation =
          action.payload.occupation ??
          state.selectedPatientUserProfile?.occupation;
        state.selectedPatientUserProfile.occupationOther =
          action.payload.occupationOther ??
          state.selectedPatientUserProfile?.occupationOther;
        state.selectedPatientUserProfile.domicile =
          action.payload.domicile ?? state.selectedPatientUserProfile?.domicile;
        state.selectedPatientUserProfile.affiliatedHospital =
          action.payload.affiliatedHospital ??
          state.selectedPatientUserProfile?.affiliatedHospital;
        state.selectedPatientUserProfile.healthBenefit =
          action.payload.healthBenefit ??
          state.selectedPatientUserProfile?.healthBenefit;
        state.selectedPatientUserProfile.healthBenefitOther =
          action.payload.healthBenefitOther ??
          state.selectedPatientUserProfile?.healthBenefitOther;
        state.selectedPatientUserProfile.bariatricSurgeryHistory =
          action.payload.bariatricSurgeryHistory ??
          state.selectedPatientUserProfile?.bariatricSurgeryHistory;
        state.selectedPatientUserProfile.drinkingHistory =
          action.payload.drinkingHistory ??
          state.selectedPatientUserProfile?.drinkingHistory;
        state.selectedPatientUserProfile.smokingHistory =
          action.payload.smokingHistory ??
          state.selectedPatientUserProfile?.smokingHistory;
        state.selectedPatientUserProfile.weightLossMethod =
          action.payload.weightLossMethod ??
          state.selectedPatientUserProfile?.weightLossMethod;
        state.selectedPatientUserProfile.weightLossMethodOther =
          action.payload.weightLossMethodOther ??
          state.selectedPatientUserProfile?.weightLossMethodOther;
        state.selectedPatientUserProfile.chronicDisease =
          action.payload.chronicDisease ??
          state.selectedPatientUserProfile?.chronicDisease;
        state.selectedPatientUserProfile.psychoticDisorderOther =
          action.payload.psychoticDisorderOther ??
          state.selectedPatientUserProfile?.psychoticDisorderOther;
        state.selectedPatientUserProfile.cancerOther =
          action.payload.cancerOther ??
          state.selectedPatientUserProfile?.cancerOther;
        state.selectedPatientUserProfile.chronicDiseaseOther =
          action.payload.chronicDiseaseOther ??
          state.selectedPatientUserProfile?.chronicDiseaseOther;
        state.selectedPatientUserProfile.isSexuallyActive =
          action.payload.isSexuallyActive ??
          state.selectedPatientUserProfile?.isSexuallyActive;
        state.selectedPatientUserProfile.sexualActivityFrequency =
          action.payload.sexualActivityFrequency ??
          state.selectedPatientUserProfile?.sexualActivityFrequency;
        state.selectedPatientUserProfile.contraceptionMethodList =
          action.payload.contraceptionMethodList ??
          state.selectedPatientUserProfile?.contraceptionMethodList;
        state.selectedPatientUserProfile.protectionMethodOther =
          action.payload.protectionMethodOther ??
          state.selectedPatientUserProfile?.protectionMethodOther;
        state.selectedPatientUserProfile.wantChild =
          action.payload.wantChild ??
          state.selectedPatientUserProfile?.wantChild;
        state.selectedPatientUserProfile.yearTillChildIsExpected =
          action.payload.yearTillChildIsExpected ??
          state.selectedPatientUserProfile?.yearTillChildIsExpected;
        state.selectedPatientUserProfile.stillHavingPeriod =
          action.payload.stillHavingPeriod ??
          state.selectedPatientUserProfile?.stillHavingPeriod;
        state.selectedPatientUserProfile.periodGapDuration =
          action.payload.periodGapDuration ??
          state.selectedPatientUserProfile?.periodGapDuration;
        state.selectedPatientUserProfile.isPregnant =
          action.payload.isPregnant ??
          state.selectedPatientUserProfile?.isPregnant;
        state.selectedPatientUserProfile.pregnancyStatusList =
          action.payload.pregnancyStatusList ??
          state.selectedPatientUserProfile?.pregnancyStatusList;
        state.selectedPatientUserProfile.numberOfPregnancy =
          action.payload.numberOfPregnancy ??
          state.selectedPatientUserProfile?.numberOfPregnancy;
        state.selectedPatientUserProfile.numberOfMiscarriage =
          action.payload.numberOfMiscarriage ??
          state.selectedPatientUserProfile?.numberOfMiscarriage;
        state.selectedPatientUserProfile.registrationDate =
          action.payload.registrationDate ??
          state.selectedPatientUserProfile?.registrationDate;
        state.selectedPatientUserProfile.userStatus =
          action.payload.userStatus ??
          state.selectedPatientUserProfile?.userStatus;
        state.selectedPatientUserProfile.hasChild =
          action.payload.hasChild ?? state.selectedPatientUserProfile?.hasChild;
        state.selectedPatientUserProfile.numberOfChild =
          action.payload.numberOfChild ??
          state.selectedPatientUserProfile?.numberOfChild;
        state.selectedPatientUserProfile.expectedNumberOfChild =
          action.payload.expectedNumberOfChild ??
          state.selectedPatientUserProfile?.expectedNumberOfChild;
        state.selectedPatientUserProfile.periodDuration =
          action.payload.periodDuration ??
          state.selectedPatientUserProfile?.periodDuration;
      }
    },
    addPregnancyStatus: (state) => {
      state.selectedPatientUserProfile?.pregnancyStatusList.push({
        deliverMethod: "",
        complication: "",
      });
    },
    removePregnancyStatus: (state) => {
      state.selectedPatientUserProfile?.pregnancyStatusList.pop();
    },
    updatePregnancyStatus: (
      state,
      action: PayloadAction<PregnancyStatusPayload>
    ) => {
      const currentPregnancyStatus =
        state.selectedPatientUserProfile?.pregnancyStatusList[
          action.payload.pregnancyStatusIndex
        ];
      if (currentPregnancyStatus) {
        if (action.payload.deliverMethod !== undefined) {
          currentPregnancyStatus.deliverMethod = action.payload.deliverMethod;
        }

        if (action.payload.complication !== undefined) {
          currentPregnancyStatus.complication = action.payload.complication;
        }
      }
      return state;
    },
  },
});

export const {
  setPatientUser,
  setPatientUserProfile,
  setSelectedVisitId,
  setSelectedVisitDate,
  updateSelectedVisitDate,
  setSelectedLasEntryId,
  setLatestEntryIds,
  updateLatestEntryIds,
  setEntryIds,
  updateEntryIds,
  updateTabsIsDirty,
  updatePatientUserProfile,
  addPregnancyStatus,
  removePregnancyStatus,
  updatePregnancyStatus,
} = patientSlice.actions;

export default patientSlice.reducer;

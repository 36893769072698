import axios from "axios";

const BASE_URL = "https://service.obesityconnects.com";

export const getHospital = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/v1/datalist/affiliatedHospital`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

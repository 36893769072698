import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Radio,
  DialogActions,
  Button,
  RadioGroup,
  Stack,
} from "@mui/material";
import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  RadioGreyBox,
  TextGreyBox,
  TextWhiteBox,
  YesNoBox,
} from "../../../components/TextBox";
import { InvestigationFibroScan } from "./InvestigationFibroScan";
import { store } from "../../../redux/store";

interface InvestigationFibroProps extends InvestigationFibroScan {
  open: boolean;
  onClose: (data: InvestigationFibroScan) => void;
  onSave: (data: InvestigationFibroScan) => void;
}

export const InvestigationFibroScanDialog: React.FC<
  InvestigationFibroProps
> = ({ open, onClose, onSave, fibro }) => {
  const initialValues = useMemo(
    () => ({
      fibro,
    }),
    [fibro]
  );
  const [fibroValues, setFibroyValues] =
    useState<InvestigationFibroScan>(initialValues);
  const [fibroValuesTemp, setFibroValuesTemp] =
    useState<InvestigationFibroScan>(initialValues);
  
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  useEffect(() => {
    setFibroyValues(initialValues);
    setFibroValuesTemp(initialValues);
  }, [initialValues]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFibroyValues({
      ...fibroValues,
      [event.target.name]: event.target.value === "yes" ? "yes" : "no",
    });
  };
  const handleClickCancle = () => {
    setFibroyValues(fibroValuesTemp);
    onClose(fibroValuesTemp);
  };
  const handleClickSave = () => {
    if(patientDataPermissionEditAble === false){
      setFibroyValues(fibroValuesTemp);
      onClose(fibroValuesTemp);
      return;
    }
    onSave(fibroValues);
  };
  return (
    <Dialog open={open} onClose={handleClickCancle}>
      <DialogTitle>Endoscopy</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div style={{ width: "550px", display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Stack spacing={1}>
              <Stack spacing={1} direction={"row"}>
                <TextWhiteBox>{``}</TextWhiteBox>
                <YesNoBox />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>FibroScan</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="fibro"
                    defaultValue="no"
                  >
                    <Radio
                      checked={fibroValues.fibro === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={fibroValues.fibro === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
            </Stack>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClickCancle}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GreyBox = ({ children }: { children: ReactNode }) => (
  <div
    style={{
      backgroundColor: "#F5F5F5",
      width: "350px",
      height: "48px",
      marginBottom: "10px",
    }}
  >
    <Typography style={{ padding: "10px", fontSize: "14px" }}>
      {children}
    </Typography>
  </div>
);

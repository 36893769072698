import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  Divider,
  Stack,
  RadioGroup,
  TextField,
  IconButton,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { store } from "../../../redux/store";
import { title10Tab } from "../../../style";
import { StatusBottomBar } from "../StatusBottomBar";
import { SubmitBottomBar } from "../SubmitBottomBar";
import { LayoutTab } from "../LayoutTab";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useEffectOnce } from "react-use";
import { visitDataService } from "../../../service/visitDataService";
import { useMutation } from "@tanstack/react-query";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { enqueueSnackbar } from "notistack";
import {
  OtherAdd,
  OtherArrow,
  OtherCheck,
  OtherRemove,
} from "../../../asset/iconSvg";
import { FormContainer } from "react-hook-form-mui";
import { LateComplicationHistoryDialog } from "./LateComplicationHistoryDialog";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import { LateComplicationVisitLogDialog } from "./LateComplicationVisitLogDialog";

// const RadioGroup = ({
//   value,
//   setValue,
//   label,
// }: {
//   value: boolean;
//   setValue: (value: boolean) => void;
//   label: string;
// }) => (
//   <>
//     <FormControlLabel
//       value="true"
//       control={
//         <Radio
//           color="default"
//           style={{ color: "#098484" }}
//           onChange={() => setValue(true)}
//         />
//       }
//       label="Yes"
//       checked={value}
//     />
//     <FormControlLabel
//       value="false"
//       control={
//         <Radio
//           color="default"
//           style={{ color: "#098484" }}
//           onChange={() => setValue(false)}
//         />
//       }
//       label="No"
//       checked={!value}
//     />
//   </>
// );
export type LateComplication = {
  editorId?: string | number;
  lateComplication: boolean;
  gerd: boolean;
  hernia: boolean;
  marginalUlcer: boolean;
  anastomosisStricture: boolean;
  dumpingSyndrome: boolean;
  vte: boolean;
  cardiovascularEvents: boolean;
  psychiatricProblems: boolean;
  osteoporosis: boolean;
  other: boolean;
};
export type LateComplicationResponse = {
  entryId: number;
  editorId: number | string;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  lateComplication: boolean;
  gerd: boolean;
  gerdType: number;
  hernia: boolean;
  herniaType: number;
  marginalUlcer: boolean;
  anastomosisStricture: boolean;
  dumpingSyndrome: boolean;
  vte: boolean;
  cardiovascularEvents: boolean;
  psychiatricProblems: boolean;
  osteoporosis: boolean;
  other: boolean;
  otherText: string[];
};
type Props = {
  handleCancelClick: () => void;
};

export const LateComplication = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(2);
  const [gerdType, setGerdType] = useState(0);
  const [herniaType, setHerniaType] = useState(0);
  const [otherDetails, setOtherDetails] = useState([""]);
  const [validationError, setValidationError] = useState(false);
  const latestEntryId = useAppSelector(
    (state) => state.patientUser.latestEntryIds?.lateComplicationStatus
  );
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.lateComplicationStatus
  );
  const [lateComplicationValues, setLateComplicationValues] =
    useState<LateComplication>({
      lateComplication: false,
      gerd: false,
      hernia: false,
      marginalUlcer: false,
      anastomosisStricture: false,
      dumpingSyndrome: false,
      vte: false,
      cardiovascularEvents: false,
      psychiatricProblems: false,
      osteoporosis: false,
      other: false,
    });
  const setData = (lateComplicationData: LateComplicationResponse) => {
    setLateComplicationValues({
      lateComplication: lateComplicationData.lateComplication,
      gerd: lateComplicationData.gerd,
      hernia: lateComplicationData.hernia,
      marginalUlcer: lateComplicationData.marginalUlcer,
      anastomosisStricture: lateComplicationData.anastomosisStricture,
      dumpingSyndrome: lateComplicationData.dumpingSyndrome,
      vte: lateComplicationData.vte,
      cardiovascularEvents: lateComplicationData.cardiovascularEvents,
      psychiatricProblems: lateComplicationData.psychiatricProblems,
      osteoporosis: lateComplicationData.osteoporosis,
      other: lateComplicationData.other,
    });
    setOtherDetails(lateComplicationData.otherText ?? [""]);
  };
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openVisitLogDialog, setOpenVisitLogDialog] = useState(false);
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const lateComplicationData: LateComplicationResponse =
          await visitDataService.getLateComplicationFromEntryId(currentEntryId);
        setData(lateComplicationData);
        setStatus(lateComplicationData.visitDataStatus);
        setGerdType(lateComplicationData.gerdType);
        setHerniaType(lateComplicationData.herniaType);
      } else if (latestEntryId) {
        const lateComplicationData: LateComplicationResponse =
          await visitDataService.getLateComplicationFromEntryId(latestEntryId);
        setData(lateComplicationData);
        setStatus(lateComplicationData.visitDataStatus);
        setGerdType(lateComplicationData.gerdType);
        setHerniaType(lateComplicationData.herniaType);
      }
    };
    fetchData();
  },[openNoPermissionDialog]);
  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.saveLateComplicationData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        lateComplication: lateComplicationValues.lateComplication,
        gerd: lateComplicationValues.lateComplication
          ? lateComplicationValues.gerd
          : false,
        gerdType:
          lateComplicationValues.lateComplication && lateComplicationValues.gerd
            ? gerdType
            : 0,
        hernia: lateComplicationValues.lateComplication
          ? lateComplicationValues.hernia
          : false,
        herniaType:
          lateComplicationValues.lateComplication &&
          lateComplicationValues.hernia
            ? herniaType
            : 0,
        marginalUlcer: lateComplicationValues.lateComplication
          ? lateComplicationValues.marginalUlcer
          : false,
        anastomosisStricture: lateComplicationValues.lateComplication
          ? lateComplicationValues.anastomosisStricture
          : false,
        dumpingSyndrome: lateComplicationValues.lateComplication
          ? lateComplicationValues.dumpingSyndrome
          : false,
        vte: lateComplicationValues.lateComplication
          ? lateComplicationValues.vte
          : false,
        cardiovascularEvents: lateComplicationValues.lateComplication
          ? lateComplicationValues.cardiovascularEvents
          : false,
        psychiatricProblems: lateComplicationValues.lateComplication
          ? lateComplicationValues.psychiatricProblems
          : false,
        osteoporosis: lateComplicationValues.lateComplication
          ? lateComplicationValues.osteoporosis
          : false,
        other: lateComplicationValues.lateComplication
          ? lateComplicationValues.other
          : false,
        otherText:
          lateComplicationValues.lateComplication &&
          lateComplicationValues.other
            ? otherDetails
            : null,
      });
      return response as LateComplicationResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          lateComplicationStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleSubmitData = async () => {
    // await submitDataAsync();
    if(patientDataPermissionEditAble === false){
      setOpenNoPermissionDialog(true);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    if (lateComplicationValues.other) {
      if (validateOtherRequiredFields()) {
        setValidationError(false);
        await submitDataAsync();
        dispatch(updateTabsIsDirty(false));
      } else {
        setValidationError(true);
      }
    } else {
      await submitDataAsync();
      setValidationError(false);
      dispatch(updateTabsIsDirty(false));
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLateComplicationValues({
      ...lateComplicationValues,
      [event.target.name]: event.target.value === "yes" ? true : false,
    });
    dispatch(updateTabsIsDirty(true));
    // if(patientDataPermissionEditAble === false){
    //   dispatch(updateTabsIsDirty(false));
    // }
  };
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
    dispatch(updateTabsIsDirty(true));
    // if(patientDataPermissionEditAble === false){
    //   dispatch(updateTabsIsDirty(false));
    // }
  };
  const handleOtherAdd = () => {
    setOtherDetails((prevDetails) => [...prevDetails, ""]);
    dispatch(updateTabsIsDirty(true));
    // if(patientDataPermissionEditAble === false){
    //   dispatch(updateTabsIsDirty(false));
    // }
  };
  const handleOtherRemove = (index: number) => {
    if (otherDetails.length !== 1) {
      const newDetails = otherDetails.filter((_, i) => i !== index);
      setOtherDetails(newDetails);
      dispatch(updateTabsIsDirty(true));
      // if(patientDataPermissionEditAble === false){
      //   dispatch(updateTabsIsDirty(false));
      // }
    }
  };
  const handleOtherChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newDetails = [...otherDetails];
    newDetails[index] = event.target.value;
    setOtherDetails(newDetails);
    dispatch(updateTabsIsDirty(true));
    // if(patientDataPermissionEditAble === false){
    //   dispatch(updateTabsIsDirty(false));
    // }
  };
  const handleCloseDialog = () => {
    setOpenHistoryDialog(false);
    setOpenNoPermissionDialog(false);
    setOpenVisitLogDialog(false);
  };
  const validateOtherRequiredFields = () => {
    for (let detail of otherDetails) {
      if (!detail.trim()) {
        return false;
      }
    }
    return true;
  };
  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box sx={{ p: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => setOpenVisitLogDialog(true)}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Typography style={title10Tab}>Late complication</Typography>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Stack spacing={1}>
              <Typography color={"#646D78"}>
                Late complication <span style={{ color: "red" }}>*</span>
              </Typography>
              <Box>
                <FormControlLabel
                  name="lateComplication"
                  value="yes"
                  control={
                    <Radio
                      color="default"
                      style={{ color: "#098484" }}
                      onChange={(e) => handleChange(e)}
                    />
                  }
                  label="Yes"
                  checked={lateComplicationValues.lateComplication === true}
                />
                <FormControlLabel
                  name="lateComplication"
                  value="no"
                  control={
                    <Radio
                      color="default"
                      style={{ color: "#098484" }}
                      onChange={(e) => handleChange(e)}
                    />
                  }
                  label="No"
                  checked={lateComplicationValues.lateComplication === false}
                />
              </Box>
            </Stack>
            {lateComplicationValues.lateComplication && (
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    GERD <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="gerd"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={lateComplicationValues.gerd === true}
                    />
                    <FormControlLabel
                      name="gerd"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={lateComplicationValues.gerd === false}
                    />
                  </Box>
                </Stack>
                {lateComplicationValues.gerd && (
                  <Stack spacing={1}>
                    <Typography color={"#646D78"}>กรุณาระบุประเภท</Typography>
                    <Box>
                      <RadioGroup
                        row
                        name="gerdType"
                        onChange={(e) => setGerdType(Number(e.target.value))}
                        value={gerdType}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio color="secondary" />}
                          label="De novo"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="secondary" />}
                          label="Improve"
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio color="secondary" />}
                          label="Worsening"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio color="secondary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  </Stack>
                )}
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    Hernia <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="hernia"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={lateComplicationValues.hernia === true}
                    />
                    <FormControlLabel
                      name="hernia"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={lateComplicationValues.hernia === false}
                    />
                  </Box>
                </Stack>
                {lateComplicationValues.hernia && (
                  <Stack spacing={1}>
                    <Typography color={"#646D78"}>กรุณาระบุประเภท</Typography>
                    <Box>
                      <RadioGroup
                        row
                        name="herniaType"
                        onChange={(e) => setHerniaType(Number(e.target.value))}
                        value={herniaType}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio color="secondary" />}
                          label="Internal"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="secondary" />}
                          label="Abdominal wall"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio color="secondary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  </Stack>
                )}
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>Marginal Ulcer</Typography>
                  <Box>
                    <FormControlLabel
                      name="marginalUlcer"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={lateComplicationValues.marginalUlcer === true}
                    />
                    <FormControlLabel
                      name="marginalUlcer"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={lateComplicationValues.marginalUlcer === false}
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    Anastomosis stricture
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="anastomosisStricture"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={
                        lateComplicationValues.anastomosisStricture === true
                      }
                    />
                    <FormControlLabel
                      name="anastomosisStricture"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={
                        lateComplicationValues.anastomosisStricture === false
                      }
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>Dumping syndrome</Typography>
                  <Box>
                    <FormControlLabel
                      name="dumpingSyndrome"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={lateComplicationValues.dumpingSyndrome === true}
                    />
                    <FormControlLabel
                      name="dumpingSyndrome"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={lateComplicationValues.dumpingSyndrome === false}
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>VTE</Typography>
                  <Box>
                    <FormControlLabel
                      name="vte"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={lateComplicationValues.vte === true}
                    />
                    <FormControlLabel
                      name="vte"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={lateComplicationValues.vte === false}
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    Cardiovascular events (MI, stroke)
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="cardiovascularEvents"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={
                        lateComplicationValues.cardiovascularEvents === true
                      }
                    />
                    <FormControlLabel
                      name="cardiovascularEvents"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={
                        lateComplicationValues.cardiovascularEvents === false
                      }
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    Psychiatric problems
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="psychiatricProblems"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={
                        lateComplicationValues.psychiatricProblems === true
                      }
                    />
                    <FormControlLabel
                      name="psychiatricProblems"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={
                        lateComplicationValues.psychiatricProblems === false
                      }
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>Osteoporosis</Typography>
                  <Box>
                    <FormControlLabel
                      name="osteoporosis"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={lateComplicationValues.osteoporosis === true}
                    />
                    <FormControlLabel
                      name="osteoporosis"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={lateComplicationValues.osteoporosis === false}
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>Other</Typography>
                  <Box>
                    <FormControlLabel
                      name="other"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={lateComplicationValues.other === true}
                    />
                    <FormControlLabel
                      name="other"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={lateComplicationValues.other === false}
                    />
                  </Box>
                </Stack>

                {lateComplicationValues.other && (
                  <Stack spacing={1}>
                    {otherDetails.map((detail, index) => {
                      return (
                        <Stack
                          key={index}
                          spacing={1}
                          direction={"row"}
                          // alignItems={"center"}
                        >
                          <Box sx={{ pt: 1 }}>
                            <OtherArrow />
                          </Box>

                          <TextField
                            required
                            sx={{ width: "30%" }}
                            value={detail}
                            onChange={(e) => handleOtherChange(index, e)}
                            error={validationError && !detail.trim()}
                            helperText={
                              validationError && !detail.trim()
                                ? "This field is required"
                                : ""
                            }
                          />
                          <Box
                            sx={{ pt: 0.5 }}
                            onClick={() => handleOtherRemove(index)}
                          >
                            <OtherRemove />
                          </Box>
                          <Box sx={{ pt: 0.5 }} onClick={handleOtherAdd}>
                            <OtherAdd />
                          </Box>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
          <StatusBottomBar
            value={status}
            handleStatusChange={handleStatusChange}
          />
        </Box>
      </Box>
      <LateComplicationHistoryDialog
        open={openHistoryDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <LateComplicationVisitLogDialog
        open={openVisitLogDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
    </LayoutTab>
  );
};

import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Frame_1672 from "../asset/Frame_1672.png";
import viewMode from "../asset/viewMode.png";
import downloadImg from "../asset/download.png";
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { WarningDialog } from "../components/dialog/WarningDialog";
import "../Pages/Register/Register.css";
import { userManagementService } from "../service/userManagementService";

type Document = {
  documentId: number;
  documentType: String;
  documentCategory: String;
  uploadDateTime: String;
  documentName: String;
  fileSize: number;
};
interface DownloadFileProps {
  userId: string;
}
function formatBytes(bytes: number): string {
  const megabytes = bytes / (1024 * 1024);
  if (megabytes < 1) {
    return `${bytes.toFixed(2)} bytes`;
  }

  return `${megabytes.toFixed(2)} MB`;
}
const DownloadFile: React.FC<DownloadFileProps> = ({ userId }) => {
  const [files, setFiles] = useState<string[]>([]);
  const [openUnsaveDataDialog, setOpenUnsaveDataDialog] = useState(false);
  const [dialog, setDialog] = useState({ title: "", content: "" });
  const [documentList, setDocumentList] = useState<Document[]>([]);

  const handleCloseDialog = () => {
    setOpenUnsaveDataDialog(false);
  };
  const handleConfirmDialog = () => {
    setOpenUnsaveDataDialog(false);
  };

  const handleRemove = (index: number) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const documentLists = await userManagementService.getDocumentList(
          userId
        );
        setDocumentList(documentLists);
      } catch (error: any) {
        console.log("Error", error);
      }
    };
    fetchData();
  }, []);

  const showFile = async (documentId: string) => {
    try {
      const response = await userManagementService.getDocumentById(documentId);
      if (response) {
        const url = URL.createObjectURL(response);
        window.open(url, "_blank");
      } else {
        console.error("Image data not found in response", response);
      }
    } catch (error) {
      console.error("Error fetching image", error);
    }
  };

  const downloadFile = async (documentId: string, documentName: string) => {
    try {
      const response = await userManagementService.getDocumentById(documentId);
      if (response) {
        const url = URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = url;
        a.download = documentName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error fetching image", error);
    }
  };
  return (
    <div className="preview-section">
      <ListItem
        style={{
          border: "1px solid #ccc",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <ListItemAvatar>
          <img src={Frame_1672} alt="File Icon" />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span style={{ fontSize: "14px", fontWeight: 500 }}>
              บัตรประจำตัวแพทย์
            </span>
          }
          secondary={
            <span
              style={{
                color: "#9E9E9E",
                wordBreak: "break-word",
                whiteSpace: "normal",
                fontWeight: 500,
              }}
            >
              {`${documentList[0]?.documentName} , ${formatBytes(
                documentList[0]?.fileSize
              )}`}
            </span>
          }
        />
        <IconButton
          edge="end"
          aria-label="delete"
          component="label"
          onClick={() => showFile(documentList[0]?.documentId.toString())}
        >
          <img src={viewMode} />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          component="label"
          onClick={() =>
            downloadFile(
              documentList[0]?.documentId.toString(),
              documentList[0]?.documentName.toString()
            )
          }
        >
          <img src={downloadImg} />
        </IconButton>
      </ListItem>
      {documentList[1]?.documentName && (
        <ListItem
          style={{
            border: "1px solid #ccc",
            marginBottom: "10px",
            borderRadius: "4px",
          }}
        >
          <ListItemAvatar>
            <img src={Frame_1672} alt="File Icon" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span style={{ fontSize: "14px", fontWeight: 500 }}>
                บัตรประจำตัวแพทย์
              </span>
            }
            secondary={
              <span
                style={{
                  color: "#9E9E9E",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  fontWeight: 500,
                }}
              >
                {`${documentList[1]?.documentName} , ${formatBytes(
                  documentList[1]?.fileSize
                )}`}
              </span>
            }
          />
          <IconButton
            edge="end"
            aria-label="delete"
            component="label"
            onClick={() => showFile(documentList[1]?.documentId.toString())}
          >
            <img src={viewMode} />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            component="label"
            onClick={() =>
              downloadFile(
                documentList[1]?.documentId.toString(),
                documentList[1]?.documentName.toString()
              )
            }
          >
            <img src={downloadImg} />
          </IconButton>
        </ListItem>
      )}
      {documentList[2]?.documentName && (
        <ListItem
          style={{
            border: "1px solid #ccc",
            marginBottom: "10px",
            borderRadius: "4px",
          }}
        >
          <ListItemAvatar>
            <img src={Frame_1672} alt="File Icon" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span style={{ fontSize: "14px", fontWeight: 500 }}>
                เอกสารอื่นๆ
              </span>
            }
            secondary={
              <span
                style={{
                  color: "#9E9E9E",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  fontWeight: 500,
                }}
              >
                {`${documentList[2]?.documentName} , ${formatBytes(
                  documentList[2]?.fileSize
                )}`}
              </span>
            }
          />
          <IconButton
            edge="end"
            aria-label="delete"
            component="label"
            onClick={() => showFile(documentList[2]?.documentId.toString())}
          >
            <img src={viewMode} />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            component="label"
            onClick={() =>
              downloadFile(
                documentList[2]?.documentId.toString(),
                documentList[2]?.documentName.toString()
              )
            }
          >
            <img src={downloadImg} />
          </IconButton>
        </ListItem>
      )}
      <WarningDialog
        openDialog={openUnsaveDataDialog}
        title={dialog.title}
        content={dialog.content}
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleConfirmDialog}
      />
    </div>
  );
};
export default DownloadFile;

import React, { useEffect, useState } from "react";
import MenuAppBar from "./shared/MenuAppBar";
import { Box } from "@mui/material";
import VerticalTab from "./shared/VerticalTab";
import { VerticalTabs } from "./type";

type Props = {
  children: React.ReactNode;
  activeTab: VerticalTabs;
};

export function Layout({ children, activeTab }: Props) {
  const [dimensions, setDimensions] = useState({
    adjustedHeight: window.innerHeight,
    adjustedWidth: window.innerWidth,
  });

  useEffect(() => {
    // Function to adjust the dimensions based on zoom level
    const adjustDimensions = () => {
      const zoomLevel = 0.85; // 85% zoom
      const adjustedHeight = window.innerHeight / zoomLevel;
      const adjustedWidth = window.innerWidth / zoomLevel;
      setDimensions({ adjustedHeight, adjustedWidth });
    };

    // Initial adjustment
    adjustDimensions();

    // Add event listener for window resize to re-adjust the dimensions
    window.addEventListener("resize", adjustDimensions);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", adjustDimensions);
    };
  }, []);

  // Detect browser
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  return (
    <div style={{ minHeight: "100%", minWidth: "100%" }}>
      <div style={{ width: `calc(${dimensions.adjustedWidth}px)` }}>
        <MenuAppBar />
      </div>

      {isChrome && (
        <div style={{ position: "fixed", height: "100%" }}>
          <VerticalTab activeTab={activeTab} />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flex: 1,
          // minHeight: `calc(${dimensions.adjustedHeight}px - 72px)`,
          height: `calc(${dimensions.adjustedHeight}px - 72px)`,
          width: `calc(${dimensions.adjustedWidth}px)`,
        }}
      >
        {isSafari && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <VerticalTab activeTab={activeTab} />
          </div>
        )}
        <Box
          sx={{
            backgroundColor: "#DEE2E6",
            display: "flex",
            flex: 1,
            overflow: "auto",
            scrollbarWidth: "thin",
            marginLeft: isChrome ? "110px" : "0",
            ...(isSafari && {
              // Safari-specific styles
              height: `calc(${dimensions.adjustedHeight}px - 72px)`, // Extended height
              width: `calc(${dimensions.adjustedWidth}px)`,
            }),
            ...(isChrome && {
              // Chrome-specific styles
              // minHeight: `calc(${dimensions.adjustedHeight}px - 72px)`,
              // width: `calc(${dimensions.adjustedWidth}px)`,
              height: "inherit",
              width: "inherit",
            }),
          }}
        >
          {children}
        </Box>
      </div>
    </div>
  );
}

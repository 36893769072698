import CloseIcon from "@mui/icons-material/Close";
import { Box, MenuItem, Select, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { ChangeEvent, useState } from "react";
import {
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useEffectOnce } from "react-use";
import { updatePatientUserProfile } from "../../../redux/features/patientSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { healthBenefitList } from "../../../sharedValue";
import { body14400, profileTitle, spacingForm } from "../../../style";
import { theme2 } from "../../../theme";
import { MasterData, PatientUserProfile } from "../../../type";
import { calculateAge } from "../../../utils/calculateAge";
import { getDomicile } from "../../../utils/get-data/getDomicile";
import { getHospital } from "../../../utils/get-data/getHospital";
import { getOccupation } from "../../../utils/get-data/getOccupation";
import { getPrefix } from "../../../utils/get-data/getPrefix";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
type Props = {
  openDialog: boolean;
  handleCloseDialog: () => void;
  handleSubmit: () => void;
};
export default function ProfileDialog({
  openDialog,
  handleCloseDialog,
  handleSubmit,
}: Props) {
  const dispatch = useAppDispatch();
  const patientUserProfile = useAppSelector(
    (state) => state.patientUser.selectedPatientUserProfile
  ) as PatientUserProfile;
  const [occupationList, setOccupationList] = useState<MasterData[]>();
  const [domicileList, setDomicileList] = useState<MasterData[]>();
  const [hospitalList, setHospitalList] = useState<MasterData[]>();
  const [prefixList, setPrefixList] = useState<MasterData[]>();
  const [isHn, setIsHn] = useState<string | undefined>("");

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const occupationList = await getOccupation();
        setOccupationList(occupationList);
        const domicileList = await getDomicile();
        setDomicileList(domicileList);
        const prefixList = await getPrefix();
        setPrefixList(prefixList);
        const hospitalList = await getHospital();
        setHospitalList(hospitalList);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
    setIsHn(patientUserProfile.hn ? "มี" : "ไม่มี");
  });

  const handleIsHnChange = (
    value:
      | string
      | {
          id: string;
          label: JSX.Element;
        }
      | undefined
  ) => {
    setIsHn(value?.toString());
    if (value?.toString() === "ไม่มี") {
      dispatch(updatePatientUserProfile({ hn: "" }));
    }
  };

  const handleHnChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updatePatientUserProfile({ hn: event.target.value }));
  };
  const handlePrefixChange = (value: string) => {
    dispatch(updatePatientUserProfile({ prefix: value }));
  };
  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updatePatientUserProfile({ firstName: event.target.value }));
  };
  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updatePatientUserProfile({ lastName: event.target.value }));
  };
  const handleGenderChange = (
    value:
      | string
      | {
          id: string;
          label: string;
        }
      | undefined
  ) => {
    if (value === "ชาย") {
      dispatch(
        updatePatientUserProfile({
          stillHavingPeriod: null,
          periodGapDuration: "",
          isPregnant: null,
          pregnancyStatusList: [],
          numberOfPregnancy: 0,
          numberOfMiscarriage: 0,
        })
      );
    }
    dispatch(updatePatientUserProfile({ sex: value?.toString() }));
  };
  const handleBirthDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    dispatch(updatePatientUserProfile({ birthDate: formattedDate }));
  };
  const handleOccupationChange = (value: string) => {
    dispatch(updatePatientUserProfile({ occupation: value }));
    if (value !== "อื่นๆ") {
      dispatch(
        updatePatientUserProfile({
          occupationOther: "",
        })
      );
    }
  };
  const handleOccupationOtherChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updatePatientUserProfile({ occupationOther: event.target.value }));
  };
  const handleDomicileChange = (value: string) => {
    dispatch(updatePatientUserProfile({ domicile: value }));
  };
  const handleHospitalChange = (value: string) => {
    dispatch(updatePatientUserProfile({ affiliatedHospital: value }));
  };
  const handleHealthBenefitChange = (value: string) => {
    dispatch(updatePatientUserProfile({ healthBenefit: value }));
    if (value !== "อื่นๆ") {
      dispatch(
        updatePatientUserProfile({
          healthBenefitOther: "",
        })
      );
    }
  };
  const handleHealthBenefitOtherChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updatePatientUserProfile({ healthBenefitOther: event.target.value })
    );
  };
  return (
    <ThemeProvider theme={theme2}>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="profile-dialog-title"
        open={openDialog}
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, py: 1, px: 2, bgcolor: "#F7F8FC" }}
          id="profile-dialog-title"
        >
          <Typography fontSize={18} fontWeight={500}>
            ข้อมูลส่วนตัว
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ scrollbarWidth: "thin" }}>
          <FormContainer
            defaultValues={
              patientUserProfile && {
                isHn: patientUserProfile.hn !== null ? "มี" : "ไม่มี",
                hn: patientUserProfile.hn !== null ? patientUserProfile.hn : "",
                prefix: patientUserProfile.prefix,
                firstName: patientUserProfile.firstName,
                lastName: patientUserProfile.lastName,
                sex: patientUserProfile.sex,
                occupation: patientUserProfile.occupation ?? "",
                occupationOther: patientUserProfile.occupationOther,
                domicile: patientUserProfile.domicile,
                hospital: patientUserProfile.affiliatedHospital,
                healthBenefit: patientUserProfile.healthBenefit,
                healthBenefitOther: patientUserProfile.healthBenefitOther,
              }
            }
            // onSuccess={(data) => console.log(data)}
            onSuccess={handleSubmit}
          >
            <Stack spacing={3} sx={{ pb: 6 }}>
              <Typography component="label" color={"#646D78"}>
                กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Stack>
                <Typography component="label" style={profileTitle}>
                  มีข้อมูล HN <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioButtonGroup
                  row
                  required
                  name="isHn"
                  onChange={(e) => handleIsHnChange(e)}
                  options={[
                    {
                      id: "มี",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          มี
                        </span>
                      ),
                    },
                    {
                      id: "ไม่มี",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          ไม่มี
                        </span>
                      ),
                    },
                  ]}
                />
              </Stack>
              {isHn === "มี" && (
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    HN <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required={isHn === "มี"}
                    name={"hn"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handleHnChange}
                    InputProps={{
                      value: patientUserProfile.hn,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                  />
                </Stack>
              )}
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  คำนำหน้า <span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectElement
                  required
                  name="prefix"
                  size="small"
                  sx={{ width: 1 }}
                  onChange={(e) => handlePrefixChange(e)}
                  SelectProps={{
                    value: patientUserProfile.prefix,
                    displayEmpty: true,
                    sx: {
                      fontSize: 14,
                    },
                    MenuProps: {
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                          minWidth: "465px !important",
                        },
                      },
                    },
                  }}
                  options={[
                    {
                      id: "",
                      label: "โปรดระบุ",
                      disabled: true,
                    },
                    ...(prefixList
                      ? prefixList.map((item) => ({
                          id: item.nameThai,
                          label: item.nameThai,
                        }))
                      : []),
                  ]}
                />
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    ชื่อ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name={"firstName"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handleFirstNameChange}
                    InputProps={{
                      value: patientUserProfile.firstName,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    นามสกุล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name={"lastName"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handleLastNameChange}
                    InputProps={{
                      value: patientUserProfile.lastName,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  เพศ <span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectElement
                  required
                  name="sex"
                  size="small"
                  sx={{ width: 1 }}
                  onChange={(e) => handleGenderChange(e)}
                  SelectProps={{
                    value: patientUserProfile.sex,
                    displayEmpty: true,
                    sx: {
                      fontSize: 14,
                    },
                    MenuProps: {
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                          minWidth: "465px !important",
                        },
                      },
                    },
                  }}
                  options={[
                    {
                      id: "",
                      label: "โปรดระบุ",
                      disabled: true,
                    },
                    {
                      id: "male",
                      label: "male (ผู้ชาย)",
                    },
                    {
                      id: "female",
                      label: "female (ผู้หญิง)",
                    },
                  ]}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  วันเดือนปีเกิด <span style={{ color: "red" }}>*</span>
                </Typography>
                <DatePicker
                  maxDate={dayjs()}
                  value={dayjs(patientUserProfile.birthDate)}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      fontSize: 14,
                    },
                  }}
                  onChange={(e) => handleBirthDateChange(e)}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  อายุ
                </Typography>
                <Typography
                  component="label"
                  style={body14400}
                  color={"#098484"}
                >
                  {calculateAge(patientUserProfile.birthDate)}
                </Typography>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  อาชีพ
                </Typography>
                <SelectElement
                  name="occupation"
                  value={patientUserProfile.occupation}
                  size="small"
                  sx={{ width: 1 }}
                  onChange={(e) => handleOccupationChange(e)}
                  SelectProps={{
                    value: patientUserProfile.occupation ?? "",
                    displayEmpty: true,
                    sx: {
                      fontSize: 14,
                      color: "#212121",
                    },
                    MenuProps: {
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                          minWidth: "465px !important",
                        },
                      },
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                    },
                  }}
                  options={[
                    {
                      id: "",
                      label: "โปรดระบุ",
                      disabled: true,
                    },
                    ...(occupationList
                      ? occupationList?.map((item) => ({
                          id: item.nameThai,
                          label: item.nameThai,
                        }))
                      : []),
                  ]}
                />
              </Stack>
              {patientUserProfile.occupation === "อื่นๆ (โปรดระบุ)" && (
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    อาชีพ (อื่นๆ) <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required={
                      patientUserProfile.occupation === "อื่นๆ (โปรดระบุ)"
                    }
                    name={"occupationOther"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    InputProps={{
                      value: patientUserProfile.occupationOther,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={handleOccupationOtherChange}
                  />
                </Stack>
              )}
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  ภูมิลำเนา
                </Typography>
                <SelectElement
                  name="domicile"
                  value={patientUserProfile.domicile}
                  size="small"
                  sx={{ width: 1 }}
                  onChange={(e) => handleDomicileChange(e)}
                  SelectProps={{
                    value: patientUserProfile.domicile ?? "",
                    displayEmpty: true,
                    sx: {
                      fontSize: 14,
                      color: "#212121",
                    },
                    MenuProps: {
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                          minWidth: "465px !important",
                        },
                      },
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                    },
                  }}
                  options={[
                    {
                      id: "",
                      label: "โปรดระบุ",
                      disabled: true,
                    },
                    ...(domicileList
                      ? domicileList?.map((item) => ({
                          id: item.nameThai,
                          label: item.nameThai,
                        }))
                      : []),
                  ]}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  โรงพยาบาล
                </Typography>
                <Select
                  name="hospital"
                  value={patientUserProfile.affiliatedHospital ?? ""}
                  size="small"
                  sx={{ width: 1, fontSize: 14, color: "#212121" }}
                  onChange={(e) => handleHospitalChange(e.target.value)}
                  displayEmpty
                  MenuProps={{
                    sx: {
                      zoom: 100 * (100 / 85) + "%",
                      top: "0px",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: "250px",
                        scrollbarWidth: "thin",
                        minWidth: "465px !important",
                      },
                    },
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  }}
                >
                  <MenuItem disabled value={""}>
                    โปรดระบุ
                  </MenuItem>
                  {hospitalList &&
                    hospitalList.length > 0 &&
                    hospitalList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.nameThai}>
                          {item.nameThai}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  สิทธิ์การรักษา
                </Typography>
                <SelectElement
                  name="healthBenefit"
                  value={patientUserProfile.healthBenefit}
                  size="small"
                  sx={{ width: 1 }}
                  onChange={(e) => handleHealthBenefitChange(e)}
                  SelectProps={{
                    value: patientUserProfile.healthBenefit ?? "",
                    displayEmpty: true,
                    sx: {
                      fontSize: 14,
                      color: "#212121",
                    },
                    MenuProps: {
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                          minWidth: "465px !important",
                        },
                      },
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                    },
                  }}
                  options={[
                    {
                      id: "",
                      label: "โปรดระบุ",
                      disabled: true,
                    },
                    ...(healthBenefitList
                      ? healthBenefitList?.map((item) => ({
                          id: item,
                          label: item,
                        }))
                      : []),
                  ]}
                />
              </Stack>
              {patientUserProfile.healthBenefit === "อื่นๆ" && (
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    สิทธิ์การรักษา (อื่นๆ){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required={patientUserProfile.healthBenefit === "อื่นๆ"}
                    name={"healthBenefitOther"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    InputProps={{
                      value: patientUserProfile.healthBenefitOther,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={handleHealthBenefitOtherChange}
                  />
                </Stack>
              )}
            </Stack>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                bgcolor: "white",
                height: "45px",
                display: "flex",
                justifyContent: "flex-end",
                width: "95%",
                pt: 1,
                borderTop: "1px solid #E3E7F0",
              }}
            >
              <Stack direction={"row"} spacing={1} height={35} sx={{ pr: 2 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleCloseDialog}
                  sx={{
                    width: 100,
                    bgcolor: "#7A8688",
                    "&:hover": {
                      bgcolor: "#7A8688",
                    },
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  autoFocus
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    width: 100,
                    bgcolor: "#098484",
                    "&:hover": {
                      bgcolor: "#098484",
                    },
                  }}
                >
                  บันทึก
                </Button>
              </Stack>
            </Box>
          </FormContainer>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  );
}

import 'dayjs/locale/th'
import Dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

Dayjs.extend(buddhistEra)

export default class newAdapter extends AdapterDayjs {
    /*eslint-disable-next-line*/
    formatByString = (date: any, format: string) => {
        const newFormat = format.replace(/\bYYYY\b/g, 'BBBB')

        return this.dayjs(date).format(newFormat)
    }
}

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Pagination,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useNavigate } from "react-router";
import { DeleteDialog } from "../../components/dialog/DeleteDialog";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { AddIcon, DeleteIcon, EditIcon } from "../../icon";
import { Layout } from "../../Layout";
import { store } from "../../redux/store";
import { masterDataService } from "../../service/masterDataService";
import { MasterDataFiltering } from "../../shared/MasterDataFiltering";
import { labelForm, spacingForm, title16500 } from "../../style";
import { theme2 } from "../../theme";

export type CustomFieldItem = {
  id: number;
  name: string;
  type: string;
  measurementUnit: string;
  description: string;
  isActivated: boolean;
};

type CreateUpdateCustomFieldMasterPayload = {
  id?: number;
  name: string;
  type: string;
  measurementUnit: string;
  description: string;
  isActivated: boolean;
};

const tableCellValues = [
  { name: "ลำดับ", width: "5%", align: "center" },
  { name: "ชื่อฟิลด์ข้อมูล", width: "25%", align: "left" },
  { name: "Type", width: "10%", align: "left" },
  { name: "หน่วย", width: "10%", align: "left" },
  { name: "Description", width: "30%", align: "left" },
  { name: "สถานะ", width: "10%", align: "left" },
  { name: "เครื่องมือ", width: "10%", align: "center" },
];

export const CustomFieldMaster = () => {
  const navigate = useNavigate();
  const filterRef = useRef<HTMLDivElement>(null);
  const [customFieldList, setCustomFieldList] = useState<CustomFieldItem[]>([]);
  const [filteredList, setFilteredList] = useState<CustomFieldItem[]>([]);
  const [currentCustomFieldItem, setCurrentCustomFieldItem] =
    useState<CustomFieldItem>();
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(0);
  const [activeStatus, setActiveStatus] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isFetchData, setIsFetchData] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [messageError, setMessageError] = useState(
    "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
  );
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const masterDataPermissionAddAble =
    userId === "1" ? true : userPermission?.masterDataPermission.addAble;
  const masterDataPermissionEditAble =
    userId === "1" ? true : userPermission?.masterDataPermission.editAble;
  const masterDataPermissionDeleteAble =
    userId === "1" ? true : userPermission?.masterDataPermission.deleteAble;

  const queryFunction = async () => {
    const response = await masterDataService.getCustomFieldMasterData();
    return response;
  };
  const {
    data: customFieldData,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["customeFieldMasterData"],
    queryFn: queryFunction,
    enabled: false,
  });
  useEffect(() => {
    if (error && error instanceof AxiosError) {
      if (error.response?.status === 401) {
        setIsTokenExpired(true);
      } else {
        setIsShowError(true);
      }
    }
  }, [error]);
  const {
    mutateAsync: createCustomFieldMasterAsync,
    isPending: isPendingCreateCustomFieldMaster,
  } = useMutation({
    mutationFn: async ({
      name,
      type,
      measurementUnit,
      description,
      isActivated,
    }: CreateUpdateCustomFieldMasterPayload) => {
      const response = await masterDataService.createCustomFieldMasterData({
        name: name,
        type: type,
        measurementUnit: measurementUnit,
        description: description,
        isActivated: isActivated,
      });
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          setIsShowError(true);
        }
      }
    },
  });
  const {
    mutateAsync: updateCustomFieldMasterAsync,
    isPending: isPendingUpdateCustomFieldMaster,
  } = useMutation({
    mutationFn: async ({
      id,
      name,
      type,
      measurementUnit,
      description,
      isActivated,
    }: CreateUpdateCustomFieldMasterPayload) => {
      const response = await masterDataService.updateCustomFieldMasterData({
        id: id,
        name: name,
        type: type,
        measurementUnit: measurementUnit,
        description: description,
        isActivated: isActivated,
      });
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          setIsShowError(true);
        }
      }
    },
  });
  const { mutateAsync: deleteCustomFieldMasterAsync } = useMutation({
    mutationFn: async (id: string) => {
      const response = await masterDataService.deleteCustomFieldMasterData(id);
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          if (
            error.response?.data.message ===
            "Cannot perform operation, data is in use"
          ) {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล เนื่องจากมีการใช้ข้อมูลทำให้ไม่สามารถลบได้"
            );
          } else {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }
          setIsShowError(true);
        }
      }
    },
  });
  useEffect(() => {
    refetch();
  }, [isFetchData]);
  useEffect(() => {
    if (customFieldData) {
      setCustomFieldList(customFieldData);
      setFilteredList(customFieldData);
    } else {
      setCustomFieldList([]);
      setFilteredList([]);
    }
  }, [customFieldData]);
  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleTextInputChange = (newValue: string) => {
    setSearchText(newValue);
  };
  const handleSearchTypeChange = (newValue: string) => {
    setSearchType(newValue);
  };
  const handleSearchStatusChange = (newValue: string) => {
    setSearchStatus(newValue);
  };
  const handleSearchClick = () => {
    const result = customFieldList.filter((item) => {
      const matchesText = item.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const matchType = searchType ? item.type === searchType : true;
      const currentStatus = item.isActivated ? "ใช้งานได้" : "ปิดการใช้งาน";
      const matchesStatus = searchStatus
        ? currentStatus === searchStatus
        : true;
      return matchesText && matchType && matchesStatus;
    });
    setPage(1);
    setFilteredList(result);
  };
  const handleClearSearchClick = () => {
    setSearchText("");
    setSearchStatus("");
    setSearchType("");
    setFilteredList(customFieldList);
  };
  const handleActiveStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveStatus(event.target.checked);
  };
  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };
  const handleAddNewItem = async (data: FieldValues) => {
    await createCustomFieldMasterAsync({
      name: data.name,
      type: data.type,
      measurementUnit: data.measurementUnit,
      description: data.description,
      isActivated: activeStatus,
    });
    setIsFetchData((prev) => !prev);
    // const newItem = {
    //   name: data.name,
    //   type: data.type,
    //   measurementUnit: "g.",
    //   description: data.description,
    //   isActivated: activeStatus
    // };
    // setFilteredList([...customFieldList, newItem]);
    // customFieldList.push(newItem);
    handleCloseDialog();
  };
  const handleDeleteClick = (index: number) => {
    setDeleteItemIndex(index);
    setOpenDeleteDialog(true);
  };
  const handleEditClick = (data: CustomFieldItem) => {
    setOpenEditDialog(true);
    setCurrentCustomFieldItem(data);
    setActiveStatus(data?.isActivated);
  };
  const handleEditItem = async (data: CustomFieldItem) => {
    // setCustomFieldList((prevList) =>
    //   prevList.map((item) =>
    //     item.name === currentCustomFieldItem?.name
    //       ? { ...data, status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน" }
    //       : item
    //   )
    // );
    // setFilteredList((prevList) =>
    //   prevList.map((item) =>
    //     item.name === currentCustomFieldItem?.name
    //       ? { ...data, status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน" }
    //       : item
    //   )
    // );
    if (currentCustomFieldItem) {
      await updateCustomFieldMasterAsync({
        id: currentCustomFieldItem.id,
        name: data.name,
        type: data.type,
        measurementUnit: data.measurementUnit,
        description: data.description,
        isActivated: activeStatus,
      });
      setIsFetchData((prev) => !prev);
      handleCloseDialog();
    }
  };
  const handleConfirmDeleteDialog = async () => {
    // setCustomFieldList((prevList) =>
    //   prevList.filter((_, i) => i !== deleteItemTemp)
    // );
    // setFilteredList((prevList) =>
    //   prevList.filter((_, i) => i !== deleteItemTemp)
    // );
    // setOpenDeleteDialog(false);
    await deleteCustomFieldMasterAsync(deleteItemIndex.toString());
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleAddClick = () => {
    setActiveStatus(true);
    setOpenAddDialog(true);
  };
  return (
    <Layout activeTab="setting">
      <ThemeProvider theme={theme2}>
        <Box sx={{ p: "20px", width: 1 }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              <Stack direction={"row"} gap={1}>
                <Box
                  onClick={() => navigate("/masterData")}
                  sx={{ cursor: "pointer" }}
                >
                  ตั้งค่าข้อมูลหลัก
                </Box>
                /
                <Typography
                  component={"span"}
                  color={"#7A8688"}
                  style={title16500}
                >
                  {`Custom Field`}
                </Typography>
              </Stack>
            </Typography>
          </Box>
          <Box ref={filterRef}>
            <MasterDataFiltering
              isType
              title="Custom field"
              searchText={searchText}
              searchType={searchType}
              searchStatus={searchStatus}
              searchTextLabel="ค้นหาชื่อฟิลด์ข้อมูล"
              handleSearchTextChange={handleTextInputChange}
              handleSearchTypeChange={handleSearchTypeChange}
              handleSearchStatusChange={handleSearchStatusChange}
              handleSearchClick={handleSearchClick}
              handleClearSearchClick={handleClearSearchClick}
            />
          </Box>

          <Box
            sx={{
              bgcolor: "white",
              mt: 2,
              overflow: "auto",
              scrollbarWidth: "thin",
              p: "20px",
              boxSizing: "border-box",
            }}
          >
            {masterDataPermissionAddAble && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#FF366B",
                  float: "right",
                  height: "40px",
                  fontSize: "14px",
                  marginBottom: "20px",
                  minWidth: "90px",
                }}
                startIcon={<AddIcon />}
                onClick={handleAddClick}
              >
                เพิ่ม
              </Button>
            )}
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableCellValues.map((item, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            width: item.width,
                            textAlign: item.align,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {filteredList.length > 0 && (
                    <TableBody>
                      {filteredList
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ textAlign: "center" }}>
                              {(page - 1) * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>{item.measurementUnit}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "5px",
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: item.isActivated
                                      ? "green"
                                      : "red",
                                  }}
                                />
                                {item.isActivated
                                  ? "ใช้งานได้"
                                  : "ปิดการใช้งาน"}
                              </div>
                            </TableCell>
                            <TableCell>
                              <Stack
                                spacing={1}
                                direction={"row"}
                                justifyContent={"center"}
                              >
                                <Box
                                  sx={{
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "secondary.main",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 14,
                                    cursor: masterDataPermissionEditAble
                                      ? "pointer"
                                      : "not-allowed",
                                    pointerEvents: masterDataPermissionEditAble
                                      ? "auto"
                                      : "none",
                                  }}
                                  onClick={() => {
                                    if (masterDataPermissionEditAble) {
                                      handleEditClick(item);
                                    }
                                  }}
                                >
                                  <EditIcon
                                    fontSize="inherit"
                                    color="secondary"
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    border: "1px solid #7A8688",
                                    borderRadius: 2,
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#7A8688",
                                    fontSize: 14,
                                    cursor: masterDataPermissionDeleteAble
                                      ? "pointer"
                                      : "not-allowed",
                                    pointerEvents:
                                      masterDataPermissionDeleteAble
                                        ? "auto"
                                        : "none",
                                  }}
                                  onClick={() => {
                                    if (masterDataPermissionDeleteAble) {
                                      handleDeleteClick(item.id);
                                    }
                                  }}
                                >
                                  <DeleteIcon
                                    fontSize="inherit"
                                    color="inherit"
                                  />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {filteredList.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 20,
                    color: "#9AA1AE",
                    py: 2,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "100px",
                  }}
                >
                  {isLoading ? (
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <CircularProgress size={20} />
                      {` กำลังโหลดข้อมูล...`}
                    </Box>
                  ) : (
                    "--ไม่พบข้อมูล--"
                  )}
                </Box>
              )}
            </Box>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                count={Math.ceil(filteredList.length / rowsPerPage)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                sx={{ mt: 2 }}
              />
            </div>
          </Box>
        </Box>
        {/* Add  Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>เพิ่ม</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer onSuccess={(data) => handleAddNewItem(data)}>
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อฟิลด์ข้อมูล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                    // placeholder="โปรดระบุข้อมูล"
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        color: "black",
                        opacity: 1,
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <SelectElement
                    name="type"
                    required
                    id="type"
                    value={""}
                    size="small"
                    // SelectProps={{
                    //   displayEmpty: true,
                    // }}
                    options={[
                      {
                        id: "",
                        label: "- โปรดระบุข้อมูล --",
                        disabled: true,
                      },
                      {
                        id: "TEXT",
                        label: "TEXT",
                      },
                      {
                        id: "NUMBER",
                        label: "NUMBER",
                      },
                    ]}
                    sx={{
                      minWidth: "150px",
                    }}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    หน่วย <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="measurementUnit"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>Description</Typography>
                  <TextFieldElement
                    name="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    checked={activeStatus}
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={isPendingCreateCustomFieldMaster}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>แก้ไข</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer
              onSuccess={handleEditItem}
              defaultValues={{
                name: currentCustomFieldItem?.name,
                type: currentCustomFieldItem?.type,
                measurementUnit: currentCustomFieldItem?.measurementUnit,
                description: currentCustomFieldItem?.description,
              }}
            >
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อฟิลด์ข้อมูล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <SelectElement
                    name="type"
                    required
                    id="type"
                    value={""}
                    size="small"
                    options={[
                      {
                        id: "",
                        label: "- โปรดระบุข้อมูล --",
                      },
                      {
                        id: "TEXT",
                        label: "TEXT",
                      },
                      {
                        id: "NUMBER",
                        label: "NUMBER",
                      },
                    ]}
                    sx={{
                      minWidth: "150px",
                    }}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    หน่วย <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="measurementUnit"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>Description</Typography>
                  <TextFieldElement
                    name="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={
                      currentCustomFieldItem?.isActivated ? true : false
                    }
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={isPendingUpdateCustomFieldMaster}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Delete Dialog */}
        <DeleteDialog
          openDialog={openDeleteDialog}
          title="ต้องการลบรายการใช่หรือไม่"
          content="คุณต้องการลบรายการ"
          content2="ต้องการดำเนินการต่อใช่หรือไม่?"
          handleCloseDialog={handleCloseDialog}
          handleCancelClick={handleCloseDialog}
          handleConfirmClick={handleConfirmDeleteDialog}
        />
        {/* Failure Dialog */}
        <ErrorDialog
          openDialog={isShowError}
          handleCloseDialog={() => {
            setIsShowError(false);
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }}
          title="เกิดข้อผิดพลาด"
          content={messageError}
        />
        {/* Session Expired Dialog */}
        <ErrorDialog
          title="เซสชันหมดอายุ"
          content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
          openDialog={isTokenExpired}
          handleCloseDialog={() => {
            navigate("/");
            setIsTokenExpired(false);
          }}
        />
      </ThemeProvider>
    </Layout>
  );
};

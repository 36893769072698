import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { BodyLabResponse } from "./BodyAndCompositionAndLab";
import { CustomField, CustomLabs, OtherField } from "./Other";
import { masterDataService } from "../../../service/masterDataService";

type Props = {
  open: boolean;
  handleCloseDialog: () => void;
};
interface BodyLab {
  sbp: string;
  dbp: string;
  weight: number;
  bmi: number;
  idealWeight: number;
  excessWeightLoss: number;
  totalWeightLoss: number;
  excessBmiLoss: number;
  smm: string;
  bfm: string;
  pbf: string;
  fbs: string;
  hba1c: string;
  chol: string;
  hdl: string;
  tg: string;
  cr: string;
  alb: string;
  sgot: string;
  sgpt: string;
  alp: string;
  hb: string;
  hct: string;
  mcv: string;
  plt: string;
  vitaminD: string;
  vitaminB12: string;
  folate: string;
  ferritin: string;
  serumIron: string;
  tib: string;
  ldlc: string;
  customLabs: CustomLabs[];
}
const bodyLabLabels: { [key in keyof Omit<BodyLab, "customLabs">]: string } = {
  sbp: "SBP",
  dbp: "DBP",
  weight: "Weight",
  bmi: "BMI",
  idealWeight: "Ideal weight",
  excessWeightLoss: "Percent of excess weight loss (%EWL)",
  totalWeightLoss: "Percent of total weight loss (%TWL)",
  excessBmiLoss: "Percent excess BMI loss (%EBMIL)",
  smm: "SMM",
  bfm: "BFM",
  pbf: "PBF",
  fbs: "FBS",
  hba1c: "HbA1C",
  chol: "Cholesterol",
  hdl: "HDL",
  tg: "Triglyceride",
  ldlc: "LDL-C",
  cr: "Creatinine",
  alb: "Albumin",
  sgot: "SGOT",
  sgpt: "SGPT",
  alp: "ALP",
  hb: "Hb",
  hct: "Hct",
  mcv: "MCV",
  plt: "Platelet",
  vitaminD: "Vitamin D",
  vitaminB12: "Vitamin B12",
  folate: "Folate",
  ferritin: "Ferritin",
  serumIron: "Serum Iron",
  tib: "TIBC",
};
export const BodyAndCompositionHistoryDialog = ({
  open,
  handleCloseDialog,
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [bodyLabHistory, setBodyLabHistory] = useState<BodyLabResponse[]>([]);
  const [customFieldMappingList, setCustomFieldMappingList] = useState<{
    [key: string]: string;
  }>({});
  useEffect(() => {
    const fetchData = async () => {
      const patientId = store.getState().patientUser.patientUser?.id;
      if (patientId) {
        try {
          const bodyLabHistory: BodyLabResponse[] =
            await visitDataService.getBodyLabHistory(patientId);

          const masterData =
            await masterDataService.getCustomFieldMasterDataForMapping();
          setCustomFieldMappingList(masterData);

          const customFieldMapping = masterData.reduce(
            (acc: any, item: any) => {
              acc[item.id] = item.name;
              return acc;
            },
            {}
          );

          setCustomFieldMappingList(customFieldMapping);

          if (bodyLabHistory) {
            setBodyLabHistory(bodyLabHistory);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [open]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={"lg"}>
      <DialogTitle>Visit History</DialogTitle>
      <DialogContent dividers sx={{ overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            maxHeight: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
          }}
        >
          <Table stickyHeader sx={{ maxHeight: 500, overflowY: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    width: 250,
                    bgcolor: "white",
                    color: "secondary.main",
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "1px solid #E5EBEB",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  Visit Date
                </TableCell>

                {bodyLabHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableCell
                      key={index}
                      variant="head"
                      sx={{
                        minWidth: 100,
                        bgcolor: "white",
                        fontWeight: 700,
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      {dayjs(entry.visitDate).format("DD/MM/BBBB")}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(bodyLabLabels)
                .concat("customLabs")
                .map((key) => (
                  <TableRow key={key}>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        textAlign: "center",
                        borderRight: "1px solid #E5EBEB",
                        position: "sticky",
                        left: 0,
                        zIndex: 2,
                        bgcolor: "white",
                      }}
                    >
                      {bodyLabLabels[key as keyof typeof bodyLabLabels] ||
                        "Others"}
                    </TableCell>

                    {bodyLabHistory
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((entry, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            textAlign: "center",
                            alignContent: "baseline",
                          }}
                        >
                          {key === "customLabs" &&
                          Array.isArray(entry.customLabs)
                            ? entry.customLabs.map((lab, i) => (
                                <div key={i}>
                                  <strong>
                                    {customFieldMappingList[lab.customLabId] ||
                                      lab.customLabId}
                                    :
                                  </strong>{" "}
                                  {lab.value  ? lab.value : " -"}
                                </div>
                              ))
                            :  entry[key as keyof BodyLab]?.toString()}
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={bodyLabHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};

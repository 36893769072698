export const generateMaskCitizenId = (citizenId: string) => {
  const maskedId = `*-****-*****-${citizenId.slice(10, 12)}-${citizenId[12]}`;

  return maskedId;
};
export const generateMaskSiteRoleId = (siteRoleId: string) => {
  const length = siteRoleId.length;

  if(length === 1) {
    return "*";
  }
  
  if (length < 3) {
    return "**";
  }

  if (length === 3) {
    return "***";
  }

  const maskedLength = length - 3;
  const maskedId = "*".repeat(maskedLength) + siteRoleId.slice(-3);

  return maskedId;
};

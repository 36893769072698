import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { HematologyDialog } from "./HematologyDialog";
import { BodyLabRow } from "../../../components/BodyLabRow";
import { useEffectOnce } from "react-use";
import { BodyLabResponse } from "./BodyAndCompositionAndLab";
import { visitDataService } from "../../../service/visitDataService";
import { title10Tab } from "../../../style";
import { store } from "../../../redux/store";
import { set } from "date-fns";
import { masterDataService } from "../../../service/masterDataService";
import { LabItem } from "../../../Pages/MaterData/LabMaster";

export type HematologyValues = {
  Hb: string;
  Hct: string;
  MVC: string;
  Platelet: string;
};
type Props = {
  handleSaveHematologyValues: (value: HematologyValues) => void;
  latestEntryId?: number | null;
  currentEntryId?: number | null;
};
export const Hematology = ({
  handleSaveHematologyValues,
  latestEntryId,
  currentEntryId,
}: Props) => {
  const [values, setValues] = useState<HematologyValues>({
    Hb: "",
    Hct: "",
    MVC: "",
    Platelet: "",
  });
  const [openHematologyDialog, setOpenHematologyDialog] = useState(false);
  const [hematologyIsDirty, setHematologyIsDirty] = useState(false);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  
  const handleClickHematology = () => {
    setOpenHematologyDialog(true);
  };
  const handleClickCancle = (newValues: HematologyValues) => {
    setValues(newValues);
    setOpenHematologyDialog(false);
  };
  const handleClickSave = (newValues: HematologyValues) => {
    if(patientDataPermissionEditAble === true){
      setValues(newValues);
      handleSaveHematologyValues(newValues);
      setOpenHematologyDialog(false);
      setHematologyIsDirty(true);
    } else {
      setOpenHematologyDialog(false);
    }
  };
  const [labList, setLabList] = useState<LabItem[]>([]);
  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const bodyLabData: BodyLabResponse =
          await visitDataService.getBodyLabFromEntryId(currentEntryId);
        setValues({
          Hb: bodyLabData.hb,
          Hct: bodyLabData.hct,
          MVC: bodyLabData.mcv,
          Platelet: bodyLabData.plt,
        });
      }
      const masterData = await masterDataService.getLabMasterData();
      setLabList(masterData);
      // else if (latestEntryId) {
      //   const bodyLabData: BodyLabResponse =
      //     await visitDataService.getBodyLabFromEntryId(latestEntryId);
      //   setValues({
      //     Hb: bodyLabData.hb,
      //     Hct: bodyLabData.hct,
      //     MVC: bodyLabData.mcv,
      //     Platelet: bodyLabData.plt,
      //   });
      // }
    };
    fetchData();
  });
  const getTitleAndUnit = (id: number) => {
    const data = labList.find((item) => item.id === id);
    if (data) {
      return {
        title: data.name,
        unit: data.measurementUnit,
        maximum: data.maximum,
        minimum: data.minimum,
        maximumWarning: data.maximumWarning,
        minimumWarning: data.minimumWarning,
      };
    }
    return { title: "Unknown", unit: "" };
  };
  const hbData = getTitleAndUnit(18);
  const hctData = getTitleAndUnit(19);
  const mvcData = getTitleAndUnit(20);
  const plateletData = getTitleAndUnit(21);

  const hematologyList = [
    {
      title: hbData.title !== "Unknown" ? hbData.title : "Hb",
      value: values.Hb,
      unit: hbData.unit ? hbData.unit : "g/dL",
      maximum: hbData.maximum,
      minimum: hbData.minimum,
      maximumWarning: hbData.maximumWarning,
      minimumWarning: hbData.minimumWarning,
    },
    {
      title: hctData.title !== "Unknown" ? hctData.title : "Hct",
      value: values.Hct,
      unit: hctData.unit ?  hctData.unit : "%",
      maximum: hctData.maximum,
      minimum: hctData.minimum,
      maximumWarning: hctData.maximumWarning,
      minimumWarning: hctData.minimumWarning,
    },
    {
      title: mvcData.title !== "Unknown" ? mvcData.title : "MVC",
      value: values.MVC,
      unit: mvcData.unit ? mvcData.unit : "fL",
      maximum: mvcData.maximum,
      minimum: mvcData.minimum,
      maximumWarning: mvcData.maximumWarning,
      minimumWarning: mvcData.minimumWarning,
    },
    {
      title: plateletData.title !== "Unknown" ? plateletData.title : "Platelet",
      value: values.Platelet,
      unit: plateletData.unit ? plateletData.unit : "x 10^3/uL",
      maximum: plateletData.maximum,
      minimum: plateletData.minimum,
      maximumWarning: plateletData.maximumWarning,
      minimumWarning: plateletData.minimumWarning,
    },
  ];
  return (
    <Box width={"100%"} height={"100%"}>
      <Box width={"90%"} height={"100%"} sx={{ border: "2px solid #D8D8D8" }}>
        <Box
          sx={{
            px: 2,
            py: 1,
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={title10Tab}>Hematology</Typography>
          <Typography
            sx={{ color: "#646D78", cursor: "pointer" }}
            onClick={handleClickHematology}
          >
            แก้ไข
          </Typography>
        </Box>
        <Box sx={{ px: 2, py: 1 }}>
          {hematologyList.map((hematology, index) => (
            <BodyLabRow
              key={index}
              title={hematology?.title ?? ""}
              value={
                currentEntryId || hematologyIsDirty
                  ? hematology?.value ?? undefined
                  : "-"
              }
              detail={hematology?.unit ?? undefined}
              maximum={hematology?.maximum ?? undefined}
              minimum={hematology?.minimum ?? undefined}
              maximumWarning={hematology?.maximumWarning ?? undefined}
              minimumWarning={hematology?.minimumWarning ?? undefined}
            />
          ))}
        </Box>
      </Box>
      <HematologyDialog
        open={openHematologyDialog}
        onClose={handleClickCancle}
        onSave={handleClickSave}
        {...values}
      />
    </Box>
  );
};

import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { InvestigationRow } from "../../../components/InvestigateRow";
import { title10Tab } from "../../../style";
import { useEffectOnce } from "react-use";
import { visitDataService } from "../../../service/visitDataService";
import { InvestigationResponse } from "./Investigation";
import { InvestigationFibroScanDialog } from "./InvestigationFibroScanDialog";
import { store } from "../../../redux/store";

export type InvestigationFibroScan = {
  fibro: string;
};
type Props = {
  handleSaveFibroValues: (value: InvestigationFibroScan) => void;
  latestEntryId?: number | null;
  currentEntryId?: number | null;
};
export const InvestigationFibroScan = ({
  handleSaveFibroValues,
  latestEntryId,
  currentEntryId,
}: Props) => {
  const [values, setValues] = useState<InvestigationFibroScan>({
    fibro: "",
  });
  const [openFibroDialog, setOpenFibroDialog] = useState(false);
  const [fibroIsDirty, setFibroIsDirty] = useState(false);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  
  const handleClickFibro = () => {
    setOpenFibroDialog(true);
  };
  const handleClickFibroClose = (data: InvestigationFibroScan) => {
    setValues(data);
    setOpenFibroDialog(false);
  };
  const handleClickFibroSave = (data: InvestigationFibroScan) => {
    if(patientDataPermissionEditAble === false){
      setOpenFibroDialog(false);
      setFibroIsDirty(false);
      return;
    }
    setValues(data);
    setOpenFibroDialog(false);
    handleSaveFibroValues(data);
    setFibroIsDirty(true);
  };
  const fibroList = [
    {
      title: "FibroScan",
      value: values.fibro,
    },
  ];
  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const investigationData: InvestigationResponse =
          await visitDataService.getInvestigationFromEntryId(currentEntryId);
        setValues({
          fibro: investigationData.fibro ? "yes" : "no",
        });
      } else if (latestEntryId) {
        const investigationData: InvestigationResponse =
          await visitDataService.getInvestigationFromEntryId(latestEntryId);
        setValues({
          fibro: investigationData.fibro ? "yes" : "no",
        });
      }
    };
    fetchData();
  });
  return (
    <Box width={"100%"} height={"100%"}>
      <Box width={"90%"} height={"100%"} sx={{ border: "2px solid #D8D8D8" }}>
        <Box
          sx={{
            px: 2,
            py: 1,
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={title10Tab}>FibroScan</Typography>
          <Typography
            style={{
              color: "#646D78",
              cursor: "pointer",
            }}
            onClick={handleClickFibro}
          >
            แก้ไข
          </Typography>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          {fibroList.map((endoscopy, index) => (
            <InvestigationRow
              key={index}
              title={endoscopy?.title}
              value={
                currentEntryId || fibroIsDirty ? endoscopy?.value : undefined
              }
            />
          ))}
        </Box>
      </Box>
      <InvestigationFibroScanDialog
        open={openFibroDialog}
        onClose={handleClickFibroClose}
        onSave={handleClickFibroSave}
        {...values}
      />
    </Box>
  );
};

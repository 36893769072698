import React, { useEffect, useState } from "react";
import UserFiltering from "../../shared/UserFiltering";
import "../UserTab/UserTab.css"; // Import a CSS file for styling
import AddIcon from "@mui/icons-material/Add";
import editIcon from "../../asset/editIcon.png";
import viewMode from "../../asset/viewMode.png";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  Grid,
  ThemeProvider,
  Pagination,
  createMuiTheme,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { userManagementService } from "../../service/userManagementService";
import { Layout } from "../../Layout";
import ApproveUserFiltering from "../../shared/ApproveUserFiltering";
import dayjs from "dayjs";
import { SubmitBottomBar } from "../../shared/visit/SubmitBottomBar";
import { SubmitApproveButtomBar } from "../SubmitApproveButtomBar";
import { set } from "date-fns";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { ConfirmDialog } from "../../components/dialog/ConfirmDialog";
import { CancelDialog } from "../../components/dialog/CancelDialog";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { Unstable_PickersSectionListSection } from "@mui/x-date-pickers";
import { UserPermission } from "../../type";
import { useSelector } from "react-redux";

const theme = createMuiTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          "&.Mui-selected": {
            backgroundColor: "#DF5C8E",
            color: "#ffffff",
          },
        },
      },
    },
  },
});

export type User = {
  userId: string;
  registerDateTime: string;
  userPrefix: string;
  firstName: string;
  lastName: string;
  appliedPermission: string;
  accountStatus: string;
};
const ApproveUser = () => {
  const navigate = useNavigate();
  const tableCellValues = [
    "ลำดับ",
    "วันที่ลงทะเบียน",
    "คำนำหน้า",
    "ชื่อ-นามสกุล",
    "ประเภทสิทธิ์การใช้งาน",
    "สถานะ",
    "เครื่องมือ",
  ];
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [userList, setUserList] = useState<User[]>([]);
  const [userPermissionList, setUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isShowConfrimApproveDialog, setIsShowConfrimApproveDialog] =
    useState(false);
  const [isShowCancelApprovalDialog, setIsShowCancelApprovalDialog] =
    useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUsers, setCurrentUsers] = useState<User[]>([]);
  const [originalUserList, setOriginalUserList] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const usersPerPage = 10;
  const userPermissionStatus = useSelector(
    (state: any) => state.loginUser.userPermission
  );
  const loginUserId = useSelector((state: any) => state.loginUser.userId);
  const permissionEditAble =
  loginUserId === "1" ? true : userPermissionStatus?.userApprovePermission.editAble;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const userLists = await userManagementService.getApprovalWaitingList(
          "waiting for approval"
        );
        setUserList(userLists);
        setOriginalUserList(userLists);
      } catch (error: any) {
        console.error("Error fetching List", error);
      }
    };
    setIsLoading(true);
    fetchData().then(() => setIsLoading(false));
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCurrentUsers(
          userList.slice(
            (currentPage - 1) * usersPerPage,
            currentPage * usersPerPage
          )
        );

        const userPermissionList =
          await userManagementService.getUserPermission();
        setUserPermissionList(userPermissionList);
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    setIsLoading(true);
    fetchData().then(() => setIsLoading(false));
  }, [isSelected, selectedUsers]);

  useEffect(() => {
    setCurrentUsers(
      userList.slice(
        (currentPage - 1) * usersPerPage,
        currentPage * usersPerPage
      )
    );
  }, [currentPage, userList]);

  // Calculate the total number of pages
  const totalPages = Math.ceil(userList.length / 10);

  const { mutateAsync: approveUser } = useMutation({
    mutationFn: async () => {
      const response = await userManagementService.approveBatchUser({
        targetUserIdList: selectedUsers,
        isApproved: true,
      });
      return response;
    },
    onSuccess(data) {
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      setIsShowConfrimApproveDialog(false);
      setSelectedUsers([]);
      setIsSelected(false);
    },
    onError(error) {
      setIsShowError(true);
      console.log("Error", error);
    },
  });

  const handleApproveUser = async () => {
    try {
      setIsShowConfrimApproveDialog(true);
    } catch (error) {
      console.error("Error fetching List", error);
    }
  };

  const { mutateAsync: rejectUser } = useMutation({
    mutationFn: async () => {
      const response = await userManagementService.approveBatchUser({
        targetUserIdList: selectedUsers,
        isApproved: false,
      });
      return response;
    },
    onSuccess(data) {
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      setIsShowCancelApprovalDialog(false);
      setSelectedUsers([]);
      setIsSelected(false);
    },
    onError(error) {
      setIsShowError(true);
      console.log("Error", error);
    },
  });

  const handleRejectUser = async () => {
    try {
      setIsShowCancelApprovalDialog(true);
    } catch (error) {
      console.error("Error fetching List", error);
    }
  };

  const handleCancelClick = async () => {
    setSelectedUsers([]);
    setIsSelected(false);
  };

  const handleSelect = (userId: string) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
      setIsSelected(false);
    } else {
      setSelectedUsers([...selectedUsers, userId]);
      setIsSelected(true);
    }
  };

  const handleSelectAll = () => {
    if (selectedUsers.length === currentUsers.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(currentUsers.map((user) => user.userId));
    }
  };

  const handleSearch = (data: {
    name: string;
    userPermission: string;
    selectedStartDate: string;
    selectedEndDate: string;
  }) => {
    const { name, userPermission, selectedStartDate, selectedEndDate } = data;

    const filteredUsers = originalUserList.filter((user) => {
      const isNameMatch =
        name === ""
          ? true
          : user.firstName.includes(name) ||
            user.lastName.includes(name) ||
            `${user.firstName} ${user.lastName}`.includes(name);

      const permissionItem = userPermissionList.find(
        (item) => item.nameThai === user.appliedPermission
      );

      const isPermissionMatch =
        userPermission === ""
          ? true
          : permissionItem?.id === Number(userPermission);

      // Check if the user's date is within the search dates
      let isDateMatch;
      if (selectedStartDate !== "" && selectedEndDate !== "") {
        isDateMatch =
          user.registerDateTime >= selectedStartDate &&
          user.registerDateTime <= selectedEndDate;
      } else if (selectedStartDate === "" && selectedEndDate !== "") {
        isDateMatch = user.registerDateTime <= selectedEndDate;
      } else if (selectedStartDate !== "" && selectedEndDate === "") {
        isDateMatch = user.registerDateTime >= selectedStartDate;
      } else {
        isDateMatch = true;
      }
      return isNameMatch && isPermissionMatch && isDateMatch;
    });
    setUserList(filteredUsers);
    setCurrentPage(1);
    // setCurrentUsers(
    //   filteredUsers.slice(
    //     0, usersPerPage
    //   )
    // );
  };

  const handleClearFiltering = () => {
    setUserList(originalUserList);
    setCurrentUsers(
      originalUserList.slice(
        (currentPage - 1) * usersPerPage,
        currentPage * usersPerPage
      )
    );
  };

  return (
    <Layout activeTab="userPermission">
      <div className="user-filtering-container">
        <ApproveUserFiltering
          handleSearch={handleSearch}
          handleClearFiltering={handleClearFiltering}
        />
        <Box
          sx={{
            p: 3,
            overflowY: "auto",
            scrollbarWidth: "thin",
            backgroundColor: "white",
            // height:
            //   selectedUsers.length > 0
            //     ? "calc(100vh - 375px)"
            //     : "calc(100vh - 300px)",
            mt: 2,
          }}
        >
          <Box>
            <TableContainer component={Paper}>
              <Table className="pink-table-line">
                <TableHead>
                  <TableRow className="pink-bottom-line">
                    <TableCell padding="checkbox">
                      <Checkbox
                        // Add logic for handling select all
                        checked={selectedUsers.length === currentUsers.length}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    {tableCellValues.map((value) => (
                      <TableCell style={{ fontSize: "16px" }}>
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currentUsers.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          // Add logic for handling row selection
                          checked={selectedUsers.includes(user.userId)}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleSelect(user.userId)}
                        />
                      </TableCell>
                      <TableCell>
                        {(currentPage - 1) * usersPerPage + index + 1}
                      </TableCell>
                      <TableCell>
                        {dayjs(user.registerDateTime).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{user.userPrefix}</TableCell>
                      <TableCell>
                        {user.firstName} {user.lastName}
                      </TableCell>
                      <TableCell>{user.appliedPermission}</TableCell>
                      <TableCell>
                        {user?.accountStatus === "waiting for approval" ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                marginRight: "5px",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                backgroundColor: "#FBC235",
                              }}
                            />
                            รออนุมัติ
                          </div>
                        ) : user?.accountStatus === "suspend" ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                marginRight: "5px",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                backgroundColor: "red",
                              }}
                            />
                            ปิดการใช้งาน
                          </div>
                        ) : (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                marginRight: "5px",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                                backgroundColor: "#22BE8F",
                              }}
                            />
                            อนุมัติ
                          </div>
                        )}
                      </TableCell>
                      <TableCell style={{ padding: "8px" }}>
                        {user.accountStatus === "waiting for approval" ? (
                          <img
                            src={editIcon}
                            alt="editIcon"
                            style={{
                              width: "25px",
                              height: "25px",
                              paddingLeft: "20px",
                            }}
                            onClick={() =>
                              navigate("/approveUserDetailViewMode", {
                                state: { user: user.userId },
                              })
                            }
                          />
                        ) : (
                          <img
                            src={viewMode}
                            alt="viewMode"
                            style={{ width: "25px", height: "25px" }}
                            onClick={() =>
                              navigate("/approveUserDetailViewMode ", {
                                state: { user: user.userId },
                              })
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: isLoading ? "flex" : "none",
                justifyContent: "center",
                fontSize: 20,
                color: "#9AA1AE",
                py: 8,
                border: "1px solid #ddd", // Add this line
                borderRadius: "4px",
              }}
            >
              <CircularProgress />
              <Typography>กำลังโหลดข้อมูล...</Typography>
            </Box>
            {currentUsers.length === 0 && !isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 20,
                  color: "#9AA1AE",
                  py: 2,
                  border: "1px solid #ddd", // Add this line
                  borderRadius: "4px",
                }}
              >
                --ไม่พบผู้ใช้งาน--
              </Box>
            )}
          </Box>
          <ThemeProvider theme={theme}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "30px",
              }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </ThemeProvider>
        </Box>
        {selectedUsers.length > 0 && (
          <Box sx={{ mt: 1, width: 1 }}>
            <SubmitApproveButtomBar
              permissionEditAble={permissionEditAble}
              handleCancelClick={handleCancelClick}
              handleRejectClicked={handleRejectUser}
              handleSubmitData={handleApproveUser}
            />
          </Box>
        )}
      </div>
      <ConfirmDialog
        title="ต้องการอนุมัติรายการใช่หรือไม่"
        content="คุณต้องการอนุมัติรายการ ต้องการดำเนินการต่อใช่หรือไม่?"
        openDialog={isShowConfrimApproveDialog}
        handleCloseDialog={() => {
          setIsShowConfrimApproveDialog(false);
        }}
        handleCancelClick={() => {
          setIsShowConfrimApproveDialog(false);
        }}
        handleConfirmClick={async () => {
          await approveUser();
        }}
      />
      <CancelDialog
        title="ไม่อนุมัติรายการใช่หรือไม่"
        content="คุณไม่ต้องการอนุมัติรายการ ต้องการดำเนินการต่อใช่หรือไม่?"
        openDialog={isShowCancelApprovalDialog}
        handleCloseDialog={() => {
          setIsShowCancelApprovalDialog(false);
        }}
        handleCancelClick={() => {
          setIsShowCancelApprovalDialog(false);
        }}
        handleConfirmClick={async () => {
          await rejectUser();
        }}
      />
      <ErrorDialog
        title="เกิดข้อผิดพลาด"
        content="กรุณาลองใหม่อีกครั้งหรือติดต่อผู้ดูแลระบบ"
        openDialog={isShowError}
        handleCloseDialog={() => {
          setIsShowError(false);
        }}
      />
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
    </Layout>
  );
};

export default ApproveUser;

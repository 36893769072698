import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Doctor from "../../asset/Doctor.png";
import Document from "../../asset/Document.png";
import HeartBeat from "../../asset/Heartbeat.png";
import DoctorGray from "../../asset/Doctor-gray.png";
import DocumentPink from "../../asset/Document-pink.png";
import HeartPink from "../../asset/HeartBeat-pink.png";
import { useEffectOnce } from "react-use";
import { userManagementService } from "../../service/userManagementService";
import { MasterData } from "../../type";
import { getHospital } from "../../utils/get-data/getHospital";

const AffiliateHospital = ({
  prevStep,
  nextStep,
  registerForm,
  handleRegisterChange,
}: {
  prevStep: any;
  nextStep: any;
  registerForm: any;
  handleRegisterChange: any;
}) => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [affiliateHospitalHospitalList, setAffiliateHospitalHospitalList] =
    useState<MasterData[]>([]);

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const affiliatedHospitalListResponse = await getHospital();
        setAffiliateHospitalHospitalList(affiliatedHospitalListResponse);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
  });

  const handleClick = (buttonSelected: any) => {
    handleRegisterChange({
      target: { name: "appliedPermission", value: buttonSelected },
    });
  };

  return (
    <Box style={{ padding: "20px", width: "98%" }}>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={12} sm={6} md={4}>
          <p style={{ color: "#646D78" }}>
            ชื่อโรงพยาบาล <span style={{ color: "red" }}>*</span>
          </p>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
            <Select
              label="Regular Dropdown"
              id="affiliatedHospitalId"
              name="affiliatedHospitalId"
              value={registerForm.affiliatedHospitalId}
              onChange={handleRegisterChange}
              size="small"
              fullWidth={true}
              error={!registerForm.affiliatedHospitalId && isSubmit}
              MenuProps={{
                sx: {
                  zoom: 100 * (100 / 85) + "%",
                  top: "0px",
                },
                PaperProps: {
                  sx: {
                    maxHeight: "250px",
                    scrollbarWidth: "thin",
                  },
                },
              }}
            >
              {affiliateHospitalHospitalList.map((hospital) => (
                <MenuItem value={hospital.id} sx={{ fontSize: 14 }}>
                  {hospital.nameThai}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <p style={{ color: "#646D78" }}>
            เลขที่ใบอนุญาตประกอบวิชาชีพ <span style={{ color: "red" }}>*</span>
          </p>
          <TextField
            label="-- โปรดระบุข้อมูล --"
            variant="outlined"
            id="medicalLicenseNumber"
            name="medicalLicenseNumber"
            value={registerForm.medicalLicenseNumber}
            onChange={handleRegisterChange}
            size="small"
            fullWidth={true}
            error={!registerForm.medicalLicenseNumber && isSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <p style={{ color: "#646D78" }}>ตำแหน่ง</p>
          <TextField
            label="-- โปรดระบุข้อมูล --"
            variant="outlined"
            id="jobPosition"
            name="jobPosition"
            value={registerForm.jobPosition}
            onChange={handleRegisterChange}
            size="small"
            fullWidth={true}
          />
        </Grid>
      </Grid>

      <Divider style={{ paddingTop: "40px", marginBottom: "10px" }}></Divider>

      <Grid container spacing={2} columns={12}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography style={{ color: "#646D78", padding: "10px" }}>
            สิทธิ์การใช้งานที่ต้องการลงทะเบียน{" "}
            <span style={{ color: "#F44F58" }}>
              * (เลือกได้เพียง 1 ประเภทเท่านั้น)
            </span>
          </Typography>
          <Button
            variant="outlined"
            onClick={() => handleClick("doctor")}
            style={{
              borderColor:
                registerForm.appliedPermission === "doctor"
                  ? "#DF5C8E"
                  : "#E3E7F0",
              color:
                registerForm.appliedPermission === "doctor"
                  ? "#DF5C8E"
                  : "#212121",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "90px",
                height: "90px",
              }}
            >
              <img
                src={
                  registerForm.appliedPermission === "doctor"
                    ? Doctor
                    : DoctorGray
                }
              />
              แพทย์
            </div>
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleClick("med_student")}
            style={{
              borderColor:
                registerForm.appliedPermission === "med_student"
                  ? "#DF5C8E"
                  : "#E3E7F0",
              color:
                registerForm.appliedPermission === "med_student"
                  ? "#DF5C8E"
                  : "#212121",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "90px",
                height: "90px",
              }}
            >
              <img
                style={{
                  paddingTop:
                    registerForm.appliedPermission === "med_student"
                      ? "10px"
                      : "0px",
                  paddingBottom:
                    registerForm.appliedPermission === "med_student"
                      ? "5px"
                      : "0px",
                }}
                src={
                  registerForm.appliedPermission === "med_student"
                    ? HeartPink
                    : HeartBeat
                }
              />
              นักศึกษาแพทย์
            </div>
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleClick("staff")}
            style={{
              borderColor:
                registerForm.appliedPermission === "staff"
                  ? "#DF5C8E"
                  : "#E3E7F0",
              color:
                registerForm.appliedPermission === "staff"
                  ? "#DF5C8E"
                  : "#212121",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "90px",
                height: "90px",
              }}
            >
              <img
                src={
                  registerForm.appliedPermission === "staff"
                    ? DocumentPink
                    : Document
                }
                style={{ paddingTop: "17px" }}
              />
              เจ้าหน้าที่
            </div>
          </Button>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "50px",
        }}
      >
        <Button
          variant="contained"
          style={{
            border: "1px solid #DF5C8E",
            backgroundColor: "transparent",
            color: "#DF5C8E",
            width: "300px",
            height: "35px",
            maxWidth: "350px", // Add your desired max width
            minWidth: "200px", // Add your desired min width
            marginBottom: "10px",
          }}
          onClick={prevStep}
        >
          ก่อนหน้า
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#DF5C8E",
            width: "300px",
            height: "35px",
            maxWidth: "350px", // Add your desired max width
            minWidth: "200px", // Add your desired min width,
          }}
          onClick={() => {
            nextStep();
            setIsSubmit(true);
          }}
        >
          ถัดไป
        </Button>
        <Button
          style={{ paddingTop: "20px", color: "#DF5C8E" }}
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/")}
        >
          กลับไปยังหน้าเข้าสู่ระบบ
        </Button>
      </div>
    </Box>
  );
};

export default AffiliateHospital;

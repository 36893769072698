import { ThemeProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { gapi } from "gapi-script";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { Route, Routes, useLocation } from "react-router";
import "./App.css";
import ApproveUser from "./Pages/ApproveUser/ApproveUser";
import ApproveUserDetail from "./Pages/ApproveUser/ApproveUserDetail";
import ApproveUserDetailViewMode from "./Pages/ApproveUser/ApproveUserDetailViewMode";
import Dashboard from "./Pages/Dashboard";
import ForgotPasswordScreen from "./Pages/ForgotPasswordScreen";
import ForgotPasswordScreenComplete from "./Pages/ForgotPasswordScreenComplete";
import History from "./Pages/History";
import Home from "./Pages/Home";
import { LoginScreen } from "./Pages/LoginScreen";
import { ComplicationMaster } from "./Pages/MaterData/ComplicationMaster";
import { ContraceptionMaster } from "./Pages/MaterData/ContraceptionMaster";
import { CustomFieldMaster } from "./Pages/MaterData/CustomFieldMaster";
import { FaqMaster } from "./Pages/MaterData/FaqMaster";
import { AddFoodMaster } from "./Pages/MaterData/FoodMaster/AddFoodMaster";
import { EditFoodMaster } from "./Pages/MaterData/FoodMaster/EditFoodMaster";
import { FoodMaster } from "./Pages/MaterData/FoodMaster/FoodMaster";
import { HospitalMaster } from "./Pages/MaterData/HospitalMaster";
import { LabMaster } from "./Pages/MaterData/LabMaster";
import { MasterData } from "./Pages/MaterData/MasterData";
import { PatientDetail } from "./Pages/PatientDetail";
import PrivacyPolicy from "./Pages/Register/PrivacyPolicy";
import Register from "./Pages/Register/Register";
import TermOfService from "./Pages/Register/TermOfService";
import { Report } from "./Pages/Report/Report";
import ResetPasswordScreen from "./Pages/ResetPasswordScreen";
import ResetPasswordScreenComplete from "./Pages/ResetPasswordScreenComplete";
import AddUserPermission from "./Pages/UserPermission/AddUserPermission";
import GrantUserPermission from "./Pages/UserPermission/GrantUserPermission";
import UserPermission from "./Pages/UserPermission/UserPermission";
import UserProfile from "./Pages/UserProfile/UserProfile";
import AddUser from "./Pages/UserTab/AddUser";
import EditUser from "./Pages/UserTab/EditUser";
import UserTab from "./Pages/UserTab/UserTab";
import ViewUser from "./Pages/UserTab/ViewUser";
import { store } from "./redux/store";
import { theme } from "./theme";
import newAdapter from "./utils/newAdapter";

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      networkMode: "offlineFirst",
    },
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const location = useLocation();

  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId:
        "1074184698174-anqbrehtq1hro1h410un1mfo50n81s74.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });

  return (
    <GoogleOAuthProvider clientId="1074184698174-anqbrehtq1hro1h410un1mfo50n81s74.apps.googleusercontent.com">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={newAdapter} adapterLocale="th">
            <QueryClientProvider client={queryClient}>
              <SnackbarProvider autoHideDuration={3000} />
              <Routes location={location}>
                <Route path="/" element={<LoginScreen />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/home" element={<Home />} />
                <Route path="/userTab" element={<UserTab />} />
                <Route path="/userPermission" element={<UserPermission />} />
                <Route
                  path="/grantUserPermission"
                  element={<GrantUserPermission />}
                />
                <Route
                  path="/addUserPermission"
                  element={<AddUserPermission />}
                />
                <Route path="/patientDetail" element={<PatientDetail />} />
                <Route path="/addUser" element={<AddUser />} />
                <Route path="/editUser" element={<EditUser />} />
                <Route path="/viewUser" element={<ViewUser />} />
                <Route path="/register" element={<Register />} />
                <Route path="/termOfService" element={<TermOfService />} />
                <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/history" element={<History />} />
                <Route path="/approveUser" element={<ApproveUser />} />
                <Route
                  path="/approveUserDetail"
                  element={<ApproveUserDetail />}
                />
                <Route
                  path="/approveUserDetailViewMode"
                  element={<ApproveUserDetailViewMode />}
                />
                <Route path="/masterData" element={<MasterData />} />
                <Route
                  path="/masterData/customField"
                  element={<CustomFieldMaster />}
                />
                <Route
                  path="/masterData/contraception"
                  element={<ContraceptionMaster />}
                />
                <Route
                  path="/masterData/complication"
                  element={<ComplicationMaster />}
                />
                <Route
                  path="/masterData/hospital"
                  element={<HospitalMaster />}
                />
                <Route path="/masterData/food" element={<FoodMaster />} />
                <Route
                  path="/masterData/food/add"
                  element={<AddFoodMaster />}
                />
                <Route
                  path="/masterData/food/:foodId"
                  element={<EditFoodMaster />}
                />
                <Route path="/masterData/lab" element={<LabMaster />} />
                <Route path="/masterData/faq" element={<FaqMaster />} />
                <Route
                  path="/resetPassword"
                  element={<ResetPasswordScreen />}
                />
                <Route
                  path="/resetPassword/complete"
                  element={<ResetPasswordScreenComplete />}
                />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/report" element={<Report />} />
                <Route
                  path="/forgotPassword"
                  element={<ForgotPasswordScreen />}
                />
                <Route
                  path="/forgotPassword/complete"
                  element={<ForgotPasswordScreenComplete />}
                />
              </Routes>
            </QueryClientProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;

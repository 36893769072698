import {
  Container,
  Box,
  Typography,
  Stack,
  Button,
  useTheme,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from "@mui/material";
import "./LoginScreen.css";
import HeaderMenuBar from "../HeaderMenuBar";
import Footer from "../Footer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "../components/dialog/ErrorDialog";
import { useEffect, useState } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { ArrowBackIcon, CheckCircleIcon } from "../icon";
import { userManagementService } from "../service/userManagementService";
import { useMutation } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import { useEffectOnce } from "react-use";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface PasswordValidation {
  lengthValid: boolean;
  lowercaseValid: boolean;
  uppercaseValid: boolean;
  numberValid: boolean;
  passwordsMatch: boolean;
}

function validatePassword(
  newPassword: string,
  confirmPassword: string
): PasswordValidation {
  return {
    lengthValid: newPassword.length > 8,
    lowercaseValid: /[a-z]/.test(newPassword),
    uppercaseValid: /[A-Z]/.test(newPassword),
    numberValid: /\d/.test(newPassword),
    passwordsMatch: newPassword === confirmPassword,
  };
}
const ResetPasswordScreen = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isResetFailed, setIsResetFailed] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const validation = validatePassword(newPassword, confirmPassword);
  const location = useLocation();
  const { status, email } = location.state || {};
  const [isForgotPassword, setIsForgetPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffectOnce(() => {
    if (token) {
      setIsForgetPassword(true);
    } else {
      setIsForgetPassword(false);
    }
  });
  const handleResetPassword = async () => {
    try {
      resetPasswordAsync();
    } catch (error) {
      console.error("Error:", error);
      setIsResetFailed(true);
    }
    resetPasswordAsync();
  };
  const { mutateAsync: resetPasswordAsync, isPending } = useMutation({
    mutationFn: async () => {
      if (isForgotPassword && token) {
        await userManagementService.resetPasswordWithNoEmail(
          token,
          newPassword
        );
      } else {
        await userManagementService.resetPassword(email, newPassword);
      }
    },
    onSuccess() {
      navigate("complete");
    },
  });
  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <Box className="reset-passsword-container">
        <HeaderMenuBar />
        <Box
          sx={{
            mt: mediumScreen ? "80px" : "110px",
            height: mediumScreen ? "65vh" : "80vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "white",
                padding: "30px 80px",
                borderRadius: 2,
              }}
            >
              <Stack spacing={2} width={1}>
                <Typography fontSize={24} fontWeight={500} color={"#0F1011"}>
                  ตั้งค่ารหัสผ่านใหม่ (Reset Password)
                </Typography>
                <Typography fontSize={14} color={"#212121"}>
                  โปรดตั้งค่ารหัสผ่านใหม่ และยืนยันรหัสผ่านใหม่{" "}
                </Typography>
              </Stack>
              <FormContainer onSuccess={handleResetPassword}>
                <Stack spacing={4} sx={{ mt: 4, width: 1 }}>
                  <Stack spacing={1}>
                    <Typography fontSize={12} color={"#646D78"}>
                      รหัสผ่านใหม่ <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextFieldElement
                      name="newPassword"
                      required
                      variant="outlined"
                      size="small"
                      type={showNewPassword ? "text" : "password"}
                      onChange={(e) => setNewPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowNewPassword(!showNewPassword)}
                              edge="end"
                            >
                              {showNewPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Typography fontSize={12} color={"#646D78"}>
                      ยืนยันรหัสผ่านใหม่ <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextFieldElement
                      name="confirmPassword"
                      required
                      variant="outlined"
                      size="small"
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Box sx={{ borderRadius: 1, bgcolor: "#F7F8FC", p: 2 }}>
                    <Typography
                      fontSize={12}
                      fontWeight={700}
                      color={"#212121"}
                    >
                      คำแนะนำการกำหนดรหัสผ่านใหม่
                    </Typography>

                    <ValidationFeedback
                      valid={validation.lengthValid}
                      text="รหัสผ่านควรมีความยาวมากกว่า 8 ตัวอักษร"
                    />
                    <ValidationFeedback
                      valid={validation.lowercaseValid}
                      text="รหัสผ่านควรมีตัวอักษรพิมพ์เล็ก"
                    />
                    <ValidationFeedback
                      valid={validation.uppercaseValid}
                      text="รหัสผ่านควรมีตัวอักษรพิมพ์ใหญ่"
                    />
                    <ValidationFeedback
                      valid={validation.numberValid}
                      text="รหัสผ่านควรมีตัวเลข"
                    />

                    {!validation.passwordsMatch && (
                      <Typography fontSize={12} color={"red"}>
                        รหัสผ่านไม่ตรงกัน
                      </Typography>
                    )}
                  </Box>
                </Stack>
                <LoadingButton
                  loading={isPending}
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 2,
                    backgroundColor: "#FF6699",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#FF6699",
                    },
                  }}
                  disabled={
                    !validation.lengthValid ||
                    !validation.lowercaseValid ||
                    !validation.uppercaseValid ||
                    !validation.numberValid ||
                    !validation.passwordsMatch
                  }
                >
                  รีเซ็ตรหัสผ่าน
                </LoadingButton>
                {/* <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 4,
                    mb: 2,
                    backgroundColor: "#FF6699",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#FF6699",
                    },
                  }}
                  disabled={
                    !validation.lengthValid ||
                    !validation.lowercaseValid ||
                    !validation.uppercaseValid ||
                    !validation.numberValid ||
                    !validation.passwordsMatch
                  }
                >
                  รีเซ็ตรหัสผ่าน
                </Button> */}
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    gap: 1,
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/")}
                >
                  <ArrowBackIcon color="primary" />
                  <Typography fontSize={14} fontWeight={700} color={"primary"}>
                    กลับไปยังหน้าเข้าสู่ระบบ
                  </Typography>
                </Box>
              </FormContainer>
            </Box>
          </Container>
        </Box>
      </Box>
      <div className="reset-passsword-container">
        <Box
          sx={{
            mt: "128px",
            height: "50%",
            maxHeight: "640px",
            overflow: "auto",
          }}
        ></Box>
      </div>
      <Footer />
      <ErrorDialog
        title="ตั้งค่ารหัสผ่านใหม่ไม่สำเร็จ"
        content="กรุณาตรวจสอบความถูกต้องอีกครั้ง"
        openDialog={isResetFailed}
        handleCloseDialog={() => {
          navigate("/");
          setIsResetFailed(false);
        }}
      />
    </div>
  );
};

export default ResetPasswordScreen;

const ValidationFeedback = ({
  valid,
  text,
}: {
  valid: boolean;
  text: string;
}) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <CheckCircleIcon sx={{ fontSize: 14, color: valid ? "#26B465" : "gray" }} />
    <Typography fontSize={12} color={valid ? "#26B465" : "gray"}>
      {text}
    </Typography>
  </Stack>
);

import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { userManagementService } from "../../service/userManagementService";
import { activityMET, times } from "../../sharedValue";
import { body14400, body14600, title18500, title18700 } from "../../style";
import {
  CurrentHealthInfo,
  FoodItem,
  PatientUser,
  PatientUserProfile,
} from "../../type";
import { calculateBmi } from "../../utils/calculateBmi";
import { convertDate } from "../../utils/convertDate";
import { getTotalCalorieExercise } from "../../utils/getTotalCalorie";
import { HistoryMealCard } from "./HistoryDetail/HistoryMealCard";
import { ProfileCard } from "./ProfileCard";
import { LoadingComponent } from "../../components/LoadingComponent";

type Props = {
  selectedDate: string;
  patientUser: PatientUser;
  patientUserProfile: PatientUserProfile;
  handelViewProfile: () => void;
  handleGobackClick: () => void;
};

type GetCurrentHealthInfoPayload = {
  id: number;
  date: string;
};

dayjs.locale("th");

export const PatientHistoryDetail = ({
  selectedDate,
  patientUser,
  patientUserProfile,
  handelViewProfile,
  handleGobackClick,
}: Props) => {
  const parsedDate = dayjs(selectedDate, "DD/MM/YYYY");
  const currentDate = parsedDate.format(`${parsedDate.year() - 543}-MM-DD`);
  const [currentHealtInfo, setCurrentHealthInfo] =
    useState<CurrentHealthInfo>();
  const [totalItems, setTotalItems] = useState(1);
  const [foodItems, setFoodItems] = useState<
    {
      meal: "อาหารเช้า" | "อาหารเที่ยง" | "อาหารเย็น" | "มื้ออื่นๆ";
      food: FoodItem[];
    }[]
  >([]);
  const {
    mutateAsync: getCurrentHealthInfoAsync,
    isPending: isPendingGetCurrentHealthInfo,
  } = useMutation({
    mutationFn: async ({ id, date }: GetCurrentHealthInfoPayload) => {
      const response = await userManagementService.getCurrentHealthInfo(
        id,
        date
      );
      return response as CurrentHealthInfo;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        console.error(error);
      }
    },
  });
  const getTotalItemsNotNull = (foodIntake: any) => {
    let totalItems = 0;
    for (const mealCategory in foodIntake) {
      if (foodIntake[mealCategory] !== null) {
        totalItems += 1;
      }
    }
    return totalItems;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCurrentHealthInfoAsync({
          id: patientUser.id,
          date: currentDate,
        });
        if (data) {
          setCurrentHealthInfo(data);
          const totalItems = getTotalItemsNotNull(data.foodIntake);
          setTotalItems(totalItems);
          const foodIntake = data.foodIntake;
          const items = [
            { meal: "อาหารเช้า", food: foodIntake.morning },
            { meal: "อาหารเที่ยง", food: foodIntake.lunch },
            { meal: "อาหารเย็น", food: foodIntake.dinner },
            { meal: "มื้ออื่นๆ", food: foodIntake.other },
          ].filter(
            (
              item
            ): item is {
              meal: "อาหารเช้า" | "อาหารเที่ยง" | "อาหารเย็น" | "มื้ออื่นๆ";
              food: FoodItem[];
            } => item.food && item.food.length > 0
          );

          setFoodItems(items);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [patientUser.id, currentDate, getCurrentHealthInfoAsync]);
  return (
    <>
      {!isPendingGetCurrentHealthInfo ? (
        <div>
          {currentHealtInfo && (
            <Box display={"grid"} gridTemplateColumns={"3fr 1fr"} gap={3}>
              <Stack spacing={2}>
                <Typography style={title18700}>Daily Form (Detail)</Typography>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 2,
                    backgroundImage:
                      "linear-gradient(to right, #F8E1EA, #DF5C8E)",
                    color: "#1F4037",
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box>
                      <Typography
                        fontSize={13}
                        fontWeight={500}
                        color={"#1F4037"}
                      >
                        วันที่กรอกข้อมูล
                      </Typography>
                      <Typography
                        fontSize={24}
                        fontWeight={700}
                        color={"#1F4037"}
                      >
                        {convertDate(selectedDate)}
                      </Typography>
                    </Box>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      spacing={4}
                      color={"white"}
                    >
                      <Box>
                        <Typography fontSize={13} fontWeight={500}>
                          น้ำหนัก
                        </Typography>
                        <Typography fontSize={32} fontWeight={700}>
                          {currentHealtInfo.weight.toFixed(2)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize={13} fontWeight={500}>
                          ส่วนสูง
                        </Typography>
                        <Typography fontSize={32} fontWeight={700}>
                          {patientUser.height}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize={13} fontWeight={500}>
                          BMI
                        </Typography>
                        <Typography fontSize={32} fontWeight={700}>
                          {calculateBmi(
                            currentHealtInfo.weight,
                            patientUser.height
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <Paper sx={{ p: 2, borderRadius: 2 }}>
                  <Stack spacing={2}>
                    <Typography style={title18500}>ข้อมูล</Typography>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 5fr"}
                      sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                    >
                      <Typography style={body14400} sx={{ color: "#646D78" }}>
                        น้ำหนัก
                      </Typography>
                      <Box>
                        <Typography
                          component={"span"}
                          style={body14600}
                          sx={{ color: "secondary.main" }}
                        >
                          {`${currentHealtInfo.weight.toFixed(2)} kg`}{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 5fr"}
                      sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                    >
                      <Typography style={body14400} sx={{ color: "#646D78" }}>
                        อาหาร
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          overflow: "auto",
                          scrollbarWidth: "thin",
                        }}
                      >
                        <Typography
                          component={"span"}
                          style={body14600}
                          sx={{ color: "secondary.main" }}
                        >
                          {/* {`${Number(
                        getTotalCalorieFood(
                          currentHealtInfo.foodIntake.morning
                        ) +
                          getTotalCalorieFood(
                            currentHealtInfo.foodIntake.lunch
                          ) +
                          getTotalCalorieFood(
                            currentHealtInfo.foodIntake.dinner
                          ) +
                          getTotalCalorieFood(currentHealtInfo.foodIntake.other)
                      ).toLocaleString()} kcal`} */}
                          {`${
                            currentHealtInfo.totalCalories
                              ? currentHealtInfo.totalCalories.toLocaleString()
                              : `-`
                          } kcal`}
                        </Typography>

                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          alignItems="stretch"
                          width={totalItems <= 3 ? "auto" : "120%"}
                          sx={{ my: 1, ml: 1 / 2 }}
                        >
                          {foodItems.map((item, index) => (
                            <Grid
                              key={index}
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              lg={totalItems <= 2 ? 5 : totalItems < 4 ? 4 : 3}
                            >
                              <HistoryMealCard
                                meal={item.meal}
                                food={item.food}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 5fr"}
                      sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                    >
                      <Typography style={body14400} sx={{ color: "#646D78" }}>
                        ปริมาณการดื่มน้ำ
                      </Typography>
                      <Box>
                        <Typography
                          component={"span"}
                          style={body14600}
                          sx={{ color: "secondary.main" }}
                        >
                          {`${(
                            currentHealtInfo.waterIntake * 250
                          ).toLocaleString()} ml.`}
                          <Typography
                            component={"span"}
                            style={body14400}
                            sx={{ color: "#7A8688" }}
                          >
                            {` ( ${currentHealtInfo.waterIntake} แก้ว )`}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 5fr"}
                      sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                    >
                      <Typography style={body14400} sx={{ color: "#646D78" }}>
                        ออกกำลังกาย
                      </Typography>
                      <Box>
                        <Typography
                          style={body14600}
                          sx={{ color: "secondary.main" }}
                        >
                          {`${getTotalCalorieExercise(
                            currentHealtInfo.exerciseInfo
                          ).toLocaleString()} kcal`}
                        </Typography>
                        {currentHealtInfo.exerciseInfo?.length > 0 && (
                          <Stack>
                            <Typography style={body14600} sx={{ my: 1 }}>
                              รายละเอียด
                            </Typography>
                            {currentHealtInfo.exerciseInfo?.map((item, idx) => {
                              return (
                                <Typography
                                  key={idx}
                                  component={"span"}
                                  style={body14600}
                                >
                                  {
                                    activityMET.find(
                                      (ac) => ac.name === item.name
                                    )?.nameTh
                                  }
                                  <Typography
                                    component={"span"}
                                    style={body14400}
                                    sx={{ color: "#7A8688" }}
                                  >
                                    {` / ระยะเวลา: ${
                                      times.find((t) => t.time === item.time)
                                        ?.label
                                    } / การเผาผลาญ: ${item.kiloCalorie.toLocaleString()} kcal`}
                                  </Typography>
                                </Typography>
                              );
                            })}
                          </Stack>
                        )}
                      </Box>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 5fr"}
                      sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                    >
                      <Typography style={body14400} sx={{ color: "#646D78" }}>
                        อาการอื่นๆ
                      </Typography>
                      <Box>
                        <Typography
                          component={"span"}
                          style={body14600}
                          sx={{ color: "secondary.main" }}
                        >
                          {currentHealtInfo.conditions
                            ? currentHealtInfo.conditions
                                ?.map((item) =>
                                  item !== ""
                                    ? item === "อื่นๆ"
                                      ? `${currentHealtInfo.conditionOther}`
                                      : `${item}`
                                    : `-`
                                )
                                .join(", ")
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 5fr"}
                      sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                    >
                      <Typography style={body14400} sx={{ color: "#646D78" }}>
                        บันทึก
                      </Typography>
                      <Box>
                        <Typography
                          component={"span"}
                          style={body14600}
                          sx={{ color: "secondary.main" }}
                        >
                          {currentHealtInfo.note !== ""
                            ? currentHealtInfo.note
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Paper>
              </Stack>

              <ProfileCard
                patientUser={patientUser}
                patientUserProfile={patientUserProfile}
                handelViewProfile={handelViewProfile}
              />
            </Box>
          )}{" "}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#7A8688",
                width: "90px",
                height: "35px",
                marginRight: "10px",
              }}
              onClick={handleGobackClick}
            >
              ย้อนกลับ
            </Button>
          </Box>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
};

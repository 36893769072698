import dayjs, { Dayjs } from "dayjs";
import { PostOpDetail } from "../shared/patient-detail/PatientDashboard";

const currentYear = dayjs().year();
const isLeapYear = (year: number) =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
const daysInYear = isLeapYear(currentYear) ? 366 : 365;

export const generateDataSeries = (
  lengthType: string,
  startDate: Dayjs,
  //eslint-disable-next-line
  data: any[],
  surgeryDetails?: PostOpDetail[]
) => {
  const length =
    lengthType === "Weekly"
      ? 7
      : lengthType === "Monthly"
      ? dayjs().daysInMonth()
      : daysInYear;
  return Array.from(
    {
      length: length,
    },
    (_, index) => {
      const currentDate = startDate.add(index, "day").startOf("day").toDate();
      const matchingData = data.find((item) =>
        dayjs(item.date).isSame(currentDate, "day")
      );
      //   if (
      //     surgeryDate &&
      //     dayjs(currentDate).isSame(dayjs(surgeryDate).startOf("day"), "day")
      //   ) {
      //     return surgeryWeight !== undefined ? surgeryWeight : null;
      //   }
      if (surgeryDetails) {
        const surgeryDetail = surgeryDetails.find((detail) =>
          dayjs(currentDate).isSame(
            dayjs(detail.surgeryDate).startOf("day"),
            "day"
          )
        );
        if (surgeryDetail) {
          return surgeryDetail.postAwlWeight !== null
            ? surgeryDetail.postAwlWeight
            : surgeryDetail.demoWeight
            ? surgeryDetail.demoWeight
            : null;
        }
      }
      return matchingData && matchingData.weight > 0
        ? matchingData.weight
        : null;
    }
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Warning } from "../../asset/iconSvg";
import { DialogStyle } from "../../style";

type Props = {
  title: string;
  content: string;
  content2?: string;
  openDialog: boolean;
  handleCloseDialog: () => void;
};
export const ErrorDialog = ({
  title,
  content,
  content2,
  openDialog,
  handleCloseDialog,
}: Props) => {
  return (
    <Dialog
      onClose={handleCloseDialog}
      open={openDialog}
      fullWidth={true}
      maxWidth={"xs"}
      {...DialogStyle}
    >
      <DialogTitle align="center">
        <Warning />
        <Typography fontWeight={700} fontSize={20}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          textAlign: "center",
        }}
      >
        <Stack>
          <Typography component={"span"} variant="body2" color={"#757575"}>
            {content}
          </Typography>
          {content2 && (
            <Typography variant="body2" color={"#757575"}>
              {content2}
            </Typography>
          )}
        </Stack>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            width: 1,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: "#098484",
              border: "1px solid #098484",
              "&:hover": {
                border: "1px solid #098484",
              },
              width: "50%",
            }}
            onClick={handleCloseDialog}
          >
            ตกลง
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

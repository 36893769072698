import axios from "axios";

const BASE_URL = "https://service.obesityconnects.com";

export const dashboardService = {
  getDashboard: async function (targetUserId?: number) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.get(
        `${BASE_URL}/v1/dashboard/fetch`,

        {
          headers: {
            Authorization: `Bearer ${token}`, // include the token in the Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

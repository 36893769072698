import { Box, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { VisitData } from "../../../type";
import { calculateBmi } from "../../../utils/calculateBmi";
import { calculateEBMIL } from "../../../utils/calculateEBMIL";
import { calculateEWL } from "../../../utils/calculateEWL";
import { calculateIdealWeight } from "../../../utils/calculateIdealWeight";
import { calculateTWL } from "../../../utils/calculateTWL";
import { DemographicDataResponse } from "../Demographic/Demographic";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { LayoutTab } from "../LayoutTab";
import { StatusBottomBar } from "../StatusBottomBar";
import {
  BodyAndComposition,
  BodyAndCompositionValues,
} from "./BodyAndComposition";
import { Chemistry, ChemistryValues } from "./Chemistry";
import { Hematology, HematologyValues } from "./Hematology";
import { CustomLabs, Other } from "./Other";
import {
  VitaminAndSupplement,
  VitaminAndSupplementValues,
} from "./VitaminAndSupplement";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import { BodyAndCompositionHistoryDialog } from "./BodyAndCompositionHistoryDialog";
import { set } from "date-fns";
import { BodyAndCompositionVisitDialog } from "./BodyAndCompositionVisitDialog";

export type BodyLabResponse = {
  entryId: number;
  editorId: number | string;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  sbp: string;
  dbp: string;
  ht: string;
  weight: number;
  bmi: number;
  idealWeight: number;
  excessWeightLoss: number;
  totalWeightLoss: number;
  excessBmiLoss: number;
  smm: string;
  bfm: string;
  pbf: string;
  fbs: string;
  hba1c: string;
  dm: string;
  chol: string;
  hdl: string;
  tg: string;
  dlp: string;
  cr: string;
  alb: string;
  sgot: string;
  sgpt: string;
  alp: string;
  hb: string;
  hct: string;
  mcv: string;
  plt: string;
  vitaminD: string;
  vitaminB12: string;
  folate: string;
  ferritin: string;
  serumIron: string;
  tib: string;
  ldlc: string;
  customLabs: CustomLabs[];
};

type Props = {
  handleCancelClick: () => void;
};
export const BodyAndCompositionAndLab = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);
  const [visitDataList, setVisitDataList] = useState<VisitData[]>([]);
  const [bodyAndCompositionValues, setBodyAndCompositionValues] =
    useState<BodyAndCompositionValues>();
  const [chemistryValues, setChemistryValues] = useState<ChemistryValues>();
  const [originalChemistryValues, setOriginalChemistryValues] = useState<ChemistryValues>();
  const [hematologyValues, setHematologyValues] = useState<HematologyValues>();
  const [vitaminAndSupplementValues, setVitaminAndSupplementValues] =
    useState<VitaminAndSupplementValues>();
  const [othersValues, setOthersValues] = useState<CustomLabs[]>([]);
  const [currentDemoWeight, setCurrentDemoWeight] = useState<number | null>();
  const [previousDemoWeight, setPreviousDemoWeight] = useState<number | null>();
  const [status, setStatus] = useState(currentDemoWeight ? 2 : 1);
  const [originalStatus, setOriginalStatus] = useState(currentDemoWeight ? 2 : 1);
  const [height, setHeight] = useState<number | null>();
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openVisitDialog, setOpenVisitDialog] = useState(false);
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState(false);
  const latestEntryId = useAppSelector(
    (state) =>
      state.patientUser.latestEntryIds?.bodyCompositionAndLaboratoryStatus
  );
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.bodyCompositionAndLaboratoryStatus
  );
  const setData = (bodyLabData: BodyLabResponse) => {
    setBodyAndCompositionValues({
      sbp: bodyLabData.sbp,
      dbp: bodyLabData.dbp,
      weight: bodyLabData.weight,
      smm: bodyLabData.smm,
      bfm: bodyLabData.bfm,
      pbf: bodyLabData.pbf,
    });
    setChemistryValues({
      FBS: bodyLabData.fbs,
      HbA1C: bodyLabData.hba1c,
      cholesterol: bodyLabData.chol,
      HDL: bodyLabData.hdl,
      triglyceride: bodyLabData.tg,
      LDLC: bodyLabData.ldlc,
      creatinine: bodyLabData.cr,
      albumin: bodyLabData.alb,
      SGOT: bodyLabData.sgot,
      SGPT: bodyLabData.sgpt,
      ALP: bodyLabData.alp,
    });
    setHematologyValues({
      Hb: bodyLabData.hb,
      Hct: bodyLabData.hct,
      MVC: bodyLabData.mcv,
      Platelet: bodyLabData.plt,
    });
    setVitaminAndSupplementValues({
      vitaminD: bodyLabData.vitaminD,
      vitaminB12: bodyLabData.vitaminB12,
      folate: bodyLabData.folate,
      ferritin: bodyLabData.ferritin,
      serumIron: bodyLabData.serumIron,
      TIBC: bodyLabData.tib,
    });
    setOthersValues(bodyLabData.customLabs);
  };
  const sortedDemographicData = useMemo(() => {
    const filteredData = visitDataList.filter(
      (item) =>
        item.demographicDataStatus?.lasEntryId !== undefined ||
        item.visitDate === store.getState().patientUser.selectedVisitDate
    );
    const sortedData = filteredData.sort((a, b) => {
      const dateA = dayjs(a.visitDate);
      const dateB = dayjs(b.visitDate);
      return dateA.diff(dateB);
    });
    const data = sortedData.map((visit) => ({
      visitId: visit.visitId,
      visitDate: visit.visitDate,
      demographicDataStatus: visit.demographicDataStatus?.lasEntryId,
    }));
    return data;
  }, [visitDataList]);
  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const bodyLabData: BodyLabResponse =
          await visitDataService.getBodyLabFromEntryId(currentEntryId);
        setData(bodyLabData);
        setStatus(bodyLabData.visitDataStatus);
        setOriginalStatus(bodyLabData.visitDataStatus);
      }
      // else if (latestEntryId) {
      //   console.log("latestEntryId");
      //   const bodyLabData: BodyLabResponse =
      //     await visitDataService.getBodyLabFromEntryId(latestEntryId);
      //   setData(bodyLabData);
      // }
    };
    fetchData();
    const fetchVisits = async () => {
      try {
        const response = await visitDataService.getAllVisit(
          store.getState().patientUser.patientUser?.id
        );
        if (response.length === 0) {
          setVisitDataList([]);
        }
        setVisitDataList(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchVisits();
  });
  const fetchDemo = async (entryId: number) => {
    const demographicData = await visitDataService.getDemoGraphicFromEntryId(
      entryId
    );
    return demographicData;
  };
  useEffect(() => {
    const fetchDemographicData = async () => {
      try {
        const currentVisitId = store.getState().patientUser.selectedVisitId;
        const currentIndex = sortedDemographicData.findIndex(
          (visit) => visit.visitId === currentVisitId
        );

        if (currentIndex !== -1) {
          if (currentIndex === 0) {
            const demoId =
              sortedDemographicData[currentIndex].demographicDataStatus;
            if (demoId) {
              const demographicData = (await fetchDemo(
                Number(demoId)
              )) as DemographicDataResponse;
              if (demographicData) {
                setCurrentDemoWeight(demographicData.weightPreOperation);
                setPreviousDemoWeight(demographicData.weightPreOperation);
                setHeight(demographicData.height);
              } else {
                setCurrentDemoWeight(0);
                setPreviousDemoWeight(0);
                setHeight(0);
              }
            }
          } else {
            const currentStatus =
              sortedDemographicData[currentIndex].demographicDataStatus;
            const previousStatus =
              sortedDemographicData[currentIndex - 1].demographicDataStatus;

            const currentDemographicData = currentStatus
              ? await fetchDemo(Number(currentStatus))
              : null;
            const previousDemographicData = previousStatus
              ? await fetchDemo(Number(previousStatus))
              : null;

            if (currentDemographicData) {
              setCurrentDemoWeight(currentDemographicData.weightPreOperation);
              setHeight(currentDemographicData.height);
            } else {
              setCurrentDemoWeight(0);
              setHeight(0);
            }

            if (previousDemographicData) {
              setPreviousDemoWeight(previousDemographicData.weightPreOperation);
              setHeight(previousDemographicData.height);
            } else {
              setPreviousDemoWeight(0);
              setHeight(0);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching demographic data:", error);
        setCurrentDemoWeight(0);
        setPreviousDemoWeight(0);
      }
    };

    fetchDemographicData();
  }, [sortedDemographicData]);
  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.saveBodyLabData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        sbp: bodyAndCompositionValues?.sbp
          ? bodyAndCompositionValues.sbp
          : null,
        dbp: bodyAndCompositionValues?.dbp
          ? bodyAndCompositionValues.dbp
          : null,
        weight: bodyAndCompositionValues?.weight
          ? bodyAndCompositionValues.weight
          : 0,
        bmi:
          currentDemoWeight && height
            ? calculateBmi(currentDemoWeight, height)
            : null,
        idealWeight: height ? calculateIdealWeight(height).toString() : null,
        excessWeightLoss:
          currentDemoWeight && previousDemoWeight && height
            ? calculateEWL(
                currentDemoWeight,
                previousDemoWeight,
                calculateIdealWeight(height)
              ).toString()
            : null,
        totalWeightLoss:
          currentDemoWeight && previousDemoWeight
            ? calculateTWL(currentDemoWeight, previousDemoWeight).toString()
            : null,
        excessBmiLoss:
          currentDemoWeight && previousDemoWeight && height
            ? calculateEBMIL(
                currentDemoWeight,
                previousDemoWeight,
                height
              ).toString()
            : null,
        smm: bodyAndCompositionValues?.smm
          ? bodyAndCompositionValues.smm
          : null,
        bfm: bodyAndCompositionValues?.bfm
          ? bodyAndCompositionValues.bfm
          : null,
        pbf: bodyAndCompositionValues?.pbf
          ? bodyAndCompositionValues.pbf
          : null,
        fbs: chemistryValues?.FBS ? chemistryValues.FBS : null,
        hba1c: chemistryValues?.HbA1C ? chemistryValues?.HbA1C : null,
        chol: chemistryValues?.cholesterol ? chemistryValues.cholesterol : null,
        hdl: chemistryValues?.HDL ? chemistryValues?.HDL : null,
        tg: chemistryValues?.triglyceride
          ? chemistryValues?.triglyceride
          : null,
        ldlc: chemistryValues?.LDLC ? chemistryValues?.LDLC : null,
        cr: chemistryValues?.creatinine ? chemistryValues?.creatinine : null,
        alb: chemistryValues?.albumin ? chemistryValues?.albumin : null,
        sgot: chemistryValues?.SGOT ? chemistryValues?.SGOT : null,
        sgpt: chemistryValues?.SGPT ? chemistryValues?.SGPT : null,
        alp: chemistryValues?.ALP ? chemistryValues?.ALP : null,
        hb: hematologyValues?.Hb ? hematologyValues?.Hb : null,
        hct: hematologyValues?.Hct ? hematologyValues?.Hct : null,
        mcv: hematologyValues?.MVC ? hematologyValues?.MVC : null,
        plt: hematologyValues?.Platelet ? hematologyValues?.Platelet : null,
        vitaminD: vitaminAndSupplementValues?.vitaminD
          ? vitaminAndSupplementValues.vitaminD
          : null,
        vitaminB12: vitaminAndSupplementValues?.vitaminB12
          ? vitaminAndSupplementValues.vitaminB12
          : null,
        folate: vitaminAndSupplementValues?.folate
          ? vitaminAndSupplementValues.folate
          : null,
        ferritin: vitaminAndSupplementValues?.ferritin
          ? vitaminAndSupplementValues.ferritin
          : null,
        serumIron: vitaminAndSupplementValues?.serumIron
          ? vitaminAndSupplementValues.serumIron
          : null,
        tib: vitaminAndSupplementValues?.TIBC
          ? vitaminAndSupplementValues.TIBC
          : null,
        customLabs: othersValues?.length > 0 ? othersValues : [],
      });

      return response as BodyLabResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          bodyCompositionAndLaboratoryStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleSaveBodyAndCompositionValues = async (
    value: BodyAndCompositionValues
  ) => {
    if(patientDataPermissionEditAble === false){
      return;
    }
    setBodyAndCompositionValues(value);
    dispatch(updateTabsIsDirty(true));
  };
  const handleSaveChemistryValues = (value: ChemistryValues) => {
    if(patientDataPermissionEditAble === false){
      return;
    }
    setChemistryValues(value);
    console.log("value",value);
    dispatch(updateTabsIsDirty(true));
  };
  const handleSaveHematologyValues = (value: HematologyValues) => { 
    if(patientDataPermissionEditAble === false){
      return;
    }
    setHematologyValues(value);
    dispatch(updateTabsIsDirty(true));
  };
  const handleSaveVitaminAndSupplementValues = (
    value: VitaminAndSupplementValues
  ) => {
    if(patientDataPermissionEditAble === false){
      return;
    }
    setVitaminAndSupplementValues(value);
    dispatch(updateTabsIsDirty(true));
  };
  const handleSaveOthersValues = (value: CustomLabs[]) => {
    if(patientDataPermissionEditAble === false){
      return;
    }
    console.log("value other",value);
    setOthersValues(value);
    dispatch(updateTabsIsDirty(true));
  };
  const handleSubmitData = async () => {
    if(patientDataPermissionEditAble === false){
      setOpenNoPermissionDialog(true);
      setStatus(originalStatus);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    else if (
      currentDemoWeight === 0 ||
      currentDemoWeight === null ||
      currentDemoWeight === undefined
    ) {
      setOpenIncompleteDataDialog(true);
    } else {
      await submitDataAsync();
      dispatch(updateTabsIsDirty(false));
    }
  };
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
    dispatch(updateTabsIsDirty(true));
  };
  const handleCloseDialog = () => {
    setOpenIncompleteDataDialog(false);
    setOpenHistoryDialog(false);
    setOpenNoPermissionDialog(false);
    setOpenVisitDialog(false);
  };
  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box sx={{ p: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => setOpenVisitDialog(true)}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Grid container rowGap={3} alignItems="stretch" sx={{ my: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <BodyAndComposition
                handleSaveBodyAndCompositionValues={
                  handleSaveBodyAndCompositionValues
                }
                currentEntryId={currentEntryId}
                latestEntryId={latestEntryId}
                currentWeight={currentDemoWeight}
                previousWeight={previousDemoWeight}
                height={height}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Chemistry
                handleSaveChemistryValues={handleSaveChemistryValues}
                currentEntryId={currentEntryId}
                latestEntryId={latestEntryId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Hematology
                handleSaveHematologyValues={handleSaveHematologyValues}
                currentEntryId={currentEntryId}
                latestEntryId={latestEntryId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <VitaminAndSupplement
                handleSaveVitaminAndSupplementValues={
                  handleSaveVitaminAndSupplementValues
                }
                currentEntryId={currentEntryId}
                latestEntryId={latestEntryId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Other handleSaveOthersValues={handleSaveOthersValues} currentEntryId={currentEntryId} latestEntryId={latestEntryId}/>
            </Grid>
          </Grid>
          <StatusBottomBar
            value={
              // currentEntryId
              //   ? status
              //   : currentDemoWeight &&
              //     currentDemoWeight !== null &&
              //     currentDemoWeight > 0
              //   ? 2
              //   : 1
              status
            }
            handleStatusChange={handleStatusChange}
          />
        </Box>
      </Box>
      <WarningDialog
        openDialog={openIncompleteDataDialog}
        title="ข้อมูลไม่ครบถ้วน"
        content="ไม่พบข้อมูลน้ำหนัก กรุณากรอกข้อมูลน้ำหนักในหน้า Demographic ก่อนบันทึกข้อมูล Body Composition & Lab"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
      <BodyAndCompositionHistoryDialog
        open={openHistoryDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <BodyAndCompositionVisitDialog
        open={openVisitDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
    </LayoutTab>
  );
};

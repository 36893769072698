import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import cancel from "../../asset/cancel.png";
import { DialogStyle } from "../../style";

type Props = {
  title: string;
  content: string;
  content2?: string;
  openDialog: boolean;
  handleCloseDialog: () => void;
  handleCancelClick: () => void;
  handleConfirmClick: () => void;
};
export const CancelDialog = ({
  title,
  content,
  content2,
  openDialog,
  handleCloseDialog,
  handleCancelClick,
  handleConfirmClick,
}: Props) => {
  return (
    <Dialog
      onClose={handleCloseDialog}
      open={openDialog}
      fullWidth={true}
      maxWidth={"xs"}
      {...DialogStyle}
    >
      <DialogTitle align="center">
        <img
          src={cancel}
          style={{ width: "64px", height: "64px" }}
          alt="check"
        />
        <Typography fontWeight={700} fontSize={20}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          textAlign: "center",
        }}
      >
        <Typography component={"span"} variant="body2" color={"#757575"}>
          {content}
        </Typography>
        {content2 && <Typography variant="body2">{content2}</Typography>}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            width: 1,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: "#DF5C8E",
              border: "1px solid #DF5C8E",
              "&:hover": {
                border: "1px solid #DF5C8E",
              },
              width: "50%",
            }}
            onClick={handleCancelClick}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#D80027",
              "&:hover": {
                bgcolor: "#D80027",
              },
              width: "50%",
            }}
            onClick={handleConfirmClick}
          >
            ไม่อนุมัติ
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

import { Box, Button } from "@mui/material";

type Props = {
  handleCancelClick: () => void;
  handleSubmitData: () => void;
};
export const SubmitBottomBar = ({
  handleCancelClick,
  handleSubmitData,
}: Props) => {
  return (
    <Box
      bgcolor={"white"}
      sx={{
        // width: "calc(100% - 30px)",
        height: "35px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "sticky",
        bottom: 0,
        borderTop: "2px solid #D9D9D9",
        p: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: "#7A8688",
            width: "90px",
            height: "35px",
            marginRight: "10px",
          }}
          onClick={handleCancelClick}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#098484",
            width: "90px",
            height: "35px",
          }}
          onClick={handleSubmitData}
        >
          บันทึก
        </Button>
      </div>
    </Box>
  );
};

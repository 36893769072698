import axios from "axios";

const BASE_URL = "https://service.obesityconnects.com";

export const getDomicile = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/v1/datalist/domicile`);
    return response.data;
  } catch (error) {
    console.error("Error fetching list:", error);
    throw error;
  }
};

import { SVGProps } from "react";

export const Seemore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.3"
      d="M17.2 2H5.8C3.70132 2 2 3.70132 2 5.8V17.2C2 19.2987 3.70132 21 5.8 21H17.2C19.2987 21 21 19.2987 21 17.2V5.8C21 3.70132 19.2987 2 17.2 2Z"
      fill="#B5B5C3"
    />
    <path
      d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z"
      fill="#B5B5C3"
    />
    <path
      d="M17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13C16.5523 13 17 12.5523 17 12Z"
      fill="#B5B5C3"
    />
    <path
      d="M9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12Z"
      fill="#B5B5C3"
    />
  </svg>
);
export const Seemore2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="18" height="15" rx="2" fill="#D9D9D9" fillOpacity="0.5" />
    <rect x="1" y="2" width="16" height="3" rx="1.5" fill="#D9D9D9" />
    <rect x="1" y="10" width="16" height="3" rx="1.5" fill="#D9D9D9" />
    <rect x="1" y="6" width="16" height="3" rx="1.5" fill="#D9D9D9" />
  </svg>
);
export const Hospital = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8011 6.05127V7.23635H13.5409C13.6145 7.23635 13.6742 7.29604 13.6742 7.36968V13.7299H14.3987C14.546 13.7299 14.6654 13.8493 14.6654 13.9966V14.3997C14.6654 14.547 14.546 14.6663 14.3987 14.6663H1.5987C1.45142 14.6663 1.33203 14.547 1.33203 14.3997V13.9966C1.33203 13.8493 1.45142 13.7299 1.5987 13.7299H2.28045V3.68001C2.28045 3.60637 2.34015 3.54667 2.41379 3.54667H3.3005V2.38855C3.3005 2.31492 3.36019 2.25522 3.43383 2.25522H4.87037V1.46634C4.87037 1.3927 4.93006 1.33301 5.0037 1.33301H5.70477C5.77841 1.33301 5.83811 1.3927 5.83811 1.46634V2.25522H7.27468C7.34832 2.25522 7.40801 2.31492 7.40801 2.38855V3.54667H8.48257V7.23635H9.09401V6.05127C9.09401 5.97763 9.15371 5.91794 9.22735 5.91794H10.4637V5.11703C10.4637 5.0434 10.5234 4.9837 10.597 4.9837H11.2981C11.3717 4.9837 11.4314 5.0434 11.4314 5.11703V5.91794H12.6677C12.7414 5.91794 12.8011 5.97763 12.8011 6.05127ZM3.95101 13.0148H4.91875V12.033H3.95101V13.0148ZM4.91875 11.2023H3.95101V10.2206H4.91875V11.2023ZM3.95101 9.38984H4.91875V8.40809H3.95101V9.38984ZM4.91875 7.57736H3.95101V6.59562H4.91875V7.57736ZM3.95101 5.76489H4.91875V4.78315H3.95101V5.76489ZM6.75746 13.0148H5.78972V12.033H6.75746V13.0148ZM5.78972 11.2023H6.75746V10.2206H5.78972V11.2023ZM6.75746 9.38984H5.78972V8.40809H6.75746V9.38984ZM5.78972 7.57736H6.75746V6.59562H5.78972V7.57736ZM6.75746 5.76489H5.78972V4.78315H6.75746V5.76489ZM9.57673 13.0148H10.5445V12.033H9.57673V13.0148ZM10.5445 11.2023H9.57673V10.2206H10.5445V11.2023ZM9.57673 9.38984H10.5445V8.40809H9.57673V9.38984ZM12.383 13.0148H11.4152V12.033H12.383V13.0148ZM11.4152 11.2023H12.383V10.2206H11.4152V11.2023ZM12.383 9.38985H11.4152V8.4081H12.383V9.38985Z"
      fill="#A0ABBA"
    />
  </svg>
);
export const Mail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0396 4.26727L8.65238 7.14599C8.23516 7.348 7.7537 7.348 7.34492 7.1459L1.95779 4.26728C1.64405 4.10754 1.47988 3.71663 1.70926 3.44366C1.97201 3.13099 2.34958 2.93457 2.76867 2.93457H13.2287C13.6478 2.93457 14.0254 3.131 14.2881 3.44368C14.5175 3.71664 14.3533 4.10753 14.0396 4.26727ZM9.04973 8.03994C9.06171 8.03519 9.07376 8.03042 9.08589 8.02562L14.6654 5.06024V11.5017C14.6654 12.3639 14.0209 13.0654 13.2288 13.0654H2.76866C1.97649 13.0654 1.33203 12.3639 1.33203 11.5017V5.06024L6.91174 8.02573C7.33684 8.22121 7.60755 8.30622 7.9987 8.30622C8.37751 8.30622 8.67953 8.18658 9.04973 8.03994Z"
      fill="#A0ABBA"
    />
  </svg>
);
export const HealthBenefit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.01901 1C7.09673 1 6.18348 1.18166 5.3314 1.5346C4.78052 1.76278 4.26218 2.05967 3.78833 2.41729C3.62232 2.54257 3.60799 2.79163 3.766 2.94963L4.73693 3.92057C4.87436 4.05799 5.10195 4.07287 5.27761 3.95453C5.71627 3.65903 6.21044 3.43007 6.74949 3.28563C8.17607 2.90338 9.6245 3.19124 10.7601 3.95585C10.9355 4.07392 11.1627 4.05895 11.3 3.92165L12.272 2.94963C12.43 2.79163 12.4157 2.54257 12.2497 2.41729C11.7759 2.05967 11.2575 1.76278 10.7066 1.5346C9.85455 1.18166 8.9413 1 8.01901 1Z"
      fill="#A0ABBA"
    />
    <path
      d="M13.5861 3.75444C13.4608 3.58844 13.2118 3.57411 13.0538 3.73211L12.0831 4.70282C11.9456 4.84026 11.9308 5.06788 12.0491 5.24354C12.3448 5.68231 12.5738 6.17663 12.7183 6.71587C13.1006 8.14251 12.8127 9.59101 12.048 10.7266C11.9299 10.902 11.9449 11.1293 12.0822 11.2666L13.0538 12.2381C13.2118 12.3962 13.4608 12.3818 13.5861 12.2158C13.9437 11.742 14.2406 11.2236 14.4688 10.6727C14.8218 9.82066 15.0034 8.90741 15.0034 7.98513C15.0034 7.06285 14.8218 6.1496 14.4688 5.29752C14.2406 4.74663 13.9437 4.22829 13.5861 3.75444Z"
      fill="#A0ABBA"
    />
    <path
      d="M2.9457 3.72389C2.78771 3.5659 2.53864 3.58023 2.41335 3.74624C1.49891 4.95787 0.996094 6.44148 0.996094 7.97693C0.996094 9.83956 1.73602 11.6259 3.0531 12.943C4.37018 14.2601 6.15653 15 8.01916 15C9.55461 15 11.0382 14.4972 12.2499 13.5827C12.4159 13.4575 12.4302 13.2084 12.2722 13.0504L11.3015 12.0797C11.1641 11.9423 10.9365 11.9274 10.7608 12.0458C10.3221 12.3413 9.82787 12.5703 9.28874 12.7148C6.67214 13.4159 3.98261 11.8631 3.28149 9.2465C2.89922 7.81986 3.18713 6.37137 3.95181 5.23575C4.06989 5.06039 4.05493 4.83311 3.91762 4.69581L2.9457 3.72389Z"
      fill="#A0ABBA"
    />
    <path
      d="M7.5043 10.9457C7.5043 11.0726 7.57663 11.1439 7.72129 11.1439C7.79946 11.1477 7.87971 11.1497 7.95703 11.1516C8.0407 11.1537 8.12095 11.1557 8.19144 11.1598C8.32706 11.1598 8.39939 11.0884 8.39939 10.9695C8.39939 10.9246 8.40039 10.8796 8.4014 10.8347C8.40341 10.7448 8.40541 10.655 8.39939 10.5651C8.39939 10.4224 8.46268 10.359 8.61638 10.3193C8.96899 10.24 9.26735 10.0735 9.49338 9.82771C10.1353 9.14581 9.8912 8.15468 8.98707 7.71858C8.7548 7.60688 8.51012 7.51696 8.26331 7.42625C8.21229 7.4075 8.16118 7.38872 8.11007 7.3697C7.93828 7.30627 7.77554 7.23491 7.63088 7.13183C7.3506 6.92568 7.40485 6.60058 7.73937 6.47372C7.83883 6.43407 7.93828 6.42615 8.03774 6.41822C8.42651 6.39443 8.7972 6.45786 9.14981 6.60851C9.3216 6.67987 9.37584 6.65609 9.43913 6.49751C9.50242 6.32307 9.55667 6.15656 9.61092 5.98212C9.64708 5.87111 9.60188 5.79182 9.4753 5.74425C9.24927 5.65703 9.01419 5.59359 8.77008 5.56188C8.60396 5.5369 8.52504 5.52504 8.48756 5.47925C8.45363 5.43782 8.45363 5.36862 8.45363 5.23679C8.45363 5.04723 8.45363 4.9483 8.40422 4.89668C8.35029 4.84033 8.23749 4.84033 8.00157 4.84033H7.80266C7.59471 4.84033 7.55855 4.87205 7.54951 5.06235V5.31608C7.54951 5.43663 7.54951 5.49811 7.52042 5.5388C7.48828 5.58375 7.42064 5.60332 7.27827 5.6491C6.61825 5.86318 6.22044 6.25171 6.17523 6.8781C6.13907 7.42521 6.47359 7.8058 6.98895 8.07539C7.21181 8.19266 7.4571 8.27847 7.69954 8.36328C7.80134 8.39889 7.90264 8.43433 8.00157 8.47184C8.13719 8.51942 8.26377 8.58285 8.37226 8.66214C8.69775 8.90001 8.6435 9.28854 8.25473 9.43919C8.04678 9.51848 7.82979 9.53434 7.60375 9.51055C7.26019 9.4709 6.92566 9.39161 6.61825 9.24889C6.43743 9.16167 6.38318 9.18546 6.31989 9.3599C6.26565 9.51054 6.22044 9.66119 6.17524 9.81184C6.11195 10.018 6.13907 10.0656 6.35606 10.1607C6.64538 10.2797 6.94374 10.3431 7.25114 10.3827C7.49526 10.4145 7.5043 10.4224 7.5043 10.6444V10.9457Z"
      fill="#A0ABBA"
    />
  </svg>
);
export const File = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="33"
    height="44"
    viewBox="0 0 33 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_1429_60168)">
      <path
        d="M0 5.5C0 2.46641 2.46641 0 5.5 0H19.25V11C19.25 12.5211 20.4789 13.75 22 13.75H33V38.5C33 41.5336 30.5336 44 27.5 44H5.5C2.46641 44 0 41.5336 0 38.5V5.5ZM33 11H22V0L33 11Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1429_60168">
        <rect width="33" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const Dashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="44"
    height="40"
    viewBox="0 0 44 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_1429_60162)">
      <path
        d="M23.2231 18.3953V1.27234C23.2231 0.582517 23.7578 0 24.4453 0C33.8946 0 41.5564 7.68769 41.5564 17.1689C41.5564 17.8587 40.9759 18.3953 40.2884 18.3953H23.2231ZM2.44531 20.848C2.44531 11.5507 9.32795 3.85534 18.2578 2.62899C18.9606 2.52935 19.5564 3.09654 19.5564 3.80935V22.0743L31.5113 34.0696C32.0231 34.5831 31.9849 35.4262 31.3967 35.8401C28.4023 37.9862 24.7356 39.2432 20.7786 39.2432C10.6571 39.2432 2.44531 31.0114 2.44531 20.848ZM42.6564 22.0743C43.3668 22.0743 43.9245 22.6722 43.8328 23.3773C43.2446 27.6619 41.1898 31.4712 38.1877 34.2842C37.7293 34.7134 37.0113 34.6828 36.5682 34.2305L24.4453 22.0743H42.6564Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1429_60162">
        <rect width="44" height="39.2432" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const Patient = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7083 3.58301C26.9959 3.58301 33.0133 9.02183 33.9229 16.0619L37.955 22.4021C38.2206 22.8196 38.167 23.4431 37.5521 23.7065L34.0417 25.2102V30.458C34.0417 32.4371 32.4374 34.0413 30.4583 34.0413H26.8768L26.875 39.4163H10.75L10.7504 32.7981C10.7506 30.6823 9.96796 28.6819 8.52027 26.8766C6.55225 24.4224 5.375 21.3069 5.375 17.9163C5.375 10.0003 11.7922 3.58301 19.7083 3.58301ZM19.7083 12.5413C18.7188 12.5413 17.9167 13.3435 17.9167 14.333V16.1229L16.125 16.1247C15.1355 16.1247 14.3333 16.9268 14.3333 17.9163C14.3333 18.9059 15.1355 19.708 16.125 19.708L17.9167 19.7062V21.4997C17.9167 22.4892 18.7188 23.2913 19.7083 23.2913C20.6979 23.2913 21.5 22.4892 21.5 21.4997V19.708H23.2917C24.2812 19.708 25.0833 18.9059 25.0833 17.9163C25.0833 16.9268 24.2812 16.1247 23.2917 16.1247H21.5V14.333C21.5 13.3435 20.6979 12.5413 19.7083 12.5413Z"
      fill={props.color}
    />
  </svg>
);
export const OtherArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0589 13.2008L13.6016 9.65811M13.6016 9.65811L10.0589 6.11543M13.6016 9.65811H6.40156C4.19242 9.65811 2.40156 7.86725 2.40156 5.65811V2.80078"
      stroke="#646D78"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const OtherAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="14" cy="14" r="14" fill="black" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4041 13.3207H15.6819V8.59854C15.6819 7.94688 15.1542 7.41797 14.5013 7.41797C13.8484 7.41797 13.3207 7.94688 13.3207 8.59854V13.3207H8.59854C7.94569 13.3207 7.41797 13.8496 7.41797 14.5013C7.41797 15.153 7.94569 15.6819 8.59854 15.6819H13.3207V20.4041C13.3207 21.0557 13.8484 21.5846 14.5013 21.5846C15.1542 21.5846 15.6819 21.0557 15.6819 20.4041V15.6819H20.4041C21.0569 15.6819 21.5846 15.153 21.5846 14.5013C21.5846 13.8496 21.0569 13.3207 20.4041 13.3207Z"
      fill="white"
    />
  </svg>
);
export const OtherRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="14" cy="14" r="14" fill="#D80027" />
    <path
      d="M9 14H19"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const OtherCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="14" cy="14" r="14" fill="#26B465" />
    <path
      d="M23 14C23 18.9706 18.9706 23 14 23C9.02944 23 5 18.9706 5 14C5 9.02944 9.02944 5 14 5C15.4121 5 16.7482 5.32519 17.9375 5.90476M21.3125 8.375L13.4375 16.25L11.1875 14"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const Warning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M55.5466 48.044L35.3399 12.5538C33.9077 10.0376 30.0923 10.0376 28.6589 12.5538L8.45342 48.044C8.14244 48.5902 7.98654 49.2034 8.00091 49.8237C8.01529 50.4439 8.19946 51.0501 8.53544 51.5829C8.87143 52.1158 9.34775 52.5571 9.91792 52.8639C10.4881 53.1707 11.1326 53.3324 11.7886 53.3333H52.2055C52.862 53.3334 53.5072 53.1724 54.0782 52.866C54.6492 52.5596 55.1264 52.1183 55.463 51.5853C55.7997 51.0523 55.9844 50.4458 55.9991 49.8251C56.0137 49.2043 55.8578 48.5906 55.5466 48.044ZM32 47.8388C31.531 47.8388 31.0726 47.7072 30.6826 47.4608C30.2927 47.2144 29.9887 46.8641 29.8092 46.4543C29.6298 46.0445 29.5828 45.5936 29.6743 45.1586C29.7658 44.7235 29.9916 44.3239 30.3233 44.0103C30.6549 43.6967 31.0774 43.4831 31.5374 43.3965C31.9974 43.31 32.4741 43.3544 32.9074 43.5242C33.3407 43.6939 33.7111 43.9813 33.9716 44.3501C34.2322 44.7189 34.3713 45.1525 34.3713 45.5961C34.3713 46.1909 34.1214 46.7613 33.6767 47.1819C33.232 47.6025 32.6289 47.8388 32 47.8388ZM34.5752 25.2832L33.8946 38.9634C33.8946 39.4393 33.6948 39.8956 33.339 40.2321C32.9833 40.5685 32.5007 40.7576 31.9976 40.7576C31.4945 40.7576 31.012 40.5685 30.6562 40.2321C30.3005 39.8956 30.1006 39.4393 30.1006 38.9634L29.4201 25.2888C29.4048 24.962 29.4592 24.6357 29.5801 24.3292C29.701 24.0228 29.8859 23.7425 30.1238 23.5051C30.3617 23.2676 30.6476 23.0779 30.9647 22.9473C31.2817 22.8166 31.6233 22.7476 31.9692 22.7445H31.9941C32.3423 22.7443 32.6869 22.8109 33.0071 22.9401C33.3274 23.0694 33.6166 23.2587 33.8574 23.4965C34.0981 23.7344 34.2854 24.016 34.408 24.3242C34.5306 24.6324 34.5858 24.9609 34.5704 25.2899L34.5752 25.2832Z"
      fill="#FF8A00"
    />
  </svg>
);
export const Rearrange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.63346 21.9172C8.49809 21.8619 8.37496 21.7805 8.27114 21.6775L3.82676 17.2331C3.61754 17.0239 3.5 16.7401 3.5 16.4442C3.5 16.2977 3.52886 16.1526 3.58492 16.0173C3.64099 15.8819 3.72317 15.7589 3.82676 15.6553C3.93036 15.5517 4.05335 15.4696 4.1887 15.4135C4.32406 15.3574 4.46913 15.3286 4.61564 15.3286C4.76215 15.3286 4.90722 15.3574 5.04258 15.4135C5.17793 15.4696 5.30092 15.5517 5.40452 15.6553L7.94892 18.2109L7.94892 3.1111C7.94892 2.81642 8.06598 2.5338 8.27435 2.32543C8.48273 2.11706 8.76534 2 9.06002 2C9.3547 2 9.63731 2.11706 9.84568 2.32543C10.054 2.5338 10.1711 2.81642 10.1711 3.1111L10.1711 20.8886C10.17 21.108 10.104 21.3221 9.98142 21.504C9.85883 21.6859 9.68514 21.8274 9.48223 21.9108C9.35037 21.973 9.20577 22.0034 9.06002 21.9997C8.91379 22.0005 8.76883 21.9725 8.63346 21.9172Z"
      fill="white"
    />
    <path
      d="M16.5123 21.6743C16.3039 21.8826 16.0213 21.9997 15.7266 21.9997C15.4319 21.9997 15.1493 21.8826 14.9409 21.6743C14.7326 21.4659 14.6155 21.1833 14.6155 20.8886L14.6155 3.1111C14.6166 2.89174 14.6826 2.67761 14.8052 2.4957C14.9278 2.31379 15.1015 2.17225 15.3044 2.08889C15.5067 2.0038 15.7297 1.98055 15.9453 2.02208C16.1608 2.06361 16.3592 2.16805 16.5155 2.32222L20.9598 6.76659C21.1691 6.97582 21.2866 7.25959 21.2866 7.55547C21.2866 7.85136 21.1691 8.13513 20.9598 8.34435C20.7506 8.55357 20.4669 8.67111 20.171 8.67111C19.8751 8.67111 19.5913 8.55357 19.3821 8.34435L16.8377 5.78883V20.8886C16.8377 21.1833 16.7206 21.4659 16.5123 21.6743Z"
      fill="white"
    />
  </svg>
);
export const EditProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.20613 8.217C9.92009 8.51689 10.2447 8.69672 10.7372 9.05066C10.9642 9.21376 11.0159 9.52997 10.8528 9.75693C10.6897 9.98385 10.3735 10.0356 10.1466 9.87254C9.233 9.21604 8.40446 8.92877 7.27936 8.90215C7.23452 8.90389 7.18946 8.90478 7.14422 8.90478C7.09928 8.90478 7.05457 8.90392 7.01006 8.9022C4.4406 8.96434 2.21561 10.807 1.69043 13.3296C1.65762 13.4872 1.69668 13.6487 1.79761 13.7729C1.85606 13.8447 1.9864 13.9653 2.20243 13.9653H7.42254C7.70204 13.9653 7.9286 14.1919 7.9286 14.4714C7.9286 14.7509 7.70204 14.9774 7.42254 14.9774H2.20243C1.73868 14.9774 1.30492 14.7711 1.0124 14.4114C0.718432 14.0499 0.604393 13.5804 0.699581 13.1233C1.00329 11.6644 1.80784 10.3368 2.96498 9.38502C3.59603 8.86597 4.31702 8.47233 5.08542 8.21936C4.24676 7.59117 3.70306 6.58969 3.70306 5.46362C3.70306 3.56618 5.24674 2.02246 7.14422 2.02246C9.04169 2.02246 10.5854 3.56618 10.5854 5.46362C10.5854 6.5883 10.043 7.58869 9.20613 8.217ZM7.14422 7.88845C7.18533 7.88845 7.2264 7.88883 7.26741 7.88959C8.54978 7.82522 9.57327 6.7617 9.57327 5.46362C9.57327 4.12423 8.48361 3.03457 7.14422 3.03457C5.80482 3.03457 4.71516 4.12423 4.71516 5.46362C4.71516 6.76195 5.73903 7.82562 7.02175 7.88961C7.06254 7.88885 7.10338 7.88845 7.14422 7.88845Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1574 10.0658C15.3921 10.2973 15.3921 10.676 15.1574 10.9074L11.199 14.8071C11.0884 14.9162 10.9393 14.9774 10.7839 14.9774H9.19224C8.95906 14.9774 8.77003 14.7884 8.77003 14.5552V12.9987C8.77003 12.8405 8.83342 12.689 8.94601 12.5779L12.9125 8.67023C13.1427 8.44317 13.5126 8.44317 13.7428 8.67023L15.1574 10.0658ZM13.4462 9.58287C13.3804 9.51799 13.2747 9.51799 13.209 9.58287L9.75961 12.9803C9.67919 13.0597 9.63392 13.1679 9.63392 13.2809V13.956C9.63392 14.0493 9.70953 14.1249 9.8028 14.1249H10.4952C10.6062 14.1249 10.7127 14.0812 10.7917 14.0032L14.2402 10.6066C14.3072 10.5405 14.3072 10.4323 14.2402 10.3662L13.4462 9.58287Z"
      fill="currentColor"
    />
  </svg>
);
export const Logout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4489 2.93003C10.1017 2.77835 9.69725 2.93685 9.54557 3.28405C9.39389 3.63124 9.55238 4.03565 9.89958 4.18734C11.6321 4.94424 12.7509 6.65267 12.7509 8.54334C12.7509 11.167 10.6236 13.2943 7.99995 13.2943C5.37597 13.2943 3.24897 11.1673 3.24897 8.54334C3.24897 6.65307 4.3674 4.94464 6.09946 4.18724C6.4466 4.03546 6.60496 3.63098 6.45314 3.28384C6.30136 2.9367 5.89688 2.77835 5.54974 2.93016C3.31823 3.90592 1.87695 6.10768 1.87695 8.54331C1.87695 11.925 4.61825 14.6663 7.99998 14.6663C11.3814 14.6663 14.123 11.9248 14.123 8.54331C14.123 6.17479 12.7601 4.02773 10.633 3.01412L10.4489 2.93003ZM8.00002 1.33301C7.64641 1.33301 7.35528 1.60056 7.31804 1.94427L7.31401 2.01902L7.3138 6.90474L7.31683 6.97448L7.32945 7.05427L7.35245 7.1331C7.45074 7.41344 7.70759 7.59835 8.00002 7.59835C8.35364 7.59835 8.64476 7.33081 8.68201 6.98709L8.68603 6.91234L8.68624 2.02662L8.68322 1.95689L8.67061 1.8771L8.64761 1.79827C8.54934 1.51793 8.29247 1.33301 8.00002 1.33301Z"
      fill="currentColor"
    />
  </svg>
);
export const Info = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9996 2C6.47746 2 2 6.47746 2 11.9996C2 17.5217 6.47746 22 11.9996 22C17.5217 22 22 17.5217 22 11.9996C22 6.47746 17.5217 2 11.9996 2ZM14.1926 17.7803C13.6323 18.1309 13.3687 18.2107 13.0354 18.3265C12.703 18.4422 12.3164 18.5 11.8765 18.5C11.2007 18.5 10.6745 18.3347 10.2999 18.0051C9.92524 17.6754 9.73883 17.2576 9.73883 16.7498C9.73883 16.5524 9.75261 16.3504 9.78016 16.1447C9.80862 15.939 9.85362 15.7076 9.91514 15.4477L10.6139 12.9794C10.6755 12.7425 10.7287 12.5175 10.771 12.3082C10.8132 12.097 10.8334 11.9032 10.8334 11.7269C10.8334 11.4129 10.7682 11.1925 10.6387 11.0685C10.5074 10.9446 10.2604 10.884 9.89218 10.884C9.7122 10.884 9.52672 10.9106 9.33664 10.9666C9.14839 11.0245 8.98494 11.0768 8.85088 11.1282C8.71681 11.1796 8.85088 10.7051 9.21553 10.4391C9.58019 10.1731 9.93075 10.0217 10.3486 9.88949C10.7664 9.75542 11.1612 9.68931 11.5331 9.68931C12.2044 9.68931 12.7223 9.85276 13.0868 10.176C13.4495 10.5001 13.6323 10.9216 13.6323 11.4395C13.6323 11.5469 13.6194 11.7361 13.5946 12.0061C13.5698 12.277 13.523 12.524 13.455 12.7508L12.7599 15.2117C12.703 15.4091 12.6525 15.635 12.6066 15.8876C12.5616 16.1401 12.5395 16.3329 12.5395 16.4624C12.5395 16.7893 12.6121 17.0124 12.759 17.1309C12.9041 17.2493 13.1584 17.309 13.5184 17.309C13.6883 17.309 13.8783 17.2787 14.0932 17.2199C14.3063 17.1612 14.4605 17.1088 14.5579 17.0638C14.6552 17.0188 14.753 17.4298 14.1926 17.7803ZM14.0614 8.1501C13.7587 8.45128 13.3942 8.60188 12.968 8.60188C12.5427 8.60188 12.1756 8.45128 11.8703 8.1501C11.5668 7.84891 11.4133 7.48252 11.4133 7.05461C11.4133 6.62762 11.5676 6.26032 11.8703 5.95637C12.1756 5.65151 12.5427 5.5 12.968 5.5C13.3942 5.5 13.7595 5.65151 14.0614 5.95637C14.3641 6.26032 14.5159 6.62762 14.5159 7.05461C14.5159 7.48344 14.3641 7.84891 14.0614 8.1501Z"
      fill="currentColor"
    />
  </svg>
);

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, TextField, Button, Typography, InputAdornment, IconButton } from "@mui/material";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router";

const LoginForm = ({ onSubmit }: { onSubmit: (data: { email: string, password: string }) => void }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    
    const handleSubmit = (event: any) => {
      event.preventDefault();
      onSubmit({ email, password });
    };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="password"
        name="password"
        label="Password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button
        fullWidth
        variant="contained"
        sx={{
          mt: 3,
          mb: 2,
          backgroundColor: "#FF6699",
          color: "white",
          "&:hover": {
            backgroundColor: "#FF6699",
          },
        }}
        type="submit"
      >
        เข้าสู่ระบบ
      </Button>
      <Typography 
        style={{ margin: "5px 0", textAlign: "right" }}
        onClick={() => navigate("/forgotPassword")}
      >
        ลืมรหัสผ่าน ?
      </Typography>
    </Box>
  );
};

export default LoginForm;
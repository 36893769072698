import { Box, Grid, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useState } from "react";
import { useEffectOnce } from "react-use";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { title10Tab } from "../../../style";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { LayoutTab } from "../LayoutTab";
import { StatusBottomBar } from "../StatusBottomBar";
import { PathologyDialog } from "./PathologyDialog";
import { PathologyHistoryDialog } from "./PathologyHistoryDialog";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import { PathologyVisitLogDialog } from "./PathologyVisitLogDialog";

export type Pathology = {
  stomach: string;
  liverPreop: string;
  liverPostop: string;
  gastroscopy: string;
  stomachDetail?: string;
  liverPreopDetail?: string;
  liverPostopDetail?: string;
  gastroscopyDetail?: string;
};

const Row = ({
  title,
  value,
  detail,
}: {
  title: string;
  value?: string;
  detail?: string;
}) => (
  <Grid container direction="row" sx={{ pb: 1 }}>
    <Grid item xs={4}>
      <Typography>{title}</Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography>
        {value === "yes" ? "Yes" : value === "no" ? "No" : "-"}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography>{detail ? detail : "-"}</Typography>
    </Grid>
  </Grid>
);

export type PathologyResponse = {
  entryId: number;
  editorId: number | string;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  stomach: boolean;
  stomachNote: string;
  liverPreOp: boolean;
  liverPreOpNote: string;
  liverPostOp: boolean;
  liverPostOpNote: string;
  gastroscopy: boolean;
  gastroscopyNote: string;
};
type Props = {
  handleCancelClick: () => void;
};

export const Pathology = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(2);
  const [originalStatus, setOriginalStatus] = useState(2);
  const latestEntryId = useAppSelector(
    (state) => state.patientUser.latestEntryIds?.pathologyStatus
  );
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.pathologyStatus
  );
  const [pathologyValues, setPathologyValues] = useState<Pathology>({
    stomach: "",
    liverPreop: "",
    liverPostop: "",
    gastroscopy: "",
    stomachDetail: "",
    liverPreopDetail: "",
    liverPostopDetail: "",
    gastroscopyDetail: "",
  });
  const [openPathologyDialog, setOpenPathologyDialog] = useState(false);
  const [pathologyIsDirty, setPathologyIsDirty] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openVisitLogDialog, setOpenVisitLogDialog] = useState(false);
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);

  const setData = (pathologyData: PathologyResponse) => {
    setPathologyValues({
      stomach: pathologyData.stomach ? "yes" : "no",
      liverPreop: pathologyData.liverPreOp ? "yes" : "no",
      liverPostop: pathologyData.liverPostOp ? "yes" : "no",
      gastroscopy: pathologyData.gastroscopy ? "yes" : "no",
      stomachDetail: pathologyData.stomachNote,
      liverPreopDetail: pathologyData.liverPreOpNote,
      liverPostopDetail: pathologyData.liverPostOpNote,
      gastroscopyDetail: pathologyData.gastroscopyNote,
    });
  };
  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const pathologyData: PathologyResponse =
          await visitDataService.getPathologyFromEntryId(currentEntryId);
        setData(pathologyData);
        setStatus(pathologyData.visitDataStatus);
        setOriginalStatus(pathologyData.visitDataStatus);
      } else if (latestEntryId) {
        const pathologyData: PathologyResponse =
          await visitDataService.getPathologyFromEntryId(latestEntryId);
        setData(pathologyData);
        setStatus(pathologyData.visitDataStatus);
        setOriginalStatus(pathologyData.visitDataStatus);
      }
    };
    fetchData();
  });
  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.savePathologyData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        stomach: pathologyValues.stomach === "yes" ? true : false,
        stomachNote:
          pathologyValues.stomach === "yes"
            ? pathologyValues.stomachDetail
            : "",
        liverPreOp: pathologyValues.liverPreop === "yes" ? true : false,
        liverPreOpNote:
          pathologyValues.liverPreop === "yes"
            ? pathologyValues.liverPreopDetail
            : "",
        liverPostOp: pathologyValues.liverPostop === "yes" ? true : false,
        liverPostOpNote:
          pathologyValues.liverPostop === "yes"
            ? pathologyValues.liverPostopDetail
            : "",
        gastroscopy: pathologyValues.gastroscopy === "yes" ? true : false,
        gastroscopyNote:
          pathologyValues.gastroscopy === "yes"
            ? pathologyValues.gastroscopyDetail
            : "",
      });
      return response as PathologyResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          pathologyStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleClickPathology = () => {
    setOpenPathologyDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenPathologyDialog(false);
    setOpenNoPermissionDialog(false);
    setOpenHistoryDialog(false);
    setOpenVisitLogDialog(false);
  };
  const handleClickPathologySave = (data: Pathology) => {
    setPathologyValues(data);
    setOpenPathologyDialog(false);
    setPathologyIsDirty(true);
    dispatch(updateTabsIsDirty(true));
  };
  const pathologyList = [
    {
      title: "Stomach",
      value: pathologyValues.stomach,
      detail: pathologyValues.stomachDetail,
    },
    {
      title: "Liver Preop",
      value: pathologyValues.liverPreop,
      detail: pathologyValues.liverPreopDetail,
    },
    {
      title: "Liver Postop",
      value: pathologyValues.liverPostop,
      detail: pathologyValues.liverPostopDetail,
    },
    {
      title: "Gastroscopy",
      value: pathologyValues.gastroscopy,
      detail: pathologyValues.gastroscopyDetail,
    },
  ];
  const handleSubmitData = async () => {
    if (patientDataPermissionEditAble === false) {
      setOpenNoPermissionDialog(true);
      setOriginalStatus(status);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    await submitDataAsync();
    dispatch(updateTabsIsDirty(false));
  };
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
    dispatch(updateTabsIsDirty(true));
  };
  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box sx={{ p: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => setOpenVisitLogDialog(true)}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Typography style={title10Tab}>Pathology report</Typography>
          <Box
            width={1}
            my={2}
            alignItems="center"
            gap={4}
            sx={{ border: "2px solid #D8D8D8" }}
          >
            <Box
              sx={{
                px: 2,
                py: 1,
                borderBottom: "2px solid #D8D8D8",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography style={title10Tab}>Pathology report</Typography>
              <Typography
                style={{
                  color: "#646D78",
                  cursor: "pointer",
                }}
                onClick={handleClickPathology}
              >
                แก้ไข
              </Typography>
            </Box>

            <Box sx={{ px: 2, py: 1 }}>
              {pathologyList.map((pathology, index) => (
                <Row
                  key={index}
                  title={pathology.title}
                  value={
                    currentEntryId || pathologyIsDirty
                      ? pathology.value
                      : undefined
                  }
                  detail={
                    currentEntryId || pathologyIsDirty
                      ? pathology.detail
                      : undefined
                  }
                />
              ))}
            </Box>
          </Box>
          <PathologyDialog
            open={openPathologyDialog}
            onClose={handleCloseDialog}
            onSave={handleClickPathologySave}
            {...pathologyValues}
          />
          <StatusBottomBar
            value={status}
            handleStatusChange={handleStatusChange}
          />
        </Box>
      </Box>
      <PathologyHistoryDialog
        open={openHistoryDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <PathologyVisitLogDialog
        open={openVisitLogDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
    </LayoutTab>
  );
};

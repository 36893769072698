import {
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Radio,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import QuantityInput from "../../NumberInput";
import bin from "../../../asset/bin.png";
import { title10Tab } from "../../../style";
import { ContraceptionFemalState, PregnancyHistory } from "./Gynecologic";

const techniqueList = [
  "Normal Vaginal Delivery",
  "Caesarean Section",
  "abortion",
];

const complicationsDuringPregnancy = ["No", "Yes"];

type Props = {
  contraceptionFemaleValues: ContraceptionFemalState;
  handleContraceptionFemaleChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  pregnancyPlan: number;
  pregnancyHistoryList: PregnancyHistory[];
  handlePregnancyHistoryChange: (newList: PregnancyHistory[]) => void;
  handleAdd: () => void;
  handleDelete: (id: number) => void;
  handlePregnancyPlanChange: (
    event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.PointerEvent<Element>
      | React.KeyboardEvent<Element>,
    newValue: number | null
  ) => void;
};

const pregnancyTable = [
  "ครั้งที่",
  "วิธีการคลอด/แท้งบุตร",
  "ปี (พ.ศ.)",
  "ภาวะการแทรกซ้อนระหว่างตั้งครรภ์",
  "ภาวะแทรกซ้อน (ระบุ)",
  "เครื่องมือ",
];
export const GynecologicFemale = ({
  pregnancyPlan,
  pregnancyHistoryList,
  handlePregnancyHistoryChange,
  contraceptionFemaleValues,
  handleContraceptionFemaleChange,
  handleAdd,
  handleDelete,
  handlePregnancyPlanChange,
}: Props) => {
  return (
    <Box>
      <Divider sx={{ width: "100%", my: 3 }} />
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography color={"#646D78"}>
            Pregnancy intention (ความต้องการมีบุตรในระยะยาว){" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="pregnancyIntention"
                  onChange={handleContraceptionFemaleChange}
                  value="yes"
                />
              }
              label="Yes"
              checked={contraceptionFemaleValues.pregnancyIntention === "yes"}
            />
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="pregnancyIntention"
                  onChange={handleContraceptionFemaleChange}
                  value="no"
                />
              }
              label="No"
              checked={contraceptionFemaleValues.pregnancyIntention === "no"}
            />
          </Box>
        </Stack>
        <Stack spacing={1}>
          <Typography color={"#646D78"}>
            วางแผนมีบุตรในระยะกี่ปี <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box>
            <QuantityInput
              handleChange={handlePregnancyPlanChange}
              value={pregnancyPlan}
            />
          </Box>
        </Stack>
        <Stack spacing={1}>
          <Typography color={"#646D78"}>
            Regular menstruation in past 3 months (ประจำเดือนมาห่าง 21-35 วัน){" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="menstruationRegular"
                  onChange={handleContraceptionFemaleChange}
                  value="yes"
                />
              }
              label="Yes"
              checked={contraceptionFemaleValues.menstruationRegular === "yes"}
            />
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="menstruationRegular"
                  onChange={handleContraceptionFemaleChange}
                  value="no"
                />
              }
              label="No"
              checked={contraceptionFemaleValues.menstruationRegular === "no"}
            />
            {/* <FormControlLabel
              value="yes"
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleChangeMenstruationRegular(e)}
                />
              }
              label="Yes"
              checked={menstruationRegular === true}
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleChangeMenstruationRegular(e)}
                />
              }
              label="No"
              checked={menstruationRegular === false}
            /> */}
          </Box>
        </Stack>
      </Stack>

      <Divider sx={{ width: "100%", my: 3 }} />
      <Stack spacing={2}>
        <Typography style={title10Tab}> Menstrual Status</Typography>
        <Stack spacing={1}>
          <Typography color={"#646D78"}>
            Menstrual Status <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="menstrualStatus"
                  onChange={handleContraceptionFemaleChange}
                  value="yes"
                />
              }
              label="มีประจำเดือน"
              checked={contraceptionFemaleValues.menstrualStatus === "yes"}
            />
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="menstrualStatus"
                  onChange={handleContraceptionFemaleChange}
                  value="no"
                />
              }
              label="หมดประจำเดือน"
              checked={contraceptionFemaleValues.menstrualStatus === "no"}
            />
            {/* <FormControlLabel
              value="yes"
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleMenstrualStatus(e)}
                />
              }
              label="มีประจำเดือน"
              checked={menstrualStatus === true}
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleMenstrualStatus(e)}
                />
              }
              label="หมดประจำเดือน"
              checked={menstrualStatus === false}
            /> */}
          </Box>
        </Stack>
        {contraceptionFemaleValues.menstrualStatus === "yes" && (
          <div>
            <Typography color={"#646D78"}>
              ความสม่ำเสมอของการมีประจำเดือน{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="menstrualFrequently"
                  onChange={handleContraceptionFemaleChange}
                  value="yes"
                />
              }
              label="Yes"
              checked={contraceptionFemaleValues.menstrualFrequently === "yes"}
            />
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="menstrualFrequently"
                  onChange={handleContraceptionFemaleChange}
                  value="no"
                />
              }
              label="No"
              checked={contraceptionFemaleValues.menstrualFrequently === "no"}
            />
            {/* <FormControlLabel
              value="yes"
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleMenstrualFrequently(e)}
                />
              }
              label="Yes"
              checked={menstrualFrequently === true}
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleMenstrualFrequently(e)}
                />
              }
              label="No"
              checked={menstrualFrequently === false}
            /> */}
          </div>
        )}
        {contraceptionFemaleValues.menstrualFrequently === "yes" &&
          contraceptionFemaleValues.menstrualStatus !== "no" && (
            <Stack spacing={2}>
              <Stack spacing={1}>
                <Typography color={"#646D78"}>
                  ระยะห่างรอบประจำเดือน (วัน){" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  // label="-- โปรดระบุข้อมูล --"
                  variant="outlined"
                  size="small"
                  style={{ width: "80px" }}
                  name="cycleLength"
                  value={contraceptionFemaleValues.cycleLength}
                  onChange={handleContraceptionFemaleChange}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography color={"#646D78"}>
                  ระยะเวลาการเป็นประจำเดือน (วัน){" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <TextField
                  // label="-- โปรดระบุข้อมูล --"
                  variant="outlined"
                  size="small"
                  style={{ width: "80px" }}
                  name="menstruationLength"
                  value={contraceptionFemaleValues.menstruationLength}
                  onChange={handleContraceptionFemaleChange}
                />
              </Stack>
            </Stack>
          )}
      </Stack>

      <Divider sx={{ width: "100%", my: 3 }} />
      <Stack spacing={2}>
        <Typography style={title10Tab}>กรณีตั้งครรภ์</Typography>
        <Stack spacing={1}>
          <Typography color={"#646D78"}>
            เคยตั้งครรภ์ <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="hasBeenPregnant"
                  onChange={handleContraceptionFemaleChange}
                  value="yes"
                />
              }
              label="Yes"
              checked={contraceptionFemaleValues.hasBeenPregnant === "yes"}
            />
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  name="hasBeenPregnant"
                  onChange={handleContraceptionFemaleChange}
                  value="no"
                />
              }
              label="No"
              checked={contraceptionFemaleValues.hasBeenPregnant === "no"}
            />
            {/* <FormControlLabel
              value="yes"
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleHasBeenPregnant(e)}
                />
              }
              label="Yes"
              checked={hasBeenPregnant === true}
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleHasBeenPregnant(e)}
                />
              }
              label="No"
              checked={hasBeenPregnant === false}
            /> */}
          </Box>
        </Stack>
        {contraceptionFemaleValues.hasBeenPregnant === "yes" && (
          <div>
            <div style={{ paddingBottom: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
                style={{ float: "right" }}
              >
                เพิ่ม
              </Button>
              <Typography color={"#646D78"}>
                ข้อมูลการตั้งครรภ์ <span style={{ color: "red" }}>*</span>
              </Typography>
            </div>
            <TableContainer component={Paper} sx={{ mt: 1 }}>
              <Table style={{ border: "1px solid #E5EBEB" }}>
                <TableHead>
                  <TableRow style={{ borderBottom: "1px solid #E5EBEB" }}>
                    {pregnancyTable.map((value) => (
                      <TableCell style={{ fontSize: "16px" }}>
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pregnancyHistoryList?.map((pregnancyHistory, index) => (
                    <TableRow key={index + 1}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <FormControl variant="outlined" size="small">
                          <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                          <Select
                            label="Delivery Technique Dropdown"
                            value={pregnancyHistory.technique}
                            style={{ width: "296px" }}
                            onChange={(event: SelectChangeEvent) => {
                              const updatedPregnancyHistory = {
                                ...pregnancyHistory,
                                technique: event.target.value,
                              };
                              const updatedList = pregnancyHistoryList.map(
                                (item, i) =>
                                  i === index ? updatedPregnancyHistory : item
                              );
                              handlePregnancyHistoryChange(updatedList);
                            }}
                            MenuProps={{
                              sx: {
                                zoom: 100 * (100 / 85) + "%",
                                top: "0px",
                              },
                              PaperProps: {
                                sx: {
                                  maxHeight: "250px",
                                  scrollbarWidth: "thin",
                                  minWidth: "296px !important",
                                },
                              },
                            }}
                          >
                            {techniqueList.map((technique) => (
                              <MenuItem value={technique} sx={{ fontSize: 14 }}>
                                {technique}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="Year"
                          label="-- โปรดระบุข้อมูล --"
                          variant="outlined"
                          size="small"
                          style={{ width: "180px" }}
                          value={pregnancyHistory.year}
                          onChange={(event) => {
                            const updatedPregnancyHistory = {
                              ...pregnancyHistory,
                              year: event.target.value,
                            };
                            const updatedList = pregnancyHistoryList.map(
                              (item, i) =>
                                i === index ? updatedPregnancyHistory : item
                            );
                            handlePregnancyHistoryChange(updatedList);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl variant="outlined" size="small">
                          <InputLabel>{`-- โปรดระบุข้อมูล --`}</InputLabel>
                          <Select
                            label="Complications During Pregnancy Dropdown"
                            value={
                              pregnancyHistory.complicationsDuringPregnancy
                            }
                            style={{ width: "296px" }}
                            onChange={(event: SelectChangeEvent) => {
                              const updatedPregnancyHistory = {
                                ...pregnancyHistory,
                                complicationsDuringPregnancy:
                                  event.target.value,
                              };
                              const updatedList = pregnancyHistoryList.map(
                                (item, i) =>
                                  i === index ? updatedPregnancyHistory : item
                              );
                              handlePregnancyHistoryChange(updatedList);
                            }}
                            MenuProps={{
                              sx: {
                                zoom: 100 * (100 / 85) + "%",
                                top: "0px",
                              },
                              PaperProps: {
                                sx: {
                                  maxHeight: "250px",
                                  scrollbarWidth: "thin",
                                  minWidth: "296px !important",
                                },
                              },
                            }}
                          >
                            {complicationsDuringPregnancy.map(
                              (complicationsDuringPregnancy) => (
                                <MenuItem
                                  value={complicationsDuringPregnancy}
                                  sx={{ fontSize: 14 }}
                                >
                                  {complicationsDuringPregnancy}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          name="ComplicationDetail"
                          label="-- โปรดระบุข้อมูล --"
                          variant="outlined"
                          size="small"
                          style={{ width: "180px" }}
                          value={pregnancyHistory.complicationDetail}
                          error={
                            pregnancyHistory.complicationsDuringPregnancy ===
                              "Yes" &&
                            pregnancyHistory.complicationDetail === ""
                          }
                          disabled={
                            pregnancyHistory.complicationsDuringPregnancy ===
                            "No"
                          }
                          onChange={(event) => {
                            const updatedPregnancyHistory = {
                              ...pregnancyHistory,
                              complicationDetail: event.target.value,
                            };
                            const updatedList = pregnancyHistoryList.map(
                              (item, i) =>
                                i === index ? updatedPregnancyHistory : item
                            );
                            handlePregnancyHistoryChange(updatedList);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <div>
                          <img
                            src={bin}
                            alt="Delete"
                            onClick={() =>
                              pregnancyHistoryList.length > 1 &&
                              handleDelete(index)
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Stack>
    </Box>
  );
};

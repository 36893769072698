import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { addYears, format, set } from "date-fns";
import { da, th } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { EditBorderIcon, MailIcon, PersonIcon, SearchIcon } from "../../icon";
import { Layout } from "../../Layout";
import { userManagementService } from "../../service/userManagementService";
import DownloadFile from "../../shared/DownloadFile";
import UserInformationDialog from "../../shared/user-profile/dialog/UserInformationDialog";
import UserProfileDialog from "../../shared/user-profile/dialog/UserProfileDialog";
import { body14400, title16500, title18500 } from "../../style";
import {
  generateMaskCitizenId,
  generateMaskSiteRoleId,
} from "../../utils/generateMaskData";
import { formatDateTh } from "../../utils/date-utils";
import EditUserProfileDialog from "./EditUserProfileDialog";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import { UpdateUserDetail, UserPermission } from "../../type";
import { enqueueSnackbar } from "notistack";
import { Update } from "@reduxjs/toolkit";
import { updateUserDetailProfile } from "../../redux/features/loginUserSlice";

export type UserDetail = {
  title: string;
  firstName: string;
  lastName: string;
  nationalId: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  mobilePhoneNumber: string;
  affiliatedHospital: string;
  medicalLicenseNumber: string;
  jobPosition: string;
  siteRoleId: string;
  username: string;
  registrationDate: string;
  approvalDate: string;
  approveByUserId: string;
  email: string;
  permission: string;
};

const UserProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userDetails = useSelector((state: any) => state.loginUser.userDetail);
  const [userDetail, setUserDetail] = useState<UserDetail>({
    title: "",
    firstName: "",
    lastName: "",
    nationalId: "",
    phoneNumber: "",
    phoneNumberExtension: "",
    mobilePhoneNumber: "",
    affiliatedHospital: "",
    medicalLicenseNumber: "",
    jobPosition: "",
    siteRoleId: "",
    username: "",
    registrationDate: "",
    approvalDate: "",
    approveByUserId: "",
    email: "",
    permission: "",
  });
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [openUserInformationDialog, setOpenUserInformationDialog] =
    useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [userPermissions, setUserPermissions] = useState<UserPermission>();
  const userId = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : localStorage.getItem("userId")
    : "";

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const userDetails = store.getState().loginUser.userDetail;
        const userPermission = store.getState().loginUser.userPermission;
        if (userDetails) {
          setUserDetail(userDetails);
        }
        if (userPermission) {
          setUserPermissions(userPermission);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData();
  });

  const handleCloseDialog = () => {
    setOpenUserInformationDialog(false);
    setOpenProfileDialog(false);
  };
  const submitForm = async (data: UpdateUserDetail) => {
    try {
      setUserDetail({
        ...userDetail,
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        nationalId: data.nationalId,
        phoneNumber: data.phoneNumber,
        phoneNumberExtension: data.phoneNumberExtension,
        mobilePhoneNumber: data.mobilePhoneNumber,
        medicalLicenseNumber: data.medicalLicenseNumber,
      });
      const modifiedData = { ...data };
      delete modifiedData.userRestricted;
      delete modifiedData.affiliatedHospitalId;
      delete modifiedData.permissionId;

      userManagementService
        .updateUserDetailProfile(userId || "", modifiedData)
        .then(
          (res) => {
            console.log("res==", res);
            enqueueSnackbar("บันทึกข้อมูลสำเร็จ", {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
            dispatch(updateUserDetailProfile(res));
            localStorage.setItem("userDetail", JSON.stringify(res));
          },
          (error) => {
            console.log("error==", error);
            enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            });
            return;
          }
        );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Layout activeTab="">
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh + 80px)",
        }}
      >
        <Box sx={{ padding: "20px", pb: 2 }}>
          <Typography component={"span"} style={title16500}>
            โปรไฟล์
          </Typography>
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            height: "calc(100% - 80px - 48px)",
            width: "calc(100% - 48px)",
            p: 3,
            overflow: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Avatar sx={{ width: 128, height: 128 }}>
              <PersonIcon sx={{ width: 80, height: 80 }} />
            </Avatar>
            <Stack spacing={1 / 2}>
              <Typography fontSize={24} fontWeight={500} color={"#212121"}>
                {`${userDetail.firstName} ${userDetail.lastName}`}
              </Typography>
              <Stack
                spacing={1}
                direction={"row"}
                fontSize={14}
                fontWeight={500}
                alignItems={"cenetr"}
              >
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <div
                    style={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      backgroundColor: "#26B465",
                    }}
                  />
                  <Typography fontSize={14} fontWeight={500} color={"#26B465"}>
                    Active
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    borderLeft: "1px solid #E3E7F0",
                    pl: 1,
                  }}
                >
                  <MailIcon fontSize="small" sx={{ color: "#646D78" }} />
                  <Typography fontSize={14} fontWeight={500} color={"#646D78"}>
                    {userDetail.email}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>{" "}
          <Paper sx={{ mt: 2, p: 2, borderRadius: 2 }}>
            <Stack spacing={1}>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography component={"span"} style={title18500}>
                  ข้อมูลส่วนตัว
                </Typography>
                <IconButton
                  sx={{
                    "&:hover": {
                      bgcolor: "inherit",
                    },
                  }}
                  onClick={() => setOpenProfileDialog(true)}
                >
                  <EditBorderIcon fontSize="small" />
                </IconButton>
              </Stack>

              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  คำนำหน้า
                </Typography>

                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {userDetail.title}
                </Typography>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  ชื่อ-นามสกุล
                </Typography>

                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {`${userDetail.firstName} ${userDetail.lastName}`}
                </Typography>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr "}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  เลขบัตรประชาชน
                </Typography>
                <Box display={"grid"} gridTemplateColumns={"3fr 3fr 6fr"}>
                  <Typography style={body14400} sx={{ color: "#272E39" }}>
                    {generateMaskCitizenId(userDetail.nationalId)}
                  </Typography>
                  <Typography style={body14400} sx={{ color: "#646D78" }}>
                    เลขที่ใบอนุญาตประกอบวิชาชีพ
                  </Typography>
                  <Typography style={body14400} sx={{ color: "#272E39" }}>
                    {generateMaskSiteRoleId(userDetail.medicalLicenseNumber)}
                  </Typography>
                </Box>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  เบอร์มือถือ
                </Typography>

                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {userDetail.mobilePhoneNumber}
                </Typography>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  เบอร์โทรศัพท์
                </Typography>

                <Box display={"grid"} gridTemplateColumns={"3fr 3fr 6fr"}>
                  <Typography style={body14400} sx={{ color: "#272E39" }}>
                    {userDetail.phoneNumber ? userDetail.phoneNumber : "-"}
                  </Typography>
                  <Typography style={body14400} sx={{ color: "#646D78" }}>
                    Ext
                  </Typography>
                  <Typography style={body14400} sx={{ color: "#272E39" }}>
                    {userDetail.phoneNumberExtension
                      ? userDetail.phoneNumberExtension
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Paper>
          <Paper sx={{ mt: 2, p: 2, borderRadius: 2 }}>
            <Stack spacing={1}>
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography component={"span"} style={title18500}>
                  ข้อมูลการใช้งาน
                </Typography>
                <IconButton
                  sx={{
                    "&:hover": {
                      bgcolor: "inherit",
                    },
                  }}
                  onClick={() => setOpenUserInformationDialog(true)}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>
              </Stack>

              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  สังกัด
                </Typography>

                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {userDetail.affiliatedHospital}
                </Typography>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  ตำแหน่ง
                </Typography>

                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {userDetail.jobPosition ? userDetail.jobPosition : "-"}
                </Typography>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  Email Address
                </Typography>

                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {userDetail.email}
                </Typography>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  วันที่สมัครเข้าใช้งาน
                </Typography>

                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {formatDateTh(userDetail.registrationDate)}
                </Typography>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 6fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  วันและเวลาที่อนุมัติ
                </Typography>

                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {formatDateTh(userDetail.approvalDate)}
                </Typography>
              </Box>
            </Stack>
          </Paper>
          <Box sx={{ mt: 2, p: 2 }}>
            <Stack spacing={1}>
              <Typography component={"span"} style={title18500}>
                เอกสาร
              </Typography>
              <DownloadFile userId={userId || ""} />
              {/* <Stack spacing={2}>
                <Box
                  sx={{ p: 2, border: "1px solid #C2C2C2", borderRadius: 2 }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 2,
                          bgcolor: "#E4F2DF",
                        }}
                      >
                        <DocumentIcon sx={{ color: "#78BC61" }} />
                      </Box>
                      <Stack>
                        <Typography
                          color={"#0A0A0A"}
                          fontSize={14}
                          fontWeight={500}
                        >
                          สำเนาบัตรประชาชน
                        </Typography>
                        <Typography
                          color={"#9E9E9E"}
                          fontSize={12}
                          fontWeight={500}
                        >
                          PDF, 3 MB
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} spacing={2}>
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #64676C",
                          borderRadius: 2,
                        }}
                      >
                        <SearchIcon sx={{ color: "#64676C", fontSize: 15 }} />
                      </Box>
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #78BC61",
                          borderRadius: 2,
                        }}
                      >
                        <Download sx={{ color: "#78BC61", fontSize: 15 }} />
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Stack> */}
            </Stack>
          </Box>
        </Box>
      </Box>
      <EditUserProfileDialog
        userDetail={userDetail}
        openDialog={openProfileDialog}
        handleCloseDialog={handleCloseDialog}
        handleSubmit={submitForm}
      />
      <UserInformationDialog
        userDetail={userDetail}
        openDialog={openUserInformationDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          setIsTokenExpired(false);
          navigate("/");
        }}
      />
    </Layout>
  );
};

export default UserProfile;

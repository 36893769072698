import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import {
  FieldValues,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import {
  AddIcon,
  CancelOutlinedIcon,
  DeleteIcon,
  EditIcon,
} from "../../../icon";
import { Layout } from "../../../Layout";
import { title16500 } from "../../../style";
import { theme2 } from "../../../theme";
import defaultFoodImg from "../../../asset/default_food.jpg";
import { useNavigate } from "react-router";
import { imageToBase64 } from "../../../utils/imageToBase64";

type Nutrition = {
  amount: number;
  protein: number;
  carbohydrate: number;
  sodium: number;
  fat: number;
  kcal: number;
  status: string;
};

type FoodItem = {
  nameTh: string;
  unit: string;
  description: string;
  img: string;
  nutritions: Nutrition[];
};

const tableCellValues = [
  { name: "ลำดับ", width: "10%" },
  { name: "ปริมาณที่บริโภค (หน่วย)", width: "15%" },
  { name: "Protein", width: "10%" },
  { name: "Carbohydrate", width: "10%" },
  { name: "Sodium", width: "10%" },
  { name: "Fat", width: "10%" },
  { name: "จำนวนที่เผาผลาญ (kcal)", width: "15%" },
  { name: "สถานะ", width: "10%" },
  { name: "เครื่องมือ", width: "10%" },
];

export function AddFoodMaster() {
  const theme = useTheme();
  const navigate = useNavigate();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [currentNutrition, setCurrentNutrition] = useState<Nutrition>();
  const [activeStatus, setActiveStatus] = useState(true);
  const [currentNutritionIndex, setCurrentNutritionIndex] = useState(0);
  const [newFood, setNewFood] = useState<FoodItem>();

  const handleCloseDialog = () => {
    setOpenEditDialog(false);
    setOpenAddDialog(false);
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteImage = () => {
    setImagePreview(null);
  };

  const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
    }
  };
  const handleDeleteClick = (index: number) => {
    if (newFood) {
      setNewFood((prevFood) => {
        if (!prevFood) return prevFood;

        const updatedNutritions = prevFood.nutritions.filter(
          (_, i) => i !== index
        );

        return {
          ...prevFood,
          nutritions: updatedNutritions,
        };
      });
    }
  };
  const handleEditClick = (data: Nutrition, index: number) => {
    setCurrentNutrition(data);
    setOpenEditDialog(true);
    setCurrentNutritionIndex(index);
    setActiveStatus(currentNutrition?.status === "ใช้งานได้" ? true : false);
  };
  const handleActiveStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveStatus(event.target.checked);
  };
  const handleAddNutrition = (data: FieldValues) => {
    newFood?.nutritions.push({
      amount: data.amount,
      protein: data.protein,
      carbohydrate: data.carbohydrate,
      sodium: data.sodium,
      fat: data.fat,
      kcal: data.kcal,
      status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน",
    });
    handleCloseDialog();
  };
  const handleEditNutrition = (data: FieldValues, index: number) => {
    if (
      newFood &&
      typeof index === "number" &&
      index >= 0 &&
      index < newFood.nutritions.length
    ) {
      const updatedNutrition: Nutrition = {
        amount: data.amount,
        protein: data.protein,
        carbohydrate: data.carbohydrate,
        sodium: data.sodium,
        fat: data.fat,
        kcal: data.kcal,
        status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน",
      };

      const updatedNutritions = [...newFood.nutritions];
      updatedNutritions[index] = updatedNutrition;

      setNewFood({ ...newFood, nutritions: updatedNutritions });
      handleCloseDialog();
    }
  };
  const handleAddClick = () => {
    setActiveStatus(true);
    setOpenAddDialog(true);
    if (!newFood) {
      setNewFood({
        nameTh: "",
        unit: "",
        description: "",
        img: "",
        nutritions: [],
      });
    }
  };
  const handleSubmitData = async (data: FieldValues) => {
    const base64Image = await imageToBase64(defaultFoodImg);
    if (newFood) {
      setNewFood({
        ...newFood,
        nameTh: data.nameTh,
        unit: data.unit,
        description: data.description,
        img: imagePreview
          ? imagePreview
          : typeof base64Image === "string"
          ? base64Image
          : "",
      });
    }
    console.log({
      ...newFood,
      nameTh: data.nameTh,
      unit: data.unit,
      description: data.description,
      img: imagePreview
        ? imagePreview
        : typeof base64Image === "string"
        ? base64Image
        : "",
    });
  };
  const handleCancelAddNutritionClick = () => {
    if (newFood?.nutritions.length === 0) {
      setNewFood(undefined);
    }
    handleCloseDialog();
  };
  return (
    <Layout activeTab="setting">
      <ThemeProvider theme={theme2}>
        <Box sx={{ p: "20px", width: 1, height: "100%", overflow: "auto" }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              {`ตั้งค่าข้อมูลหลัก / อาหาร`}
            </Typography>
            <Typography component={"span"} color={"#7A8688"} style={title16500}>
              {` / เพิ่ม`}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 3,
              bgcolor: "white",
              height: "calc(100% - 80px - 48px)",
              overflow: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <p
              style={{
                ...title16500,
                borderBottom: "2px solid #D8D8D8",
                marginTop: "0px",
                paddingBottom: "4px",
              }}
            >
              <span
                style={{
                  borderBottom: "3px solid #098484",
                  fontSize: "18px",
                  paddingBottom: "4px",
                }}
              >
                {`เพิ่ม`}
              </span>
            </p>
            <Box>
              <FormContainer onSuccess={handleSubmitData}>
                <Grid container rowGap={2}>
                  <Grid item sm={12} md={9} lg={9} sx={{ pr: 4 }}>
                    <Stack spacing={3}>
                      <Typography gutterBottom sx={{ color: "#646D78", pt: 1 }}>
                        กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>

                      <Stack spacing={5} direction={"row"}>
                        <Grid item lg={8}>
                          <Stack spacing={1}>
                            <Typography color={"#646D78"}>
                              ชื่ออาหาร <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextFieldElement
                              name="nameTh"
                              required
                              variant="outlined"
                              size="small"
                              sx={{ width: 1 }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item lg={4}>
                          <Stack spacing={1}>
                            <Typography color={"#646D78"}>
                              หน่วยบริโภค{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <SelectElement
                              name="unit"
                              // {...methods.register("unit")}
                              required
                              value={""}
                              size="small"
                              options={[
                                {
                                  id: "",
                                  label: "- โปรดระบุข้อมูล --",
                                },
                                {
                                  id: "กรัม",
                                  label: "กรัม",
                                },
                              ]}
                              sx={{
                                minWidth: "250px",
                              }}
                            />
                          </Stack>
                        </Grid>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography color={"#646D78"}>คำอธิบาย</Typography>
                        <TextFieldElement
                          name="description"
                          // {...methods.register("description")}
                          variant="outlined"
                          size="small"
                          multiline
                          rows={3}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={3}
                    lg={3}
                    display={"flex"}
                    justifyContent={onlySmallScreen ? "flex-start" : "flex-end"}
                  >
                    <Box
                      sx={{
                        border: "2px dashed #CBD0DC",
                        width: "250px",
                        minHeight: "200px",
                        borderRadius: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {imagePreview ? (
                        <Box
                          sx={{
                            position: "relative",
                            height: 1,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              cursor: "pointer",
                            }}
                            onClick={handleDeleteImage}
                          >
                            <CancelOutlinedIcon sx={{ color: "#979797" }} />
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            border: "2px dashed #CBD0DC",
                            width: 250,
                            height: 1,
                            borderRadius: 5,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundImage: `url(${defaultFoodImg})`,
                            backgroundSize: "cover",
                          }}
                        >
                          <Typography>เลือกไฟล์รูป</Typography>
                          <Typography color={"#54575C"}>
                            JPEG, PNG, up to 50MB{" "}
                          </Typography>
                          <Typography color={"#54575C"}>
                            (ขนาดที่แนะนำ 180*180){" "}
                          </Typography>
                          {!imagePreview && (
                            <div style={{ marginTop: "8px" }}>
                              <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="contained-button-file"
                                type="file"
                                onChange={handleUploadClick}
                              />
                              <label htmlFor="contained-button-file">
                                <Stack direction={"row"} spacing={2}>
                                  <Button
                                    variant="contained"
                                    component="span"
                                    sx={{
                                      bgcolor: "white",
                                      color: "#54575C",
                                      "&:hover": {
                                        bgcolor: "white",
                                      },
                                    }}
                                  >
                                    เลือกไฟล์
                                  </Button>
                                </Stack>
                              </label>
                            </div>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ mt: 4 }}
                >
                  <Stack>
                    <Typography fontSize={18} fontWeight={600}>
                      ข้อมูลอาหาร
                    </Typography>
                    <Typography fontSize={14}>
                      สามารถเพิ่มได้มากที่สุด 5 รายการ
                    </Typography>
                  </Stack>

                  {(!newFood || (newFood && newFood.nutritions.length < 5)) && (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#FF366B",
                        fontSize: "14px",
                        height: "40px",
                      }}
                      startIcon={<AddIcon />}
                      onClick={handleAddClick}
                    >
                      เพิ่ม
                    </Button>
                  )}
                </Stack>
                <Box id="table-nutrtion" sx={{ mt: 2 }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableCellValues.map((item, index) => (
                            <TableCell
                              key={index}
                              sx={{
                                fontSize: 16,
                                fontWeight: 700,
                                width: item.width,
                              }}
                            >
                              {item.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {newFood && (
                        <TableBody>
                          {newFood.nutritions?.map((nutr, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{nutr.amount}</TableCell>
                              <TableCell>{nutr.protein}</TableCell>
                              <TableCell>{nutr.carbohydrate}</TableCell>
                              <TableCell>{nutr.sodium}</TableCell>
                              <TableCell>{nutr.fat}</TableCell>
                              <TableCell>{nutr.kcal}</TableCell>

                              <TableCell>
                                {nutr.status === "ใช้งานได้" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: "5px",
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: "green",
                                      }}
                                    />
                                    ใช้งานได้
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: "5px",
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: "red",
                                      }}
                                    />
                                    ปิดการใช้งาน
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>
                                <Stack spacing={1} direction={"row"}>
                                  <Box
                                    sx={{
                                      border: 1,
                                      borderRadius: 2,
                                      borderColor: "secondary.main",
                                      width: "30px",
                                      height: "30px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      fontSize: 14,
                                    }}
                                    onClick={() => handleEditClick(nutr, index)}
                                  >
                                    <EditIcon
                                      fontSize="inherit"
                                      color="secondary"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      border: "1px solid #979797",
                                      borderRadius: 2,
                                      width: "30px",
                                      height: "30px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      color: "#7A8688",
                                      fontSize: 14,
                                    }}
                                    onClick={() => handleDeleteClick(index)}
                                  >
                                    <DeleteIcon
                                      fontSize="inherit"
                                      color="inherit"
                                    />
                                  </Box>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {(!newFood || newFood?.nutritions.length === 0) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 20,
                        color: "#9AA1AE",
                        py: 2,
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        height: "100px",
                      }}
                    >
                      --ไม่พบข้อมูล--
                    </Box>
                  )}
                </Box>
                <Box sx={{ mt: 5 }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    spacing={2}
                  >
                    <Button
                      onClick={() => navigate("/masterData/food")}
                      style={{ backgroundColor: "#7A8688", color: "white" }}
                    >
                      ยกเลิก
                    </Button>
                    <Button type="submit" variant="contained">
                      บันทึก
                    </Button>
                  </Stack>
                </Box>
              </FormContainer>
            </Box>
          </Box>
        </Box>
        {/* Add Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>เพิ่ม</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer onSuccess={(data) => handleAddNutrition(data)}>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>หน่วยบริโภค</Typography>
                  <Typography>{newFood?.unit}</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    ปริมาณที่บริโภค (หน่วย){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="amount"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>ข้อมูลอาหาร</Typography>
                  <Box sx={{ border: "1px solid #E5EBEB", borderRadius: 2 }}>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr 1fr"}
                      gap={3}
                      sx={{ p: 2 }}
                    >
                      <Typography>Protein</Typography>
                      <Typography>Carbohydrate</Typography>
                      <Typography>Sodium</Typography>
                      <Typography>Fat</Typography>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr 1fr"}
                      gap={3}
                      sx={{ p: 2, borderTop: "1px solid #E5EBEB" }}
                    >
                      <TextFieldElement
                        name="protein"
                        variant="outlined"
                        size="small"
                      />
                      <TextFieldElement
                        name="carbohydrate"
                        variant="outlined"
                        size="small"
                      />
                      <TextFieldElement
                        name="sodium"
                        variant="outlined"
                        size="small"
                      />
                      <TextFieldElement
                        name="fat"
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    ปริมาณที่เผาผลาญ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="kcal"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={activeStatus ? true : false}
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCancelAddNutritionClick}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#098484", color: "white" }}
                  >
                    บันทึก
                  </Button>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>แก้ไข</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer
              defaultValues={{
                amount: currentNutrition?.amount,
                protein: currentNutrition?.protein,
                carbohydrate: currentNutrition?.carbohydrate,
                sodium: currentNutrition?.sodium,
                fat: currentNutrition?.fat,
                kcal: currentNutrition?.kcal,
              }}
              onSuccess={(data) =>
                handleEditNutrition(data, currentNutritionIndex)
              }
            >
              <Stack spacing={2} sx={{ mt: 2 }}>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>หน่วยบริโภค</Typography>
                  <Typography>{newFood?.unit}</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    ปริมาณที่บริโภค (หน่วย){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="amount"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>ข้อมูลอาหาร</Typography>
                  <Box sx={{ border: "1px solid #E5EBEB", borderRadius: 2 }}>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr 1fr"}
                      gap={3}
                      sx={{ p: 2 }}
                    >
                      <Typography>Protein</Typography>
                      <Typography>Carbohydrate</Typography>
                      <Typography>Sodium</Typography>
                      <Typography>Fat</Typography>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr 1fr"}
                      gap={3}
                      sx={{ p: 2, borderTop: "1px solid #E5EBEB" }}
                    >
                      <TextFieldElement
                        name="protein"
                        variant="outlined"
                        size="small"
                      />
                      <TextFieldElement
                        name="carbohydrate"
                        variant="outlined"
                        size="small"
                      />
                      <TextFieldElement
                        name="sodium"
                        variant="outlined"
                        size="small"
                      />
                      <TextFieldElement
                        name="fat"
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    ปริมาณที่เผาผลาญ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="kcal"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={
                      currentNutrition?.status === "ใช้งานได้" ? true : false
                    }
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#098484", color: "white" }}
                  >
                    บันทึก
                  </Button>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import MenuAppBar from "../shared/MenuAppBar";
import VerticalTab from "../shared/VerticalTab";
import "./UserTab/UserTab.css"; // Import a CSS file for styling
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Modal,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  DialogActions,
  styled,
  Dialog,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router";
import HistoryFiltering from "../shared/HistoryFiltering";
import CloseIcon from "@mui/icons-material/Close";
import { Layout } from "../Layout";
import { ErrorDialog } from "../components/dialog/ErrorDialog";
import { userManagementService } from "../service/userManagementService";
import { useEffectOnce } from "react-use";
import { UserPermission } from "../type";

const History = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [userPermissionList, setUserPermissionList] = useState<UserPermission[]>(
    []
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const tableCellValues = [
    "วันที่และเวลา",
    "ชื่อผู้ใช้งาน",
    "ชื่อ-สกุล",
    "หน่วยงาน",
    "สิทธิ์การใช้งาน",
    "เมนู",
    "รายละเอียด",
  ];

  const userList = [
    {
      dateTime: "07/12/2566 11:59 น.",
      userName: "Admin01",
      firstName: "ธนัท",
      lastName: "นิกรนนท์",
      affiliatedHospital: "โรงพยาบาลจุฬาลงกรณ์",
      userType: "ผู้ดูแลระบบ",
      userPermission: "ผู้ดูแลระบบ",
      menu: "ตั้งค่าการเปลี่ยนรหัสผ่าน",
      detail: "บังคับการเปลี่ยนรหัสผ่าน",
    },
    {
      dateTime: "07/12/2566 10:53 น.",
      userName: "Admin02",
      firstName: "พชรี",
      lastName: "พงศ์พาณิชย์",
      affiliatedHospital: "โรงพยาบาลจุฬาลงกรณ์",
      userType: "ผู้ดูแลระบบ",
      userPermission: "ผู้ดูแลระบบ",
      menu: "จัดการผู้ใช้งาน",
      detail: "ดูรายการ",
    },
    {
      dateTime: "07/12/2566 10:52 น.",
      userName: "Admin03",
      firstName: "พิชญาภรณ์",
      lastName: "สิงห์ขาว",
      affiliatedHospital: "โรงพยาบาลจุฬาลงกรณ์",
      userType: "ผู้ดูแลระบบ",
      userPermission: "ผู้ดูแลระบบ",
      menu: "หน้าหลัก",
      detail: "เข้าสู่ระบบ",
    },
    {
      dateTime: "07/12/2566 10:50 น.",
      userName: "Admin04",
      firstName: "จิรณัฐ",
      lastName: "อินทรประสาท",
      affiliatedHospital: "โรงพยาบาลจุฬาลงกรณ์",
      userType: "ผู้ดูแลระบบ",
      userPermission: "ผู้ดูแลระบบ",
      menu: "คำถามที่พบบ่อย",
      detail: "ดูรายการ",
    },
  ];

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const userPermissionList =
          await userManagementService.getUserPermission();
        setUserPermissionList(userPermissionList);
      } catch (error:any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData();
  });
  return (
    <Layout activeTab="userPermission">
      <div className="user-filtering-container">
        <HistoryFiltering />
        <Box p={3} bgcolor={"white"} marginTop={2}>
          <div>
            <Button
              variant="contained"
              className="grant-permission-button"
              style={{
                backgroundColor: "#0095FF",
                float: "right",
                width: "140px",
                height: "40px",
                paddingLeft: "15px",
                fontSize: "14px",
                marginBottom: "20px",
              }}
              onClick={handleOpen}
            >
              Export
            </Button>

            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
              maxWidth="md"
              fullWidth
              PaperProps={{
                style: { width: "640px", height: "622px", margin: "auto" },
              }}
            >
              <DialogTitle
                sx={{ m: 0, p: 2 }}
                id="customized-dialog-title"
                style={{ backgroundColor: "#F7F8FC" }}
              >
                รายละเอียด
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography gutterBottom style={{ color: "#646D78" }}>
                      วันที่และเวลา
                    </Typography>
                    <Typography gutterBottom style={{ color: "#646D78" }}>
                      Username
                    </Typography>
                    <Typography gutterBottom style={{ color: "#646D78" }}>
                      ชื่อ-นามสกุล
                    </Typography>
                    <Typography gutterBottom style={{ color: "#646D78" }}>
                      หน่วยงาน
                    </Typography>
                    <Typography gutterBottom style={{ color: "#646D78" }}>
                      Menu
                    </Typography>
                    <Typography gutterBottom style={{ color: "#646D78" }}>
                      Sub Menu
                    </Typography>
                    <Typography gutterBottom style={{ color: "#646D78" }}>
                      Activity
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography gutterBottom>24/6/2022 10:08 น.</Typography>
                    <Typography gutterBottom>tanat.n@gmail.com</Typography>
                    <Typography gutterBottom>ธนัท นิกรนนท์</Typography>
                    <Typography gutterBottom>-</Typography>
                    <Typography gutterBottom>-</Typography>
                    <Typography gutterBottom>Edit</Typography>
                    <Typography gutterBottom>-</Typography>
                  </Grid>
                </Grid>
                <p style={{ color: "#646D78" }}>Detail</p>
                <div style={{ backgroundColor: "#F7F8FC", padding: "20px" }}>
                  <Typography>สิทธิ์การใช้งาน : แพทย์</Typography>
                  <br />
                  <Typography>
                    การเข้าถึง : มองเห็นเฉพาะข้อมูลภายใต้สังกัดตนเอง
                  </Typography>
                  <br />
                  <Typography>
                    ผู้อนุมัติแอคเคาท์ : นายธนเนษฐ ศิริภาพันธ์
                  </Typography>
                  <br />
                  <Typography>
                    วันและเวลาที่อนุมัติ : 07/12/2566 11:59 น.
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#7A8688",
                    float: "right",
                    width: "140px",
                    height: "40px",
                    fontSize: "14px",
                    marginBottom: "20px",
                  }}
                  onClick={handleClose}
                >
                  ปิด
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </div>
          <TableContainer component={Paper}>
            <Table className="pink-table-line">
              <TableHead>
                <TableRow className="pink-bottom-line">
                  {tableCellValues.map((value) => (
                    <TableCell style={{ fontSize: "16px" }}>{value}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{user.dateTime}</TableCell>
                    <TableCell>{user.userName}</TableCell>
                    <TableCell>
                      {user.firstName} {user.lastName}
                    </TableCell>
                    <TableCell>
                      {user.affiliatedHospital ? user.affiliatedHospital : "-"}
                    </TableCell>{" "}
                    <TableCell>{user.userPermission}</TableCell>
                    <TableCell>{user.menu}</TableCell>
                    <TableCell>{user.detail}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
    </Layout>
  );
};

export default History;

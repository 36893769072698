import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { DemographicDataResponse } from "./Demographic";
import { Day } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { EventRepeat } from "@mui/icons-material";

type Props = {
  open: boolean;
  handleCloseDialog: () => void;
};
interface Demographic {
  visitDate? : String;
  hn: string;
  previousBariatricSurgery: string;
  bariatricSurgeryHistoryHnRef: string ;
  sex: string;
  dateOfBirth: string;
  age: number;
  height: number;
  weightPreOperation: number;
  bmiPreOperation: number;
  cervicalCancerScreeningHistory: string;
}

const demographicDataLabel: { [key in keyof Demographic]: string } = {
  hn: "HN",
  previousBariatricSurgery: "Previous Bariatric Surgery",
  bariatricSurgeryHistoryHnRef: "Refer to HN",
  sex:  "Sex",
  dateOfBirth: "วันเกิด",
  age: "อายุ",
  height: "ส่วนสูง (Height)",
  weightPreOperation: "น้ำหนัก (Weight pre-opt)",
  bmiPreOperation: "BMI",
  cervicalCancerScreeningHistory: "Cervical cancer screening",
};
export const DemographicHistoryDialog = ({
  open,
  handleCloseDialog
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [demographicDataHistory, setDemographicDataHistory] = useState<Demographic[]>([]);
  const demographicDataKeys = Object.keys(demographicDataLabel) as (keyof Demographic)[];
  const patientUser = useAppSelector((state) => state.patientUser.patientUser);
  const selectedPatientUserProfile = useAppSelector(
    (state) => state.patientUser.selectedPatientUserProfile
  );

  useEffect(() => {
    const fetchData = async () => {
      const patientId = store.getState().patientUser.patientUser?.id;
      if (patientId) {
        try {
          const demographicDataHistory: Demographic[] =
            await visitDataService.getDemoGraphicListHistory(patientId);
            if (demographicDataHistory) {
              demographicDataHistory.forEach((entry) => {
                entry.hn = patientUser?.hn || "-";
                entry.previousBariatricSurgery = selectedPatientUserProfile?.bariatricSurgeryHistory  ? "Yes" : "No";
                entry.sex = patientUser?.sex || "-";
                entry.dateOfBirth = dayjs(patientUser?.birthDate).format("DD/MM/BBBB");
                entry.age = dayjs().diff(patientUser?.birthDate, "year");
                entry.cervicalCancerScreeningHistory = entry.cervicalCancerScreeningHistory ? "Yes" : "No";
                entry.bariatricSurgeryHistoryHnRef = entry.bariatricSurgeryHistoryHnRef || "-";
              });
              setDemographicDataHistory(demographicDataHistory);
            }
          
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [open]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={"lg"}>
      <DialogTitle>Visit History</DialogTitle>
      <DialogContent dividers sx={{ overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            maxHeight: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
          }}
        >
          <Table stickyHeader sx={{ maxHeight: 500, overflowY: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    width: 250,
                    bgcolor: "white",
                    color: "secondary.main",
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "1px solid #E5EBEB",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  Visit Date
                </TableCell>

                {demographicDataHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableCell
                      key={index}
                      variant="head"
                      sx={{
                        minWidth: 100,
                        bgcolor: "white",
                        fontWeight: 700,
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      {dayjs(entry.visitDate?.toString()).format("DD/MM/BBBB")}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {demographicDataKeys.map((key) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      textAlign: "center",
                      borderRight: "1px solid #E5EBEB",
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      bgcolor: "white",
                    }}
                  >
                    {demographicDataLabel[key]}
                  </TableCell>

                  {demographicDataHistory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((entry, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          alignContent: "baseline",
                        }}
                      >
                        {entry[key as keyof Demographic]}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={demographicDataHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};

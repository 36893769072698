import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Info } from "../asset/iconSvg";
import { title16500, tootlipPlacement } from "../style";

type Props = {
  isType?: boolean;
  isGender?: boolean;
  title: string;
  searchText: string;
  searchType?: string;
  searchGender?: string;
  searchStatus?: string;
  searchTextLabel?: string;
  handleSearchTextChange: (newValue: string) => void;
  handleSearchTypeChange?: (newValue: string) => void;
  handleSearchGenderChange?: (newValue: string) => void;
  handleSearchStatusChange?: (newValue: string) => void;
  handleSearchClick: () => void;
  handleClearSearchClick: () => void;
};

export const MasterDataFiltering = ({
  isType,
  isGender,
  title,
  searchText,
  searchType,
  searchGender,
  searchStatus,
  searchTextLabel,
  handleSearchTextChange,
  handleSearchTypeChange,
  handleSearchGenderChange,
  handleSearchStatusChange,
  handleSearchClick,
  handleClearSearchClick,
}: Props) => {
  return (
    <>
      <Box sx={{ bgcolor: "white" }}>
        <Box sx={{ borderBottom: "2px solid #D8D8D8" }}>
          <Box
            component={"span"}
            sx={{
              display: "inline-block",
              minWidth: "185px",
              borderBottom: "4px solid #098484",
              p: 1.5,
              fontSize: 18,
              fontWeight: 600,
              ml: 1.5,
              boxSizing: "border-box",
            }}
          >
            {title}
          </Box>
        </Box>
        <Box sx={{ px: "20px", pb: 1 }}>
          <Grid
            container
            columnGap={2}
            rowGap={2}
            sx={{ my: 2 }}
            alignItems="stretch"
          >
            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography
                style={{
                  ...title16500,
                  padding: "5px",
                  alignItems: "center",
                  display: "flex",
                  gap: 2,
                }}
              >
                {`${searchTextLabel ?? "ค้นหา"} `}
                <Tooltip
                  title="ใส่คำค้นหา"
                  placement={tootlipPlacement}
                  PopperProps={{
                    sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                  }}
                >
                  <span>
                    <Info width={15} height={15} color="#212121" />
                  </span>
                </Tooltip>
              </Typography>
              <TextField
                label="-- ระบุคำที่ต้องการค้นหา --"
                variant="outlined"
                value={searchText}
                onChange={(e) => handleSearchTextChange(e.target.value)}
                size="small"
                fullWidth
              />
            </Grid>
            {isType && handleSearchTypeChange && (
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Typography style={{ ...title16500, padding: "5px" }}>
                  Type
                </Typography>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <InputLabel>-- เลือกType --</InputLabel>
                  <Select
                    value={searchType}
                    onChange={(e) => handleSearchTypeChange(e.target.value)}
                    label="-- เลือกType --"
                    MenuProps={{
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                        },
                      },
                    }}
                  >
                    <MenuItem value={""} disabled>
                      โปรดระบุข้อมูล
                    </MenuItem>
                    <MenuItem value={"TEXT"}>TEXT</MenuItem>
                    <MenuItem value={"NUMBER"}>NUMBER</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {isGender && handleSearchGenderChange && (
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Typography style={{ ...title16500, padding: "5px" }}>
                  เพศ
                </Typography>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <InputLabel>-- เลือกเพศ --</InputLabel>
                  <Select
                    value={searchGender}
                    onChange={(e) => handleSearchGenderChange(e.target.value)}
                    label="-- เลือกเพศ --"
                    MenuProps={{
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                        },
                      },
                    }}
                  >
                    <MenuItem value={""} disabled>
                      โปรดระบุข้อมูล
                    </MenuItem>
                    <MenuItem value={"all"}>ทั้งหมด</MenuItem>
                    <MenuItem value={"male"}>ชาย</MenuItem>
                    <MenuItem value={"female"}>หญิง</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {searchStatus !== undefined && handleSearchStatusChange && (
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Typography style={{ ...title16500, padding: "5px" }}>
                  สถานะ
                </Typography>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <InputLabel>-- เลือกสถานะ --</InputLabel>
                  <Select
                    value={searchStatus}
                    onChange={(e) => handleSearchStatusChange(e.target.value)}
                    label="-- เลือกสถานะ --"
                    MenuProps={{
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                        },
                      },
                    }}
                  >
                    <MenuItem value={""} disabled>
                      โปรดระบุข้อมูล
                    </MenuItem>
                    <MenuItem value={"ใช้งานได้"}>ใช้งานได้</MenuItem>
                    <MenuItem value={"ปิดการใช้งาน"}>ปิดการใช้งาน</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={1}
              height={"inherit"}
              alignContent={"end"}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#098484",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={handleSearchClick}
              >
                ค้นหา
              </Button>
            </Grid>

            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={1}
              height={"inherit"}
              alignContent={"end"}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#7A8688",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={handleClearSearchClick}
              >
                ล้างการค้นหา
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

import withStyles from "@material-ui/core/styles/withStyles";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { Info } from "../../asset/iconSvg";
import { Layout } from "../../Layout";
import { userManagementService } from "../../service/userManagementService";
import DownloadFile from "../../shared/DownloadFile";
import { title16500, tootlipPlacement } from "../../style";
import "../UserTab/AddUser.css";

export type UserDetail = {
  title: string;
  firstName: string;
  lastName: string;
  nationalId: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  mobilePhoneNumber: string;
  affiliatedHospital: string;
  medicalLicenseNumber: string;
  jobPosition: string;
  siteRoleId: string;
  username: string;
  registrationDate: string;
  approvalDate: string;
  approveByUserId: string;
  email: string;
  userRestricted: boolean;
};

const ApproveUserDetail = () => {
  const navigate = useNavigate();
  const [userPermission, setUserPermission] = useState("");
  const [userPermissionList, setUserPermissionList] = useState<any[]>([]);
  const [affiliatedHospitalList, setAffiliateHospitalHospitalList] = useState<
    any[]
  >([]);
  const [userDetail, setUserDetail] = useState<UserDetail>({
    title: "",
    firstName: "",
    lastName: "",
    nationalId: "",
    phoneNumber: "",
    phoneNumberExtension: "",
    mobilePhoneNumber: "",
    affiliatedHospital: "",
    medicalLicenseNumber: "",
    jobPosition: "",
    siteRoleId: "",
    username: "",
    registrationDate: "",
    approvalDate: "",
    approveByUserId: "",
    email: "",
    userRestricted: false,
  });
  const [affiliatedHospital, setAffiliatedHospital] = useState("");
  const [muiDropdownValue, setMUIDropdownValue] = useState("");
  const [value, setValue] = useState(
    format(new Date(), "yyyy-MM-dd").toString()
  );

  let userId: string = "";

  const location = useLocation();
  if (location?.state?.user) {
    userId = location.state.user;
  }

  // Handle regular dropdown change
  const handleUserPermissionDropdownChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setUserPermission(event.target.value);
  };

  const handleAffiliatedHospitalDropdownChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAffiliatedHospital(event.target.value);
  };

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop: (acceptedFiles) => {
  //     console.log(acceptedFiles);
  //     // Handle the uploaded files here.
  //   },
  // });

  const GreenSwitch = withStyles({
    switchBase: {
      color: "#26B465",
      "&$checked": {
        color: "#26B465",
      },
      "&$checked + $track": {
        backgroundColor: "#26B465",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const affiliatedHospitalListResponse =
          await userManagementService.getAffiliatedHospital();
        setAffiliateHospitalHospitalList(affiliatedHospitalListResponse);

        const userPermissionList =
          await userManagementService.getUserPermission();
        setUserPermissionList(userPermissionList);

        const userDetails = await userManagementService.getUserDetail(userId);
        setUserDetail(userDetails);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
  });

  return (
    <Layout activeTab="userPermission">
      <div className="add-user-tab-content">
        <div className="add-user-filtering-container">
          <div>
            <Box sx={{ py: 2 }}>
              <Typography component={"span"} style={title16500}>
                ผู้ใช้งานและสิทธิ์
              </Typography>
              <Typography
                component={"span"}
                color={"#7A8688"}
                style={title16500}
              >
                {` / อนุมัติผู้ใช้งาน`}
              </Typography>
              <Typography
                component={"span"}
                color={"#7A8688"}
                style={title16500}
              >
                {` / รายละเอียดอนุมัติผู้ใช้งาน`}
              </Typography>
            </Box>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                p={2}
                bgcolor={"white"}
                style={{
                  width: "100%",
                  paddingTop: "0px",
                  height: "calc(100vh - 100px)",
                  overflow: "auto",
                  scrollbarWidth: "thin",
                }}
                sx={{ height: "inherit" }}
              >
                <p
                  style={{ borderBottom: "2px solid #D8D8D8", padding: "5px" }}
                >
                  <span
                    style={{
                      borderBottom: "3px solid #DF5C8E",
                      fontWeight: "bold",
                      padding: "5px",
                    }}
                  >
                    รายละเอียดอนุมัติผู้ใช้งาน
                  </span>
                </p>
                <p style={{ color: "#646D78" }}>
                  กรุณาตรวจสอบข้อมูลเพื่ออนุมัติผู้ใช้งาน{" "}
                </p>

                {!!userId && (
                  <div>
                    <p style={{ color: "#646D78" }}>
                      Username{" "}
                      <Tooltip
                        title="Username ซึ่งคืออีเมล (Email) ที่ใช้เข้าสู่ระบบ"
                        placement={tootlipPlacement}
                        PopperProps={{
                          sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                        }}
                      >
                        <span>
                          <Info width={15} height={15} color="#A0ABBA" />
                        </span>
                      </Tooltip>
                    </p>
                    <TextField
                      label={userDetail.email ? "" : "-- โปรดระบุข้อมูล --"}
                      variant="outlined"
                      value={userDetail.username}
                      onChange={(event) =>
                        setUserDetail({
                          ...userDetail,
                          username: event.target.value,
                        })
                      }
                      size="small"
                      style={{ width: "296px" }}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={{ paddingRight: "50px" }}>
                    <p style={{ color: "#646D78" }}>
                      คำนำหน้า <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      label={userDetail.title ? "" : "-- โปรดระบุข้อมูล --"}
                      variant="outlined"
                      value={userDetail.title}
                      onChange={(event) =>
                        setUserDetail({
                          ...userDetail,
                          title: event.target.value,
                        })
                      }
                      size="small"
                      style={{ width: "296px" }}
                    />
                  </div>

                  <div style={{ paddingRight: "50px" }}>
                    <p style={{ color: "#646D78" }}>
                      ชื่อ <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      label={userDetail.firstName ? "" : "-- โปรดระบุข้อมูล --"}
                      variant="outlined"
                      value={userDetail.firstName}
                      onChange={(event) =>
                        setUserDetail({
                          ...userDetail,
                          firstName: event.target.value,
                        })
                      }
                      size="small"
                      style={{ width: "296px" }}
                    />
                  </div>

                  <div style={{ paddingRight: "50px" }}>
                    <p style={{ color: "#646D78" }}>
                      นามสกุล <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      label={userDetail.lastName ? "" : "-- โปรดระบุข้อมูล --"}
                      variant="outlined"
                      value={userDetail.lastName}
                      onChange={(event) =>
                        setUserDetail({
                          ...userDetail,
                          lastName: event.target.value,
                        })
                      }
                      size="small"
                      style={{ width: "296px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={{ paddingRight: "10px" }}>
                    <p style={{ color: "#646D78" }}>เบอร์โทรศัพท์</p>
                    <TextField
                      variant="outlined"
                      value={userDetail.phoneNumber}
                      onChange={(event) =>
                        setUserDetail({
                          ...userDetail,
                          phoneNumber: event.target.value,
                        })
                      }
                      size="small"
                      style={{ width: "168px" }}
                    />
                  </div>
                  <div style={{ paddingRight: "50px" }}>
                    <p style={{ color: "#646D78" }}>Ext</p>
                    <TextField
                      variant="outlined"
                      value={userDetail.phoneNumberExtension}
                      onChange={(event) =>
                        setUserDetail({
                          ...userDetail,
                          phoneNumberExtension: event.target.value,
                        })
                      }
                      size="small"
                      style={{ width: "114px" }}
                    />
                  </div>
                  <div>
                    <p style={{ color: "#646D78" }}>
                      เบอร์มือถือ <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      variant="outlined"
                      label={
                        userDetail.mobilePhoneNumber
                          ? ""
                          : "-- โปรดระบุข้อมูล --"
                      }
                      value={userDetail.mobilePhoneNumber}
                      onChange={(event) =>
                        setUserDetail({
                          ...userDetail,
                          mobilePhoneNumber: event.target.value,
                        })
                      }
                      size="small"
                      style={{ width: "296px" }}
                    />
                  </div>
                </div>
                <br />
                <p style={{ borderBottom: "1px solid #D8D8D8" }}></p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "50px",
                  }}
                >
                  <div>
                    <p style={{ color: "#646D78" }}>ชื่อโรงพยาบาล (สังกัด)</p>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                      <Select
                        label="Regular Dropdown"
                        value={userDetail.affiliatedHospital}
                        onChange={(event) =>
                          setUserDetail({
                            ...userDetail,
                            affiliatedHospital: event.target.value,
                          })
                        }
                        size="small"
                        style={{ width: "296px" }}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "250px",
                              scrollbarWidth: "thin",
                            },
                          },
                        }}
                      >
                        {affiliatedHospitalList.map((hospital) => (
                          <MenuItem value={hospital.id} sx={{ fontSize: 14 }}>
                            {hospital.nameThai}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <p style={{ color: "#646D78" }}>
                      เลขที่ใบอนุญาตใบประกอบวิชาชีพ
                    </p>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <TextField
                        value={userDetail.siteRoleId}
                        onChange={(event) =>
                          setUserDetail({
                            ...userDetail,
                            siteRoleId: event.target.value,
                          })
                        }
                        size="small"
                        style={{ width: "296px" }}
                      >
                        {userPermissionList.map((userPermission) => (
                          <MenuItem value={userPermission.id}>
                            {userPermission.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </div>
                  <div>
                    <p style={{ color: "#646D78" }}>ตำแหน่ง</p>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                      <Select
                        label="Regular Dropdown"
                        value={userDetail.siteRoleId}
                        onChange={handleUserPermissionDropdownChange}
                        size="small"
                        style={{ width: "296px" }}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "250px",
                              scrollbarWidth: "thin",
                            },
                          },
                        }}
                      >
                        {userPermissionList.map((userPermission) => (
                          <MenuItem
                            value={userPermission.id}
                            sx={{ fontSize: 14 }}
                          >
                            {userPermission.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "50px",
                  }}
                >
                  {/* TODO: Add date picker */}
                  <div>
                    <p style={{ color: "#646D78" }}>
                      สิทธิ์การใช้งาน{" "}
                      <Tooltip
                        title="สิทธิ์การใช้งานที่ต้องการอนุญาตให้ผู้ใช้งานเข้าถึง"
                        placement={tootlipPlacement}
                        PopperProps={{
                          sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                        }}
                      >
                        <span>
                          <Info width={15} height={15} color="#A0ABBA" />
                        </span>
                      </Tooltip>
                    </p>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                      <Select
                        label="Regular Dropdown"
                        value={userDetail.siteRoleId}
                        onChange={handleUserPermissionDropdownChange}
                        size="small"
                        style={{ width: "296px" }}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "250px",
                              scrollbarWidth: "thin",
                            },
                          },
                        }}
                      >
                        {userPermissionList.map((userPermission) => (
                          <MenuItem
                            value={userPermission.id}
                            sx={{ fontSize: 14 }}
                          >
                            {userPermission.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <br />
                <p style={{ borderBottom: "1px solid #D8D8D8" }}></p>
                <DownloadFile userId={userId} />
              </Box>
            </div>
            <br />
            <Box p={2} bgcolor={"white"} style={{ height: "60px" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#7A8688",
                    width: "90px",
                    height: "35px",
                    marginRight: "10px",
                  }}
                  onClick={() => navigate("/ApproveUser")}
                >
                  ย้อนกลับ
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#D80027",
                    width: "90px",
                    height: "35px",
                    marginRight: "10px",
                  }}
                >
                  ไม่อนุมัติ
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#DF5C8E",
                    width: "90px",
                    height: "35px",
                  }}
                >
                  อนุมัติ
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ApproveUserDetail;

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EmailIcon, LocationOnIcon, PhoneIcon } from "./icon";
import "./Footer.css";

const Footer: React.FC = () => {
  const theme = useTheme();
  const [isPhone, setIsPhone] = useState(false);
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setIsPhone(event.matches);
    };

    // Set initial value
    setIsPhone(mediaQuery.matches);

    // Add listener
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  const [isContentVisible, setIsContentVisible] = useState(true);

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <div className="footer">
      {isPhone ? (
        <>
          <Button
            style={
              isContentVisible
                ? { position: "absolute", top: "10px", right: "10px" }
                : {}
            }
            onClick={toggleContentVisibility}
          >
            {isContentVisible ? <ExpandLess /> : <ExpandMore />}
          </Button>
          {isContentVisible && (
            // <Grid container spacing={1} columns={1}>
            //   <Grid item xs={2} sm={2} md={1} py={0}>
            //     <div className="column-footer -left">
            //       <div className="container">
            //         <img src={Vector} alt="Image Alt Text" className="icon" />
            //         <p className="text">ศูนย์รักษ์พุง โรงพยาบาลจุฬาลงกรณ์</p>
            //       </div>
            //       <p className="address-text">
            //         อาคาร ส.ธ. ชั้น 12 เลขที่ 1873 ถนนพระราม 4
            //       </p>
            //       <p className="address-text">
            //         แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร
            //       </p>
            //     </div>
            //   </Grid>
            //   <Grid item xs={2} sm={2} md={1}>
            //     <div className="column-footer -left">
            //       <div className="container">
            //         <img
            //           src={telephone}
            //           alt="Image Alt Text"
            //           className="icon"
            //         />
            //         <p className="text">โทรศัพท์</p>
            //       </div>
            //       <p className="address-text">02-256-4000 ต่อ 71205</p>
            //       <p className="address-text">
            //         ทุกวันอังคาร- ศุกร์ เวลา 8.30 - 15.30 น.
            //         (ยกเว้นวันหยุดราชการ)
            //       </p>
            //     </div>
            //     <div className="column-footer">
            //       <div className="row">
            //         <div className="container">
            //           <p className="text">อีเมล</p>
            //           <div className="row">
            //             <p className="email">contact@chulabmi.go.th</p>
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   </Grid>
            // </Grid>
            <>
              <Grid
                container
                spacing={0}
                justifyContent="center"
                alignItems="baseline"
                sx={{ width: "100%", padding: "0 auto" }}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    sx={{
                      padding: 2,
                      borderRadius: 2,
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 1,
                      }}
                    >
                      <LocationOnIcon sx={{ color: "#df5c8e" }} />
                      <Stack>
                        <Typography textAlign={"left"} fontWeight={600}>
                          ศูนย์รักษ์พุง โรงพยาบาลจุฬาลงกรณ์
                        </Typography>
                        <Typography mt={1} textAlign={"left"} color={"#3C4142"}>
                          อาคาร ส.ธ. ชั้น 12 เลขที่ 1873 ถนนพระราม 4 แขวงปทุมวัน
                          เขตปทุมวัน กรุงเทพมหานคร
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    sx={{
                      p: 2,
                      pt: 0,
                      borderRadius: 2,
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 1,
                      }}
                    >
                      <PhoneIcon  sx={{ color: "#df5c8e" }} />
                      <Stack direction={"row"} gap={1}>
                        <Typography
                          textAlign={"left"}
                          fontWeight={600}
                          sx={{ minWidth: 60 }}
                        >
                          โทรศัพท์
                        </Typography>
                        <Stack>
                          <Typography textAlign={"left"} color={"#3C4142"}>
                            02-256-4000 ต่อ 7120
                          </Typography>
                          <Typography textAlign={"left"} color={"#3C4142"}>
                            ทุกวันอังคาร- ศุกร์ เวลา 8.30 - 15.30 น.
                            (ยกเว้นวันหยุดราชการ)
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 1,
                      }}
                    >
                      <EmailIcon  sx={{ color: "#df5c8e" }} />
                      <Stack direction={"row"} gap={1}>
                        <Typography
                          textAlign={"left"}
                          fontWeight={600}
                          sx={{ minWidth: 60 }}
                        >
                          อีเมล
                        </Typography>
                        <Stack>
                          <Typography textAlign={"left"} color={"#3C4142"}>
                            Obesityconnects@gmail.com
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Typography fontSize={12} mb={1} color={"#363853"} px={1}>
                Copyright © 2024 Chula Bariatric and Metabolic Institute, All right reserved.
              </Typography>
            </>
          )}
        </>
      ) : (
        // <Grid container spacing={1} columns={12}>
        //   <Grid item xs={12} sm={12} md={6}>
        //     <div className="column-footer -left">
        //       <div className="container">
        //         <img src={Vector} alt="Image Alt Text" className="icon" />
        //         <p className="text">ศูนย์รักษ์พุง โรงพยาบาลจุฬาลงกรณ์</p>
        //       </div>
        //       <p className="address-text">
        //         อาคาร ส.ธ. ชั้น 12 เลขที่ 1873 ถนนพระราม 4
        //       </p>
        //       <p className="address-text">
        //         แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร
        //       </p>
        //     </div>
        //   </Grid>
        //   <Grid item xs={12} sm={12} md={6}>
        //     <div className="column-footer">
        //       <div className="row">
        //         <div className="container2">
        //           <img
        //             src={telephone}
        //             alt="Image Alt Text"
        //             className="iconTel"
        //           />
        //           <p className="text2">โทรศัพท์</p>
        //           <div className="row">
        //             <p className="tel">02-256-4000 ต่อ 71205</p>
        //           </div>
        //         </div>
        //         <p className="openTime">
        //           ทุกวันอังคาร- ศุกร์ เวลา 8.30 - 15.30 น. (ยกเว้นวันหยุดราชการ)
        //         </p>
        //       </div>
        //       <div className="row">
        //         <div className="container">
        //           <p className="text">อีเมล</p>
        //           <div className="row">
        //             <p className="email">contact@chulabmi.go.th</p>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </Grid>
        // </Grid>
        <>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="baseline"
            sx={{ width: "100%", padding: "0 auto" }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  ml: isSmScreen ? 0 : "auto",
                  width: "100%",
                  maxWidth: isSmScreen ? "100%" : "450px",
                  boxSizing: "border-box",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
                  <LocationOnIcon  sx={{ color: "#df5c8e" }} />
                  <Stack>
                    <Typography textAlign={"left"} fontWeight={600}>
                      ศูนย์รักษ์พุง โรงพยาบาลจุฬาลงกรณ์
                    </Typography>
                    <Typography mt={1} textAlign={"left"} color={"#3C4142"}>
                      อาคาร ส.ธ. ชั้น 12 เลขที่ 1873 ถนนพระราม 4 แขวงปทุมวัน
                      เขตปทุมวัน กรุงเทพมหานคร
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  p: 2,
                  pt: isSmScreen ? 0 : 2,
                  borderRadius: 2,
                  mr: "auto",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
                  <PhoneIcon  sx={{ color: "#df5c8e" }} />
                  <Stack direction={"row"} gap={1}>
                    <Typography
                      textAlign={"left"}
                      fontWeight={600}
                      sx={{ minWidth: 60 }}
                    >
                      โทรศัพท์
                    </Typography>
                    <Stack>
                      <Typography textAlign={"left"} color={"#3C4142"}>
                        02-256-4000 ต่อ 7120
                      </Typography>
                      <Typography textAlign={"left"} color={"#3C4142"}>
                        ทุกวันอังคาร- ศุกร์ เวลา 8.30 - 15.30 น.
                        (ยกเว้นวันหยุดราชการ)
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 1,
                  }}
                >
                  <EmailIcon  sx={{ color: "#df5c8e" }} />
                  <Stack direction={"row"} gap={1}>
                    <Typography
                      textAlign={"left"}
                      fontWeight={600}
                      sx={{ minWidth: 60 }}
                    >
                      อีเมล
                    </Typography>
                    <Stack>
                      <Typography textAlign={"left"} color={"#3C4142"}>
                        Obesityconnects@gmail.com
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Typography fontSize={12} mb={1} color={"#363853"}>
            Copyright © 2024 Chula Bariatric and Metabolic Institute, All right reserved.
          </Typography>
        </>
      )}
    </div>
  );
};

export default Footer;

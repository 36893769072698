import React, { useEffect, useState } from "react";
import HeaderMenuBar from "../../HeaderMenuBar";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Footer from "../../Footer";
import "./Register.css";
import Stack from "@mui/material/Stack";
import { ArrowForward } from "@mui/icons-material";
import TOS from "../../asset/TOS.png";
import { useLocation, useNavigate } from "react-router";
import { ConsentData } from "../../type";
import { useEffectOnce } from "react-use";
import { userManagementService } from "../../service/userManagementService";

const PrivacyPolicy = () => {
  // State for storing the values of the three filter fields
  const [step, setStep] = useState(1);
  const [acceptTOS, setAcceptTOS] = useState(false);
  const navigate = useNavigate();
  const [consentData, setConsentData] = useState<ConsentData>();
  const location = useLocation();
  const { status, email, password, tokenId, userId } = location.state || {};

  useEffectOnce(() => {
    userManagementService.getLatestConsent().then((response) => {
      setConsentData(response);
    });
  });
  return (
    <div className="register-container">
      <HeaderMenuBar />

      <div className="register-card">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={TOS}
            style={{ width: "60px", height: "60px", marginRight: "15px" }}
          />
          <Typography
            variant="h5"
            align="center"
            style={{
              fontWeight: "bold",
              paddingTop: "30px",
              paddingBottom: "20px",
            }}
          >
            Privacy Policy
          </Typography>
        </div>
        {/* <Typography align="center">Updated 10 มกราคม 2567</Typography> */}

        <Stack spacing={1} style={{ paddingTop: "40px", padding: "50px" }}>
          {consentData?.personalDataProtectionAct.content && (
            <div
              dangerouslySetInnerHTML={{
                __html: consentData.personalDataProtectionAct.content,
              }}
            />
          )}

          {/* <p>
            <strong>นโยบายคุ้มครองข้อมูลส่วนบุคคล</strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              ศูนย์รักษ์พุง โรงพยาบาลจุฬาลงกรณ์ สภากาชาดไทย
              (&ldquo;ศูนย์&rdquo;) เป็นผู้พัฒนาและเจ้าของ ระบบ{" "}
            </span>
            <strong>
              Chula Wellness and Obesity Network รวมไปถึง Line Official account
              @Obesityconnects{" "}
            </strong>
            <span style={{ fontWeight: 400 }}>
              ซึ่งถือเป็นบริการ หนึ่งของศูนย์ (&ldquo;บริการ&rdquo;)
              เมื่อท่านสมัครบัญชีใช้งานของศูนย์ผ่านเว็บไซต์ Obesityconnects.com
              หรือผ่าน{" "}
            </span>
            <strong>Line Official account @Obesityconnects </strong>
            <span style={{ fontWeight: 400 }}>
              ท่านจะถือว่าเป็น ผู้ใช้บริการของศูนย์ (&ldquo;ผู้ใช้บริการ&rdquo;)
              และในการให้บริการแก่ท่าน ศูนย์มีความ
              จำเป็นต้องประมวลผลข้อมูลส่วนบุคคลของผู้ใช้บริการ ดังนั้น
              ศูนย์จึงมีจุดประสงค์ประกาศนโยบาย
              คุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้น เพื่อแจ้ง เกี่ยวกับ
              สิทธิและหน้าที่ รวมถึงเงื่อนไขต่างๆ อันเกี่ยว เนื่องกับการเก็บ
              รวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลที่ศูนย์
              จะดำเนินการให้ผู้ใช้บริการ ในฐานะผู้ใช้บริการรับทราบ
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              นโยบายความเป็นส่วนตัวฉบับนี้
              มีผลใช้บังคับกับการให้บริการของศูนย์เท่านั้น ไม่มีผลใช้บังคับ กับ
              แอปพลิเคชันและบริการหรือเว็บไซต์อื่นๆ ที่อาจมีการเชื่อมต่อ
              ซึ่งเป็นของบุคคลภายนอก ที่ศูนย์ไม่มี อำนาจ ควบคุม
              และเป็นส่วนที่ผู้ใช้บริการต้องทำความตกลง
              และศึกษาเกี่ยวกับนโยบายข้อมูลส่วน บุคคล สำหรับ การใช้แอปพลิเคชัน
              บริการ หรือเว็บไซต์ดังกล่าวแยกต่างหาก
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              หากผู้ใช้บริการไม่ตกลงตามเงื่อนไขของนโยบายข้อมูลส่วนบุคคลฉบับนี้
              หรือฉบับแก้ไขอื่นๆ ศูนย์ สงวนสิทธิที่จะห้าม
              หรือไม่อนุญาตให้ผู้ใช้บริการดังกล่าวใช้บริการของศูนย์
              เนื่องจากการประมวลผลข้อมูลส่วนบุคคลภายใต้นโยบายฉบับนี้
              มีความผลจำเป็นโดยตรงต่อ การปฏิบัติตามหน้าที่ให้บริการโดยศูนย์แก่
              ผู้ใช้บริการ ทั้งนี้ การที่ผู้ใช้บริการยังคงใช้บริการของศูนย์อยู่
              ผู้ใช้บริการจะถือว่า ยอมรับนโยบายฉบับนี้เสมอ
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              ศูนย์อาจปรับปรุงนโยบายฉบับนี้ตามแต่ละระยะเวลา
              เพื่อให้สอดคล้องกับแนวปฏิบัติและกฎหมาย ข้อบังคับที่เกี่ยวข้อง
              และให้สอดคล้องกับการให้บริการต่างๆของศูนย์ ทั้งนี้
              ศูนย์จะแจ้งให้ผู้ใช้
              บริการทราบถึงการเปลี่ยนแปลงด้วยการประกาศนโยบายฉบับปรับปรุงใหม่ให้ผู้ใช้บริการทราบ
              โดยนโยบายนั้นจะถือว่ามีผลบังคับใช้ เมื่อศูนย์ได้ประกาศ
            </span>
          </p>
          <p>
            <strong>
              ลักษณะข้อมูลส่วนบุคคลที่ศูนย์ประมวลผลในการให้บริการของศูนย์
            </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              ในการให้บริการ ศูนย์จะเก็บรวบรวม และประมวลผลข้อมูลส่วนบุคคล
              จากการที่ผู้ใช้บริการดำเนิน การผ่านระบบการให้บริการ ดังต่อไปนี้
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ข้อมูลที่บ่งชี้ตัวตนโดยตรง อาทิ ชื่อ เลขบัตรประชาชน
                เลขใบอนุญาตประกอบวิชาชีพ
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ข้อมูลการติดต่อ อาทิ ที่อยู่ สถานที่ติดต่อ เบอร์โทร อีเมล
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ข้อมูลการใช้บริการ อาทิ ชื่อบัญชีผู้ใช้ รหัสผ่าน
                ประวัติการทำธุรกรรมที่ผู้ใช้บริการดำเนินการบน
                เว็บไซต์แอปพลิเคชัน
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ข้อมูลทางเทคนิคในการระบุตัวตน อาทิ หมายเลขระบุตำแหน่งคอมพิวเตอร์
                (IP Address) ข้อมูลการใช้งาน
                การตั้งค่าและการเชื่อมต่อบราวเซอร์ของอุปกรณ์ที่ผู้ใช้บริการใช้ในการใช้บริการของศูนย์
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
                <br />
              </span>
            </li>
          </ul>
          <p>
            <strong>วัตถุประสงค์ในการประมวลผลข้อมูล</strong>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ศูนย์มีความจำเป็นเก็บ รวบรวม ใช้ข้อมูลส่วนบุคคลของผู้ใช้บริการ
                เพื่อการให้บริการที่กำหนดและระบุไว้สำหรับแต่ละเงื่อนไขการให้บริการของศูนย์
                อันรวมถึง
                เพื่อการยืนยันตัวตนและติดตามในการทำธุรกรรมต่างๆของผู้ใช้บริการ
                และเพื่อใช้ในการติดต่อสื่อสารกับผู้ใช้บริการ เป็นต้น
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ศูนย์มีความจำเป็นจัดเก็บและใช้ข้อมูลส่วนบุคคลของผู้ใช้บริการ
                เพื่อเก็บเป็นประวัติในการเข้าใช้งานระบบ
                และการเช้าถึงข้อมูลของคนไข้ในระบบ
                โดยระบบจะมีการเก็บประวัติธุรกรรมต่างๆบนระบบ อาทิเช่น
                การเข้าถึงข้อมูลคนไข้ การเปลี่ยนแปลงข้อมูลคนไข้
                รวมไปถึงการส่งออกข้อมูลคนไข้ออกจากระบบ
              </span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              ทั้งนี้
              ศูนย์จะเก็บและรวบรวมข้อมูลส่วนบุคคลของผู้ใช้บริการไว้ตลอดระยะเวลา
              ตราบเท่าที่ผู้ใช้ บริการยังคงเป็นผู้ใช้บริการของศูนย์อยู่
              และศูนย์สงวนสิทธิเก็บข้อมูลไว้อีกเป็นระยะเวลา 3 ปีหลัง
              จากผู้ใช้บริการยกเลิกการใช้บริการ เพื่อประโยชน์ในการปกป้อง
              และต่อสู้สิทธิต่างๆของศูนย์ เว้นแต่
              กฎหมายที่เกี่ยวข้องกำหนดให้ศูนย์มีหน้าที่เก็บข้อมูลส่วนบุคคลไว้เป็นระยะเวลาอื่น
              ศูนย์อาจมีความจำเป็นต้องเก็บข้อมูลไว้เป็นระยะเวลาที่ยาวนานกว่า 3
              ปี
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>
          <p>
            <strong>การเปิดเผยข้อมูลส่วนบุคคล</strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              เพื่อการให้บริการ ตามเงื่อนไขที่ระบุไว้ ศูนย์อาจมีความ
              จำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการ ในกรณี ดังนี้
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ศูนย์อาจมีความจำเป็นเปิดเผยให้แก่ผู้ให้บริการภายนอกของศูนย์
                ที่ได้รับการว่าจ้างจาก
                ศูนย์ให้ช่วยเหลือสนับสนุนการให้บริการของศูนย์ เช่น ที่ปรึกษา
                ผู้รับจ้างให้บริการ ผู้พัฒนาระบบ
                รวมถึงผู้ให้บริการภายนอกที่ให้บริการประเมินการบริการของศูนย์
                เช่น Google Analytics
                ทั้งนี้ศูนย์จะเปิดเผยข้อมูลเฉพาะเท่าที่จำเป็นเท่านั้น
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ศูนย์อาจเปิดเผยด้วยการเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ในระบบระบบคอมพิวเตอร์
                ที่ใช้บริการ ได้แก่ Google Cloud Platform, Amazon AWS, Microsoft
                Azure
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ศูนย์อาจเปิดเผยข้อมูล
                ด้วยความจำเป็นเพื่อประโยชน์ในการปกป้องและต่อสู้สิทธิของศูนย์
                หรือเพื่อการป้องกันและตรวจสอบลักษณะการกระทำผิดที่เกี่ยวข้องกับการใช้บริการของศูนย์
                โดยผู้ใช้บริการ ในลักษณะต่างๆ
                โดยศูนย์จะดำเนินการดังกล่าวเท่าที่จำเป็น เท่านั้น
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                ในกรณีที่ศูนย์มีหน้าที่ตามกฎหมาย หรืออยู่ภายใต้บังคับคำพิพากษา
                หรือคำสั่งของ หน่วยงานราชการ
                ศูนย์อาจมีความจำเป็นต้องเปิดเผยข้อมูลให้แก่ หน่วยงานดังกล่าว
                เพื่อเป็นการปฏิบัติตามหน้าที่ที่มีตามกฎหมาย
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
                <br />
              </span>
            </li>
          </ul>
          <p>
            <strong>
              คำรับประกันการดำเนินมาตรการรักษาความมั่นคงปลอดภัยในข้อมูลที่เหมาะสม
            </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              ศูนย์รับประกันจัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยที่เหมาะสม
              เพื่อป้องกัน การเข้าถึง การใช้ การเปลี่ยนแปลง การแก้ไข
              หรือการเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
              นอกจากนี้ศูนย์ได้กำหนดแนวปฏิบัติภายในเพื่อกำหนดสิทธิในการเข้าถึงหรือการใช้ข้อมูล
              ส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
              เพื่อรักษาความลับและความปลอดภัยของข้อมูล ทั้งนี้
              ศูนย์จะจัดให้มีการทบทวนมาตรการดังกล่าวเป็นระยะเพื่อความเหมาะสม
              ตามกฎหมายที่เกี่ยวข้อง
            </span>
          </p>
          <p>
            <strong>สิทธิของเจ้าของข้อมูล</strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              ศูนย์รับทราบ และเคารพสิทธิตามกฎหมายของผู้ใช้บริการ
              ในส่วนที่เกี่ยวข้องกับข้อมูลส่วนบุคคล ของท่านผู้ใช้บริการ
              ซึ่งได้แก่ สิทธิ ดังนี้
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                สิทธิขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคล
                รวมถึงสิทธิในการขอแก้ไขข้อมูลดังกล่าวให้ เป็นปัจจุบันและถูกต้อง
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                สิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคล
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                สิทธิขอให้ลบหรือทำลาย
                หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลได้
                เมื่อข้อมูลนั้นหมดความจำเป็นหรือเมื่อเจ้าของข้อมูลส่วนบุคคลถอนความยินยอม
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคลได้
                ในกรณีเมื่อเป็นข้อมูลส่วนบุคคลที่ต้องลบ หรือ
                เมื่อข้อมูลดังกล่าวหมดความจำเป็น
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                สิทธิถอนความยินยอม ในการประมวลผลข้อมูลที่ผู้ใช้บริการเคยให้ไว้
              </span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              ทั้งนี้ ผู้ใช้บริการสามารถติดต่อมายังศูนย์
              เพื่อขอใช้สิทธิข้างต้นได้ ตามรายละเอียดการติดต่อที่
              ศูนย์ได้กำหนดไว้ โดยไม่ต้องเสียค่าใช้จ่ายใดๆ และศูนย์จะพิจารณา
              และแจ้งผลการพิจารณาการขอใช้สิทธิ ให้ผู้ใช้บริการทราบภายใน 60
              วันนับแต่วันที่เราได้รับคำร้องขอดังกล่าว
            </span>
          </p>
          <p>
            <strong>ช่องทางการติดต่อ</strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              ผู้ใช้บริการสามารถติดต่อศูนย์ได้ตามที่อยู่ต่อไปนี้ ศูนย์รักษ์พุง
              โรงพยาบาลจุฬาลงกรณ์ สภากาชาดไทย อาคาร ส.ธ. ชั้น 12 เลขที่ 1873
              พระราม 4 ปทุมวัน กรุงเทพ 10330
              เพื่อให้การปฏิบัติตามนโยบายความเป็นส่วนตัวของศูนย์เป็นไปอย่างมีประสิทธิภาพ
              ทางศูนย์ได้แต่งตั้งเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
              ซึ่งจะให้ความช่วยเหลือเกี่ยวกับการจัดการข้อมูลส่วนบุคคลของผู้ใช้บริการได้
              หากผู้ใช้บริการมีข้อสงสัยหรือข้อแนะนำเกี่ยวกับวิธีปฏิบัติของศูนย์เกี่ยวกับการจัดการข้อมูลส่วนบุคคล
              ผู้ใช้บริการสามารถติดต่อศูนย์ได้โดยผ่านตัวแทนผู้รับผิดชอบ
              เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล ดร. สะริญญาลภัสธร์ บูรณะกิติ
              (ฝ่ายบริการผู้ป่วยสัมพันธ์) ที่อยู่ อาคาร ส.ธ. ชั้น 12 เลขที่ 1873
              พระราม 4 ปทุมวัน กรุงเทพ หรือโทร 02-649-4000 ต่อ 71205
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              อนึ่ง ก่อนให้ความยินยอม
              ข้าพเจ้าได้อ่านและเข้าใจข้อกำหนดและเงื่อนไขในการเปิดเผยข้อมูลส่วนบุคคล
              ซึ่งระบุไว้ด้านบนของความยินยอมนี้อย่างชัดเจนแล้ว
            </span>
          </p> */}
        </Stack>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "60px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  style={{ color: "#DF5C8E", paddingLeft: "50px" }}
                  value={acceptTOS}
                  onChange={() => setAcceptTOS(!acceptTOS)}
                  checked={acceptTOS}
                />
              }
              label="ยอมรับ ข้อกำหนดในการให้บริการ (Terms of Service)"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {acceptTOS ? (
              <Typography
                variant="body1"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: "50px",
                  color: "#DF5C8E",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  if (status !== "" && status === "consent_required") {
                    userManagementService
                      .updateLatestConsent(consentData, userId)
                      .then(() => {
                        userManagementService
                          .login(email, password)
                          .then(async () => {
                            if (userId !== "1") {
                              const userDetail =
                                await userManagementService.getUserDetail(
                                  userId
                                );
                              localStorage.setItem(
                                "userDetail",
                                JSON.stringify(userDetail)
                              );

                              const userPermissions =
                                await userManagementService.getUserPermission();
                              const matchedPermission = userPermissions.find(
                                (userPermission: any) =>
                                  userPermission.id.toString() ===
                                  userDetail.siteRoleId.toString()
                              );

                              if (matchedPermission) {
                                // dispatch(setUserPermission(matchedPermission));
                                localStorage.setItem(
                                  "userPermission",
                                  JSON.stringify(matchedPermission)
                                );
                              }
                            }
                            navigate("/home");
                          });
                      });
                    console.log("consent_required");
                  } else if (
                    status !== "" &&
                    status === "consent_required_SSO"
                  ) {
                    userManagementService
                      .updateLatestConsent(consentData, userId)
                      .then(() => {
                        userManagementService
                          .loginWithSSO(tokenId)
                          .then(async () => {
                            if (userId !== "1") {
                              const userDetail =
                                await userManagementService.getUserDetail(
                                  userId
                                );
                              localStorage.setItem(
                                "userDetail",
                                JSON.stringify(userDetail)
                              );

                              const userPermissions =
                                await userManagementService.getUserPermission();

                              const matchedPermission = userPermissions.find(
                                (userPermission: any) =>
                                  userPermission.id.toString() ===
                                  userDetail.siteRoleId.toString()
                              );

                              if (matchedPermission) {
                                localStorage.setItem(
                                  "userPermission",
                                  JSON.stringify(matchedPermission)
                                );
                              }
                            }
                            navigate("/home");
                          });
                      });
                    console.log("consent_required_SSO");
                  } else {
                    navigate("/Register");
                  }
                }}
              >
                ถัดไป
                <ArrowForward />
              </Typography>
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;

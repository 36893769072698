import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import { useEffectOnce } from "react-use";
import { HealthBenefit, Hospital } from "../../asset/iconSvg";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { EditBorderIcon, PersonIcon } from "../../icon";
import { setPatientUserProfile } from "../../redux/features/patientSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { store } from "../../redux/store";
import { userManagementService } from "../../service/userManagementService";
import { body14400, title18500, title18700 } from "../../style";
import {
  ContraceptionMasterData,
  MasterData,
  PatientUserProfile,
} from "../../type";
import { calculateAge } from "../../utils/calculateAge";
import { getHospital } from "../../utils/get-data/getHospital";
import { getPrefix } from "../../utils/get-data/getPrefix";
import HealthInformationDialog from "./dialog/HealthInformationDialog";
import ProfileDialog from "./dialog/ProfileDialog";
import { useSelector } from "react-redux";
import { convertToIdArray } from "../../utils/formatData";
import { getContraception } from "../../utils/get-data/getContraception";

type Props = {
  handleGobackClick: () => void;
};
export const PatientProfileDetail = ({ handleGobackClick }: Props) => {
  const dispatch = useAppDispatch();
  const patientUserProfile = useAppSelector(
    (state) => state.patientUser.selectedPatientUserProfile
  ) as PatientUserProfile;
  const [prefixList, setPrefixList] = useState<MasterData[]>();
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [hospitalList, setHospitalList] = useState<MasterData[]>();
  const [contraceptionList, setContraceptionList] =
    useState<ContraceptionMasterData[]>();
  const [patientUserProfileTemp, setPatientUserProfileTemp] =
    useState<PatientUserProfile>();
  const [openHealthInformationDialog, setOpenHealthInformationDialog] =
    useState(false);
  const [openUpdaeProfileFailureDialog, setOpenUpdaeProfileFailureDialog] =
    useState(false);

  const userPermission = useSelector(
    (state: any) => state.loginUser.userPermission
  );
  const userId = localStorage.getItem("userId") ?? "";
  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const prefixList = await getPrefix();
        setPrefixList(prefixList);
        const hospitalList = await getHospital();
        setHospitalList(hospitalList);
        setPatientUserProfileTemp(patientUserProfile);
        const contraceptionList = await getContraception();
        setContraceptionList(contraceptionList);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
  });
  const handleCloseProfileDialog = () => {
    dispatch(
      setPatientUserProfile(patientUserProfileTemp as PatientUserProfile)
    );
    setOpenProfileDialog(false);
  };
  const handleCloseHealthInformationDialog = () => {
    dispatch(
      setPatientUserProfile(patientUserProfileTemp as PatientUserProfile)
    );
    setOpenHealthInformationDialog(false);
  };
  const { mutateAsync: updateProfileAsync } = useMutation({
    mutationFn: async () => {
      const profile = store.getState().patientUser.selectedPatientUserProfile;
      if (profile) {
        await userManagementService.updatePatientProfile({
          userId: store.getState().patientUser.patientUser?.id,
          personalData: {
            prefix: prefixList?.find((p) => p.nameThai === profile.prefix)?.id,
            firstName: profile.firstName,
            lastName: profile.lastName,
            sex: profile.sex === "male" ? 1 : 2,
            birthDate: profile.birthDate,
            weight: store.getState().patientUser.patientUser?.weight.toString(),
            height: store.getState().patientUser.patientUser?.height.toString(),
            occupation: profile.occupation ? profile.occupation : null,
            occupationOther: profile.occupationOther
              ? profile.occupationOther
              : null,
            domicile: profile.domicile ? profile.domicile : null,
          },
          healthInfo: {
            hospital:
              profile.affiliatedHospital !== null
                ? hospitalList?.find(
                    (h) => h.nameThai === profile.affiliatedHospital
                  )?.id
                : null,
            hn: profile.hn ? profile.hn : null,
            healthBenefit: profile.healthBenefit ? profile.healthBenefit : null,
            healthBenefitOther: profile.healthBenefitOther
              ? profile.healthBenefitOther
              : null,
            bariatricSurgeryHistory: profile.bariatricSurgeryHistory
              ? true
              : false,
            drinkingHistory: profile.drinkingHistory ? true : false,
            smokingHistory: profile.smokingHistory ? true : false,
            highestWeight: profile.highestWeight.toString(),
            weightLossMethod:
              profile.weightLossMethod?.length > 0
                ? profile.weightLossMethod
                : null,
            weightLossMethodOther: profile.weightLossMethodOther
              ? profile.weightLossMethodOther
              : null,
            chronicDisease:
              profile.chronicDisease?.length > 0
                ? profile.chronicDisease
                : null,
            psychoticDisorderOther: profile.psychoticDisorderOther
              ? profile.psychoticDisorderOther
              : null,
            cancerOther: profile.cancerOther ? profile.cancerOther : null,
            chronicDiseaseOther: profile.chronicDiseaseOther
              ? profile.chronicDiseaseOther
              : null,
          },
          sexualHabit: {
            isSexuallyActive: profile.isSexuallyActive ? true : false,
            sexualActivityFrequency: profile.sexualActivityFrequency
              ? profile.sexualActivityFrequency
              : null,
            contraceptionList:
              profile.contraceptionMethodList?.length > 0 && contraceptionList
                ? convertToIdArray(
                    contraceptionList,
                    profile.contraceptionMethodList
                  )
                : null,
            // protectionMethod:
            //   profile.protectionMethod?.length > 0
            //     ? profile.protectionMethod
            //     : null,
            protectionMethodOther: profile.protectionMethodOther
              ? profile.protectionMethodOther
              : null,
            hasChild: profile.hasChild ? true : false,
            numberOfChild: profile.numberOfChild,
            wantChild: profile.wantChild ? true : false,
            yearTillChildIsExpected: profile.yearTillChildIsExpected,
            expectedNumberOfChild: profile.expectedNumberOfChild,
            stillHavingPeriod: profile.stillHavingPeriod ? true : false,
            periodGapDuration: profile.periodGapDuration
              ? profile.periodGapDuration
              : null,
            periodDuration: profile.periodDuration
              ? profile.periodDuration
              : null,
            isPregnant: profile.isPregnant ? true : false,
            numberOfPregnancy: profile.numberOfPregnancy,
            pregnancyStatusList:
              profile.pregnancyStatusList?.length > 0
                ? profile.pregnancyStatusList
                : null,
            numberOfMiscarriage: profile.numberOfMiscarriage,
          },
        });
      }
    },
    onSuccess() {
      setOpenProfileDialog(false);
      setOpenHealthInformationDialog(false);
      const updatedProfile =
        store.getState().patientUser.selectedPatientUserProfile;
      setPatientUserProfileTemp(updatedProfile as PatientUserProfile);
    },
    onError(error) {
      setOpenUpdaeProfileFailureDialog(true);
      console.log(error);
    },
  });
  const submitForm = async () => {
    try {
      await updateProfileAsync();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box>
      <Typography style={title18700}>Profile</Typography>
      <Stack direction={"row"} spacing={2} alignItems={"center"} sx={{ mt: 2 }}>
        <Avatar sx={{ width: 128, height: 128 }}>
          <PersonIcon sx={{ width: 80, height: 80 }} />
        </Avatar>
        <Stack spacing={1 / 2}>
          <Typography fontSize={24} fontWeight={500} color={"secondary"}>
            {patientUserProfile.hn}
          </Typography>
          <Typography fontSize={24} fontWeight={500} color={"#212121"}>
            {`${patientUserProfile.firstName} ${patientUserProfile.lastName}`}
          </Typography>
          <Stack
            spacing={1}
            direction={"row"}
            fontSize={14}
            fontWeight={500}
            alignItems={"cenetr"}
          >
            <Stack spacing={1} direction={"row"} alignItems={"center"}>
              <Hospital />
              <Typography fontSize={14} fontWeight={500} color={"#646D78"}>
                {patientUserProfile.affiliatedHospital
                  ? patientUserProfile.affiliatedHospital
                  : "-"}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction={"row"}
              alignItems={"center"}
              sx={{
                borderLeft: "1px solid #E3E7F0",
                pl: 1,
              }}
            >
              <HealthBenefit />
              <Typography fontSize={14} fontWeight={500} color={"#646D78"}>
                {patientUserProfile.healthBenefit
                  ? patientUserProfile.healthBenefit === "อื่นๆ"
                    ? patientUserProfile.healthBenefitOther
                    : patientUserProfile.healthBenefit
                  : "-"}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Paper sx={{ mt: 2, p: 2, borderRadius: 2 }}>
        <Stack spacing={1}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography component={"span"} style={title18500}>
              ข้อมูลส่วนตัว
            </Typography>
            {patientDataPermissionEditAble && (
              <IconButton
                sx={{
                  "&:hover": {
                    bgcolor: "inherit",
                  },
                }}
                onClick={() => setOpenProfileDialog(true)}
              >
                <EditBorderIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>

          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              คำนำหน้า
            </Typography>

            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {patientUserProfile.prefix}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              ชื่อ-นามสกุล
            </Typography>

            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {`${patientUserProfile.firstName} ${patientUserProfile.lastName}`}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              เพศ
            </Typography>

            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {`${patientUserProfile.sex} ${
                patientUserProfile.sex === "male" ? "(ชาย)" : "(หญิง)"
              }`}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              วันเดือนปีเกิด
            </Typography>

            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {dayjs(patientUserProfile.birthDate).format("DD/MM/BBBB")}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              อายุ (ปี)
            </Typography>

            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {calculateAge(patientUserProfile.birthDate)}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              อาชีพ
            </Typography>

            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {patientUserProfile.occupation
                ? patientUserProfile.occupation === "อื่นๆ (โปรดระบุ)"
                  ? patientUserProfile.occupationOther
                  : patientUserProfile.occupation
                : "-"}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              ภูมิลำเนา
            </Typography>

            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {patientUserProfile.domicile ? patientUserProfile.domicile : "-"}
            </Typography>
          </Box>
        </Stack>
      </Paper>
      <Paper sx={{ mt: 2, p: 2, borderRadius: 2 }}>
        <Stack spacing={1}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography component={"span"} style={title18500}>
              ข้อมูลสุขภาพ
            </Typography>
            {patientDataPermissionEditAble && (
              <IconButton
                sx={{
                  "&:hover": {
                    bgcolor: "inherit",
                  },
                }}
                onClick={() => setOpenHealthInformationDialog(true)}
              >
                <EditBorderIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>

          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              เคยผ่าตัดกระเพาะอาหาร
            </Typography>
            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {patientUserProfile.bariatricSurgeryHistory ? "ใช่" : "ไม่"}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              การดื่มเหล้า
            </Typography>
            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {patientUserProfile.drinkingHistory ? "ใช่" : "ไม่"}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              การสูบบุหรี่
            </Typography>
            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {patientUserProfile.smokingHistory ? "ใช่" : "ไม่"}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              น้ำหนักมากที่สุด
            </Typography>
            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {`${patientUserProfile.highestWeight} kg`}
            </Typography>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              วิธีลดความอ้วนที่เคยใช้
            </Typography>
            <Stack direction={"row"} spacing={1}>
              {patientUserProfile.weightLossMethod ? (
                patientUserProfile.weightLossMethod.map((item, idx) => {
                  return (
                    <Box
                      key={`WLM-${idx}`}
                      sx={{
                        p: 1,
                        bgcolor: "#DBDBDB",
                        width: "fit-content",
                        borderRadius: 1,
                      }}
                    >
                      <Typography fontSize={13} sx={{ color: "#272E39" }}>
                        {item === "อื่นๆ"
                          ? patientUserProfile.weightLossMethodOther
                          : item}
                      </Typography>
                    </Box>
                  );
                })
              ) : (
                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {`-`}
                </Typography>
              )}
            </Stack>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              โรคประจำตัว
            </Typography>
            <Stack direction={"row"} spacing={1}>
              {patientUserProfile.chronicDisease ? (
                patientUserProfile.chronicDisease.map((item, idx) => {
                  return (
                    <Box
                      key={idx}
                      sx={{
                        p: 1,
                        bgcolor: "#DBDBDB",
                        width: "fit-content",
                        borderRadius: 1,
                      }}
                    >
                      <Typography fontSize={13} sx={{ color: "#272E39" }}>
                        {item === "โรคอื่นๆ"
                          ? `โรคอื่นๆ (${patientUserProfile.chronicDiseaseOther})`
                          : item === "โรคทางจิตเวช"
                          ? `โรคทางจิตเวช (${patientUserProfile.psychoticDisorderOther})`
                          : item === "โรคมะเร็ง"
                          ? `โรคมะเร็ง (${patientUserProfile.cancerOther})`
                          : item}
                      </Typography>
                    </Box>
                  );
                })
              ) : (
                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {`-`}
                </Typography>
              )}
            </Stack>
          </Box>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 5fr"}
            sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
          >
            <Typography style={body14400} sx={{ color: "#646D78" }}>
              เคยมีเพศสัมพันธ์หรือไม่
            </Typography>
            <Typography style={body14400} sx={{ color: "#272E39" }}>
              {patientUserProfile.isSexuallyActive ? "ใช่" : "ไม่"}
            </Typography>
          </Box>

          {patientUserProfile.isSexuallyActive && (
            <>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 5fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  ความถี่ในการมีเพศสัมพันธ์ในช่วง 3 เดือนที่ผ่านมา
                </Typography>
                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {patientUserProfile.sexualActivityFrequency}
                </Typography>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 5fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  วิธีคุมกำเนิดที่เคยใช้
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  {patientUserProfile.contraceptionMethodList ? (
                    patientUserProfile.contraceptionMethodList.map(
                      (item, idx) => {
                        return (
                          <Box
                            key={`CM-${idx}`}
                            sx={{
                              p: 1,
                              bgcolor: "#DBDBDB",
                              width: "fit-content",
                              borderRadius: 1,
                            }}
                          >
                            <Typography fontSize={13} sx={{ color: "#272E39" }}>
                              {item === "อื่นๆ"
                                ? patientUserProfile.protectionMethodOther
                                : item}
                            </Typography>
                          </Box>
                        );
                      }
                    )
                  ) : (
                    <Typography style={body14400} sx={{ color: "#272E39" }}>
                      {`-`}
                    </Typography>
                  )}
                </Stack>
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 5fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  ปัจจุบันมีบุตรหรือไม่
                </Typography>
                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {patientUserProfile.hasChild ? "ใช่" : "ไม่"}
                </Typography>
              </Box>
              {patientUserProfile.hasChild && (
                <Box
                  display={"grid"}
                  gridTemplateColumns={"1fr 5fr"}
                  sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                >
                  <Typography style={body14400} sx={{ color: "#646D78" }}>
                    ปัจจุบันมีลูกกี่คน
                  </Typography>
                  <Typography style={body14400} sx={{ color: "#272E39" }}>
                    {patientUserProfile.numberOfChild}
                  </Typography>
                </Box>
              )}
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 5fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  ความต้องการมีบุตรในระยะยาว
                </Typography>
                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {patientUserProfile.wantChild ? "ใช่" : "ไม่"}
                </Typography>
              </Box>
              {patientUserProfile.wantChild && (
                <>
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"1fr 5fr"}
                    sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                  >
                    <Typography style={body14400} sx={{ color: "#646D78" }}>
                      วางแผนมีบุตรในระยะกี่ปี
                    </Typography>
                    <Typography style={body14400} sx={{ color: "#272E39" }}>
                      {patientUserProfile.yearTillChildIsExpected}
                    </Typography>
                  </Box>{" "}
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"1fr 5fr"}
                    sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                  >
                    <Typography style={body14400} sx={{ color: "#646D78" }}>
                      ต้องการมีบุตรทั้งหมดกี่คน
                    </Typography>
                    <Typography style={body14400} sx={{ color: "#272E39" }}>
                      {patientUserProfile.expectedNumberOfChild}
                    </Typography>
                  </Box>
                </>
              )}
            </>
          )}
          {patientUserProfile.sex === "female" && (
            <>
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 5fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  ในช่วง 6 เดือนที่ผ่านมาคุณมีประจำเดือนมาหรือไม่
                </Typography>
                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {patientUserProfile.stillHavingPeriod ? "ใช่" : "ไม่"}
                </Typography>
              </Box>
              {patientUserProfile.stillHavingPeriod && (
                <>
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"1fr 5fr"}
                    sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                  >
                    <Typography style={body14400} sx={{ color: "#646D78" }}>
                      รอบประจำเดือนมาห่างกันกี่วัน
                    </Typography>
                    <Typography style={body14400} sx={{ color: "#272E39" }}>
                      {patientUserProfile.periodGapDuration}
                    </Typography>
                  </Box>
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"1fr 5fr"}
                    sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                  >
                    <Typography style={body14400} sx={{ color: "#646D78" }}>
                      ประจำเดือนมาครั้งละกี่วัน
                    </Typography>
                    <Typography style={body14400} sx={{ color: "#272E39" }}>
                      {patientUserProfile.periodDuration}
                    </Typography>
                  </Box>
                </>
              )}
              <Box
                display={"grid"}
                gridTemplateColumns={"1fr 5fr"}
                sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
              >
                <Typography style={body14400} sx={{ color: "#646D78" }}>
                  คุณกำลังตั้งครรภ์หรือไม่
                </Typography>
                <Typography style={body14400} sx={{ color: "#272E39" }}>
                  {patientUserProfile.isPregnant ? "ใช่" : "ไม่"}
                </Typography>
              </Box>
              {patientUserProfile.isPregnant && (
                <>
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"1fr 5fr"}
                    sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                  >
                    <Typography style={body14400} sx={{ color: "#646D78" }}>
                      จำนวนครั้งที่ตั้งครรภ์
                    </Typography>
                    <Typography style={body14400} sx={{ color: "#272E39" }}>
                      {patientUserProfile.numberOfPregnancy}
                    </Typography>
                  </Box>
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"1fr 5fr"}
                    sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                  >
                    <Typography style={body14400} sx={{ color: "#646D78" }}>
                      ข้อมูลการตั้งครรภ์
                    </Typography>
                    <Stack direction={"row"} spacing={1}>
                      {patientUserProfile.pregnancyStatusList ? (
                        patientUserProfile.pregnancyStatusList.map(
                          (item, idx) => {
                            return (
                              <Box
                                key={`PN-${idx}`}
                                sx={{
                                  p: 1,
                                  bgcolor: "#DBDBDB",
                                  width: "fit-content",
                                  borderRadius: 1,
                                }}
                              >
                                <Typography
                                  fontSize={13}
                                  sx={{ color: "#272E39" }}
                                >
                                  {`${idx + 1}. ${
                                    item.deliverMethod
                                  } (ภาวะการแทรกซ้อน: ${item.complication})`}
                                </Typography>
                              </Box>
                            );
                          }
                        )
                      ) : (
                        <Typography style={body14400} sx={{ color: "#272E39" }}>
                          {`-`}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"1fr 5fr"}
                    sx={{ pb: 1, borderBottom: "1px solid #E1E3EA" }}
                  >
                    <Typography style={body14400} sx={{ color: "#646D78" }}>
                      จำนวนครั้งที่แท้ง
                    </Typography>
                    <Typography style={body14400} sx={{ color: "#272E39" }}>
                      {patientUserProfile.numberOfMiscarriage}
                    </Typography>
                  </Box>
                </>
              )}
            </>
          )}
        </Stack>
      </Paper>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#7A8688",
            width: "90px",
            height: "35px",
            marginRight: "10px",
          }}
          onClick={handleGobackClick}
        >
          ย้อนกลับ
        </Button>
      </Box>
      <ProfileDialog
        openDialog={openProfileDialog}
        handleCloseDialog={handleCloseProfileDialog}
        handleSubmit={submitForm}
      />
      <HealthInformationDialog
        openDialog={openHealthInformationDialog}
        handleCloseDialog={handleCloseHealthInformationDialog}
        handleSubmit={submitForm}
      />
      <ErrorDialog
        openDialog={openUpdaeProfileFailureDialog}
        handleCloseDialog={() => setOpenUpdaeProfileFailureDialog(false)}
        title="เกิดข้อผิดพลาด"
        content="เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
      />
    </Box>
  );
};

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import {
  CheckboxElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { useNavigate } from "react-router";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { EditIcon } from "../../icon";
import { Layout } from "../../Layout";
import { store } from "../../redux/store";
import { masterDataService } from "../../service/masterDataService";
import { MasterDataFiltering } from "../../shared/MasterDataFiltering";
import { labelForm, spacingForm, title16500 } from "../../style";
import { theme2 } from "../../theme";

export type LabItem = {
  id: number;
  name: string;
  measurementUnit: string;
  minimum: number;
  maximum: number;
  minimumWarning: boolean;
  maximumWarning: boolean;
};

type UpdateLabMasterPayload = {
  id: number;
  maximum: number;
  minimum: number;
  maximumWarning: boolean;
  minimumWarning: boolean;
};

const tableCellValues = [
  { name: "ลำดับ", width: "10%", align: "center" },
  { name: "ชื่อผลแลป", width: "30%", align: "left" },
  { name: "หน่วย", width: "20%", align: "center" },
  { name: "ค่ามาตรฐาน", width: "30%", align: "center" },
  { name: "เครื่องมือ", width: "10%", align: "center" },
];

export const LabMaster = () => {
  const navigate = useNavigate();
  const filterRef = useRef<HTMLDivElement>(null);
  const [labList, setLabList] = useState<LabItem[]>([]);
  const [filteredList, setFilteredList] = useState<LabItem[]>([]);
  const [currentLabItem, setLabItem] = useState<LabItem>();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPages] = useState(10);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  // const [deleteItemIndex, setDeleteItemIndex] = useState(0);
  // const [activeStatus, setActiveStatus] = useState(true);
  const [minimumValue, setMinimumValue] = useState(0);
  const [maximumValue, setMaximumValue] = useState(0);
  const [isFetchData, setIsFetchData] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [messageError, setMessageError] = useState(
    "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
  );

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const masterDataPermissionAddAble =
    userId === "1" ? true : userPermission?.masterDataPermission.addAble;
  const masterDataPermissionEditAble =
    userId === "1" ? true : userPermission?.masterDataPermission.editAble;
  const masterDataPermissionDeleteAble =
    userId === "1" ? true : userPermission?.masterDataPermission.deleteAble;

  const queryFunction = async () => {
    const response = await masterDataService.getLabMasterData();
    return response;
  };
  const {
    data: labData,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["labMasterData"],
    queryFn: queryFunction,
    enabled: false,
  });
  useEffect(() => {
    refetch();
  }, [isFetchData]);
  useEffect(() => {
    if (labData) {
      setLabList(labData);
      setFilteredList(labData);
    } else {
      setLabList([]);
      setFilteredList([]);
    }
  }, [labData]);
  const {
    mutateAsync: updateLabMasterAsync,
    isPending: IsPendingUpdateLabMaster,
  } = useMutation({
    mutationFn: async ({
      id,
      maximum,
      minimum,
      maximumWarning,
      minimumWarning,
    }: UpdateLabMasterPayload) => {
      const response = await masterDataService.updateLabMaster({
        id: id,
        maximum: maximum,
        minimum: minimum,
        maximumWarning: maximumWarning,
        minimumWarning: minimumWarning,
      });
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          setIsShowError(true);
        }
      }
    },
  });
  useEffect(() => {
    if (error && error instanceof AxiosError) {
      if (error.response?.status === 401) {
        setIsTokenExpired(true);
      } else {
        setIsShowError(true);
      }
    }
  }, [error]);
  const handlePageChange = (event: unknown, value: number) => {
    setPage(value);
  };
  const handleTextInputChange = (newValue: string) => {
    setSearchText(newValue);
  };
  const handleSearchClick = () => {
    const result = labList.filter((item) => {
      const matchesText = item.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
      // const matchesStatus = searchStatus ? item.status === searchStatus : true;
      return matchesText;
      // && matchesStatus;
    });
    setPage(1);
    setFilteredList(result);
  };
  const handleClearSearchClick = () => {
    setSearchText("");
    setFilteredList(labList);
  };
  // const handleActiveStatusChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setActiveStatus(event.target.checked);
  // };
  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };
  // const handleAddNewItem = (data: FieldValues) => {
  //   const newItem = {
  //     name: data.name,
  //     unit: data.unit,
  //     minimum: data.minimum,
  //     maximum: data.maximum,
  //     isMinimum: data.isMinimum,
  //     isMaximum: data.isMaximum,
  //     status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน",
  //   };
  //   setFilteredList([...labList, newItem]);
  //   labList.push(newItem);
  //   handleCloseDialog();
  // };
  // const handleDeleteClick = (index: number) => {
  //   setDeleteItemTemp(index);
  //   setOpenDeleteDialog(true);
  // };
  const handleEditClick = (data: LabItem) => {
    setOpenEditDialog(true);
    setLabItem(data);
    setMinimumValue(data.minimum ?? 0);
    setMaximumValue(data.maximum ?? 0);
    // setActiveStatus(currentLabItem?.status === "ใช้งานได้" ? true : false);
  };
  const handleEditItem = async (data: LabItem) => {
    // setLabList((prevList) =>
    //   prevList.map((item) =>
    //     item.name === currentLabItem?.name
    //       ? { ...data, status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน" }
    //       : item
    //   )
    // );
    // setFilteredList((prevList) =>
    //   prevList.map((item) =>
    //     item.name === currentLabItem?.name
    //       ? { ...data, status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน" }
    //       : item
    //   )
    // );
    if (currentLabItem) {
      await updateLabMasterAsync({
        id: currentLabItem.id,
        maximum: maximumValue,
        minimum: minimumValue,
        maximumWarning: data.maximumWarning,
        minimumWarning: data.minimumWarning,
      });
      setIsFetchData((prev) => !prev);
    }

    handleCloseDialog();
  };
  // const handleAddClick = () => {
  //   setActiveStatus(true);
  //   setOpenAddDialog(true);
  // };
  // const handleConfirmDeleteDialog = async () => {
  //   setLabList((prevList) => prevList.filter((_, i) => i !== deleteItemIndex));
  //   setFilteredList((prevList) =>
  //     prevList.filter((_, i) => i !== deleteItemIndex)
  //   );
  //   setOpenDeleteDialog(false);
  // };

  return (
    <Layout activeTab="setting">
      <ThemeProvider theme={theme2}>
        {" "}
        <Box sx={{ p: "20px", width: 1 }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              <Stack direction={"row"} gap={1}>
                <Box
                  onClick={() => navigate("/masterData")}
                  sx={{ cursor: "pointer" }}
                >
                  ตั้งค่าข้อมูลหลัก
                </Box>
                /
                <Typography
                  component={"span"}
                  color={"#7A8688"}
                  style={title16500}
                >
                  {`ผลแลป`}
                </Typography>
              </Stack>
            </Typography>
          </Box>
          <Box ref={filterRef}>
            <MasterDataFiltering
              title="ผลแลป"
              searchText={searchText}
              // searchStatus={searchStatus}
              handleSearchTextChange={handleTextInputChange}
              // handleSearchStatusChange={handleSearchStatusChange}
              handleSearchClick={handleSearchClick}
              handleClearSearchClick={handleClearSearchClick}
            />
          </Box>

          <Box
            sx={{
              bgcolor: "white",
              // height: boxHeight,
              mt: 2,
              overflow: "auto",
              scrollbarWidth: "thin",
              p: "20px",
              boxSizing: "border-box",
            }}
          >
            {/* <Button
              variant="contained"
              style={{
                backgroundColor: "#FF366B",
                float: "right",
                height: "40px",
                fontSize: "14px",
                marginBottom: "20px",
                minWidth: "90px",
              }}
              startIcon={<AddIcon />}
              onClick={handleAddClick}
            >
              เพิ่ม
            </Button> */}
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableCellValues.map((item, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            width: item.width,
                            textAlign: item.align,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {filteredList.length > 0 && (
                    <TableBody>
                      {filteredList
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ textAlign: "center" }}>
                              {(page - 1) * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {item.measurementUnit}
                            </TableCell>
                            <TableCell
                              sx={{ textAlign: "center" }}
                            >{`${item.minimum} - ${item.maximum}`}</TableCell>
                            {/* <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "5px",
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                      item.status === "ใช้งานได้"
                                        ? "green"
                                        : "red",
                                  }}
                                />
                                {item.status}
                              </div>
                            </TableCell> */}
                            <TableCell>
                              <Stack
                                spacing={1}
                                direction={"row"}
                                justifyContent={"center"}
                              >
                                <Box
                                  sx={{
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "secondary.main",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 14,
                                    cursor: masterDataPermissionEditAble
                                      ? "pointer"
                                      : "not-allowed",
                                    pointerEvents: masterDataPermissionEditAble
                                      ? "auto"
                                      : "none",
                                  }}
                                  onClick={() => {
                                    if (masterDataPermissionEditAble) {
                                      handleEditClick(item);
                                    }
                                  }}
                                >
                                  <EditIcon
                                    fontSize="inherit"
                                    color="secondary"
                                  />
                                </Box>
                                {/* <Box
                                  sx={{
                                    border: "1px solid #7A8688",
                                    borderRadius: 2,
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    color: "#7A8688",
                                    fontSize: 14,
                                  }}
                                  onClick={() => handleDeleteClick(index)}
                                >
                                  <DeleteIcon
                                    fontSize="inherit"
                                    color="inherit"
                                  />
                                </Box> */}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {filteredList.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 20,
                    color: "#9AA1AE",
                    py: 2,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "100px",
                  }}
                >
                  {isLoading ? (
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <CircularProgress size={20} />
                      {` กำลังโหลดข้อมูล...`}
                    </Box>
                  ) : (
                    "--ไม่พบข้อมูล--"
                  )}
                </Box>
              )}
            </Box>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                count={Math.ceil(filteredList.length / rowsPerPage)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                sx={{ mt: 2 }}
              />
            </div>
          </Box>
        </Box>
        {/* Add  Dialog */}
        {/* <Dialog open={openAddDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>เพิ่ม</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer onSuccess={(data) => handleAddNewItem(data)}>
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อผลแลป <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    หน่วย <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="unit"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>ค่ามาตรฐาน</Typography>
                  <Stack spacing={2} direction={"row"}>
                    <Stack spacing={1}>
                      <Typography color={"#000"} fontWeight={600}>
                        Minimum <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        name="minimum"
                        required
                        variant="outlined"
                        size="small"
                        type="number"
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <Typography color={"#000"} fontWeight={600}>
                        Maximum <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        name="maximum"
                        required
                        variant="outlined"
                        size="small"
                        type="number"
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <CheckboxElement
                    label="แสดงค่าน้อยกว่า Minimum เป็นค่าที่ผิดปกติ"
                    name="isMinimum"
                  />
                  <CheckboxElement
                    label="แสดงมากกว่า Maximum เป็นค่าที่ผิดปกติ"
                    name="isMaximum"
                  />
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ pt: 4 }}
                >
                  <Switch
                    checked={activeStatus}
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#098484", color: "white" }}
                  >
                    บันทึก
                  </Button>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog> */}
        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>แก้ไข</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer
              onSuccess={handleEditItem}
              defaultValues={{
                name: currentLabItem?.name,
                measurementUnit: currentLabItem?.measurementUnit,
                minimum: currentLabItem?.minimum ?? 0,
                maximum: currentLabItem?.maximum ?? 0,
                minimumWarning: currentLabItem?.minimumWarning,
                maximumWarning: currentLabItem?.maximumWarning,
              }}
            >
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อผลแลป <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                    disabled
                    InputProps={{
                      sx: {
                        width: 1,
                        bgcolor: "#E3E7F0",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#3C4142",
                        },
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    หน่วย <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="measurementUnit"
                    required
                    variant="outlined"
                    size="small"
                    disabled
                    InputProps={{
                      sx: {
                        width: 1,
                        bgcolor: "#E3E7F0",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#3C4142",
                        },
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>ค่ามาตรฐาน</Typography>
                  <Stack spacing={2} direction={"row"}>
                    <Stack spacing={1}>
                      <Typography color={"#000"} fontWeight={600}>
                        Minimum <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        name="minimum"
                        required
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: minimumValue.toLocaleString(),
                        }}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value <= maximumValue) {
                            setMinimumValue(value);
                          }
                        }}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <Typography color={"#000"} fontWeight={600}>
                        Maximum <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        name="maximum"
                        required
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: maximumValue.toLocaleString(),
                        }}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value >= minimumValue) {
                            setMaximumValue(value);
                          }
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <CheckboxElement
                    label="แสดงค่าน้อยกว่า Minimum เป็นค่าที่ผิดปกติ"
                    name="minimumWarning"
                  />
                  <CheckboxElement
                    label="แสดงมากกว่า Maximum เป็นค่าที่ผิดปกติ"
                    name="maximumWarning"
                  />
                </Stack>
                {/* <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={
                      currentLabItem?.status === "ใช้งานได้" ? true : false
                    }
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack> */}
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={IsPendingUpdateLabMaster}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Delete Dialog */}
        {/* <DeleteDialog
          openDialog={openDeleteDialog}
          title="ต้องการลบรายการใช่หรือไม่"
          content="คุณต้องการลบรายการ"
          content2="ต้องการดำเนินการต่อใช่หรือไม่?"
          handleCloseDialog={handleCloseDialog}
          handleCancelClick={handleCloseDialog}
          handleConfirmClick={handleConfirmDeleteDialog}
        /> */}
        {/* Failure Dialog */}
        <ErrorDialog
          openDialog={isShowError}
          handleCloseDialog={() => {
            setIsShowError(false);
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }}
          title="เกิดข้อผิดพลาด"
          content={messageError}
        />
        {/* Session Expired Dialog */}
        <ErrorDialog
          title="เซสชันหมดอายุ"
          content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
          openDialog={isTokenExpired}
          handleCloseDialog={() => {
            navigate("/");
            setIsTokenExpired(false);
          }}
        />
      </ThemeProvider>
    </Layout>
  );
};

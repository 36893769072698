import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { InvestigationUltrasoundDialog } from "./InvestigationUltrasoundDialog";
import { title10Tab } from "../../../style";
import { InvestigationRow } from "../../../components/InvestigateRow";
import { InvestigationResponse } from "./Investigation";
import { useEffectOnce } from "react-use";
import { visitDataService } from "../../../service/visitDataService";
import { store } from "../../../redux/store";

export type InvestigationUltrasound = {
  fattyLiver: string;
  gallstone: string;
  cirrhosis: string;
  ascites: string;
};

type Props = {
  handleSaveUltrasoundValues: (value: InvestigationUltrasound) => void;
  latestEntryId?: number | null;
  currentEntryId?: number | null;
};

export const InvestigationUltrasound = ({
  handleSaveUltrasoundValues,
  latestEntryId,
  currentEntryId,
}: Props) => {
  const [values, setValues] = useState<InvestigationUltrasound>({
    fattyLiver: "",
    gallstone: "",
    cirrhosis: "",
    ascites: "",
  });
  const [openUltrasoundDialog, setOpenUltrasoundDialog] = useState(false);
  const [ultrasoundIsDirty, setUltrasoundIsDirty] = useState(false);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  
  const handleClickUltrasound = () => {
    setOpenUltrasoundDialog(true);
  };

  const handleClickUltrasoundClose = (data: InvestigationUltrasound) => {
    setValues(data);
    setOpenUltrasoundDialog(false);
  };
  const handleClickUltrasoundSave = (data: InvestigationUltrasound) => {
    if (patientDataPermissionEditAble === false) {
      setOpenUltrasoundDialog(false);
      setUltrasoundIsDirty(false);
      return;
    }
    setValues(data);
    setOpenUltrasoundDialog(false);
    setUltrasoundIsDirty(true);
    handleSaveUltrasoundValues(data);
  };
  const UltrasoundList = [
    {
      title: "Fatty Liver",
      value: values.fattyLiver !== "" ? values.fattyLiver : "N/a",
    },
    {
      title: "Gallstone",
      value: values.gallstone !== "" ? values.gallstone : "N/a",
    },
    {
      title: "Cirrhosis",
      value: values.cirrhosis !== "" ? values.cirrhosis : "N/a",
    },
    {
      title: "Ascites",
      value: values.ascites !== "" ? values.ascites : "N/a",
    },
  ];
  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const investigationData: InvestigationResponse =
          await visitDataService.getInvestigationFromEntryId(currentEntryId);
        setValues({
          fattyLiver: investigationData.fattyLiver ? "yes" : "no",
          gallstone: investigationData.gallstone ? "yes" : "no",
          cirrhosis: investigationData.cirrhosis ? "yes" : "no",
          ascites: investigationData.ascites ? "yes" : "no",
        });
      } else if (latestEntryId) {
        const investigationData: InvestigationResponse =
          await visitDataService.getInvestigationFromEntryId(latestEntryId);
        setValues({
          fattyLiver: investigationData.fattyLiver ? "yes" : "no",
          gallstone: investigationData.gallstone ? "yes" : "no",
          cirrhosis: investigationData.cirrhosis ? "yes" : "no",
          ascites: investigationData.ascites ? "yes" : "no",
        });
      }
    };
    fetchData();
  });
  return (
    <Box width={"100%"} height={"100%"}>
      <Box width={"90%"} height={"100%"} sx={{ border: "2px solid #D8D8D8" }}>
        <Box
          sx={{
            px: 2,
            py: 1,
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={title10Tab}>Ultrasound</Typography>
          <Typography
            style={{
              color: "#646D78",
              cursor: "pointer",
            }}
            onClick={handleClickUltrasound}
          >
            แก้ไข
          </Typography>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          {UltrasoundList.map((ultrasound, index) => (
            <InvestigationRow
              key={index}
              title={ultrasound?.title ?? ""}
              value={
                currentEntryId || ultrasoundIsDirty
                  ? ultrasound?.value ?? undefined
                  : undefined
              }
            />
          ))}
        </Box>
      </Box>
      <InvestigationUltrasoundDialog
        open={openUltrasoundDialog}
        onClose={handleClickUltrasoundClose}
        onSave={handleClickUltrasoundSave}
        {...values}
      />
    </Box>
  );
};

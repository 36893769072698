import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { LateComplication, LateComplicationResponse } from "./LateComplication";

type Props = {
  open: boolean;
  handleCloseDialog: () => void;
};

const lateComplicationLabels: { [key in keyof LateComplication]: string } = {
  lateComplication: "Late complication",
  gerd: "GERD",
  hernia: "Hernia",
  marginalUlcer: "Marginal Ulcer",
  anastomosisStricture: "Anastomosis stricture",
  dumpingSyndrome: "Dumping syndrome",
  vte: "VTE",
  cardiovascularEvents: "Cardiovascular events (MI, stroke)",
  psychiatricProblems: "Psychiatric problems",
  osteoporosis: "Osteoporosis",
  other: "Other",
};
const gerdTypeLabels = [
  { value: 1, label: "De novo" },
  { value: 2, label: "Improve" },
  { value: 3, label: "Worsening" },
  { value: 0, label: "No" },
];
const herniaTypeLabels = [
  { value: 1, label: "Internal" },
  { value: 2, label: "Abdominal wall" },
  { value: 0, label: "No" },
];

export const LateComplicationHistoryDialog = ({
  open,
  handleCloseDialog,
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [lateComplicationHistory, setLateComplicationHistory] = useState<
    LateComplicationResponse[]
  >([]);
  const medAndNutritionalKeys = Object.keys(
    lateComplicationLabels
  ) as (keyof LateComplication)[];

  useEffect(() => {
    const fetchData = async () => {
      const patientId = store.getState().patientUser.patientUser?.id;
      if (patientId) {
        try {
          const lateComplicationHistory: LateComplicationResponse[] =
            await visitDataService.getLateComplicationHistory(patientId);
          if (lateComplicationHistory) {
            setLateComplicationHistory(lateComplicationHistory);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [open]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={"lg"}>
      <DialogTitle>Visit History</DialogTitle>
      <DialogContent dividers sx={{ overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            maxHeight: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
          }}
        >
          <Table stickyHeader sx={{ maxHeight: 500, overflowY: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    width: 250,
                    bgcolor: "white",
                    color: "secondary.main",
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "1px solid #E5EBEB",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  Visit Date
                </TableCell>

                {lateComplicationHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableCell
                      key={index}
                      variant="head"
                      sx={{
                        minWidth: 100,
                        bgcolor: "white",
                        fontWeight: 700,
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      {dayjs(entry.visitDate).format("DD/MM/BBBB")}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {medAndNutritionalKeys.map((key) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      textAlign: "center",
                      borderRight: "1px solid #E5EBEB",
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      bgcolor: "white",
                    }}
                  >
                    {lateComplicationLabels[key]}
                  </TableCell>

                  {lateComplicationHistory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((entry, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          alignContent: "baseline",
                        }}
                      >
                        <Box
                          display={"grid"}
                          gridTemplateColumns={"1fr 1fr"}
                          gap={2}
                        >
                          <Typography
                            sx={{
                              textAlign: "right",
                              fontSize: 14,
                              color: "#646D78",
                            }}
                          >
                            {lateComplicationLabels[key]}
                          </Typography>
                          <Typography sx={{ textAlign: "left", fontSize: 14 }}>
                            {entry[key as keyof LateComplicationResponse]
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Box>
                        {key === "gerd" && entry[key] && (
                          <Box
                            display={"grid"}
                            gridTemplateColumns={"1fr 1fr"}
                            gap={2}
                          >
                            <Typography
                              sx={{
                                textAlign: "right",
                                fontSize: 14,
                                color: "#646D78",
                              }}
                            >
                              ประเภท
                            </Typography>
                            <Typography
                              sx={{ textAlign: "left", fontSize: 14 }}
                            >
                              {key === "gerd" && entry[key] ? (
                                <Typography>
                                  {
                                    gerdTypeLabels.find(
                                      (labelObj) =>
                                        labelObj.value === entry.gerdType
                                    )?.label
                                  }
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Box>
                        )}
                        {key === "hernia" && entry[key] && (
                          <Box
                            display={"grid"}
                            gridTemplateColumns={"1fr 1fr"}
                            gap={2}
                          >
                            <Typography
                              sx={{
                                textAlign: "right",
                                fontSize: 14,
                                color: "#646D78",
                              }}
                            >
                              ประเภท
                            </Typography>
                            <Typography
                              sx={{ textAlign: "left", fontSize: 14 }}
                            >
                              {key === "hernia" && entry[key] ? (
                                <Typography>
                                  {
                                    herniaTypeLabels.find(
                                      (labelObj) =>
                                        labelObj.value === entry.herniaType
                                    )?.label
                                  }
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Box>
                        )}
                        {key === "other" && entry[key] && (
                          <Box
                            display={"grid"}
                            gridTemplateColumns={"1fr 1fr"}
                            gap={2}
                          >
                            <Typography
                              sx={{
                                textAlign: "right",
                                fontSize: 14,
                                color: "#646D78",
                              }}
                            >
                              ระบุข้อมูลเพิ่มเติม
                            </Typography>
                            <Typography
                              sx={{ textAlign: "left", fontSize: 14 }}
                            >
                              {key === "other" && entry[key] ? (
                                <Typography>
                                  {entry.otherText.join(", ")}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={lateComplicationHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, FormHelperText, InputLabel, Select, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/material/styles";
import { ChangeEvent, useEffect, useState } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useEffectOnce } from "react-use";
import { updatePatientUserProfile } from "../../../src/redux/features/patientSlice";
import { useAppDispatch } from "../../../src/redux/hook";
import { profileTitle } from "../../../src/style";
import { theme2 } from "../../../src/theme";
import { MasterData, UpdateUserDetail } from "../../../src/type";
import { getPrefix } from "../../../src/utils/get-data/getPrefix";
import { UserDetail } from "../../../src/Pages/UserProfile/UserProfile";
import { set } from "date-fns";
import { setUserDetail } from "../../redux/features/loginUserSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
type Props = {
  userDetail: UserDetail;
  openDialog: boolean;
  handleCloseDialog: () => void;
  handleSubmit: (data: any) => void;
};
export default function EditUserProfileDialog({
  userDetail,
  openDialog,
  handleCloseDialog,
  handleSubmit,
}: Props) {
  const dispatch = useAppDispatch();
  const [prefixList, setPrefixList] = useState<MasterData[]>();
  const [userDetailState, setUserDetailState] = useState<UserDetail>({
    title: userDetail.title,
    firstName: userDetail.firstName,
    lastName: userDetail.lastName,
    nationalId: userDetail.nationalId,
    phoneNumber: userDetail.phoneNumber,
    phoneNumberExtension: userDetail.phoneNumberExtension,
    mobilePhoneNumber: userDetail.mobilePhoneNumber,
    affiliatedHospital: userDetail.affiliatedHospital,
    medicalLicenseNumber: userDetail.medicalLicenseNumber,
    jobPosition: userDetail.jobPosition,
    siteRoleId: userDetail.siteRoleId,
    username: userDetail.username,
    registrationDate: userDetail.registrationDate,
    approvalDate: userDetail.approvalDate,
    approveByUserId: userDetail.approveByUserId,
    email: userDetail.email,
    permission: userDetail.permission,
  });
  const [updateUserDetail, setUpdateUserDetail] = useState<UpdateUserDetail>({
    email: "",
    title: "",
    firstName: "",
    lastName: "",
    nationalId: "",
    phoneNumber: "",
    phoneNumberExtension: "",
    mobilePhoneNumber: "",
    affiliatedHospitalId: null,
    medicalLicenseNumber: "",
    jobPosition: "",
    permissionId: 0,
    userRestricted: false,
  });

  const nameTitleList = [
    {
      value: "นาย",
      label: "นาย",
    },
    {
      value: "นาง",
      label: "นาง",
    },
    {
      value: "นางสาว",
      label: "นางสาว",
    },
  ];

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const prefixList = await getPrefix();
        setPrefixList(prefixList);
        console.log("userDetail ==", userDetail);

        console.log("updateUserDetail ==", updateUserDetail);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
  });

  useEffect(() => {
    setUserDetailState(userDetail);
    setUpdateUserDetail({
      email: userDetail.email,
      title: userDetail.title,
      firstName: userDetail.firstName,
      lastName: userDetail.lastName,
      nationalId: userDetail.nationalId,
      phoneNumber: userDetail.phoneNumber,
      phoneNumberExtension: userDetail.phoneNumberExtension,
      mobilePhoneNumber: userDetail.mobilePhoneNumber,
      affiliatedHospitalId: null,
      medicalLicenseNumber: userDetail.medicalLicenseNumber,
      jobPosition: userDetail.jobPosition,
      permissionId: 0,
      userRestricted: false,
    });
    validateMobilePhoneNumber(userDetail.mobilePhoneNumber);
    validateNationalId(userDetail.nationalId);
  }, [userDetail, openDialog, handleCloseDialog]);

  const handlePrefixChange = (value: string) => {
    setUpdateUserDetail({ ...updateUserDetail, title: value });
    // dispatch(updatePatientUserProfile({ prefix: value }));
  };
  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdateUserDetail({ ...updateUserDetail, firstName: event.target.value });
    // setUserDetailState({ ...userDetailState, firstName: event.target.value });
    // dispatch(updatePatientUserProfile({ firstName: event.target.value }));
  };
  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdateUserDetail({ ...updateUserDetail, lastName: event.target.value });
    // setUserDetailState({ ...userDetailState, lastName: event.target.value });
    // dispatch(updatePatientUserProfile({ lastName: event.target.value }));
  };

  const handleNationIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    validateNationalId(event.target.value);
    setUpdateUserDetail({
      ...updateUserDetail,
      nationalId: event.target.value,
    });
    // setUserDetailState({ ...userDetailState, nationalId: event.target.value });
    // dispatch(updatePatientUserProfile({ nationalId: event.target.value }));
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdateUserDetail({
      ...updateUserDetail,
      phoneNumber: event.target.value,
    });
    // setUserDetailState({ ...userDetailState, phoneNumber: event.target.value });
    // dispatch(updatePatientUserProfile({ phoneNumber: event.target.value
  };

  const handleExtensionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdateUserDetail({
      ...updateUserDetail,
      phoneNumberExtension: event.target.value,
    });
    // setUserDetailState({ ...userDetailState, phoneNumberExtension: event.target.value });
    // dispatch(updatePatientUserProfile({ phoneNumberExtension: event.target.value }));
  };

  const handleMedicalLicenseNumberChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setUpdateUserDetail({
      ...updateUserDetail,
      medicalLicenseNumber: event.target.value,
    });
  };
  const [isValid, setIsValid] = useState(false);
  const [errorMobilePhone, setErrorMobilePhone] = useState('');
  const handleMobilePhoneNumberChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    validateMobilePhoneNumber(event.target.value);
    setUpdateUserDetail({
      ...updateUserDetail,
      mobilePhoneNumber: event.target.value,
    });
  };
  const validateMobilePhoneNumber = (value: any) => {
    if (!/^\d{10}$/.test(value) || value === '') {
      setErrorMobilePhone('เบอร์มือถือต้องเป็นตัวเลข 10 หลัก');
      setIsValid(false);
    } else {
      setErrorMobilePhone('');
      setIsValid(true);
    }
  }
  const [error, setError] = useState('');
  const validateNationalId = (value : any) => {
    if (!/^\d{13}$/.test(value) || value === '') {
      setError('เลขบัตรประชาชนต้องเป็นตัวเลข 13 หลัก');
      setIsValid(false);
    } else {
      setError('');
      setIsValid(true);
    }
  };

  return (
    <ThemeProvider theme={theme2}>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="profile-dialog-title"
        open={openDialog}
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, py: 1, px: 2, bgcolor: "#F7F8FC" }}
          id="profile-dialog-title"
        >
          <Typography fontSize={18} fontWeight={500}>
            รายละเอียด
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FormContainer
            defaultValues={
              userDetail && {
                title: userDetail.title,
                email: userDetail.email,
                firstName: userDetailState.firstName,
                lastName: userDetailState.lastName,
                nationalId: userDetailState.nationalId,
                medicalLicenseNumber: userDetail.medicalLicenseNumber,
                mobilePhoneNumber: userDetail.mobilePhoneNumber,
                phoneNumber: userDetail.phoneNumber,
                phoneNumberExtension: userDetail.phoneNumberExtension,
                affiliatedHospital: userDetail.affiliatedHospital,
                jobPosition: userDetail.jobPosition,
                siteRoleId: userDetail.siteRoleId,
                registrationDate: userDetail.registrationDate,
                approvalDate: userDetail.approvalDate,
                approveByUserId: userDetail.approveByUserId,
                permission: userDetail.permission,
              }
            }
            onSuccess={(data) => {
              handleSubmit(data);
              handleCloseDialog();
            }}
          >
            <Stack spacing={3} sx={{ pb: 6 }}>
              <Typography component="label" color={"#646D78"}>
                กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Stack>
                <Typography component="label" style={profileTitle}>
                  คำนำหน้า <span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectElement
                  required
                  name="title"
                  size="small"
                  sx={{ width: 1 }}
                  onChange={(e) => handlePrefixChange(e)}
                  SelectProps={{
                    value: updateUserDetail?.title,
                    displayEmpty: true,
                    sx: {
                      fontSize: 14,
                    },
                  }}
                  options={[
                    {
                      id: "",
                      label: "โปรดระบุ",
                      disabled: true,
                    },
                    ...(nameTitleList
                      ? nameTitleList.map((item) => ({
                          id: item.value,
                          label: item.label,
                        }))
                      : []),
                  ]}
                />
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack>
                  <Typography component="label" style={profileTitle}>
                    ชื่อ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name={"firstName"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handleFirstNameChange}
                    InputProps={{
                      value: updateUserDetail?.firstName,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    error={!updateUserDetail?.firstName}
                  />
                </Stack>
                <Stack>
                  <Typography component="label" style={profileTitle}>
                    นามสกุล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name={"lastName"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handleLastNameChange}
                    InputProps={{
                      value: updateUserDetail?.lastName,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    error={!updateUserDetail?.lastName}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack>
                  <Typography component="label" style={profileTitle}>
                    เลขบัตรประชาชน <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name={"nationalId"}
                    size="small"
                    sx={{
                      width: 1,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: error ? "red" : "default",
                        },
                        "&:hover fieldset": {
                          borderColor: error ? "red" : "default",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: error ? "red" : "default",
                        },
                      },
                    }}
                    placeholder="โปรดระบุ"
                    onChange={handleNationIdChange}
                    InputProps={{
                      value: updateUserDetail?.nationalId,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    error={!!error}
                  />
                  {error && <FormHelperText error>{error}</FormHelperText>}
                </Stack>
                <Stack>
                  <Typography component="label" style={profileTitle}>
                    เลขที่ใบอนุญาตประกอบวิชาชีพ{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name={"medicalLicenseNumber"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handleMedicalLicenseNumberChange}
                    InputProps={{
                      value: updateUserDetail?.medicalLicenseNumber,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    error={!updateUserDetail?.medicalLicenseNumber}
                  />
                </Stack>
              </Stack>
              <Stack>
                <Typography component="label" style={profileTitle}>
                  เบอร์มือถือ <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextFieldElement
                  required
                  name={"mobilePhoneNumber"}
                  size="small"
                  sx={{
                    width: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: errorMobilePhone ? "red" : "default",
                      },
                      "&:hover fieldset": {
                        borderColor: errorMobilePhone ? "red" : "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: errorMobilePhone ? "red" : "default",
                      },
                    },
                  }}
                  placeholder="โปรดระบุ"
                  onChange={handleMobilePhoneNumberChange}
                  InputProps={{
                    value: updateUserDetail?.mobilePhoneNumber,
                    sx: {
                      fontSize: 14,
                      color: "#212121",
                    },
                  }}
                  error={!!errorMobilePhone}
                />
                {errorMobilePhone && <FormHelperText error>{errorMobilePhone}</FormHelperText>}
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack>
                  <Typography component="label" style={profileTitle}>
                    เบอร์โทรศัพท์
                  </Typography>
                  <TextFieldElement
                    name={"phoneNumber"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handlePhoneNumberChange}
                    InputProps={{
                      value: updateUserDetail?.phoneNumber,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography component="label" style={profileTitle}>
                    Ext.
                  </Typography>
                  <TextFieldElement
                    name={"phoneNumberExtension"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handleExtensionChange}
                    InputProps={{
                      value: updateUserDetail?.phoneNumberExtension,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                bgcolor: "white",
                height: "45px",
                display: "flex",
                justifyContent: "flex-end",
                width: "95%",
                pt: 1,
                borderTop: "1px solid #E3E7F0",
              }}
            >
              <Stack direction={"row"} spacing={1} height={35} sx={{ pr: 2 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleCloseDialog}
                  sx={{
                    width: 100,
                    bgcolor: "#7A8688",
                    "&:hover": {
                      bgcolor: "#7A8688",
                    },
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  autoFocus
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    width: 100,
                    bgcolor: "#098484",
                    "&:hover": {
                      bgcolor: "#098484",
                    },
                  }}
                  disabled={!!error || !!errorMobilePhone}
                >
                  บันทึก
                </Button>
              </Stack>
            </Box>
          </FormContainer>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  );
}

import { ExerciseItem, FoodItem } from "../type";

export const getTotalCalorieExercise = (exercise: ExerciseItem[]) => {
  const totalKcal = exercise?.reduce((total, ex) => total + ex.kiloCalorie, 0);
  return isNaN(totalKcal) ? 0 : Number(totalKcal.toFixed(2));
};
export const getTotalCalorieFood = (food: FoodItem[]) => {
  const totalKcal = food?.reduce((total, f) => total + f.kcal, 0);
  return isNaN(totalKcal) ? 0 : Number(totalKcal.toFixed(2));
};

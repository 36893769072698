import axios from "axios";

const BASE_URL = "https://service.obesityconnects.com/v1/masterdata";

export const masterDataService = {
  getFoodMasterData: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/food/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getFoodMasterDataDetail: async function (foodId: number) {
    try {
      const config = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      const response = await axios.get(
        `${BASE_URL}/food/manage/${foodId}`,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createFoodMaster: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/food/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createServing: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/food/serving/create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateFoodMaster: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(`${BASE_URL}/food/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateServing: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(
        `${BASE_URL}/food/serving/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteFoodMaster: async function (foodId: string) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(`${BASE_URL}/food/delete`, {
        data: {
          foodId: foodId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteServing: async function (servingId: string) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(`${BASE_URL}/food/serving/delete`, {
        data: {
          servingId: servingId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getHospitalMasterData: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/hospital/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createHospitalMaster: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/hospital/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateHospitalMaster: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(`${BASE_URL}/hospital/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateHospitalOrderMaster: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(`${BASE_URL}/hospital/order`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteHospitalMaster: async function (id: string) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(`${BASE_URL}/hospital/delete`, {
        data: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getLabMasterData: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/lab/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateLabMaster: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(`${BASE_URL}/lab/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getCustomFieldMasterData: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/customfield/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getCustomFieldMasterDataForMapping: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/customfield/fetch`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createCustomFieldMasterData: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/customfield/create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateCustomFieldMasterData: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(`${BASE_URL}/customfield/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteCustomFieldMasterData: async function (id: string) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(`${BASE_URL}/customfield/delete`, {
        data: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getComplicationMasterData: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/complication/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getActiveComplicationMasterData: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/complication/fetch`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createComplicationMasterData: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/complication/create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateComplicationMasterData: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(
        `${BASE_URL}/complication/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteComplicationMasterData: async function (id: string) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(`${BASE_URL}/complication/delete`, {
        data: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getContraceptionMasterData: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/contraception/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createContraceptionMasterData: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/contraception/create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateContraceptionMasterData: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(
        `${BASE_URL}/contraception/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteContraceptionMasterData: async function (id: string) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(`${BASE_URL}/contraception/delete`, {
        data: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getFaqMasterData: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/faq/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createFaqMasterData: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/faq/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateFaqMasterData: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(`${BASE_URL}/faq/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateFaqOrderMaster: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(`${BASE_URL}/faq/order`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteFaqMasterData: async function (id: string) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(`${BASE_URL}/faq/delete`, {
        data: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

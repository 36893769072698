import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import emptySrc from "../asset/empty.png";
import { ErrorDialog } from "../components/dialog/ErrorDialog";
import { LoadingComponent } from "../components/LoadingComponent";
import { DoubleArrowRightIcon } from "../icon";
import { Layout } from "../Layout";
import {
  setPatientUser,
  setPatientUserProfile,
} from "../redux/features/patientSlice";
import { useAppDispatch } from "../redux/hook";
import { userManagementService } from "../service/userManagementService";
import { PatientDashboard } from "../shared/patient-detail/PatientDashboard";
import { PatientFilterTab } from "../shared/patient-detail/PatientFilterTab";
import { title16500 } from "../style";
import { PatientUser, PatientUserProfile } from "../type";
import { getPatientList } from "../utils/get-data/getPatientList";
import { DoctorDetail } from "./DoctorDetail";
import "./PatientDetail.css";

export const PatientDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [patientList, setPatientList] = useState<PatientUser[]>();
  const [selectedTab, setSelectedTab] = useState("Patient");
  const [selectedPatientUserProfile, setSelectedPatientUserProfile] = useState<
    PatientUserProfile | undefined
  >(undefined);
  const [selectedPatientUser, setSelectedPatientUser] = useState<
    PatientUser | undefined
  >(undefined);
  const [isHistory, setIsHistory] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [selectedHistoryDate, setSelectedHistoryDate] = useState("");
  const [isHideFilterTab, setIsHideFilterTab] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const patientList = await getPatientList();
        setPatientList(patientList);
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
          console.log("Error fetching List", error);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData();
  });

  const getPatientUserDetail = async (id: number) => {
    try {
      const response = await userManagementService.getPatientUserDetail(
        id.toString()
      );
      return response as PatientUserProfile;
    } catch (error: any) {
      if (error.response.status === 401) {
        setIsTokenExpired(true);
        console.log("Error fetching List", error);
      } else {
        console.log("Error fetching List", error);
      }
    }
  };
  const handleHistoryClick = (selectedDate: string) => {
    if (selectedDate !== "") {
      setIsHistory(true);
      setSelectedHistoryDate(selectedDate);
    }
  };
  const handelViewProfile = () => {
    if (isHistory) {
      setIsHistory(false);
      setIsProfile(true);
    } else {
      setIsProfile(true);
    }
  };
  const handlePatientClick = async (patient: PatientUser) => {
    setIsHistory(false);
    setIsProfile(false);
    setSelectedHistoryDate("");
    setSelectedPatientUser(patient);
    setSelectedTab("Patient");
    dispatch(setPatientUser(patient));
    try {
      const selectedPatient = await getPatientUserDetail(patient.id);
      setSelectedPatientUserProfile(selectedPatient);
      if (selectedPatient) {
        dispatch(setPatientUserProfile(selectedPatient));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleHideFilterTab = () => {
    setIsHideFilterTab(!isHideFilterTab);
  };
  const handleGobackClick = () => {
    setIsHistory(false);
    setIsProfile(false);
  };

  return (
    <Layout activeTab="patient">
      {patientList ? (
        <Box
          sx={{
            // height: `calc(${dimensions.adjustedHeight}px + 70px)`, minWidth: "100%",
            height: "inherit",
            minWidth: "100%",
            p: 2,
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              // width: "calc(100% - 143px)",
              width: 1,
              height: 1,
              display: patientList ? "flex" : "none",
              overflow: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <Stack
              display={isHideFilterTab ? "flex" : "none"}
              spacing={1}
              sx={{ minWidth: 90 }}
            >
              <Typography style={title16500}>ข้อมูลผู้ป่วย</Typography>
              <Stack spacing={1} direction={"row"}>
                <Typography style={title16500}>ค้นหา</Typography>
                <Box
                  sx={{
                    bgcolor: "white",
                    border: "1px solid #646D78",
                    borderRadius: 1 / 2,
                    width: "fit-content",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleHideFilterTab}
                >
                  <DoubleArrowRightIcon
                    fontSize="small"
                    sx={{ color: "#646D78" }}
                  />
                </Box>
              </Stack>
            </Stack>
            <div
              style={{
                display: isHideFilterTab ? "none" : "flex",
                minWidth: 350,
                maxWidth: 350,
                background: "inherit",
                border: 0,
                position: "relative",
                zIndex: 900,
                gap: 16,
                paddingRight: 8,
              }}
            >
              {patientList && (
                <PatientFilterTab
                  patientList={patientList}
                  currentPatient={selectedPatientUser}
                  handlePatientClick={handlePatientClick}
                  handleHideFilterTab={handleHideFilterTab}
                />
              )}
            </div>
            <Box
              sx={{
                ml: 1,
                minHeight: "100%",
                width: "100%",
              }}
            >
              <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} gap={1}>
                <Button
                  onClick={() => setSelectedTab("Patient")}
                  sx={{
                    bgcolor: selectedTab === "Patient" ? "white" : "#F2F2F2",
                    color: "rgba(0,0,0,0.8)",
                    fontWeight: selectedTab === "Patient" ? 600 : 400,
                    textAlign: "center",
                  }}
                >
                  Patient
                </Button>
                <Button
                  onClick={() => setSelectedTab("Doctor")}
                  sx={{
                    bgcolor: selectedTab === "Doctor" ? "white" : "#F2F2F2",
                    color: "rgba(0,0,0,0.8)",
                    fontWeight: selectedTab === "Doctor" ? 600 : 400,
                    textAlign: "center",
                  }}
                >
                  Doctor
                </Button>
              </Box>
              <Box
                sx={{
                  bgcolor: "white",
                  mt: 1,
                  height: "calc(100vh + 10px)",
                  // width: "100%",
                  // overflow: "auto",
                }}
              >
                {selectedTab === "Patient" &&
                  selectedPatientUser &&
                  selectedPatientUserProfile && (
                    <PatientDashboard
                      patientUser={selectedPatientUser}
                      patientUserProfile={selectedPatientUserProfile}
                      isHistory={isHistory}
                      isProfile={isProfile}
                      selectedHistoryDate={selectedHistoryDate}
                      handleHistoryClick={handleHistoryClick}
                      handelViewProfile={handelViewProfile}
                      handleGobackClick={handleGobackClick}
                    />
                  )}
                {selectedTab === "Patient" &&
                  selectedPatientUserProfile === undefined && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 4,
                        height: "calc(80vh + 30px)",
                      }}
                    >
                      <img src={emptySrc} alt="empty" width={200} />
                      <Typography
                        fontSize={20}
                        fontWeight={500}
                        color={"#6C757D"}
                      >
                        --กรุณาเลือกผู้ป่วยที่ต้องการดูข้อมูล--
                      </Typography>
                    </Box>
                  )}
                {selectedTab === "Doctor" &&
                  selectedPatientUserProfile &&
                  selectedPatientUser && (
                    <DoctorDetail
                      patientUser={selectedPatientUser}
                      selectedPatientUserProfile={selectedPatientUserProfile}
                    />
                  )}
                {selectedTab === "Doctor" &&
                  selectedPatientUserProfile === undefined && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 4,
                        height: "calc(80vh + 30px)",
                      }}
                    >
                      <img src={emptySrc} alt="empty" width={200} />
                      <Typography
                        fontSize={20}
                        fontWeight={500}
                        color={"#6C757D"}
                      >
                        --กรุณาเลือกผู้ป่วยที่ต้องการดูข้อมูล--
                      </Typography>
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <LoadingComponent />
      )}
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
    </Layout>
  );
};

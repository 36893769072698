import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import HeaderMenuBar from "../HeaderMenuBar";
import loginLogo from "../asset/resetpassword.png";
import { ArrowBackIcon } from "../icon";
import "./LoginScreen.css";

const ResetPasswordScreenComplete = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <Box className="reset-passsword-container">
        <HeaderMenuBar />
        <Box
          sx={{
            mt: mediumScreen ? "80px" : "110px",
            height: mediumScreen ? "65vh" : "80vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="sm">
            <Box
              sx={{
                bgcolor: "white",
                padding: "30px 80px",
                borderRadius: 2,
              }}
            >
              <Stack spacing={3} width={1} alignItems={"center"}>
                <Typography fontSize={24} fontWeight={500} color={"primary"}>
                  ตั้งค่ารหัสผ่านใหม่เรียบร้อยแล้ว
                </Typography>
                <img src={loginLogo} />
                <Typography fontSize={16} color={"#212121"}>
                  กรุณาเข้าสู่ระบบอีกครั้ง ด้วยรหัสที่ทำการตั้งค่าเมื่อสักครู่
                </Typography>{" "}
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    gap: 1,
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/")}
                >
                  <ArrowBackIcon color="primary" />
                  <Typography fontSize={14} fontWeight={700} color={"primary"}>
                    กลับไปยังหน้าเข้าสู่ระบบ
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
      </Box>
      <div className="reset-passsword-container">
        <Box
          sx={{
            mt: "128px",
            height: "50%",
            maxHeight: "640px",
            overflow: "auto",
          }}
        ></Box>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPasswordScreenComplete;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  TextField,
  RadioGroup,
  Divider,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import infoIcon from "../../../asset/icn.png";
import { useEffect, useState } from "react";
import {
  BariaticSurgeryData,
  EarlyComplicationType,
  OperationLocation,
} from "../../../type";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { visitDataService } from "../../../service/visitDataService";
import React from "react";
import { updateTabsIsDirty } from "../../../redux/features/patientSlice";
import { useAppDispatch } from "../../../redux/hook";
import { SubstitutionType } from "typescript";

interface BariatricSurgeryDialog {
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  currentEntryId: number | null | undefined;
  selectedEntryId: number | null;
  newBariatricSurgeryData: BariaticSurgeryData | null;
}

export const BariatricSurgeryDialog: React.FC<BariatricSurgeryDialog> = ({
  open,
  onClose,
  onSave,
  currentEntryId,
  selectedEntryId,
  newBariatricSurgeryData,
}) => {
  const dispatch = useAppDispatch();
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
  const [operationLocationTemp, setOperationLocationTemp] = useState<
    OperationLocation[]
  >([]);
  const [bariatricSurgeryData, setBariatricSurgeryData] =
    useState<BariaticSurgeryData>({
      entryId: null,
      editorId: null,
      createDateTime: "",
      visitDate: "",
      visitDataStatus: null,
      surgeryDate: "",
      operationTime: null,
      acuteWeightLoss: false,
      acuteWeightLossType: "",
      acuteWeightLossDay: null,
      preAwlWeight: null,
      postAwlWeight: null,
      surgicalTechnique: "",
      operationType: "",
      operationTypeOtherText: "",
      operationLocation: [],
      bougieSize: "",
      bpLimb: "",
      rouxLimb: "",
      ebl: "",
      subsequentOperation: {
        laparoscopicCholecystectomy: false,
        laparoscopicHiatalHerniaRepair: false,
        other: false,
      },
      subsequentOperationOther: [],
      earlyComplication: false,
      earlyComplicationType: {
        leakage: false,
        surgicalSiteInfection: false,
        obstruction: false,
        cardiovascularEvents: false,
        bleeding: false,
        pulmonaryComplication: false,
        vte: false,
        other: false,
      },
      earlyComplicationTypeOtherText: "",
      treatment: [],
      reOperation: false,
    });

  const surgericalTypeList1 = [
    "Roux-en-Y gastric bypass (RYGB)",
    "Sleeve-DJB (Roux-en-Y)",
    "Sleeve-PJB",
    "Gastric Banding",
    "Endoscopic Sleeve Gastroplasty",
  ];

  const surgericalTypeList2 = [
    "Sleeve Gastrectomy (SG)",
    "Sleeve-DJB (Loop)",
    "SADI-S",
    "Intragastric Balloon",
    "อื่นๆ",
  ];

  const sizeList = [
    { label: "Bougie size", value: "bougieSize", icon: infoIcon },
    { label: "BP limb (cm)", value: "bpLimb", icon: infoIcon },
    { label: "Roux limb (cm)", value: "rouxLimb", icon: infoIcon },
    { label: "EBL(mL)", value: "ebl", icon: infoIcon, required: true },
  ];

  const subsequentOperationList = [
    {
      label: "Laparoscopic cholecystectomy",
      value: "laparoscopicCholecystectomy",
    },
    {
      label: "Laparoscopic hiatal hernia repair",
      value: "laparoscopicHiatalHerniaRepair",
    },
    {
      label: "Other",
      value: "other",
    },
  ];
  const positionOfSurgerry = [
    {
      surgicalType: "Roux-en-Y gastric bypass (RYGB)",
      position: [
        {
          id: 1,
          label: "GJ anastomosis",
        },
        {
          id: 2,
          label: "JJ anastomosis",
        },
      ],
    },
    {
      surgicalType: "Sleeve-DJB (Roux-en-Y)",
      position: [
        {
          id: 1,
          label: "DJ anastomosis",
        },
        {
          id: 2,
          label: "JJ anastomosis",
        },
      ],
    },
    {
      surgicalType: "Sleeve-DJB (Loop)",
      position: [
        {
          id: 1,
          label: "DJ anastomosis",
        },
      ],
    },
    {
      surgicalType: "SADI-S",
      position: [
        {
          id: 1,
          label: "DJ anastomosis",
        },
      ],
    },
    {
      surgicalType: "Intragastric Balloon",
      position: [
        {
          id: 1,
          label: "DJ anastomosis",
        },
      ],
    },
    {
      surgicalType: "Sleeve Gastrectomy (SG)",
      position: null,
    },
    {
      surgicalType: "Sleeve-PJB",
      position: [
        {
          id: 1,
          label: "JJ amastomosis",
        },
      ],
    },
    {
      surgicalType: "Gastric Banding",
      position: null,
    },
    {
      surgicalType: "Endoscopic Sleeve Gastroplasty",
      position: null,
    },
    {
      surgicalType: "others",
      position: null,
    },
  ];

  const techniqueList = [
    { label: "Linear", value: "Linear" },
    { label: "Hand sewn", value: "Hand sewn" },
    { label: "Circular", value: "Circular" },
  ];

  const positionOfSurgerryTable = ["ลำดับ", "ตำแหน่ง", "Technique"];

  const earlyComplicationsTypeList = [
    {
      label: "Leakage",
      value: "leakage",
    },
    {
      label: "Surgical Site Infection",
      value: "surgicalSiteInfection",
    },
    {
      label: "Obstruction",
      value: "obstruction",
    },
    {
      label: "Cardiovascular Events",
      value: "cardiovascularEvents",
    },
  ];

  const earlyComplicationsTypeList2 = [
    {
      label: "Bleeding",
      value: "bleeding",
    },
    {
      label: "Pulmonary Complication",
      value: "pulmonaryComplication",
    },
    {
      label: "VTE",
      value: "vte",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBariatricSurgeryData({
      ...bariatricSurgeryData,
      [event.target.name]: event.target.value,
    });
  };

  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const handleDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setBariatricSurgeryData((prevState) => ({
      ...prevState,
      surgeryDate: formattedDate,
    }));
    setSelectedDate(formattedDate);
  };
  const handleClickSave = () => {
    setIsSaveClicked(true);
    if (
      bariatricSurgeryData.surgeryDate !== "" &&
      bariatricSurgeryData.operationTime !== null &&
      (bariatricSurgeryData.acuteWeightLoss === true
        ? !!bariatricSurgeryData.preAwlWeight &&
          !!bariatricSurgeryData.postAwlWeight &&
          bariatricSurgeryData.acuteWeightLossType !== null &&
          bariatricSurgeryData.acuteWeightLossDay !== null
        : true) &&
      bariatricSurgeryData.surgicalTechnique !== null &&
      bariatricSurgeryData.surgicalTechnique !== "" &&
      bariatricSurgeryData.operationType !== null &&
      bariatricSurgeryData.operationType !== "" &&
      (bariatricSurgeryData.operationType ===
        "Roux-en-Y gastric bypass (RYGB)" ||
      bariatricSurgeryData.operationType === "Sleeve-DJB (Roux-en-Y)"
        ? bariatricSurgeryData.operationLocation &&
          bariatricSurgeryData.operationLocation.length > 0 &&
          bariatricSurgeryData.operationLocation[0]?.technique !== "" &&
          bariatricSurgeryData.operationLocation[1]?.technique !== ""
        : true) &&
      (bariatricSurgeryData.operationType === "Sleeve-PJB" ||
      bariatricSurgeryData.operationType === "Sleeve-DJB (Loop)" ||
      bariatricSurgeryData.operationType === "SADI-S"
        ? bariatricSurgeryData.operationLocation &&
          bariatricSurgeryData.operationLocation.length > 0 &&
          bariatricSurgeryData.operationLocation[0]?.technique !== ""
        : true) &&
      (bariatricSurgeryData.operationType === "อื่นๆ"
        ? bariatricSurgeryData?.operationTypeOtherText !== "" || null
        : true) &&
      bariatricSurgeryData.ebl !== null &&
      bariatricSurgeryData.ebl !== "" &&
      (bariatricSurgeryData.earlyComplication === true
        ? bariatricSurgeryData.acuteWeightLossType !== null
        : true) &&
      (bariatricSurgeryData.earlyComplicationType["other"] === true
        ? bariatricSurgeryData.earlyComplicationTypeOtherText !== null
        : true)
    ) {
      onSave(bariatricSurgeryData);
      dispatch(updateTabsIsDirty(true));
    }
  };
  const handleCloseDialog = () => {
    setIsSaveClicked(false);
    onClose();
  };

  const isShowPositionOfSurgerry = () => {
    return (
      bariatricSurgeryData.operationType !== "Sleeve Gastrectomy (SG)" &&
      bariatricSurgeryData.operationType !== "Gastric Banding" &&
      bariatricSurgeryData.operationType !== "อื่นๆ" &&
      bariatricSurgeryData.operationType !== "Endoscopic Sleeve Gastroplasty"
    );
  };

  const isEntrySelected =
    selectedEntryId !== null || newBariatricSurgeryData !== null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedEntryId) {
          const responseBariatricSurgery =
            await visitDataService.getBariatricSurgeryFromEntryId(
              selectedEntryId
            );
          setOperationLocationTemp(responseBariatricSurgery.operationLocation);
          setBariatricSurgeryData({
            entryId: responseBariatricSurgery.entryId,
            editorId: responseBariatricSurgery.editorId,
            createDateTime: responseBariatricSurgery.createDateTime,
            visitDate: responseBariatricSurgery.visitDate,
            visitDataStatus: responseBariatricSurgery.visitDataStatus,
            surgeryDate: responseBariatricSurgery.surgeryDate,
            operationTime: responseBariatricSurgery.operationTime,
            acuteWeightLoss: responseBariatricSurgery.acuteWeightLoss,
            acuteWeightLossType: responseBariatricSurgery.acuteWeightLossType,
            acuteWeightLossDay: responseBariatricSurgery.acuteWeightLossDay,
            preAwlWeight: responseBariatricSurgery.preAwlWeight,
            postAwlWeight: responseBariatricSurgery.postAwlWeight,
            surgicalTechnique: responseBariatricSurgery.surgicalTechnique,
            operationType: responseBariatricSurgery.operationType,
            operationTypeOtherText:
              responseBariatricSurgery.operationTypeOtherText,
            operationLocation: responseBariatricSurgery.operationLocation,
            bougieSize: responseBariatricSurgery.bougieSize,
            bpLimb: responseBariatricSurgery.bpLimb,
            rouxLimb: responseBariatricSurgery.rouxLimb,
            ebl: responseBariatricSurgery.ebl,
            subsequentOperation:
              responseBariatricSurgery.subsequentOperation === null
                ? {
                    laparoscopicCholecystectomy: false,
                    laparoscopicHiatalHerniaRepair: false,
                    other: false,
                  }
                : responseBariatricSurgery.subsequentOperation,
            subsequentOperationOther:
              responseBariatricSurgery.subsequentOperationOther,
            earlyComplication: responseBariatricSurgery.earlyComplication,
            earlyComplicationType:
              responseBariatricSurgery.earlyComplicationType === null
                ? {
                    leakage: false,
                    surgicalSiteInfection: false,
                    obstruction: false,
                    cardiovascularEvents: false,
                    bleeding: false,
                    pulmonaryComplication: false,
                    vte: false,
                    other: false,
                  }
                : responseBariatricSurgery.earlyComplicationType,
            earlyComplicationTypeOtherText:
              responseBariatricSurgery.earlyComplicationTypeOtherText,
            treatment: responseBariatricSurgery.treatment,
            reOperation: responseBariatricSurgery.reOperation,
          });
          console.log("newBariatricSurgeryData1", newBariatricSurgeryData);
        } else if (newBariatricSurgeryData === null) {
          setOperationLocationTemp([]);
          setBariatricSurgeryData({
            entryId: null,
            editorId: null,
            createDateTime: "",
            visitDate: "",
            visitDataStatus: 0,
            surgeryDate: "",
            operationTime: null,
            acuteWeightLoss: false,
            acuteWeightLossType: "",
            acuteWeightLossDay: null,
            preAwlWeight: null,
            postAwlWeight: null,
            surgicalTechnique: "",
            operationType: "",
            operationTypeOtherText: "",
            operationLocation: [],
            bougieSize: "",
            bpLimb: "",
            rouxLimb: "",
            ebl: "",
            subsequentOperation: {
              laparoscopicCholecystectomy: false,
              laparoscopicHiatalHerniaRepair: false,
              other: false,
            },
            subsequentOperationOther: [],
            earlyComplication: false,
            earlyComplicationType: {
              leakage: false,
              surgicalSiteInfection: false,
              obstruction: false,
              cardiovascularEvents: false,
              bleeding: false,
              pulmonaryComplication: false,
              vte: false,
              other: false,
            },
            earlyComplicationTypeOtherText: "",
            treatment: [],
            reOperation: false,
          });
          console.log("newBariatricSurgeryData2", newBariatricSurgeryData);
        } else {
          setOperationLocationTemp([]);
          setBariatricSurgeryData(newBariatricSurgeryData);
          console.log("newBariatricSurgeryData3", newBariatricSurgeryData);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [selectedEntryId]);

  useEffect(() => {
    if (selectedEntryId === null) {
      setBariatricSurgeryData((prevState) => ({
        ...prevState,
        acuteWeightLoss: false,
      }));
    }
  }, [selectedEntryId]);

  useEffect(() => {
    const initialSurgicalType = positionOfSurgerry.find(
      (item) => item.surgicalType === bariatricSurgeryData.operationType
    );
    // if (initialSurgicalType && initialSurgicalType.position) {
    //   const initialOperationLocations = initialSurgicalType.position.map(
    //     (position) => ({
    //       location: position.label,
    //       technique: "",
    //       techniqueDetail: "",
    //     })
    //   );
    //   setBariaticSurgeryData((prevState) => ({
    //     ...prevState,
    //     operationLocation: initialOperationLocations,
    //   }));
    // }
    if (initialSurgicalType && initialSurgicalType.position) {
      // if (
      //   initialSurgicalType.surgicalType === "Endoscopic Sleeve Gastroplasty"
      // ) {
      //   setBariatricSurgeryData((prevState) => {
      //     const existingOperationLocations = operationLocationTemp || [];
      //     const updatedOperationLocations = initialSurgicalType.position.map(
      //       (position) => {
      //         const existingLocation = existingOperationLocations.find(
      //           (location) => location.location || location.location === ""
      //         );
      //         return (
      //           existingLocation || {
      //             location: position.label,
      //             technique: "",
      //             techniqueDetail: "",
      //           }
      //         );
      //       }
      //     );
      //     return {
      //       ...prevState,
      //       operationLocation: updatedOperationLocations,
      //     };
      //   });
      setBariatricSurgeryData((prevState) => {
        const existingOperationLocations = prevState.operationLocation || [];
        const updatedOperationLocations = initialSurgicalType.position.map(
          (position) => {
            const existingLocation = existingOperationLocations.find(
              (location) => location.location === position.label
            );
            return (
              existingLocation || {
                location: position.label,
                technique: "",
                techniqueDetail: "",
              }
            );
          }
        );
        return {
          ...prevState,
          operationLocation: updatedOperationLocations,
        };
      });
    } else if (initialSurgicalType && initialSurgicalType.position === null) {
      setBariatricSurgeryData((prevState) => ({
        ...prevState,
        operationLocation: [],
      }));
    }
  }, [bariatricSurgeryData.operationType]);

  const handleTechniqueChange = (
    event: SelectChangeEvent<string | null>,
    index: number
  ) => {
    const newOperationLocations = bariatricSurgeryData.operationLocation
      ? [...bariatricSurgeryData.operationLocation]
      : [];

    const selectedTechnique = event.target.value as string;

    newOperationLocations[index] = {
      ...newOperationLocations[index],
      technique: event.target.value as string,
      techniqueDetail:
        selectedTechnique === "Linear" || selectedTechnique === "Hand sewn"
          ? "-"
          : newOperationLocations[index]?.techniqueDetail,
    };

    setBariatricSurgeryData((prevState) => ({
      ...prevState,
      operationLocation: newOperationLocations,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Bariatric Surgery</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              วันที่ผ่าตัด <img src={infoIcon} />
            </p>
            <DatePicker
              maxDate={dayjs()}
              value={
                bariatricSurgeryData.surgeryDate !== ""
                  ? dayjs(bariatricSurgeryData.surgeryDate)
                  : null
              }
              onAccept={(newValue) => handleDateChange(newValue)}
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: 14,
                },
                width: 150,
                border:
                  bariatricSurgeryData.surgeryDate === "" && isSaveClicked
                    ? "1px solid red"
                    : "none",
              }}
            />
            {bariatricSurgeryData.surgeryDate === "" && isSaveClicked && (
              <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
            )}
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Op-Time (minutes) <span style={{ color: "red" }}>*</span>{" "}
              <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="operationTime"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                onChange={handleChange}
                value={
                  isEntrySelected ? bariatricSurgeryData.operationTime : null
                }
                error={!bariatricSurgeryData.operationTime && isSaveClicked}
              />
            </div>
            {!bariatricSurgeryData.operationTime && isSaveClicked && (
              <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
            )}
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <Typography style={{ padding: "5px", color: "#646D78" }}>
              Acute weight loss
              <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
            </Typography>
            <FormControlLabel
              value={
                isEntrySelected ? bariatricSurgeryData.acuteWeightLoss : null
              }
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={() =>
                    setBariatricSurgeryData((prevState) => ({
                      ...prevState,
                      acuteWeightLoss: true,
                    }))
                  }
                />
              }
              label="Yes"
              checked={bariatricSurgeryData.acuteWeightLoss === true}
            />
            <FormControlLabel
              value={
                isEntrySelected ? bariatricSurgeryData.acuteWeightLoss : null
              }
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={() =>
                    setBariatricSurgeryData((prevState) => ({
                      ...prevState,
                      acuteWeightLoss: false,
                    }))
                  }
                />
              }
              label="No"
              checked={bariatricSurgeryData.acuteWeightLoss === false}
            />
          </Grid>
          {bariatricSurgeryData.acuteWeightLoss && (
            <Grid style={{ padding: "5px" }}>
              <Typography style={{ padding: "5px", color: "#646D78" }}>
                Acute weight loss (Type)
                <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
              </Typography>
              <FormControlLabel
                value={
                  isEntrySelected
                    ? bariatricSurgeryData.acuteWeightLossType
                    : null
                }
                control={
                  <Radio
                    color="default"
                    style={{ color: "#098484" }}
                    onChange={() =>
                      setBariatricSurgeryData((prevState) => ({
                        ...prevState,
                        acuteWeightLossType: "Admit",
                      }))
                    }
                  />
                }
                label="Admit"
                checked={bariatricSurgeryData.acuteWeightLossType === "Admit"}
              />
              <FormControlLabel
                value={bariatricSurgeryData.acuteWeightLossType}
                control={
                  <Radio
                    color="default"
                    style={{ color: "#098484" }}
                    onChange={() =>
                      setBariatricSurgeryData((prevState) => ({
                        ...prevState,
                        acuteWeightLossType: "Ambulatory",
                      }))
                    }
                  />
                }
                label="Ambulatory"
                checked={
                  bariatricSurgeryData.acuteWeightLossType === "Ambulatory"
                }
              />
              <Typography style={{ padding: "5px", color: "#646D78" }}>
                ระบุจำนวนวัน
                <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  name="acuteWeightLossDay"
                  variant="outlined"
                  size="small"
                  style={{ width: "500px" }}
                  value={
                    isEntrySelected
                      ? bariatricSurgeryData.acuteWeightLossDay
                      : null
                  }
                  onChange={handleChange}
                  error={
                    !bariatricSurgeryData.acuteWeightLossDay && isSaveClicked
                  }
                />
              </div>
              {!bariatricSurgeryData.acuteWeightLossDay && isSaveClicked && (
                <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
              )}
              <Divider
                sx={{ width: "95%" }}
                style={{ paddingBottom: "20px" }}
              />

              <Grid>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  Pre-AWL Weight
                  <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    name="preAwlWeight"
                    variant="outlined"
                    size="small"
                    style={{ width: "500px" }}
                    onChange={handleChange}
                    value={
                      isEntrySelected ? bariatricSurgeryData.preAwlWeight : null
                    }
                    error={!bariatricSurgeryData.preAwlWeight && isSaveClicked}
                  />
                </div>
                {!bariatricSurgeryData.preAwlWeight && isSaveClicked && (
                  <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
                )}
              </Grid>
              <Grid>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  Post-AWL Weight
                  <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    name="postAwlWeight"
                    variant="outlined"
                    size="small"
                    style={{ width: "500px" }}
                    value={
                      isEntrySelected
                        ? bariatricSurgeryData.postAwlWeight
                        : null
                    }
                    onChange={handleChange}
                    error={!bariatricSurgeryData.postAwlWeight && isSaveClicked}
                  />
                </div>
                {!bariatricSurgeryData.postAwlWeight && isSaveClicked && (
                  <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
                )}
              </Grid>
              <Grid>
                <Typography style={{ padding: "5px" }}>
                  Weight change (%){" "}
                  <span style={{ color: "#098484", paddingLeft: "50px" }}>
                    {bariatricSurgeryData.preAwlWeight !== null &&
                    bariatricSurgeryData.postAwlWeight !== null
                      ? (
                          ((bariatricSurgeryData.preAwlWeight -
                            bariatricSurgeryData.postAwlWeight) /
                            bariatricSurgeryData.postAwlWeight) *
                          100
                        ).toFixed(2) + "%"
                      : "Auto calculation"}{" "}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}
          <Typography style={{ padding: "5px", color: "#646D78" }}>
            Surgical Technique
            <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
            {bariatricSurgeryData.surgicalTechnique === "" && isSaveClicked && (
              <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
            )}
          </Typography>
          <Grid style={{ padding: "5px" }}>
            <Grid item xs={4}>
              <FormControlLabel
                value={bariatricSurgeryData.surgicalTechnique}
                control={
                  <Radio
                    color="default"
                    style={{ color: "#098484" }}
                    onChange={() =>
                      setBariatricSurgeryData((prevState) => ({
                        ...prevState,
                        surgicalTechnique: "Open",
                      }))
                    }
                  />
                }
                label="Open"
                checked={bariatricSurgeryData.surgicalTechnique === "Open"}
                style={{ paddingRight: "190px" }}
              />
              <FormControlLabel
                value={bariatricSurgeryData.surgicalTechnique}
                control={
                  <Radio
                    color="default"
                    style={{ color: "#098484" }}
                    onChange={() =>
                      setBariatricSurgeryData((prevState) => ({
                        ...prevState,
                        surgicalTechnique: "Laparoscopic-assisted",
                      }))
                    }
                  />
                }
                label="Laparoscopic-assisted"
                checked={
                  bariatricSurgeryData.surgicalTechnique ===
                  "Laparoscopic-assisted"
                }
              />
            </Grid>
            <FormControlLabel
              value={bariatricSurgeryData.surgicalTechnique}
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={() =>
                    setBariatricSurgeryData((prevState) => ({
                      ...prevState,
                      surgicalTechnique: "Robotic-assisted",
                    }))
                  }
                />
              }
              label="Robotic-assisted"
              checked={
                bariatricSurgeryData.surgicalTechnique === "Robotic-assisted"
              }
            />
          </Grid>
          <div>
            <Typography style={{ padding: "5px", color: "#646D78" }}>
              ชนิดการผ่าตัด
              <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
              {bariatricSurgeryData.operationType === "" && isSaveClicked && (
                <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
              )}
            </Typography>
            <Grid container style={{ padding: "5px" }}>
              <Grid item xs={5}>
                <RadioGroup value={bariatricSurgeryData.operationType}>
                  {surgericalTypeList1.map((option) => (
                    <FormControlLabel
                      control={
                        <Radio color="default" style={{ color: "#098484" }} />
                      }
                      label={option}
                      checked={bariatricSurgeryData.operationType === option}
                      onChange={() =>
                        setBariatricSurgeryData((prevState) => ({
                          ...prevState,
                          operationType: option,
                        }))
                      }
                    />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item xs={5}>
                <RadioGroup value={bariatricSurgeryData.operationType}>
                  {surgericalTypeList2.map((option) => (
                    <FormControlLabel
                      control={
                        <Radio color="default" style={{ color: "#098484" }} />
                      }
                      label={
                        option === "อื่นๆ" ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {option}
                            {bariatricSurgeryData.operationType === "อื่นๆ" && (
                              <TextField
                                name="operationTypeOtherText"
                                variant="outlined"
                                size="small"
                                label="-- โปรดระบุข้อมูล --"
                                style={{ marginLeft: "10px", width: "300px" }}
                                value={
                                  bariatricSurgeryData.operationTypeOtherText
                                }
                                onChange={(e) =>
                                  setBariatricSurgeryData((prevState) => ({
                                    ...prevState,
                                    operationTypeOtherText: e.target.value,
                                  }))
                                }
                                error={
                                  !bariatricSurgeryData.operationTypeOtherText &&
                                  isSaveClicked
                                }
                              />
                            )}
                          </div>
                        ) : (
                          option
                        )
                      }
                      checked={bariatricSurgeryData.operationType === option}
                      onChange={() =>
                        setBariatricSurgeryData((prevState) => ({
                          ...prevState,
                          operationType: option,
                        }))
                      }
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
          </div>
          {!!bariatricSurgeryData.operationType &&
            isShowPositionOfSurgerry() && (
              <div>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  ตำแหน่ง
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TableContainer component={Paper}>
                  <Table style={{ border: "1px solid #E5EBEB" }}>
                    <TableHead>
                      <TableRow style={{ borderBottom: "1px solid #E5EBEB" }}>
                        {positionOfSurgerryTable.map((value) => (
                          <TableCell style={{ fontSize: "16px" }}>
                            {value}
                          </TableCell>
                        ))}
                        {bariatricSurgeryData.operationLocation &&
                          bariatricSurgeryData.operationLocation.some(
                            (item) => item.technique === "Circular"
                          ) && (
                            <TableCell style={{ fontSize: "16px" }}>
                              Technique (ระบุข้อมูล)
                            </TableCell>
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bariatricSurgeryData.operationLocation?.map(
                        (item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell style={{ width: "50xpx" }}>
                                {index + 1}
                              </TableCell>
                              <TableCell style={{ width: "190px" }}>
                                {item.location}
                              </TableCell>
                              <TableCell>
                                <FormControl variant="outlined">
                                  <InputLabel
                                    style={{
                                      color:
                                        item.technique === "" && isSaveClicked
                                          ? "red"
                                          : "inherit",
                                    }}
                                  >
                                    -- โปรดระบุข้อมูล --
                                  </InputLabel>
                                  <Select
                                    label="Regular Dropdown"
                                    value={item?.technique}
                                    onChange={(e) =>
                                      handleTechniqueChange(e, index)
                                    }
                                    error={
                                      item.technique === "" && isSaveClicked
                                    }
                                    size="small"
                                    style={{ width: "300px" }}
                                    MenuProps={{
                                      sx: {
                                        zoom: 100 * (100 / 85) + "%",
                                        top: "0px",
                                      },
                                      PaperProps: {
                                        sx: {
                                          maxHeight: "250px",
                                          scrollbarWidth: "thin",
                                          minWidth: "300px !important",
                                        },
                                      },
                                    }}
                                  >
                                    {techniqueList.map((technique) => (
                                      <MenuItem
                                        value={technique.value}
                                        sx={{ fontSize: 14 }}
                                      >
                                        {technique.value}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell style={{ width: "300px" }}>
                                {item.technique === "Circular" && (
                                  <FormControl variant="outlined">
                                    <TextField
                                      name="techniqueDetail"
                                      value={item?.techniqueDetail}
                                      label="-- โปรดระบุข้อมูล --"
                                      error={
                                        item.technique === "Circular" &&
                                        item.techniqueDetail === ""
                                      }
                                      onChange={(e) =>
                                        setBariatricSurgeryData(
                                          (prevState) => ({
                                            ...prevState,
                                            operationLocation:
                                              prevState.operationLocation.map(
                                                (location, i) =>
                                                  i === index
                                                    ? {
                                                        ...location,
                                                        techniqueDetail:
                                                          e.target.value,
                                                      }
                                                    : location
                                              ),
                                          })
                                        )
                                      }
                                      size="small"
                                      style={{ width: "300px" }}
                                    ></TextField>
                                  </FormControl>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          {/* {bariatricSurgeryData.operationLocation && ( */}
          <div>
            {sizeList.map((size) => (
              <div>
                <Grid style={{ padding: "5px" }}>
                  <Typography style={{ padding: "5px", color: "#646D78" }}>
                    {size.label}
                    {size.required && (
                      <span style={{ color: "red" }}>*</span>
                    )}{" "}
                    <img src={size.icon} />
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      name={size.value}
                      variant="outlined"
                      size="small"
                      style={{ width: "500px" }}
                      value={
                        isEntrySelected
                          ? bariatricSurgeryData[
                              size.value as keyof BariaticSurgeryData
                            ]
                          : null
                      }
                      onChange={handleChange}
                      error={
                        size.required &&
                        !bariatricSurgeryData[
                          size.value as keyof BariaticSurgeryData
                        ] &&
                        isSaveClicked
                      }
                    />
                  </div>
                  {size.required &&
                    !bariatricSurgeryData[
                      size.value as keyof BariaticSurgeryData
                    ] &&
                    isSaveClicked && (
                      <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
                    )}
                </Grid>
              </div>
            ))}
          </div>
          {/* )} */}
          <Grid style={{ padding: "5px" }}>
            <Typography style={{ padding: "5px", color: "#646D78" }}>
              Subsequent Operation <img src={infoIcon} />
            </Typography>
            <Grid container>
              {subsequentOperationList.map((subsequentOperation, index) => (
                <Grid item xs={6} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="medium"
                        name={subsequentOperation.value}
                        style={{ color: "#098484" }}
                        value={bariatricSurgeryData.subsequentOperation}
                        checked={
                          bariatricSurgeryData.subsequentOperation[
                            subsequentOperation.value as keyof typeof bariatricSurgeryData.subsequentOperation
                          ]
                        }
                        onChange={(event) => {
                          setBariatricSurgeryData((prevState) => ({
                            ...prevState,
                            subsequentOperation: {
                              ...prevState.subsequentOperation,
                              [subsequentOperation.value]: event.target.checked,
                            },
                          }));
                        }}
                      />
                    }
                    label={subsequentOperation.label}
                  />
                  {subsequentOperation.value === "other" &&
                    bariatricSurgeryData.subsequentOperation["other"] && (
                      <TextField
                        name="subsequentOperationOther"
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: "10px", width: "300px" }}
                        value={bariatricSurgeryData.subsequentOperationOther}
                        onChange={(event) => {
                          setBariatricSurgeryData((prevState) => ({
                            ...prevState,
                            subsequentOperationOther: [event.target.value], // Wrap the value in an array
                          }));
                        }}
                      />
                    )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
        <Divider sx={{ width: "95%" }} style={{ paddingBottom: "20px" }} />
        <div>
          <Typography style={{ padding: "5px", color: "#098484" }}>
            Early Complications
          </Typography>
          <Typography style={{ padding: "5px", color: "#646D78" }}>
            Early Complications <span style={{ color: "red" }}>*</span>{" "}
            <img src={infoIcon} />
          </Typography>
          <Grid style={{ padding: "5px" }}>
            <FormControlLabel
              value={bariatricSurgeryData.earlyComplication}
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={() =>
                    setBariatricSurgeryData((prevState) => ({
                      ...prevState,
                      earlyComplication: true,
                    }))
                  }
                />
              }
              label="Yes"
              checked={bariatricSurgeryData.earlyComplication === true}
            />
            <FormControlLabel
              value={bariatricSurgeryData.earlyComplication}
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={() =>
                    setBariatricSurgeryData((prevState) => ({
                      ...prevState,
                      earlyComplication: false,
                    }))
                  }
                />
              }
              label="No"
              checked={bariatricSurgeryData.earlyComplication === false}
            />
          </Grid>
        </div>
        {bariatricSurgeryData.earlyComplication && (
          <div>
            <Typography style={{ padding: "5px", color: "#646D78" }}>
              Early Complications Type <span style={{ color: "red" }}>*</span>{" "}
              <img src={infoIcon} />
            </Typography>
            <Grid container style={{ padding: "5px" }}>
              <Grid item xs={5}>
                {earlyComplicationsTypeList.map(
                  (earlyComplicationType, index) => (
                    <Grid item xs={12} key={index}>
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            size="medium"
                            name={earlyComplicationType.value}
                            style={{ color: "#098484" }}
                            value={bariatricSurgeryData.earlyComplicationType}
                            checked={
                              bariatricSurgeryData.earlyComplicationType[
                                earlyComplicationType.value as keyof EarlyComplicationType
                              ]
                            }
                            onChange={(event) => {
                              setBariatricSurgeryData((prevState) => ({
                                ...prevState,
                                earlyComplicationType: {
                                  ...prevState.earlyComplicationType,
                                  [earlyComplicationType.value]:
                                    event.target.checked,
                                },
                              }));
                            }}
                          />
                        }
                        label={earlyComplicationType.label}
                      />
                    </Grid>
                  )
                )}
              </Grid>
              <Grid xs={4}>
                {earlyComplicationsTypeList2.map(
                  (earlyComplicationType, index) => (
                    <Grid item xs={12} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="medium"
                            name={earlyComplicationType.value}
                            style={{ color: "#098484" }}
                            value={bariatricSurgeryData.earlyComplicationType}
                            checked={
                              bariatricSurgeryData.earlyComplicationType[
                                earlyComplicationType.value as keyof EarlyComplicationType
                              ]
                            }
                            onChange={(event) => {
                              setBariatricSurgeryData((prevState) => ({
                                ...prevState,
                                earlyComplicationType: {
                                  ...prevState.earlyComplicationType,
                                  [earlyComplicationType.value]:
                                    event.target.checked,
                                },
                              }));
                            }}
                          />
                        }
                        label={earlyComplicationType.label}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
            {bariatricSurgeryData.earlyComplicationType["leakage"] && (
              <div>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  Treatment
                </Typography>
                <TextField
                  name="treatment"
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: "10px", width: "300px" }}
                  value={bariatricSurgeryData.treatment}
                  onChange={(event) => {
                    setBariatricSurgeryData((prevState) => ({
                      ...prevState,
                      treatment: [event.target.value],
                    }));
                  }}
                />
              </div>
            )}
            {bariatricSurgeryData.earlyComplicationType["other"] && (
              <div>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  ระบุข้อมูลเพิ่มเติม
                </Typography>
                <TextField
                  name="earlyComplicationTypeOtherText"
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: "10px", width: "300px" }}
                  value={bariatricSurgeryData.earlyComplicationTypeOtherText}
                  onChange={(event) => {
                    setBariatricSurgeryData((prevState) => ({
                      ...prevState,
                      earlyComplicationTypeOtherText: event.target.value,
                    }));
                  }}
                />
              </div>
            )}
          </div>
        )}

        <div>
          <Typography style={{ padding: "5px", color: "#646D78" }}>
            Re-operation <span style={{ color: "red" }}>*</span>{" "}
            <img src={infoIcon} />
          </Typography>
          <Grid style={{ padding: "5px" }}>
            <FormControlLabel
              value={bariatricSurgeryData.reOperation}
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={() =>
                    setBariatricSurgeryData((prevState) => ({
                      ...prevState,
                      reOperation: true,
                    }))
                  }
                />
              }
              label="Yes"
              checked={bariatricSurgeryData.reOperation === true}
            />
            <FormControlLabel
              value={bariatricSurgeryData.reOperation}
              control={
                <Radio
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={() =>
                    setBariatricSurgeryData((prevState) => ({
                      ...prevState,
                      reOperation: false,
                    }))
                  }
                />
              }
              label="No"
              checked={bariatricSurgeryData.reOperation === false}
            />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Box, Grid, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { InvestigationUltrasound } from "./InvestigationUltrasound";
import { InvestigationEndoscopy } from "./InvestigationEndoscopy";
import { StatusBottomBar } from "../StatusBottomBar";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { store } from "../../../redux/store";
import { LayoutTab } from "../LayoutTab";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useEffectOnce } from "react-use";
import { visitDataService } from "../../../service/visitDataService";
import { InvestigationFibroScan } from "./InvestigationFibroScan";
import { useMutation } from "@tanstack/react-query";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { enqueueSnackbar } from "notistack";
import { InvestigationHistoryDialog } from "./InvestigationHistoryDialog";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import { set } from "date-fns";
import { InvestigationVisitLogDialog } from "./InvestigationVisitLogDialog";

export type InvestigationResponse = {
  entryId: number;
  editorId?: number | string;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  fattyLiver: boolean;
  gallstone: boolean;
  cirrhosis: boolean;
  ascites: boolean;
  fibro: boolean;
  hiatalHernia: boolean;
  refluxEsophagitis: boolean;
  gastritis: boolean;
  gastric: boolean;
  hpyroli: boolean;
  other: boolean;
  otherText: string[];
};
type Props = {
  handleCancelClick: () => void;
};

export const Investigation = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(2);
  const [originalStatus, setOriginalStatus] = useState(2);
  const [ultrasoundValues, setUltrasoundValues] =
    useState<InvestigationUltrasound>();
  const [endoscopyValues, setEndoscopyValues] =
    useState<InvestigationEndoscopy>();
  const [fibroValues, setFibroValues] = useState<InvestigationFibroScan>();
  const latestEntryId = useAppSelector(
    (state) => state.patientUser.latestEntryIds?.investigationStatus
  );
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.investigationStatus
  );
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openVisitLogDialog, setOpenVisitLogDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenHistoryDialog(false);
    setOpenVisitLogDialog(false);
    setOpenNoPermissionDialog(false);
  };

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);

  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const investigationData: InvestigationResponse =
          await visitDataService.getInvestigationFromEntryId(currentEntryId);
        setStatus(investigationData.visitDataStatus);
        setOriginalStatus(investigationData.visitDataStatus);
      } else if (latestEntryId) {
        const investigationData: InvestigationResponse =
          await visitDataService.getInvestigationFromEntryId(latestEntryId);
        setStatus(investigationData.visitDataStatus);
        setOriginalStatus(investigationData.visitDataStatus);
      }
    };
    fetchData();
  });
  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.saveInvestigationData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        fattyLiver: ultrasoundValues?.fattyLiver === "yes" ? true : false,
        gallstone: ultrasoundValues?.gallstone === "yes" ? true : false,
        cirrhosis: ultrasoundValues?.cirrhosis === "yes" ? true : false,
        ascites: ultrasoundValues?.ascites === "yes" ? true : false,
        fibro: fibroValues?.fibro === "yes" ? true : false,
        hiatalHernia: endoscopyValues?.hiatalHernia === "yes" ? true : false,
        refluxEsophagitis:
          endoscopyValues?.refluxEsophagitis === "yes" ? true : false,
        gastritis: endoscopyValues?.gastritis === "yes" ? true : false,
        gastric: endoscopyValues?.gastricDuodenalUlcer === "yes" ? true : false,
        hpyroli: endoscopyValues?.hPyroliInfection === "yes" ? true : false,
        other: endoscopyValues?.other === "yes" ? true : false,
        otherText: endoscopyValues?.otherDetail,
      });

      return response as InvestigationResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          investigationStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleSaveUltrasoundValues = (value: InvestigationUltrasound) => {
    setUltrasoundValues(value);
    dispatch(updateTabsIsDirty(true));
  };
  const handleSaveEndoscopyValues = (value: InvestigationEndoscopy) => {
    setEndoscopyValues(value);
    dispatch(updateTabsIsDirty(true));
  };
  const handleSaveFibroValues = (value: InvestigationFibroScan) => {
    setFibroValues(value);
    dispatch(updateTabsIsDirty(true));
  };
  const handleSubmitData = async () => {
    if (patientDataPermissionEditAble === false) {
      setOpenNoPermissionDialog(true);
      setStatus(originalStatus);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    await submitDataAsync();
    dispatch(updateTabsIsDirty(false));
  };
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
    dispatch(updateTabsIsDirty(true));
  };
  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box style={{ padding: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => setOpenVisitLogDialog(true)}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Grid container rowGap={3} alignItems="stretch" sx={{ my: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <InvestigationUltrasound
                handleSaveUltrasoundValues={handleSaveUltrasoundValues}
                currentEntryId={currentEntryId}
                latestEntryId={latestEntryId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <InvestigationEndoscopy
                handleSaveEndoscopyValues={handleSaveEndoscopyValues}
                currentEntryId={currentEntryId}
                latestEntryId={latestEntryId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <InvestigationFibroScan
                handleSaveFibroValues={handleSaveFibroValues}
                currentEntryId={currentEntryId}
                latestEntryId={latestEntryId}
              />
            </Grid>
          </Grid>
          <InvestigationHistoryDialog
            open={openHistoryDialog}
            handleCloseDialog={handleCloseDialog}
          />  
          <InvestigationVisitLogDialog
            open={openVisitLogDialog}
            handleCloseDialog={handleCloseDialog}
          /> 
          <StatusBottomBar
            value={status}
            handleStatusChange={handleStatusChange}
          />
        </Box>
        <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
      </Box>
    </LayoutTab>
  );
};

import React, { useEffect, useState } from "react";
import HeaderMenuBar from "../../HeaderMenuBar";
import { Typography } from "@mui/material";
import Footer from "../../Footer";
import PersonalInfo from "./PersonalInfo";
import "./Register.css";
import AffiliateHospital from "./AffiliateHospital";
import RegisterDocument from "./RegisterDocument";

import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckIcon from '@mui/icons-material/Check';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { useLocation } from "react-router";

const Register = () => {
  const location = useLocation();
  const { status, email } = location.state || {};
  const [step, setStep] = useState(1);
  const statusSSO = status === "unregisteredSSO" ? true : false;
  const [registerForm, setRegisterForm] = useState({
    prefix: "",
    firstName: "",
    lastName: "",
    nationalId: "",
    phoneNumber: "",
    phoneNumberExtension: "",
    mobilePhoneNumber: "",
    nameTitle: "",
    email: email ? email : "",
    password: statusSSO ? null : "",
    confirmPassword: statusSSO ? null : "",
    affiliatedHospitalId: "",
    medicalLicenseNumber: "",
    jobPosition: "",
    appliedPermission: "",
    fileDoctor: "",
    fileDoctorName: "",
    fileDoctorType: "",
    fileOther1: "",
    fileOther1Name: "",
    fileOther1Type: "",
    fileOther2: "",
    fileOther2Name: "",
    fileOther2Type: "",
    medicalDocument: {
      documentName: "",
      documentType: "",
      documentData: []
    },
    otherDocument: [
      {
        documentName: "",
        documentType: "",
        documentData: []
      },
      {
        documentName: "",
        documentType: "",
        documentData: []
      }
    ],
    loginType: status === "unregisteredSSO" ? "SSO" : "PASSWORD",
    userRestricted: false
  });

  const handleRegisterChange = (e: any) => {
    const { name, value } = e.target;
    if(name.toLowerCase().includes('file') && name.toLowerCase().includes('name') && value) {
      let nameArr = value.split('\\');
      let nameFile = nameArr[nameArr.length-1];
      setRegisterForm(prevState => ({
        ...prevState,
        [name]: nameFile
      }));
    } else {
      setRegisterForm(prevState => ({
        ...prevState,
        [name]: value
      }));
    }    
  };

  const nextStep = () => {
    if(
      (
        step === 1 &&
        (
          registerForm.prefix &&
          registerForm.firstName &&
          registerForm.lastName &&
          registerForm.nationalId.length === 13 &&
          registerForm.mobilePhoneNumber &&
          registerForm.email &&
          statusSSO ? true : !!registerForm.password &&
          statusSSO ? true : !!registerForm.confirmPassword &&
          statusSSO ? true : (registerForm.password === registerForm.confirmPassword) &&
          statusSSO ? true : !(registerForm.password && registerForm.password.length < 8 && /[^\x00-\x7F]/.test(registerForm.password) && !/[a-z]/.test(registerForm.password) && !/[A-Z]/.test(registerForm.password) && !/[0-9]/.test(registerForm.password))
        )
      ) ||
      (
        step === 2 &&
        (
          registerForm.affiliatedHospitalId &&
          registerForm.medicalLicenseNumber &&
          registerForm.appliedPermission
        )
      ) ||
      (
        step === 3 && registerForm.fileDoctor
      )
    ) {
      step >= 1 && step <= 3 ? setStep(step + 1) : setStep(4);
    }
  };

  const prevStep = () => {
    step >= 2 && step <= 4 ? setStep(step - 1) : setStep(1);
  };

  const bindingIdCard = () => {
    if(registerForm.nationalId.length === 13) {
      let result = registerForm.nationalId.split("");
      for (let index = 1; index <= 17; index++) {
        switch (index) {
          case 1:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[0];
            break;      
          case 3:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[1];
            break;      
          case 4:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[2];
            break;      
          case 5:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[3];
            break;      
          case 7:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[4];
            break;      
          case 8:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[5];
            break;      
          case 9:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[6];
            break;      
          case 10:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[7];
            break;      
          case 12:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[8];
            break;    
          case 14:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[9];
            break;      
          case 15:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[10];
            break;      
          case 16:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[11];
            break;      
          case 17:
            (document.getElementById(`idCard${index}`) as HTMLInputElement).value = result[12];
            break;      
          default:
        }
      }
    }
  }

  setTimeout(() => {
    if(step === 1 || step === 4) {
      bindingIdCard();
    }
  }, 200);

  let stepComponent;
  switch (step) {
    case 1: {
      stepComponent = <PersonalInfo step={step} prevStep={prevStep} nextStep={nextStep} registerForm={registerForm} handleRegisterChange={handleRegisterChange} />;
      break;
    }  
    case 2: {
      stepComponent = <AffiliateHospital prevStep={prevStep} nextStep={nextStep} registerForm={registerForm} handleRegisterChange={handleRegisterChange} />;
      break;
    } 
    case 3: {
      stepComponent = <RegisterDocument prevStep={prevStep} nextStep={nextStep} registerForm={registerForm} handleRegisterChange={handleRegisterChange} />;
      break;
    }
    default:
      stepComponent = <PersonalInfo step={step} prevStep={prevStep} nextStep={nextStep} registerForm={registerForm} handleRegisterChange={handleRegisterChange} />;
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient( 136deg, #DF5C8E 0%, #DF5C8E 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: 'linear-gradient( 136deg, #DF5C8E 0%, #DF5C8E 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage: 'linear-gradient( 136deg, #ffffff 0%, #ffffff 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      border: '3px solid #DF5C8E',
      color: '#DF5C8E',
    }),
    ...(ownerState.completed && {
      backgroundImage: 'linear-gradient( 136deg, #DF5C8E 0%, #DF5C8E 100%)',
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
  
    const icons: { [index: string]: React.ReactElement } = {
      1: step === 1 ? <FiberManualRecordIcon /> : (step === 2 || step === 3 ? <CheckIcon /> : <CheckIcon />),
      2: step === 1 ? <label></label> : (step === 2 ? <FiberManualRecordIcon /> : (step === 3 ? <CheckIcon /> : <CheckIcon />)),
      3: step === 1 ? <label></label> : (step === 2 ? <label></label> : (step === 3 ? <FiberManualRecordIcon /> : <CheckIcon />)),
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const stepsLabel = ['ข้อมูลส่วนตัว', 'ข้อมูลสังกัด', 'เอกสาร'];

  return (
    <div className="register-container">
      
        <HeaderMenuBar />

        <div className="register-card">
          <Typography
            variant="h5"
            align="center"
            style={{
              fontWeight: "bold",
              paddingTop: "30px",
              paddingBottom: "20px",
            }}
          >
            ลงทะเบียน (Register)
          </Typography>
          <Typography align="center" style={{}}>
            ภายหลังจากการกรอกข้อมูลลงทะเบียนเรียบร้อยแล้ว
            ระบบจะแจ้งผลการอนุมัติผู้ใช้งานผ่านทางอีเมล
          </Typography>
          <Typography align="center" style={{}}>
            กรุณาระบุข้อมูลที่มี <span style={{ color: "red" }}>*</span>
          </Typography>

          <Stack spacing={1} style={{paddingTop: "40px"}} className="stepper">
            <Stepper alternativeLabel activeStep={step-1} connector={<ColorlibConnector />}>
              {stepsLabel.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>

          {stepComponent}

        </div>

      <Footer />

    </div>
  );
};

export default Register;

import axios from "axios";

const BASE_URL =
  "https://service.obesityconnects.com/v1/dataCollection";

export const dataCollectionService = {
  getAllCollection: async function (targetUserId?: number) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/daily/mobile/list/all`,
        {
          targetUserId: targetUserId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // include the token in the Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import ExcelExport from "../../components/ExcelExport";
import { Layout } from "../../Layout";
import { ReportFiltering } from "../../shared/ReportFiltering";
import { reportValues } from "../../sharedValue";
import { title16500 } from "../../style";
import { theme2 } from "../../theme";

export type FilterItem = {
  name: string;
  minDate: string;
  maxDate: string;
  sex: string;
  age: string[];
  // minAge: number;
  // maxAge: number;
  minWeightFirstVisitValue: number;
  maxWeightFirstVisitValue: number;
  minWeightCurrentValue: number;
  maxWeightCurrentValue: number;
  minBmiFirstVisit: number,
  maxBmiFirstVisit: number,
  minBmiCurrent: number,
  maxBmiCurrent: number,
  minHeight: number;
  maxHeight: number;
  comorbidities: string[];
  surgeryType: string;
  // surgeryNo: number;
  surgeryCount: string;
  pwl: string;
  ewl: string;
  twl: string;
  // minPwl: number;
  // maxPwl: number;
  ultrasound: string;
  endoscopy: string;
  fibroScan: string;
  unComorbidities: string[];
  complication: string[];
  followUp: string[];
  pathology: string[];
};

export const Report = () => {
  const filterRef = useRef<HTMLDivElement>(null);
  const [reportType, setReportType] = useState("");
  const [filter, setFilter] = useState<FilterItem>();
  const [isHideFilter, setIsHideFilter] = useState(false);
  const [boxHeight, setBoxHeight] = useState("calc(100vh - 120px)");

  useEffect(() => {
    const handleResize = () => {
      if (filterRef.current) {
        const stackHeight = filterRef.current.clientHeight;
        const calculatedHeight =
          stackHeight > 56
            ? `calc(100vh - ${stackHeight}px)`
            : "calc(100vh - 120px)";
        setBoxHeight(calculatedHeight);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filter, isHideFilter]);
  const currentReport = useMemo(() => {
    return reportValues.find((item) => item.reportType === reportType);
  }, [reportType]);

  const handleApplyFilter = (newFilter: FilterItem) => {
    setFilter(newFilter);
    console.log("Applied Filter:", newFilter);
  };
  return (
    <Layout activeTab="report">
      <ThemeProvider theme={theme2}>
        <Box sx={{ p: "20px", width: 1, boxSizing: "border-box" }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              รายงาน
            </Typography>
          </Box>
          <Box ref={filterRef}>
            <ReportFiltering
              filter={filter}
              reportType={reportType}
              isHideFilter={isHideFilter}
              onApplyFilter={handleApplyFilter}
              setReportType={setReportType}
              setFilter={setFilter}
              setIsHideFilter={setIsHideFilter}
            />
          </Box>

          <Box
            sx={{
              bgcolor: "white",
              mt: 2,
              overflow: "auto",
              scrollbarWidth: "thin",
              p: "20px",
              boxSizing: "border-box",
              height: boxHeight,
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={18} fontWeight={600}>
                รายการข้อมูลที่ตรงตามเงื่อนไขที่ค้นหา
              </Typography>
              {reportType && (
                <ExcelExport
                  columns={currentReport?.columns as any[]}
                  data={currentReport?.data as any[]}
                  fileName={currentReport?.reportType as string}
                  reportTitle={currentReport?.reportTitle as string}
                  reportDescription={currentReport?.reportDescription as string}
                />
              )}
            </Stack>
            <Box mt={"20px"}>
              {reportType && (
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      minWidth: currentReport?.columns.length
                        ? currentReport?.columns.length * 180
                        : 1500,
                      boxSizing: "border-box",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {currentReport?.columns.map((item, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              textAlign: item.align,
                            }}
                          >
                            {item.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentReport?.data?.map((item: any, index) => (
                        <TableRow key={index} style={{ padding: 0 }}>
                          {reportValues
                            .find((x) => x.reportType === reportType)
                            ?.columns.map((column, colIndex) => {
                              const value = item[column.key];
                              return (
                                <TableCell
                                  key={colIndex}
                                  sx={{
                                    textAlign: column.align,
                                  }}
                                >
                                  {value === true
                                    ? "Yes"
                                    : value === false
                                    ? "No"
                                    : value}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
            {!reportType && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 20,
                  color: "#9AA1AE",
                  py: 2,
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  height: "100px",
                  mt: 5,
                }}
              >
                --ไม่พบข้อมูล--
              </Box>
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </Layout>
  );
};

import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  Box,
  InputLabel,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import "./UserFiltering.css"; // Import a CSS file for styling
import { userManagementService } from "../service/userManagementService";
import { useEffectOnce } from "react-use";
import { title16500 } from "../style";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface ApproveUserFilteringProps {
  handleSearch: (data: {
    name: string;
    userPermission: string;
    selectedStartDate: string;
    selectedEndDate: string;
  }) => void;
  handleClearFiltering: () => void;
}

const ApproveUserFiltering: React.FC<ApproveUserFilteringProps> = ({
  handleSearch,
  handleClearFiltering,
}) => {
  const [name, setName] = useState("");
  const [userPermission, setUserPermission] = useState("");
  const [userPermissionList, setUserPermissionList] = useState<any[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleUserPermissionDropdownChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setUserPermission(event.target.value);
  };

  const handleStartedDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedStartDate(formattedDate);
  };

  const handleEndedDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedEndDate(formattedDate);
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const userPermissionList =
          await userManagementService.getUserPermission();
        setUserPermissionList(userPermissionList);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
  });

  const handleClickSearch = () => {
    handleSearch({ name, userPermission, selectedStartDate, selectedEndDate });
  };

  const handleClickClearFiltering = () => {
    return () => {
      setName("");
      setUserPermission("");
      setSelectedStartDate("");
      setSelectedEndDate("");
      handleClearFiltering();
    };
  };

  return (
    <div style={{ overflow: "auto" }}>
      <Box sx={{ py: 2 }}>
        <Typography component={"span"} style={title16500}>
          ผู้ใช้งานและสิทธิ์
        </Typography>
        <Typography component={"span"} color={"#7A8688"} style={title16500}>
          {` / อนุมัติผู้ใช้งาน`}
        </Typography>
      </Box>

      <Box sx={{ py: 3, px: 3, bgcolor: "white" }}>
        <p
          style={{
            ...title16500,
            borderBottom: "2px solid #D8D8D8",
            marginTop: "0px",
          }}
        >
          <span style={{ borderBottom: "3px solid #DF5C8E", fontSize: "18px" }}>
            อนุมัติผู้ใช้งาน
          </span>
        </p>
        <Box>
          <Grid container columnGap={3} sx={{ my: 2 }} alignItems="stretch">
            <Grid item xs={3} sm={3} md={3} lg={2}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                สิทธิ์การใช้งาน
              </Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel>-- เลือกสิทธิ์การใช้งาน --</InputLabel>
                <Select
                  label="Regular Dropdown"
                  value={userPermission}
                  onChange={handleUserPermissionDropdownChange}
                  MenuProps={{
                    sx: {
                      zoom: 100 * (100 / 85) + "%",
                      top: "0px",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: "250px",
                        scrollbarWidth: "thin",
                      },
                    },
                  }}
                >
                  {userPermissionList.map((userPermission) => (
                    <MenuItem value={userPermission.id} sx={{ fontSize: 14 }}>
                      {userPermission.nameThai}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                ค้นหา ชื่อ-นามสกุล{" "}
                {/* <img src={InfoIcon} style={{ width: "15px", height: "15px" }} /> */}
              </Typography>
              <TextField
                label="-- ระบุคำที่ต้องการค้นหา --"
                variant="outlined"
                value={name}
                onChange={handleNameChange}
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                วันที่เริ่มต้น
              </Typography>
              <DatePicker
                maxDate={dayjs()}
                value={selectedStartDate ? dayjs(selectedStartDate) : null}
                onAccept={(newValue) => handleStartedDateChange(newValue)}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    fontSize: 16,
                  },
                  width: "100%",
                }}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                วันที่สิ้นสุด
              </Typography>
              <DatePicker
                maxDate={dayjs()}
                minDate={selectedStartDate ? dayjs(selectedStartDate) : dayjs()}
                value={selectedEndDate ? dayjs(selectedEndDate) : null}
                onAccept={(newValue) => handleEndedDateChange(newValue)}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    fontSize: 16,
                  },
                  width: "100%",
                }}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={1} pt={4}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#DF5C8E",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={handleClickSearch}
              >
                ค้นหา
              </Button>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={1} pt={4}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#7A8688",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }} // adjust the height as needed
                onClick={handleClickClearFiltering()}
              >
                ล้างการค้นหา
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default ApproveUserFiltering;

import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { title10Tab } from "../../../style";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { StatusBottomBar } from "../StatusBottomBar";
import { GynecologicFemale } from "./GynecologicFemale";
import { GynecologicContraception } from "./GynecologicContraception";
import { LayoutTab } from "../LayoutTab";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useEffectOnce } from "react-use";
import { visitDataService } from "../../../service/visitDataService";
import { useMutation } from "@tanstack/react-query";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { enqueueSnackbar } from "notistack";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import { GynecologicHistoryDialog } from "./GynecologicHistoryDialog";
import { GynecologicVisitLogDialog } from "./GynecologicVisitLogDialog";
import { masterDataService } from "../../../service/masterDataService";

export type PregnancyHistory = {
  year: string;
  technique: string;
  complicationsDuringPregnancy: string;
  complicationDetail: string;
};
export type ContraceptionState = {
  frequencyOfSexualIntercourse: string;
  other: boolean;
  selectedContraceptions?: number[];
};
export type ContraceptionFemalState = {
  pregnancyIntention: string;
  menstruationRegular: string;
  menstrualStatus: string;
  menstrualFrequently: string;
  hasBeenPregnant: string;
  cycleLength: number;
  menstruationLength: number;
};
export type PregnancyDetail = {
  method: string;
  year: string;
  complication: boolean;
  complicationDetail: string;
};

export type GynecologicResponse = {
  entryId: number;
  editorId: number | string;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  sexuallyActive: boolean;
  sexFrequency: number;
  contraception: number[];
  contraptionMethodOther: string[];
  pregnancyIntention: boolean;
  pregnancyPlan: number;
  regularMenstruation: boolean;
  stillHaveMenstruation: boolean;
  regularMenstruationPastThreeMonth: boolean;
  menstruationGap: number;
  menstruationDuration: number;
  pregnancyHistory: boolean;
  pregnancyDetail: PregnancyDetail[];
};
type Props = {
  handleCancelClick: () => void;
};

export const Gynecologic = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(2);
  const [validationError, setValidationError] = useState(false);
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState(false);
  const [openNoPermissionDialog, setOpenNoPermissionDialog] =
    useState(false);
    const userId = localStorage.getItem("userId") ?? "";
  
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  
  const latestEntryId = useAppSelector(
    (state) => state.patientUser.latestEntryIds?.gynecologicConditionsStatus
  );
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.gynecologicConditionsStatus
  );
  const [sexuallyActive, setSexuallyActive] = useState<boolean | undefined>(
    false
  );
  const [pregnancyPlan, setPregnancyPlan] = useState<number>(0);
  const [otherDetails, setOtherDetails] = useState([""]);
  const [pregnancyHistoryList, setPregnancyHistoryList] = useState<
    PregnancyHistory[]
  >([
    {
      year: "",
      technique: "",
      complicationsDuringPregnancy: "",
      complicationDetail: "",
    },
  ]);
  const [contraceptionValues, setContraceptionValues] =
    useState<ContraceptionState>({
      frequencyOfSexualIntercourse: "",
      other: false,
    });
  const [contraceptionFemaleValues, setContraceptionFemaleValues] =
    useState<ContraceptionFemalState>({
      pregnancyIntention: "",
      menstruationRegular: "",
      menstrualStatus: "",
      menstrualFrequently: "",
      hasBeenPregnant: "",
      cycleLength: 0,
      menstruationLength: 0,
    });
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openVisitLogDialog, setOpenVisitLogDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const contraceptionMasterData = await masterDataService.getContraceptionMasterData(); 
      const activeContraceptionMasterData = contraceptionMasterData.filter((item : any) => item.isActivated && item.name !== "อื่นๆ");
      const activeContraceptionIds = new Set(activeContraceptionMasterData.map((item : any) => item.id));

      if (currentEntryId) {
        const gynecologicData: GynecologicResponse =
          await visitDataService.getGynecologicFromEntryId(currentEntryId);
          setContraceptionValues({
            frequencyOfSexualIntercourse:
              gynecologicData.sexFrequency?.toString(),
            selectedContraceptions: gynecologicData.contraception.filter(id => activeContraceptionIds.has(id)),
            other: gynecologicData.contraptionMethodOther ? true : false,
          });
        setContraceptionFemaleValues({
          pregnancyIntention: gynecologicData.sexuallyActive
            ? gynecologicData.pregnancyIntention
              ? "yes"
              : "no"
            : "",
          menstruationRegular: gynecologicData.sexuallyActive
            ? gynecologicData.regularMenstruationPastThreeMonth
              ? "yes"
              : "no"
            : "",
          menstrualStatus: gynecologicData.sexuallyActive
            ? gynecologicData.stillHaveMenstruation
              ? "yes"
              : "no"
            : "",
          menstrualFrequently: gynecologicData.sexuallyActive
            ? gynecologicData.regularMenstruation
              ? "yes"
              : "no"
            : "",
          hasBeenPregnant: gynecologicData.sexuallyActive
            ? gynecologicData.pregnancyHistory
              ? "yes"
              : "no"
            : "",
          cycleLength: gynecologicData.menstruationGap,
          menstruationLength: gynecologicData.menstruationDuration,
        });
        setOtherDetails(gynecologicData.contraptionMethodOther ?? [""]);
        setSexuallyActive(gynecologicData.sexuallyActive);
        setStatus(gynecologicData.visitDataStatus);
        const transformedData = gynecologicData.sexuallyActive
          ? gynecologicData.pregnancyDetail?.map((item) => ({
              year: item.year,
              technique: item.method,
              complicationsDuringPregnancy: item.complication ? "Yes" : "No",
              complicationDetail: item.complicationDetail ?? "",
            }))
          : [
              {
                year: "",
                technique: "",
                complicationsDuringPregnancy: "",
                complicationDetail: "",
              },
            ];
        setPregnancyHistoryList(transformedData);
        setPregnancyPlan(gynecologicData.pregnancyPlan);
      } else if (latestEntryId) {
        const gynecologicData: GynecologicResponse =
          await visitDataService.getGynecologicFromEntryId(latestEntryId);
          setContraceptionValues({
            frequencyOfSexualIntercourse:
              gynecologicData.sexFrequency?.toString(),
            selectedContraceptions: gynecologicData.contraception.filter(id => activeContraceptionIds.has(id)),
            other: gynecologicData.contraptionMethodOther ? true : false,
          });
        setContraceptionFemaleValues({
          pregnancyIntention: gynecologicData.sexuallyActive
            ? gynecologicData.pregnancyIntention
              ? "yes"
              : "no"
            : "",
          menstruationRegular: gynecologicData.sexuallyActive
            ? gynecologicData.regularMenstruationPastThreeMonth
              ? "yes"
              : "no"
            : "",
          menstrualStatus: gynecologicData.sexuallyActive
            ? gynecologicData.stillHaveMenstruation
              ? "yes"
              : "no"
            : "",
          menstrualFrequently: gynecologicData.sexuallyActive
            ? gynecologicData.regularMenstruation
              ? "yes"
              : "no"
            : "",
          hasBeenPregnant: gynecologicData.sexuallyActive
            ? gynecologicData.pregnancyHistory
              ? "yes"
              : "no"
            : "",
          cycleLength: gynecologicData.menstruationGap,
          menstruationLength: gynecologicData.menstruationDuration,
        });
        setOtherDetails(gynecologicData.contraptionMethodOther ?? [""]);
        setPregnancyPlan(gynecologicData.pregnancyPlan);
        setSexuallyActive(gynecologicData.sexuallyActive);
        setStatus(gynecologicData.visitDataStatus);
        const transformedData = gynecologicData.sexuallyActive
          ? gynecologicData.pregnancyDetail?.map((item) => ({
              year: item.year,
              technique: item.method,
              complicationsDuringPregnancy: item.complication ? "Yes" : "No",
              complicationDetail: item.complicationDetail,
            }))
          : [
              {
                year: "",
                technique: "",
                complicationsDuringPregnancy: "",
                complicationDetail: "",
              },
            ];
        setPregnancyHistoryList(transformedData);
      }
    };
    fetchData();
  }, [openNoPermissionDialog]);
  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.saveGynecologicData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        sexuallyActive: sexuallyActive,
        sexFrequency: sexuallyActive
          ? Number(contraceptionValues.frequencyOfSexualIntercourse)
          : null,
        contraception: sexuallyActive
          ? contraceptionValues.selectedContraceptions || []
          : [],
        contraptionMethodOther:
          sexuallyActive && contraceptionValues.other ? otherDetails : null,
        pregnancyIntention:
          sexuallyActive &&
          contraceptionFemaleValues.pregnancyIntention === "yes"
            ? true
            : false,
        pregnancyPlan: sexuallyActive ? pregnancyPlan : 0,
        regularMenstruation:
          sexuallyActive &&
          contraceptionFemaleValues.menstrualStatus === "yes" &&
          contraceptionFemaleValues.menstrualFrequently === "yes"
            ? true
            : false,
        stillHaveMenstruation:
          sexuallyActive && contraceptionFemaleValues.menstrualStatus === "yes"
            ? true
            : false,
        regularMenstruationPastThreeMonth:
          sexuallyActive &&
          contraceptionFemaleValues.menstruationRegular === "yes"
            ? true
            : false,
        menstruationGap:
          sexuallyActive &&
          contraceptionFemaleValues.menstrualStatus === "yes" &&
          contraceptionFemaleValues.menstrualFrequently === "yes"
            ? contraceptionFemaleValues.cycleLength
            : 0,
        menstruationDuration:
          sexuallyActive &&
          contraceptionFemaleValues.menstrualStatus === "yes" &&
          contraceptionFemaleValues.menstrualFrequently === "yes"
            ? contraceptionFemaleValues.menstruationLength
            : 0,
        pregnancyHistory:
          sexuallyActive && contraceptionFemaleValues.hasBeenPregnant === "yes"
            ? true
            : false,
        pregnancyDetail:
          sexuallyActive && contraceptionFemaleValues.hasBeenPregnant === "yes"
            ? pregnancyHistoryList?.map((item) => ({
                method: item.technique,
                year: item.year,
                complication:
                  item.complicationsDuringPregnancy === "Yes" ? true : false,
                complicationDetail: item.complicationDetail,
              }))
            : null,
      });

      return response as GynecologicResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          gynecologicConditionsStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleSexuallyActiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLInputElement).value;
    value === "yes" ? setSexuallyActive(true) : setSexuallyActive(false);
    dispatch(updateTabsIsDirty(true));
  };

  const handleContraceptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;
    setContraceptionValues({
      ...contraceptionValues,
      [name]: type === "checkbox" ? checked : value,
    });
    dispatch(updateTabsIsDirty(true));
  };
  const handleContraceptionFemaleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type } = event.target;
    if (name === "hasBeenPregnant") {
      setPregnancyHistoryList([
        {
          year: "",
          technique: "",
          complicationsDuringPregnancy: "",
          complicationDetail: "",
        },
      ]);
    }
    if (type === "number") {
      setContraceptionFemaleValues({
        ...contraceptionFemaleValues,
        [name]: parseInt(value, 10),
      });
    } else {
      setContraceptionFemaleValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    dispatch(updateTabsIsDirty(true));
  };
  const handleAdd = () => {
    const newItem = {
      // id: pregnancyHistoryList.length + 1,
      year: "",
      technique: "",
      complicationsDuringPregnancy: "",
      complicationDetail: "",
    };
    setPregnancyHistoryList([...pregnancyHistoryList, newItem]);
    dispatch(updateTabsIsDirty(true));
  };
  const handleDelete = (index: number) => {
    // setPregnancyHistoryList(
    //   pregnancyHistoryList.filter((item) => item.id !== id)
    // );
    setPregnancyHistoryList((prev) => prev.filter((_, i) => i !== index));
    dispatch(updateTabsIsDirty(true));
  };
  const handlePregnancyPlanChange = (
    event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.PointerEvent<Element>
      | React.KeyboardEvent<Element>,
    newValue: number | null
  ) => {
    if (newValue !== null) {
      setPregnancyPlan(newValue);
      dispatch(updateTabsIsDirty(true));
    }
  };
  const handleSubmitData = async () => {
    if(!patientDataPermissionEditAble){
      setOpenNoPermissionDialog(true);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    if (contraceptionValues.other) {
      if (validateOtherRequiredFields()) {
        setValidationError(false);
        await submitDataAsync();
        dispatch(updateTabsIsDirty(false));
      } else {
        setValidationError(true);
      }
    } else {
      if (sexuallyActive) {
        if (store.getState().patientUser.patientUser?.sex === "male") {
          if (validateContraceptionValues(contraceptionValues)) {
            await submitDataAsync();
            setValidationError(false);
            dispatch(updateTabsIsDirty(false));
          } else {
            setOpenIncompleteDataDialog(true);
          }
        } else if (store.getState().patientUser.patientUser?.sex === "female") {
          if (
            validateContraceptionValues(contraceptionValues) &&
            contraceptionFemaleValues.pregnancyIntention !== "" &&
            contraceptionFemaleValues.menstruationRegular !== "" &&
            contraceptionFemaleValues.menstrualStatus !== "" &&
            (contraceptionFemaleValues.menstrualStatus === "yes"
              ? contraceptionFemaleValues.menstrualFrequently !== ""
              : true) &&
            contraceptionFemaleValues.hasBeenPregnant !== "" &&
            (contraceptionFemaleValues.hasBeenPregnant === "yes"
              ? validatePregnancyHistoryList(pregnancyHistoryList)
              : true)
          ) {
            await submitDataAsync();
            setValidationError(false);
            dispatch(updateTabsIsDirty(false));
          } else {
            setOpenIncompleteDataDialog(true);
          }
        }
      } else {
        await submitDataAsync();
        setValidationError(false);
        dispatch(updateTabsIsDirty(false));
      }
    }
    // await submitDataAsync();
  };
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
    dispatch(updateTabsIsDirty(true));
  };
  const handlePregnancyHistoryChange = (newList: PregnancyHistory[]) => {
    setPregnancyHistoryList(newList);
    dispatch(updateTabsIsDirty(true));
  };
  const handleOtherAdd = () => {
    setOtherDetails((prevDetails) => [...prevDetails, ""]);
    dispatch(updateTabsIsDirty(true));
  };
  const handleOtherRemove = (index: number) => {
    if (otherDetails.length !== 1) {
      const newDetails = otherDetails.filter((_, i) => i !== index);
      setOtherDetails(newDetails);
      dispatch(updateTabsIsDirty(true));
    }
  };
  const handleOtherChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newDetails = [...otherDetails];
    newDetails[index] = event.target.value;
    setOtherDetails(newDetails);
    dispatch(updateTabsIsDirty(true));
  };
  const validateContraceptionValues = (values: ContraceptionState): boolean => {
    const { frequencyOfSexualIntercourse, ...rest } = values;
    const hasSelectedContraceptions = rest.selectedContraceptions && rest.selectedContraceptions.length > 0;
    const hasOther = rest.other === true;
    return frequencyOfSexualIntercourse !== "" && (hasSelectedContraceptions || hasOther);
  };
  const validateOtherRequiredFields = () => {
    for (let detail of otherDetails) {
      if (!detail.trim()) {
        return false;
      }
    }
    return true;
  };
  const validatePregnancyHistoryList = (list: PregnancyHistory[]): boolean => {
    return list.every(
      (item) =>
        item.year.trim() !== "" &&
        item.technique.trim() !== "" &&
        item.complicationsDuringPregnancy.trim() !== "" &&
        (item.complicationsDuringPregnancy === "Yes"
          ? item.complicationDetail.trim() !== ""
          : true)
    );
  };
  const handleCloseDialog = () => {
    setOpenIncompleteDataDialog(false);
    setOpenNoPermissionDialog(false);
    setOpenHistoryDialog(false);
    setOpenVisitLogDialog(false);
  };
  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box sx={{ p: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => setOpenVisitLogDialog(true)}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Typography style={title10Tab}>Sexually active</Typography>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color={"#646D78"}>
              Sexually active (ever had sexual intercourse){" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box>
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    color="default"
                    style={{ color: "#098484" }}
                    onChange={(e) => handleSexuallyActiveChange(e)}
                  />
                }
                label="Yes"
                checked={sexuallyActive === true}
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    color="default"
                    style={{ color: "#098484" }}
                    onChange={(e) => handleSexuallyActiveChange(e)}
                  />
                }
                label="No"
                checked={sexuallyActive === false}
              />
            </Box>
          </Stack>
          <Box>
            {sexuallyActive && (
              <GynecologicContraception
                ContraceptionValues={contraceptionValues}
                setContraceptionValues={setContraceptionValues}
                handleContraceptionChange={handleContraceptionChange}
                otherDetails={otherDetails}
                handleOtherAdd={handleOtherAdd}
                handleOtherChange={handleOtherChange}
                handleOtherRemove={handleOtherRemove}
                validationError={validationError}
              />
            )}
          </Box>
          <Box>
            {sexuallyActive &&
              store.getState().patientUser.patientUser?.sex === "female" && (
                <GynecologicFemale
                  contraceptionFemaleValues={contraceptionFemaleValues}
                  handleContraceptionFemaleChange={
                    handleContraceptionFemaleChange
                  }
                  handleAdd={handleAdd}
                  handleDelete={handleDelete}
                  pregnancyHistoryList={pregnancyHistoryList}
                  handlePregnancyHistoryChange={handlePregnancyHistoryChange}
                  handlePregnancyPlanChange={handlePregnancyPlanChange}
                  pregnancyPlan={pregnancyPlan}
                />
              )}
          </Box>
          <StatusBottomBar
            value={status}
            handleStatusChange={handleStatusChange}
          />
        </Box>
      </Box>
       <GynecologicHistoryDialog
        open={openHistoryDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <GynecologicVisitLogDialog
        open={openVisitLogDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openIncompleteDataDialog}
        title="ข้อมูลไม่ครบถ้วน"
        content="กรุณาระบุข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
    </LayoutTab>
  );
};

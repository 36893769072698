import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#DF5C8E",
    },
    secondary: {
      main: "#098484",
    },
    text: {
      primary: "#080C2F",
      secondary: "#9AA1AE",
    },
    background: {
      default: "#DFEAEB80",
    },
    success: {
      main: "#5DC472",
    },
  },
  typography: {
    fontFamily: ["Sarabun", "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
  },
});
export const theme2 = createTheme({
  palette: {
    primary: {
      main: "#098484",
    },
    secondary: {
      main: "#DF5C8E",
    },
    text: {
      primary: "#080C2F",
      secondary: "#9AA1AE",
    },
    background: {
      default: "#DFEAEB80",
    },
    success: {
      main: "#5DC472",
    },
  },
  typography: {
    fontFamily: ["Sarabun", "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          "&.Mui-selected": {
            backgroundColor: "#098484",
            color: "#ffffff",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#098484",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 16,
          padding: "12px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "inherit",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 18,
          fontWeight: 700,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          sx: { zoom: 100 * (100 / 85) + "%", top: "0px" },
          PaperProps: {
            sx: {
              maxHeight: "300px",
              scrollbarWidth: "thin",
              minWidth: "0px !important",
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
  },
});

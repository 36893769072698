import React, { useEffect, useState } from "react";
import HeaderMenuBar from "../../HeaderMenuBar";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Footer from "../../Footer";
import "./Register.css";
import Stack from "@mui/material/Stack";
import { ArrowForward } from "@mui/icons-material";
import TOS from "../../asset/TOS.png";
import { useLocation, useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { userManagementService } from "../../service/userManagementService";
import { ConsentData } from "../../type";

const TermOfService = () => {
  // State for storing the values of the three filter fields
  const [step, setStep] = useState(1);
  const [acceptTOS, setAcceptTOS] = useState(false);
  const [consentData, setConsentData] = useState<ConsentData>();
  const location = useLocation();
  const { status, email, password, tokenId, userId } = location.state || {};

  const navigate = useNavigate();
  useEffectOnce(() => {
    userManagementService.getLatestConsent().then((response) => {
      setConsentData(response);
    });
  });

  return (
    <div className="register-container">
      <HeaderMenuBar />

      <div className="register-card">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={TOS}
            style={{ width: "60px", height: "60px", marginRight: "10px" }}
          />
          <Typography
            variant="h5"
            align="center"
            style={{
              fontWeight: "bold",
              paddingTop: "30px",
              paddingBottom: "20px",
            }}
          >
            Term of Service
          </Typography>
        </div>
        {/* <Typography align="center">Updated 10 มกราคม 2567</Typography> */}

        <Stack spacing={1} style={{ paddingTop: "40px", padding: "50px" }}>
          {consentData?.termAndCondition?.content && (
            <div
              dangerouslySetInnerHTML={{
                __html: consentData.termAndCondition.content,
              }}
            />
          )}
          {/* <p>
            <span style={{ fontWeight: 400 }}>
              ศูนย์รักษ์พุง ศูนย์จุฬาลงกรณ์ สภากาชาดไทย (&ldquo;ศูนย์&rdquo;)
              เป็นผู้พัฒนาและเจ้าของ ระบบ{" "}
            </span>
            <strong>
              Chula Wellness and Obesity Network รวมไปถึง Line Official account
              @Obesityconnects{" "}
            </strong>
            <span style={{ fontWeight: 400 }}>
              ซึ่งถือเป็นบริการ หนึ่งของศูนย์ (&ldquo;บริการ&rdquo;)
              เมื่อท่านสมัครบัญชีใช้งานของศูนย์ผ่านเว็บไซต์ Obesityconnects.com
              หรือผ่าน{" "}
            </span>
            <strong>Line Official account @Obesityconnects </strong>
            <span style={{ fontWeight: 400 }}>
              ท่านจะถือว่าเป็น ผู้ใช้บริการของศูนย์ (&ldquo;ผู้ใช้บริการ&rdquo;)
              โดยมีวัตถุประสงค์เพื่อการให้บริการเก็บรวบรวมข้อมูลคนไข้ทั้งก่อนและหลังผ่าตัด&nbsp;
              แสดงข้อมูลภาพรวมและรายบุคคลของคนไข้ก่อนและหลังผ่าตัด
              รวมถึงสามารถจัดทำรายงานตามข้อกำหนดของผู้ใช้บริการได้
              และเมื่อผู้ใช้บริการเว็บไซต์นี้กด &ldquo;ตกลง&rdquo;
              ผู้ใช้บริการจะต้องถูกผูกพันตามข้อตกลงและเงื่อนไขของเว็บไซต์นี้
              ดังนั้นขอให้ท่านโปรดอ่านข้อตกลงและเงื่อนไขเหล่านี้ด้วยความระมัดระวังก่อนทำธุรกรรมใด
              ๆ บนเว็บไซต์หรือบริการนี้
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              กรุณาอ่านข้อตกลงและเงื่อนไขการใช้บริการเว็บไซต์นี้โดยละเอียดถี่ถ้วน
              ก่อนเข้าใช้งานเว็บไซต์
              ท่านต้องยอมรับข้อตกลงและเงื่อนไขนี้ก่อนเข้าใช้งานเว็บไซต์
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
          </p>
          <ol>
            <li>
              <strong> ข้อจำกัดสิทธิในการใช้บริการ</strong>
            </li>
          </ol>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              1.1
              ผู้ใช้บริการสามารถใช้บริการได้เฉพาะเพื่อวัตถุประสงค์ที่ตั้งใจไว้เท่านั้น
              และอาจใช้ในกรณีอื่นใดได้ก็ต่อเมื่อได้รับอนุญาตตามกฎหมาย
              ระเบียบข้อบังคับที่เกี่ยวข้องและข้อกำหนดเหล่านี้เท่านั้น
            </span>
          </p>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              1.2
              การใช้บริการของผู้ใช้บริการไม่ได้ทำให้ท่านเป็นเจ้าของสิทธิในทรัพย์สินทางปัญญาในบริการหรือเนื้อหาอื่นใดที่ท่านอาจเข้าถึงได้ซึ่งเป็นของศูนย์
              นอกจากนี้ท่านไม่สามารถใช้โลโก้ เครื่องหมายการค้า
              ตราสินค้า หรือเนื้อหาอื่นใดที่อาจใช้หรือจัดให้อยู่ในบริการของศูนย์โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรอย่างชัดแจ้ง
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}></span>
          </p>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              1.3 โปรดทราบว่าศูนย์อาจดำเนินการแก้ไข เปลี่ยนแปลง ระงับ
              หรือหยุดการให้บริการส่วนหนึ่งส่วนใดหรือทั้งหมดได้ตลอดเวลา
              โดยตั้งเป้าหมายที่จะแจ้งให้ทราบมากที่สุดเท่าที่จะสามารถทำได้ตามที่เห็นสมควรตามดุลยพินิจของศูนย์
            </span>
          </p>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              1.4 ผู้ใช้บริการไม่มีสิทธิคัดลอก แก้ไข แจกจ่าย ขาย แปล ดัดแปลง
              แยกส่วน วิศกรรมย้อนกลับ ดึงซอร์สโค้ด (Source Code)
              ไม่ว่าโดยตรงหรือโดยอ้อม สื่อสาร ดำเนินการ จัดการ ทำซ้ำหรือผลิตซ้ำ
              สร้างงานลอกเลียนแบบจากหรือตาม เผยแพร่ซ้ำ อัปโหลด เชื่อมโยง
              หรือใช้ประโยชน์จากส่วนใดส่วนหนึ่งหรือทั้งหมดของบริการอย่างใดอย่างหนึ่งซึ่งเป็นของศูนย์ไม่ว่าจะเป็น
              ซอร์สโค้ด ซอฟต์แวร์ เนื้อหา
              สภาพแวดล้อมคอมพิวเตอร์ในเครือข่ายหรือนอกเครือข่ายที่เกี่ยวข้อง
              สื่อใด ซึ่งทั้งหมดนี้เป็นของศูนย์
              หากฝ่าฝืนกรณีดังกล่าวข้างต้นจะถือว่าเป็นการใช้งานโดยไม่ได้รับอนุญาตทั้งสิ้นซึ่งถือเป็นการละเมิดลิขสิทธิ์และกรรมสิทธิ์อื่น
              ๆ ของศูนย์ และ/หรือ
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              ผู้อนุญาตและผู้ให้บริการเนื้อหา
              อันมีลักษณะเข้าข่ายเป็นการละเมิดต่อกฎหมายลิขสิทธิ์อาจมีโทษทั้งทางแพ่งและทางอาญาอย่างรุนแรง
            </span>
          </p>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              1.5 ในการใช้บริการของเว็บไซต์นี้:
            </span>
          </p>
          <p style={{ paddingLeft: "90px" }}>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              - ผู้ใช้บริการจะต้องเป็นมนุษย์ซึ่งมีตัวตนจริงและสามารถระบุตัวตนได้
              &ldquo;บอท&rdquo;
              หรือการลงทะเบียนเข้าใช้งานด้วยวิธีการอัตโนมัติอื่นใดจะไม่ได้รับอนุญาตในการเข้าใช้บริการเว็บไซต์นี้
            </span>
          </p>
          <p style={{ paddingLeft: "90px" }}>
            <span style={{ fontWeight: 400 }}>
              - ผู้ใช้บริการจะต้องระบุชื่อนามสกุลตามกฎหมาย ที่อยู่อีเมล
              ที่ถูกต้องและสามารถใช้งานได้ รวมไปถึงข้อมูลอื่น ๆ
              ที่เว็บไซต์ร้องขอเพื่อให้ขั้นตอนการสมัครเสร็จสมบูรณ์
            </span>
          </p>
          <p style={{ paddingLeft: "90px" }}>
            <span style={{ fontWeight: 400 }}>-&nbsp;</span>
            <span style={{ fontWeight: 400 }}>
              ผู้ใช้บริการจะต้องมีอายุ 20
              ปีบริบูรณ์ขึ้นไปหรือบรรลุนิติภาวะภายใต้กฎหมายที่บังคับเพื่อเข้าใช้บริการ
              ในกรณีที่ผู้ใช้บริการมีอายุต่ำกว่า 20 ปีบริบูรณ์
              ท่านจะต้องได้รับความยินยอมจากผู้แทนโดยชอบธรรมหรือผู้ปกครองตามกฎหมายเพื่อเข้าใช้บริการ
            </span>
          </p>
          <p style={{ paddingLeft: "90px" }}>
            <span style={{ fontWeight: 400 }}>-&nbsp;</span>
            <span style={{ fontWeight: 400 }}>
              บัญชีหนึ่งจะสามารถเข้าสู่ระบบได้เพียงคนเดียวต่อการเข้าสู่ระบบหนึ่งครั้งและหนึ่งบัญชีเท่านั้น
              เว็บไซต์จะไม่อนุญาตให้คนหลายคนเข้าสู่ระบบด้วยบัญชีเดียวพร้อมกันในเวลาเดียวกัน
            </span>
          </p>
          <p style={{ paddingLeft: "90px" }}>
            <span style={{ fontWeight: 400 }}>
              -
              ผู้ใช้บริการมีหน้าที่รับผิดชอบในการรักษาความปลอดภัยของบัญชีและรหัสผ่านของท่านเอง
              รวมไปถึงการรักษาความปลอดภัยของบัญชีภายนอก
              ในกรณีที่ท่านใช้การเข้าสู่ระบบผ่านบัญชีของ Google (SSO)
              ศูนย์จะไม่รับผิดชอบอย่างใด ๆ
              ต่อความสูญหายหรือเสียหายอันเกิดจากการที่ท่านไม่ปฏิบัติตามภาระผูกพันด้านความปลอดภัยนี้
            </span>
          </p>
          <p style={{ paddingLeft: "90px" }}>
            <span style={{ fontWeight: 400 }}>
              - ผู้ใช้บริการจะต้องรับผิดชอบต่อเนื้อหา หรือข้อมูลที่กรอก
              รวมถึงกิจกรรมทั้งหมดที่เกิดขึ้นภายใต้การเข้าสู่ระบบบัญชีของท่านเอง
            </span>
          </p>
          <p style={{ paddingLeft: "90px" }}>
            <span style={{ fontWeight: 400 }}>
              -
              ผู้ใช้บริการไม่มีสิทธิในการใช้บริการซึ่งมีวัตถุประสงค์ในทางผิดกฎหมายหรือไม่ได้รับอนุญาตในการใช้บริการ
              และต้องไม่กระทำการใดอันเป็นการละเมิดกฎหมายภายใต้เขตอำนาจศาล
            </span>
          </p>
          <p style={{ paddingLeft: "90px" }}>&nbsp;</p>
          <ol start={2}>
            <li>
              <strong> เกี่ยวกับข้อมูลในเว็บไซต์</strong>
            </li>
          </ol>
          <p style={{ paddingLeft: "60px" }}><span style={{ fontWeight: 400 }}>ศูนย์พยายามที่จะเก็บรวบรวมข้อมูลของคนไข้ ให้ล่าสุดและถูกต้องอยู่ตลอดเวลา และทางศูนย์จะมีการเก็บข้อมูลในการทำธุรกรรมต่างๆบนเว็บไซต์ของท่าน เพื่อเป็นประวัติในการใช้บริการ ทั้งการเรียกดูข้อมูลของคนไข้ การเพิ่ม ลบ หรือแก้ไขข้อมูลคนไข้ รวมไปถึงการส่งออก (Download) ข้อมูลของคนไข้ออกจากระบบ เพื่อใช้ในการติดตาม และ/หรือ ตรวจสอบในกรณีที่มีการใช้ข้อมูลของคนไข้นอกวัตถุประสงค์ของการใช้งานระบบ</span></p>
          <p>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
          </p>
          <ol start={3}>
            <li>
              <strong> การจัดส่งข้อมูลในเว็บไซต์</strong>
            </li>
          </ol>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              3.1
              ข้อมูลทุกชนิดที่ท่านมีการกรอกหรือส่งข้อมูลให้ศูนย์ถือว่าเป็นข้อมูลที่ท่านยินยอมให้ศูนย์เป็นผู้ควบคุมและจัดเก็บข้อมูลนั้น
            </span>
          </p>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              3.2 เมื่อท่านใช้งานเว็บไซต์นี้ ท่านจะต้องยินยอมให้ และ/หรือ
              ศูนย์รวบรวมข้อมูลเกี่ยวกับท่านและการใช้งานเว็บไซต์ของท่าน
              หรือศูนย์มีสิทธิในการใช้ข้อมูลดังกล่าว ทั้งนี้
              ตามที่ได้ระบุไว้ในนโยบายความเป็นส่วนตัวของศูนย์
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
          </p>
          <ol start={4}>
            <li>
              <strong> การเชื่อมโยงกับเว็บไซต์อื่น ๆ</strong>
            </li>
          </ol>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              4.1 เว็บไซต์นี้อาจมีการเชื่อมต่อกับเว็บไซต์อื่น ๆ
              การเชื่อมต่อนี้เพื่อช่วยให้ท่านค้นหาเว็บไซต์หริอบริการที่เกี่ยวข้องได้อย่างสะดวกและง่ายดาย
            </span>
          </p>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              4.2 โปรดทราบว่าศูนย์ไม่มีอำนาจที่จะควบคุม
              รับรองยืนยันความถูกต้องหรือความน่าเชื่อถือ
              หรือรับผิดชอบในเนื้อหาของเว็บไซต์ของบุคคลภายนอก หรือการเชื่อมต่อใด
              ๆ ที่บรรจุอยู่ในเว็บไซต์ของบุคคลภายนอก การตัดสินใจว่าบทความ บริการ
              และ/หรือ
              ผลิตภัณฑ์ที่นำเสนอผ่านเว็บไซต์เหล่านี้สอดคล้องกับวัตถุประสงค์ของท่านหรือไม่นั้น
              เป็นความรับผิดชอบของท่านเอง
            </span>
          </p>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              4.3
              ศูนย์ไม่สามารถควบคุมและจะไม่รับผิดชอบเนื้อหาของเว็บไซต์เหล่านั้นใดๆ
              ทั้งสิ้น
              และศูนย์ไม่ได้มีส่วนเกี่ยวข้องในการกำหนดถึงการรับประกันใดๆ
              หรือต้องรับภาระค่าเสียหายที่เกี่ยวพันกับเจ้าของ
              หรือผู้ดำเนินงานของเว็บไซต์ใด ๆ
              (รวมทั้งความเสียหายที่เกิดจากข้ออ้างใด ๆ
              ก็ตามที่ละเมิดกฎหมายลิขสิทธิ์
              หรือทรัพย์สินทางปัญญาประเภทอื่นของเว็บไซต์ของบุคคลที่สามนั้น)
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
          </p>
          <ol start={5}>
            <li>
              <strong> เบ็ดเตล็ด</strong>
            </li>
          </ol>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              ในกรณีที่เกิดความล้มเหลวในการบังคับใช้สิทธิหรือข้อกำหนดใด ๆ
              ของศูนย์
              ไม่ถือเป็นการสละสิทธิในการบังคับใช้สิทธิหรือข้อกำหนดเหล่านั้น
              หากบทบัญญัติใด ๆ ของข้อกำหนดเหล่านี้ถูกตัดสินโดยองค์การตุลาการ
              องค์กรทางปกครองหรือองค์กรอื่นใดที่มีอำนาจตามกฎหมายว่าเป็นบทบัญญัติที่ไม่ถูกต้องหรือไม่สามารถใช้บังคับได้
              บทบัญญัติอื่น ๆ
              ที่เหลือของข้อกำหนดเหล่านี้จะยังคงมีผลบังคับใช้ต่อไปเท่าที่ถูกต้องและสามารถใช้บังคับได้ตามกฎหมาย
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
          </p>
          <ol start={6}>
            <li>
              <strong> การเปลี่ยนแปลงข้อตกลงและเงื่อนไข</strong>
            </li>
          </ol>
          <p style={{ paddingLeft: "60px" }}>
            <span style={{ fontWeight: 400 }}>
              การดำเนินการพัฒนาระบบหรือบริการของศูนย์เป็นไปอย่างต่อเนื่อง
              เพื่อพัฒนาประสบการณ์การใช้งานของท่านให้ดียิ่งขึ้น
              และอาจมีบริการอื่นๆเพิ่มเติมบนหน้าเว็บไซต์หรือบริการ
              ดังนั้นนโยบายความเป็นส่วนตัว (Privacy Policy)
              ข้อตกลงและเงื่อนไขการใช้บริการ (Terms and Conditions)
              อาจมีการเปลี่ยนแปลงบ้างเป็นครั้งคราว
              ศูนย์แนะนำให้ท่านตรวจสอบอย่างต่อเนื่องเพื่อติดตามถึงการเปลี่ยนแปลง
              แก้ไข หรือเพิ่มข้อกำหนดใด ๆ ที่เกี่ยวกับศูนย์
            </span>
          </p> */}
        </Stack>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "50px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  style={{ color: "#DF5C8E", paddingLeft: "50px" }}
                  value={acceptTOS}
                  onChange={() => setAcceptTOS(!acceptTOS)}
                  checked={acceptTOS}
                />
              }
              label="ยอมรับ ข้อกำหนดในการให้บริการ (Terms of Service)"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {acceptTOS ? (
              <Typography
                variant="body1"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: "50px",
                  color: "#DF5C8E",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  if (status === "consent_required") {
                    navigate("/privacyPolicy", {
                      state: {
                        status: "consent_required",
                        email: email,
                        password: password,
                        userId: userId,
                      },
                    });
                  } else if (status === "consent_required_SSO") {
                    navigate("/privacyPolicy", {
                      state: {
                        status: "consent_required_SSO",
                        tokenId: tokenId,
                        userId: userId,
                      },
                    });
                  } else {
                    navigate("/privacyPolicy");
                  }
                }}
              >
                ถัดไป
                <ArrowForward />
              </Typography>
            ) : null}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TermOfService;

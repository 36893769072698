import {
  Box,
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  createMuiTheme,
  makeStyles,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ChangeEvent, useEffect, useState } from "react";
import { BariatricSurgeryDialog } from "./BariatricSurgeryDialog";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { BariaticSurgeryData } from "../../../type";
import { visitDataService } from "../../../service/visitDataService";
import editIcon from "../../../asset/editIcon.png";
import deleteIcon from "../../../asset/deleteIcon.png";
import viewMode from "../../../asset/viewMode.png";
import { LayoutTab } from "../LayoutTab";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { store } from "../../../redux/store";
import { title10Tab } from "../../../style";
import { useMutation } from "@tanstack/react-query";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { enqueueSnackbar } from "notistack";
import { StatusBottomBar } from "../StatusBottomBar";
import { BariatricSurgeryDialogViewMode } from "./BariatricSurgeryDialogViewMode";
import { useEffectOnce } from "react-use";
import { BariatricSurgeryHistoryDialog } from "./BariatricSurgeryHistoryDialog";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import { userManagementService } from "../../../service/userManagementService";
import { set } from "date-fns";
import { BariatricSurgeryVisitLogDialog } from "./BariatricSurgeryVisitLogDialog";

type Props = {
  handleCancelClick: () => void;
};

const theme = createMuiTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          "&.Mui-selected": {
            backgroundColor: "#098484",
            color: "#ffffff",
          },
        },
      },
    },
  },
});

export const BariatricSurgery = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(2);
  const [originalStatus, setOriginalStatus] = useState(2);
  const [open, setOpen] = useState(false); // Add a state to control the dialog
  const [openViewMode, setOpenViewMode] = useState(false);
  const patientUser = useAppSelector((state) => state.patientUser.patientUser);
  const patientUserId = patientUser?.id ? patientUser.id : null;
  const latestEntryIds = useAppSelector(
    (state) => state.patientUser.latestEntryIds?.demographicDataStatus
  );
  const [isShowConfrimationDialog, setIsShowConfrimationDialog] =
    useState(false);
  const [comfrimToDelete, setComfrimToDelete] = useState(false);
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.bariaticSurgeryStatus
  );
  const currentVisitId = useAppSelector(
    (state) => state.patientUser?.selectedVisitId
  );
  const currentVisitDate = useAppSelector(
    (state) => state.patientUser?.selectedVisitDate
  );
  const [selectedEntryId, setSelectedEntryId] = useState<number | null>(null);

  const [bariaticSurgeryHistoryList, setBariaticSurgeryHistoryList] = useState<
    BariaticSurgeryData[]
  >([]);
  const [bariaticSurgeryHistoryLisTemp, setBariaticSurgeryHistoryLisTemp] =
    useState<BariaticSurgeryData[]>([]);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openVisitLogDialog, setOpenVisitLogDialog] = useState(false);

  const [newBariatricSurgeryData, setNewBariatricSurgeryData] =
    useState<BariaticSurgeryData | null>(null);
  const [viewBariatricSurgeryData, setViewBariatricSurgeryData] =
    useState<BariaticSurgeryData | null>(null);

  const [currentBariatricEntryId, setCurrentBariatricEntryId] = useState<
    number | null
  >();

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);

  const handleClickOpen = (selectedEntryId: number | null) => {
    setSelectedEntryId(selectedEntryId);
    let selectedData;
    if (selectedEntryId) {
      selectedData = bariaticSurgeryHistoryList.find(
        (data) => data.entryId === selectedEntryId
      );
    } else if (selectedEntryId === null && newBariatricSurgeryData) {
      selectedData = newBariatricSurgeryData;
    } else {
      selectedData = null;
    }
    setNewBariatricSurgeryData(selectedData || null);
    setOpen(true);
  };

  const handleClickOpenViewMode = (selectedEntryId: number | null) => {
    setSelectedEntryId(selectedEntryId);
    let selectedData;
    selectedData = bariaticSurgeryHistoryList.find(
      (data) => data.entryId === selectedEntryId
    );
    setViewBariatricSurgeryData(selectedData || null);
    setOpenViewMode(true);
  };
  useEffect(() => {}, [selectedEntryId, newBariatricSurgeryData]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDialog = () => {
    setOpenHistoryDialog(false);
    setOpenNoPermissionDialog(false);
    setOpenVisitLogDialog(false);
  };
  const handleOpenConfirmationDialog = (entryId: number | null) => {
    if (entryId !== null) {
      setCurrentBariatricEntryId(entryId);
    }
    setIsShowConfrimationDialog(true);
  };
  const handleCloseConfirmationDialog = () => {
    setIsShowConfrimationDialog(false);
    setComfrimToDelete(false);
  };
  const handleDelete = () => {
    setComfrimToDelete(true);
    setIsShowConfrimationDialog(false);
    handleDeleteBaraitricSurgeryData();
  };
  const handleCloseViewMode = () => {
    setOpenViewMode(false);
  };
  const handleSave = (data: BariaticSurgeryData) => {
    if (data.entryId === null) {
      if (bariaticSurgeryHistoryList.some((item) => item.entryId === null)) {
        // If an item with entryId === null exists, update it
        setBariaticSurgeryHistoryList(
          bariaticSurgeryHistoryList.map((item) =>
            item.entryId === null ? data : item
          )
        );
        console.log("bariaticSurgeryHistoryList ==1", bariaticSurgeryHistoryList);
      } else {
        // If no item with entryId === null exists, add data to the list
        setBariaticSurgeryHistoryList([...bariaticSurgeryHistoryList, data]);
        console.log("bariaticSurgeryHistoryList ==", bariaticSurgeryHistoryList);
      }
      // setIsNewBariatricSurgery(true);
    } else {
      const updatedData = bariaticSurgeryHistoryList.map((item) =>
        item.entryId === data.entryId ? data : item
      );
      setBariaticSurgeryHistoryList(updatedData);
      console.log("updatedData ==", updatedData);
      // setIsNewBariatricSurgery(false);
    }

    setNewBariatricSurgeryData(data);
    setOpen(false);
  };

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
  };

  const tableCellValues = [
    "ลำดับ",
    "Visit Date",
    "วันที่ผ่าตัด",
    "ชนิดการผ่าตัด",
    "Op-Time",
    "EBL",
    "Subsequent Operation",
    "สถานะ",
    "เครื่องมือ",
  ];
  const subsequentOperationLabel = {
    laparoscopicCholecystectomy: "Laparoscopic Cholecystectomy",
    laparoscopicHiatalHerniaRepair: "Laparoscopic Hiatal Hernia Repair",
    other: "Other",
  };

  const isVisitDateIsOlderThanCurrentVisitDate = (visitDate: string | null) => {
    console.log("visitDate ==", visitDate);
    console.log("currentVisitDate ==", currentVisitDate);
    if (visitDate && currentVisitDate) {
      const visitDateObj = new Date(visitDate);
      const currentVisitDateObj = new Date(currentVisitDate);
      console.log("visitDateObj <= currentVisitDateObj ==", visitDateObj <= currentVisitDateObj);
      return visitDateObj <= currentVisitDateObj;
    }
    return false;
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const responseBariaticHistoryList =
          await visitDataService.getBariatricSurgeryHistory(patientUserId);
        if (responseBariaticHistoryList) {
          const filteredBariaticSurgeryHistoryList =
            responseBariaticHistoryList.filter((item: any) =>
              isVisitDateIsOlderThanCurrentVisitDate(item.visitDate)
            );
          setBariaticSurgeryHistoryList(filteredBariaticSurgeryHistoryList);
        } else {
          setBariaticSurgeryHistoryList([]);
        }
        if(currentEntryId) {
          const response = await visitDataService.getBariatricSurgeryFromEntryId(currentEntryId);
          setNewBariatricSurgeryData(response);
          setStatus(response.visitDataStatus);
          setOriginalStatus(response.visitDataStatus);
        }
      
      } catch (error) {
        setBariaticSurgeryHistoryList([]);
        console.error(error);
      }
    };
    fetchData();
  });

  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.saveBariatricSurgeryData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        surgeryDate: newBariatricSurgeryData?.surgeryDate,
        operationTime: Number(newBariatricSurgeryData?.operationTime),
        acuteWeightLoss: newBariatricSurgeryData?.acuteWeightLoss,
        acuteWeightLossType: newBariatricSurgeryData?.acuteWeightLossType,
        acuteWeightLossDay: Number(newBariatricSurgeryData?.acuteWeightLossDay),
        preAwlWeight: Number(newBariatricSurgeryData?.preAwlWeight),
        postAwlWeight: Number(newBariatricSurgeryData?.postAwlWeight),
        surgicalTechnique: newBariatricSurgeryData?.surgicalTechnique,
        operationType: newBariatricSurgeryData?.operationType,
        operationLocation: newBariatricSurgeryData?.operationLocation,
        operationTypeOtherText: newBariatricSurgeryData?.operationTypeOtherText,
        bougieSize: newBariatricSurgeryData?.bougieSize,
        bpLimb: newBariatricSurgeryData?.bpLimb,
        rouxLimb: newBariatricSurgeryData?.rouxLimb,
        ebl: newBariatricSurgeryData?.ebl,
        subsequentOperation: {
          laparoscopicCholecystectomy: newBariatricSurgeryData
            ?.subsequentOperation.laparoscopicCholecystectomy
            ? true
            : false,
          laparoscopicHiatalHerniaRepair: newBariatricSurgeryData
            ?.subsequentOperation.laparoscopicHiatalHerniaRepair
            ? true
            : false,
          other: newBariatricSurgeryData?.subsequentOperation.other
            ? true
            : false,
        },
        subsequentOperationOther:
          newBariatricSurgeryData?.subsequentOperationOther,
        earlyComplication: newBariatricSurgeryData?.earlyComplication,
        earlyComplicationType: {
          leakage: newBariatricSurgeryData?.earlyComplicationType.leakage,
          surgicalSiteInfection:
            newBariatricSurgeryData?.earlyComplicationType
              .surgicalSiteInfection,
          obstruction:
            newBariatricSurgeryData?.earlyComplicationType.obstruction,
          cardiovascularEvents:
            newBariatricSurgeryData?.earlyComplicationType.cardiovascularEvents,
          bleeding: newBariatricSurgeryData?.earlyComplicationType.bleeding,
          pulmonaryComplication:
            newBariatricSurgeryData?.earlyComplicationType
              .pulmonaryComplication,
          vte: newBariatricSurgeryData?.earlyComplicationType.vte,
          other: newBariatricSurgeryData?.earlyComplicationType.other,
        },
        earlyComplicationTypeOtherText:
          newBariatricSurgeryData?.earlyComplicationTypeOtherText,
        treatment: newBariatricSurgeryData?.treatment,
        reOperation: newBariatricSurgeryData?.reOperation,
      });

      return response as BariaticSurgeryData;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          bariaticSurgeryStatus: data.entryId ?? undefined,
        })
      );
      setCurrentBariatricEntryId(data.entryId);
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleSubmitData = async () => {
    if (patientDataPermissionEditAble === false) {
      setOpenNoPermissionDialog(true);
      setStatus(originalStatus);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    if (
      !!bariaticSurgeryHistoryList.some((item) => item.entryId === null) ||
      !!newBariatricSurgeryData
    ) {
      //To prevent user from submitting data when there is an unsaved data
      await submitDataAsync();
      dispatch(updateTabsIsDirty(false));
    }
  };

  const handleDeleteBaraitricSurgeryData = async () => {
    if (currentBariatricEntryId) {
      try {
        await visitDataService.deleteBariatricSurgeryDataFromEntryId(currentBariatricEntryId);
        setNewBariatricSurgeryData(null); 
        try {
          const bariaticSurgeryHistoryList = await visitDataService.getBariatricSurgeryHistory(patientUserId);
          const filteredList = bariaticSurgeryHistoryList.filter((item: any) =>
            isVisitDateIsOlderThanCurrentVisitDate(item.visitDate)
          );
          setBariaticSurgeryHistoryList(filteredList);     
        } catch (error) {
          if ((error as any).response && (error as any).response.status === 404) {
            setBariaticSurgeryHistoryList([]);
          } else {
            throw error;
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      const updatedBariaticSurgeryHistoryList = bariaticSurgeryHistoryList.filter((item) => item.entryId !== null);
      setBariaticSurgeryHistoryList(updatedBariaticSurgeryHistoryList);
    }
  };

  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box sx={{ p: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => setOpenVisitLogDialog(true)}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Typography style={title10Tab}>Bariatric Surgery</Typography>
          {(bariaticSurgeryHistoryList.length === 0 ||
            !bariaticSurgeryHistoryList.some(
              (item) => item.visitDate === currentVisitDate
            )) &&
            !bariaticSurgeryHistoryList.some(
              (item) => item.entryId === null
            ) && patientDataPermissionEditAble && (
              <Button
                variant="contained"
                className="grant-permission-button"
                style={{
                  backgroundColor: "#FF366B",
                  float: "right",
                  width: "100px",
                  height: "40px",
                  fontSize: "14px",
                  marginBottom: "20px",
                }}
                startIcon={
                  <AddIcon style={{ color: "white", padding: "0.5px" }} />
                }
                onClick={() => handleClickOpen(null)}
              >
                เพิ่ม
              </Button>
            )}
          <BariatricSurgeryDialog
            open={open}
            onClose={handleClose}
            onSave={handleSave}
            currentEntryId={currentEntryId}
            selectedEntryId={selectedEntryId}
            newBariatricSurgeryData={newBariatricSurgeryData}
          />
          <BariatricSurgeryDialogViewMode
            openViewMode={openViewMode}
            onClose={handleCloseViewMode}
            currentEntryId={currentEntryId}
            selectedEntryId={selectedEntryId}
            viewBariatricSurgeryData={viewBariatricSurgeryData}
          />
          <TableContainer component={Paper}>
            <Table style={{ border: "1px solid #E5EBEB" }}>
              <TableHead>
                <TableRow style={{ borderBottom: "1px solid #E5EBEB" }}>
                  {tableCellValues.map((value) => (
                    <TableCell style={{ fontSize: "16px" }}>{value}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {bariaticSurgeryHistoryList?.map(
                  (bariaticSurgeryHistory, index) => (
                    <TableRow key={bariaticSurgeryHistory.entryId}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {bariaticSurgeryHistory.visitDate
                          ? new Date(
                              bariaticSurgeryHistory.visitDate
                            ).toLocaleDateString("th-TH")
                          : currentVisitDate
                          ? new Date(currentVisitDate).toLocaleDateString(
                              "th-TH"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {new Date(
                          bariaticSurgeryHistory.surgeryDate
                        ).toLocaleDateString("th-TH")}
                      </TableCell>
                      <TableCell>
                        {bariaticSurgeryHistory.operationType}
                      </TableCell>
                      <TableCell>
                        {bariaticSurgeryHistory.operationTime}
                      </TableCell>
                      <TableCell>{bariaticSurgeryHistory.ebl}</TableCell>
                      <TableCell>
                        {Object.keys(bariaticSurgeryHistory.subsequentOperation)
                          .filter(
                            (key) =>
                              bariaticSurgeryHistory.subsequentOperation[
                                key as keyof typeof bariaticSurgeryHistory.subsequentOperation
                              ] === true
                          )
                          .map(
                            (key) =>
                              subsequentOperationLabel[
                                key as keyof typeof subsequentOperationLabel
                              ]
                          )
                          .join(", ")}
                      </TableCell>
                      <TableCell>
                        {bariaticSurgeryHistory.visitDataStatus === 1
                          ? "Unverified"
                          : bariaticSurgeryHistory.visitDataStatus === 2
                          ? "Complete"
                          : "System Default"}
                      </TableCell>
                      {(currentVisitDate === bariaticSurgeryHistory.visitDate ||
                      bariaticSurgeryHistory.entryId === null) && patientDataPermissionEditAble ? (
                        <TableCell style={{ padding: "8px" }}>
                          <img
                            src={editIcon}
                            alt="editIcon"
                            style={{
                              width: "25px",
                              height: "25px",
                              paddingRight: "10px",
                            }}
                            onClick={() =>
                              handleClickOpen(bariaticSurgeryHistory.entryId)
                            }
                          />
                          <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            style={{ width: "25px", height: "25px" }}
                            onClick={() =>
                              handleOpenConfirmationDialog(
                                bariaticSurgeryHistory.entryId
                              )
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell style={{ padding: "8px" }}>
                          <img
                            src={viewMode}
                            alt="viewMode"
                            style={{ width: "25px", height: "25px" }}
                            onClick={() =>
                              handleClickOpenViewMode(
                                bariaticSurgeryHistory.entryId
                              )
                            }
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
            {bariaticSurgeryHistoryList.length === 0 && (
              <Box
                sx={{
                  justifyContent: "center",
                  fontSize: 14,
                  color: "#9AA1AE",
                  py: 6,
                }}
                marginTop={1}
              >
                <div style={{ textAlign: "center" }}>-- ไม่พบข้อมูล --</div>
              </Box>
            )}
          </TableContainer>
        </Box>
        <BariatricSurgeryHistoryDialog
          open={openHistoryDialog}
          handleCloseDialog={handleCloseDialog}
        />
        <BariatricSurgeryVisitLogDialog
          open={openVisitLogDialog}
          handleCloseDialog={handleCloseDialog}
        />
        {/* <ThemeProvider theme={theme}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Pagination
              count={10}
              variant="outlined"
              shape="rounded"
              style={{ padding: "20px" }}
            />
          </div>
        </ThemeProvider> */}
        <StatusBottomBar
          value={status}
          handleStatusChange={handleStatusChange}
        />
        <WarningDialog
          openDialog={isShowConfrimationDialog}
          title="ยืนยันการลบข้อมูล"
          content="คุณต้องการลบข้อมูลใช่หรือไม่"
          handleCancelClick={handleCloseConfirmationDialog}
          handleCloseDialog={handleCloseConfirmationDialog}
          handleConfirmClick={handleDelete}
        />
        <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
      </Box>
    </LayoutTab>
  );
};
